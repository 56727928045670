import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menuButton: {
    height: 64,
    width: 64
  },
  appBar: {
    top: 0,
    maxHeight: 107,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fefefe'
  },
  offset: theme.mixins.toolbar,
  offset1: {
    minHeight: 64
  },
  divider: {
    width: '100%'
  },
  circularProgress: {
    marginTop: '1.5rem',
    alignSelf: 'center'
  },
  title:{
    fontSize: '1.6rem',
    marginTop: '20px',
    marginBottom: '20px',
    alignSelf: 'center'
  },
}));
