import React, { useState, useEffect, useContext } from "react";

import _ from 'lodash';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { IProduto } from '../../../../../interfaces/produto';
import { IProdutoGradeCarrinho } from '../../../../../interfaces/produtoGradeCarrinho';

import { Informations, Quantidade} from './styles';
import stylesMaterial from './stylesMaterial';
import { ThemeContext } from "styled-components";

interface PizzaComponentProps{
  pizza: IProduto;
  setPizzaComponent: (cartPizzaComponent: IProdutoGradeCarrinho) => void;
  base: boolean;
  full: boolean;
  numSelectedFlavors: number;
}

const PizzaComponent: React.FC<PizzaComponentProps> = ({
                                                          pizza,
                                                          setPizzaComponent,
                                                          base,
                                                          full,
                                                          numSelectedFlavors
                                                        }) => {
  const classes = stylesMaterial();
  const [complementMin, setComplementMin] = useState(false);
  const [quantidade, setQuantidade] = useState(base ? 1 : 0);

  const { primary } = useContext(ThemeContext);

  useEffect(() => {
    setPizzaComponent({produto: pizza, quantidade: quantidade});
    setComplementMin(!(quantidade>(base ? 1 : 0)));
  }, [quantidade]);

  const handleAdition = () => {
    setQuantidade(_.toNumber(quantidade)+1);
  }
  const handleRemove = () => {
    if(quantidade>(base ? 1 : 0))
      setQuantidade(_.toNumber(quantidade)-1);
  }

  return (
    <ListItem
      divider
      className={classes.listItem}
    >
      <Informations>
        <Typography
          variant='body1'
          className={classes.productTitle}
        >
          {pizza.descricao}
        </Typography>

        {
          pizza.produto_grades[0].estoque &&
          pizza.produto_grades[0].estoque.ativo &&
          pizza.produto_grades[0].estoque.estoque_atual == 0 ? (
            <Typography className={classes.priceText}>
              Sem estoque
            </Typography>
          ) : (
            <section>
              <Typography
                variant='h6'
                className={classes.priceText}
              >
                R$ {Number(
                  pizza.produto_grades[0].promocao?.ativo
                  ? pizza.produto_grades[0].preco.valor-pizza.produto_grades[0].promocao.preco
                  : pizza.produto_grades[0].preco.valor
                ).toFixed(2).replace('.',',')}
              </Typography>

              {
                pizza.produto_grades[0].promocao?.ativo && (
                  <Typography className={classes.oldPrice}>
                    R$ {Number(pizza.produto_grades[0].preco.valor).toFixed(2).replace('.',',')}
                  </Typography>
                )
              }
            </section>
          )
        }
      </Informations>
      <Quantidade>
        <IconButton
          aria-label="remove"
          disabled={complementMin}
          onClick={handleRemove}
        >
          <RemoveIcon
            style={{ color: complementMin ? '#9e9e9e' : primary }}
          />
        </IconButton>
        <label className={classes.quantityLabel}> {quantidade} </label>
        <IconButton
          aria-label="add"
          disabled={
            full ||
            (pizza.produto_grades[0].estoque &&
              pizza.produto_grades[0].estoque.ativo &&
              (quantidade + 1)/(numSelectedFlavors + 1) > pizza.produto_grades[0].estoque.estoque_atual)
          }
          onClick={handleAdition}
        >
          <AddIcon
            style={{
              color: (
                full  ||
                (
                  pizza.produto_grades[0].estoque &&
                  pizza.produto_grades[0].estoque.ativo &&
                  (quantidade + 1)/(numSelectedFlavors + 1) > pizza.produto_grades[0].estoque.estoque_atual
                )
              ) ? '#9e9e9e ' : primary
            }}
          />
        </IconButton>
      </Quantidade>
    </ListItem>
  )
}
export default PizzaComponent;
