import styled from 'styled-components';

interface OptionProps {
  active: boolean;
  isDisabled: boolean;
}

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  min-height: max-content;
  overflow: hidden;
  border-radius: 8px;
  border: solid 1px #c4c4c4;
  margin-top: 10px;
`;

export const Option = styled.div<OptionProps>`
  display: -webkit-flex;
  flex-direction: row;
  min-height: max-content;
  background-color: ${props => props.active ? '#269660' : '#FFF' };
  color: ${props => props.active ? '#FFF' : '#757575' };
  flex: 1;
  cursor: ${props => props.isDisabled ? 'auto' : 'pointer' };
  padding: 8px 30px;
  opacity: ${props => props.isDisabled ? '0.3' : '1' };
`;