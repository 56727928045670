import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  span: {
    width: 40,
    height: 0
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
  },
  title: {
    marginTop: '10px'
  },
  subtitle: {
    color: '#9e9e9e',
    marginTop: '20px',
    marginLeft: '5px',
    alignSelf: 'flex-start'
  },
  titleDivider: {
    width: '100%'
  },
  offset1: {
    minHeight: 90,
    [theme.breakpoints.up(960)]: {
      display: 'none'
    },
  },
  locationButton: {
    marginTop: '60px',
    padding: '10px 15px',
    fontSize: '1rem',
    fontWeight: 500,
    color: '#616161',
    backgroundColor: '#FFF',
    borderRadius: '0.3rem',
    border: '1px solid #e0e0e0',
    boxShadow: '0 0 5px rgba(0, 0, 0, .25)',
    left: '10px !important'
  }
}));
