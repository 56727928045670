import styled from 'styled-components';

export const GridItem = styled.div`
  justify-content: center;
  margin-top: 12px;
`;

export const EnderecoCard = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  justify-content: space-between;

  height: -webkit-fill-available;
  width: 100%;
  
  margin-left: 10px;
  padding-right: 15px;
`;