import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dividersegfilbuslis: {
    height: 29,
    margin: 4,
  },
  seeallitem: {
    textAlign: 'center',
  },
  seeallsegment: {
    alignSelf: 'flex-end',
    margin: '8px 12px 0px 0px',
  },
  linedivider1: {
    margin: '21px 0px 22.5px 0px',
    '@media (max-width: 650px)': {
      margin: '21.5px 0px 17.5px 0px',
    },
  },
  linedivider2: {
    margin: '25.5px 0px 0px 0px',
    '@media (max-width: 650px)': {
      margin: '18.5px 0px 0px 0px',
    },
  },
  appBar: {
    top: 0,
    maxHeight: 104,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fefefe',
  },
  offset: theme.mixins.toolbar,
  offset1: {
    minHeight: 84,
  },
  divider: {
    width: '100%',
  },
  paper: {
    padding: '2px 4px',
    display: '-webkit-flex',
    alignItems: 'center',
    maxWidth: 300,
    border: '1px solid #d4d4d4',
    [theme.breakpoints.down(640)]: {
      width: 200,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  //

  filtertitle: {
    width: '239px',
    height: '24px',
    margin: '30px 33px 26px 31px',
    fontsize: '18px',
    fontweight: '600',
    fontstretch: 'normal',
    fontstyle: 'normal',
    lineheight: 'normal',
    letterspacing: 'normal',
    textalign: 'center',
    color: '#000',
  },
}));
