import styled from "styled-components";
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 40px);

  @media (max-width: 700px){
    flex-direction: column;
    padding-inline: 0.5rem;
    overflow-y: scroll;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;

  @media (max-width: 700px){
    width: 100%;
    height: 70vh;
  }
`;

export const InformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  overflow-y: scroll;
  width: 50%;
  padding-inline: 10px;

  & > div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
  }

  @media (max-width: 700px){
    margin-top: 1rem;
    width: 100%;
    overflow-y: visible;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0px;
  margin-left: 25px;
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center !important;
  margin-top: 10px;
`;
