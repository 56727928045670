import React from 'react';

import {
  Size,
  SizeText
} from './styles';

interface SizeItemProps {
  selected: boolean;
  size: string;
  setSelectedSize: (size: string) => void;
}

export const SizeItem: React.FC<SizeItemProps> = (props) => {
  const { selected, size, setSelectedSize } = props;

  return (
    <Size
      selected={selected}
      onClick={() => setSelectedSize(size)}
    >
      <SizeText selected={selected}>
        {size}
      </SizeText>
    </Size>
  );
}
