import React, { useContext, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { IEmpresa } from "../../interfaces/empresa";

import { GridItem } from './styles';
import stylesMaterial from './stylesMaterial';

interface CompanyAdressProps{
  empresa: IEmpresa;
}
//https://maps.google.com/?q=-2.5084871,-44.25913
const CompanyContactInfo: React.FC<CompanyAdressProps> = ({empresa}) => {
  const classes = stylesMaterial();

  return (
    <GridItem>
      <Card className={classes.container}>
        <CardContent
          className={classes.card}
        >
            <Typography 
              variant="body1"
              className={classes.description}
            >
              {empresa.nome_fantasia}
            </Typography>
            <Typography 
              variant="body1"
              className={classes.infos}
            >
              {empresa.endereco.logradouro}, {empresa.endereco.numero}, {empresa.endereco.bairro}, {empresa.endereco.cep}
            </Typography>
            <Typography 
              variant="body1"
              className={clsx(classes.infos, {[classes.hide]: empresa.endereco.complemento === undefined})}
            >
              {empresa.endereco.complemento}
            </Typography>
            <Typography 
              variant="body1"
              className={classes.infos}
            >
              {empresa.telefone.substr(0,2)} {empresa.telefone.substr(2)}
            </Typography>
            <Typography 
              variant="body1"
              className={classes.infos}
            >
              {empresa.email}
            </Typography>
            <Typography 
              variant="body1"
              className={classes.infos}
            >
              {empresa.endereco.cidade}, {empresa.endereco.estado}
            </Typography>
        </CardContent>

        {empresa.lat && empresa.lon && (
          <a 
            href={`https://maps.google.com/?q=${empresa.lat},${empresa.lon}`} 
            target="_blank"
            className={classes.locationLink}
          >
            <LocationOnIcon 
              fontSize="large"
              color="secondary"
              className={classes.locationIcon} 
            />
          </a>
        )}
      </Card>
    </GridItem>
  )
}

export default CompanyContactInfo;