import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  clotheTitle: {
    fontSize: '24px',
    lineHeight: '25px',
    fontWeight: 500,
  },
  description: {
    overflowWrap: 'break-word',
  },
  price: {
    fontSize: '18px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  oldPrice: {
    fontSize: '16px',
    color: '#757575',
    fontWeight: 300,
    textDecoration: 'line-through',
    whiteSpace: 'nowrap',
  },
  discount: {
    fontSize: '16px',
    color: '#ae414d',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  shipping: {
    fontSize: '1rem',
    color: '#0c65ea',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  divider: {
    marginTop: '10px',
  },
  cores: {
    fontSize: '18px',
    color: '#000',
    fontWeight: 400,
  },
  quantity: {
    fontSize: '18px',
    color: '#000',
    fontWeight: 400,
  },
  consultShipping: {
    fontSize: '1rem',
    marginTop: '15px',
  },
  button: {
    marginTop: '20px',
    width: '80%',
    height: '46px',
    alignSelf: 'center',
  },
}));
