import React, { useState, useEffect, useContext, useCallback } from 'react';

import clsx from 'clsx';
import _ from 'lodash';
import { useParams, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';

import Check from '@material-ui/icons/Check';

import { CustomAppBar } from '../../new-components/custom-appbar';
import { Identificacao } from './Identificacao';
import { Container, Body, StepContent, Price, PricesValues } from './styles';

import { Context } from '../../hooks/cartStore';
import { AuthContext } from '../../hooks/provideAuth';
import { IEmpresa } from '../../interfaces/empresa';
import { IItemCarrinho } from '../../interfaces/itemCarrinho';
import RequiredFieldsModal from "../../components/required-fields-modal";

import empresaApi from '../../services/empresaApi';
import userApi from '../../services/userApi';
import { Entrega } from './Entrega';
import { Pagamento } from './Pagamento';
import pedidoApi, { Shipping } from '../../services/pedidoApi';
import { Footer } from '../../components/footer';
import { useTheme } from '../../hooks/useThemeState';
import { DefaultTheme } from 'styled-components';
import { lighten } from 'polished';
import { calcOrderPrice } from '../../utils/calculator';
import { checkStoreOpen } from '../../utils/checkStoreOpen';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: 20,
    width: 150,
    height: 40
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#c4c4c4'
  },
  closed: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#f33',
    textAlign: 'center'
  },
  subtotal:{
    fontSize: '16px',
    fontWeight: 300,
    whiteSpace: 'nowrap'
  },
  total:{
    fontSize: '16px',
    fontWeight: 600
  },
  dividerStep:{
    width: '100%',
    backgroundColor: '#c4c4c4',
    [theme.breakpoints.up(760)]: {
      display: 'none'
    }
  },
}),
);

function getSteps() {
  return ['Identificação', 'Entrega', 'Pagamento'];
}

export const SaleNew: React.FC = () => {
  const classes = useStyles();

  const { companieId } = useParams<{ companieId: string }>();
  const { state, dispatch } = useContext(Context);
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  let history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [companie, setCompanie] = useState<IEmpresa>();
  const [underMinimum, setUnderMinimum] = useState(false);
  const [deliveryTax, setDeliveryTax] = useState<number>(0);
  const [cart, setCart] = useState<{id: number, cart: IItemCarrinho[]} | null>(null);
  const [subtotal, setSubtotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openRequiredFields, setOpenRequiredFields] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(-1);
  const [valueRadioGroupRM, setValueRadioGroupRM] = useState("retirada");
  const [valueRadioGroupPM, setValueRadioGroupPM] = useState(-1);
  const [inDeliveryRange, setInDeliveryRange] = useState(true);
  const [selectedCompanie, setSelectedCompanie] = useState<number>(-1);
  const [changeMoney, setChangeMoney] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState<{cnpjCpf: string, razaoSocial: string, fone: string, email: string}>();
  const [requiredFields, setRequiredFields] = useState<string[]>();
  const [scheduledDelivery, setSchedeledDelivery] = useState<Date | null>(null);
  const [desconto, setDesconto] = useState(0);
  const [cupom, setCupom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isClothesStore, setIsClothesStore] = useState(false);
  const [withdrawOptions, setWithdrawOptions] = useState('default');
  const [selectedShipping, setSelectedShipping] = useState<Shipping | null>(null);
  const [promotionDelivery, setPromotionDelivery] = useState(0);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [hasTaxByNeighborhoods, setHasTaxByNeighborhoods] = useState(false);

  const { theme, setTheme } = useTheme()

  const steps = getSteps();
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    empresaApi.getEmpresa(companieId).then((res) =>{
      setCompanie(res);
      if((subtotal<res.valor_minimo_pedido)&&(valueRadioGroupRM==='delivery')){
        setUnderMinimum(true);
      }
    }).catch((error) => {
      empresaApi.getEmpresaSlug(companieId).then((res) =>{
        setCompanie(res);
        if((subtotal<res.valor_minimo_pedido)&&(valueRadioGroupRM==='delivery')){
          setUnderMinimum(true);
        }
      }).catch((error) => {
        // console.log("Erro no slug");
        // console.log(error);
      })
    });
  }, [companieId]);

  useEffect(() => {
    if (companie) {
      empresaApi
      .getParametro(`${companie.id}`, 'customizations')
      .then(res => {
        if (!!res) {
          const role: DefaultTheme = JSON.parse(res.role)
          if (role.title === 'custom') {
            setTheme({
              title: role.title,
              primary: role.primary,
              secondary: role.secondary
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
    
    if (companie) {
      empresaApi
        .getParametro(companie.id.toString(), 'view_mode')
        .then((res) => {
          const role = JSON.parse(res.role);
          if (role) {
            if (role.value === 'store') {
              setIsClothesStore(true);
            }
          }
          setIsLoading(false);
        }).catch((err) => {
          setIsClothesStore(false);
        });

      setIsOpen(checkStoreOpen(companie));
    }
  }, [companie]);

  useEffect(() => {
    if (companie && activeStep !== 0) {
      empresaApi
        .getParametro(companie.id.toString(), 'taxa_bairro')
        .then((res) => {
          const role = JSON.parse(res.role);
          const warned = localStorage.getItem('warn-taxa-bairro');

          setHasTaxByNeighborhoods(!!role.status);

          if (role.status && !warned) {
            handleOpenWarnDialog()

            localStorage.setItem('warn-taxa-bairro', "warned");
          }
        }).catch((err) => {
          setIsClothesStore(false);
        });
    }
  }, [companie, activeStep])

  useEffect(() => {
    const tempPrice = Number(deliveryTax)+subtotal-desconto-promotionDelivery;
    setTotalPrice((tempPrice >= 0 ) ? tempPrice : 0);

    if (companie?.valor_minimo_pedido !== undefined){
      if((subtotal<companie.valor_minimo_pedido)&&(valueRadioGroupRM==='delivery')){
        setUnderMinimum(true);
      } else{
        setUnderMinimum(false);
      }
    }
  }, [deliveryTax, subtotal, desconto, promotionDelivery]);

  useEffect(() => {
    if((authState.token !== "") || (authState.tokenLogin !== "")){
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [authState]);

  useEffect(() => {
    setCart(state.carts.find((cart) => _.toString(cart.id) === companie?.id.toString())||
                            {id: 0, cart: new Array<IItemCarrinho>()});
  }, [state.carts, companie]);

  useEffect(() => {
    handlePriceUpdate();
  }, [cart, state.carts]);

  useEffect(() => {
    if(valueRadioGroupRM==='retirada'){
      setUnderMinimum(false);
      setPromotionDelivery(0);
      
    } else if(valueRadioGroupRM==='delivery'){
      if (companie?.valor_minimo_pedido !== undefined){
        if(subtotal<companie.valor_minimo_pedido){
          setUnderMinimum(true);
        } else{
          setUnderMinimum(false);
        }
      }
    }
  }, [valueRadioGroupRM]);

  useEffect(() => {
    if(selectedShipping){
      setDeliveryTax(Number(selectedShipping.custom_price));
    } else {
      setDeliveryTax(0);
    }
  }, [selectedShipping]);

  useEffect(() => {
    setSelectedShipping(null);
  }, [selectedAddress]);

  const handleOpenWarnDialog = useCallback(() => {
    setOpenWarnDialog(true);
  }, []);

  const handleCloseWarnDialog = useCallback(() => {
    setOpenWarnDialog(false);
  }, []);

  const handlePriceUpdate = () => {
    if (cart && cart.cart.length){
      setSubtotal(cart.cart.map((item) => calcOrderPrice(item))
                                .reduce((total,next) => total+next)
                  );
    } else{
      setSubtotal(0);
    }
  }

  const handleGoBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleNext = () => {
    if(companie !== undefined){
      empresaApi.getRequiredFields(companie.id).then((res1) => {
        if(authState.token){
          userApi.getUserInfo(authState.token).then((res2) => {
            const {cnpjCpf, fone, email} = res2;
            let rF: string[] = [];

            if (res1.fields.includes("cpf")){
              if(cnpjCpf === "" || cnpjCpf === null){
                rF.push("cpf");
              }
            }
            if (res1.fields.includes("email")){
              if(email === "" || email === null){
                rF.push("email");
              }
            }
            if (res1.fields.includes("telefone")){
              if(fone === "" || fone === null){
                rF.push("telefone");
              }
            }

            if(rF.length === 0) {
              setActiveStep((prevActiveStep) => {
                if (prevActiveStep < 2) {
                  return prevActiveStep + 1;
                } else {
                  return prevActiveStep;
                }
              });
            } else{
              setUserData(res2);
              setRequiredFields(rF);
              handleOpenRequiredFields();
            }
            // console.log(res2);
          }).catch((error) => {
            console.log("Erro nas infos");
            console.log(error);
          });
        } else {
          userApi.getUserInfoLoginByToken(authState.tokenLogin).then((res2) => {
            const {cpf, fone, email} = res2;
            let rF: string[] = [];

            if (res1.fields.includes("cpf")){
              if(cpf === "" || cpf === null){
                rF.push("cpf");
              }
            }
            if (res1.fields.includes("email")){
              if(email === "" || email === null){
                rF.push("email");
              }
            }
            if (res1.fields.includes("telefone")){
              if(fone === "" || fone === null){
                rF.push("telefone");
              }
            }

            if(rF.length === 0){
              setActiveStep((prevActiveStep) => {
                if (prevActiveStep < 2) {
                  return prevActiveStep + 1;
                } else {
                  return prevActiveStep;
                }
              });
            } else{
              toast.warning("Você precisar adicionar dados no cadastro, entre pelo menu lateral para isso", toast_config)
            }
            // console.log(res2);
          }).catch((error) => {
            console.log("Erro nas infos");
            console.log(error);
          });
        }

      }).catch((error) => {
        console.log("Erro nos fields");
        console.log(error);
      })
    }
  }

  const isActiveButtonNext = () => {
    if (!companie?.aberto) {
      return false
    } else if (isOpen || !!scheduledDelivery) {
      return true
    }
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Identificacao empresaId={companie ? companie.id : -1} />
        );
      case 1:
        return (
          <Entrega
            handleNext={handleNext}
            handleBack={handleGoBack}
            selectAddress={handleSelectedAddress}
            selectedAddress={selectedAddress}
            valueRM={valueRadioGroupRM}
            selectRM={handleSelectRM}
            handleDeliveryTax={handleDeliveryTax}
            selectedCompany={selectedCompanie}
            setSelectedCompanie={handleSelectedCompanie}
            setScheduledDelivery={handleScheduledDelivery}
            underMinimum={underMinimum}
            inDeliveryRange={inDeliveryRange}
            handleInDeliveryRange={handleInDeliveryRange}
            closed={!isActiveButtonNext()}
            companieId={companie ? companie.id : -1}
            deliveryTax={deliveryTax}
            scheduledDelivery={scheduledDelivery}
            isClothesStore={isClothesStore}
            withdrawOptions={withdrawOptions}
            setWithdrawOptions={setWithdrawOptions}
            selectedShipping={selectedShipping}
            setSelectedShipping={setSelectedShipping}
            cart={cart ? cart.cart : []}
            setPromotionDelivery={setPromotionDelivery}
            schedule={companie?.horario}
            timezone={companie?.time_zone}
            hasTaxByNeighborhoods={hasTaxByNeighborhoods}
          />
        );
      case 2:
        return (
          <Pagamento
            handleBack={handleGoBack}
            valuePM={valueRadioGroupPM}
            selectPM={handleSelectPM}
            handleConclusion={handleConclusion}
            isLoading={isLoading}
            selectedCompanie={selectedCompanie}
            changeMoney={changeMoney}
            handleChangeMoney={handleChangeMoney}
            handleDesconto={handleDesconto}
            cupom={cupom}
            handleCupom={handleCupom}
            token={authState.token}
            login={authState.tokenLogin}
            subtotal={subtotal}
            companieId={companie?.id.toString()}
          />
        );
      default:
        return <div>4</div>;
    }
  }

  const handleRegisterSucess = () => {
    toast.success('Dados salvos com sucesso!', toast_config);
  }

  const handleOpenRequiredFields = () => {
    setOpenRequiredFields(true);
  }

  const handleCloseRequiredFields = () => {
    setOpenRequiredFields(false);
  }

  const handleDeliveryTax = (tax: number) => {
    setDeliveryTax(tax);
  }

  const handleSelectedCompanie = (selected: number) => {
    setSelectedCompanie(selected);
  }

  const handleInDeliveryRange = (is: boolean) => {
    setInDeliveryRange(is);
  }

  const handleScheduledDelivery = (data: Date | null) => {
    setSchedeledDelivery(data);
  }

  const handleSelectedAddress = (id: number) => {
    setSelectedAddress(id);
  }

  const handleSelectRM = (RM: string) => {
    setValueRadioGroupRM(RM);
  }

  const handleChangeMoney = (value: string) => {
    setChangeMoney(value);
  }

  const handleDesconto = (value: number) => {
    setDesconto(value);
  }

  const handleCupom = (value: string) => {
    setCupom(value);
  }

  const handleSelectPM = (PM: number) => {
    setValueRadioGroupPM(PM);
  }

  const handleConclusion = () => {
    setIsLoading(true);
    let tempCM = changeMoney;
    tempCM.replace(",", ".");
    let CM = _.toNumber(tempCM) - totalPrice;
    const storeCart = state.carts.find((cart) => _.toString(cart.id) === companie?.id.toString());

    if(authState.token){
      pedidoApi.pedido(
        storeCart?.cart || new Array<IItemCarrinho>(),
        valueRadioGroupRM,
        valueRadioGroupPM,
        selectedAddress,
        _.toNumber(companie?.id.toString()),
        (withdrawOptions === 'onSite' ? 'Consumo no local' : '')+
        (storeCart?.observacao && withdrawOptions === 'onSite' ? ' - ' : '')+
        (storeCart?.observacao ? storeCart.observacao : ''),
        selectedCompanie,
        (CM>=0)?CM:0,
        // scheduledDelivery ? scheduledDelivery.toLocaleString('pt-BR', { timeZone: companie?.time_zone }) : '',
        scheduledDelivery,
        cupom,
        selectedShipping,
        authState.token,
      ).then((res) => {
        dispatch({type: 'SET_CART', empresaId: _.toNumber(companie?.id.toString()), payload: []});
        dispatch({type: 'SET_OBSERVACAO', empresaId: _.toNumber(companie?.id.toString()), payload: ""});
        setDeliveryTax(0);
        history.push(`/${companieId}/${res.id}/salefinished`)
      }).catch((error) => {
        setIsLoading(false);
        if(
          error.response.data.message === "product-out-of-stock"
          ||
          error.response.data.message === "sub-product-out-of-stock"
        ) {
          toast.error('Existem produtos fora de estoque no carrinho', toast_config);
        } else if(error.response.data.message === "invalid-operation!") {
          toast.error('O método de envio selecionado não está disponível no momento', toast_config);
        } else{
          toast.error('Erro na conclusão da compra. Verifique seus dados ou tente novamente mais tarde', toast_config);
        }
      });
    } else {
      pedidoApi.pedidoWithoutToken(storeCart?.cart||new Array<IItemCarrinho>(),
                      valueRadioGroupRM,
                      valueRadioGroupPM,
                      selectedAddress,
                      _.toNumber(companie?.id.toString()),
                      (withdrawOptions === 'onSite' ? 'Consumo no local' : '')+
                      (storeCart?.observacao && withdrawOptions === 'onSite' ? ' - ' : '')+
                      (storeCart?.observacao ? storeCart.observacao : ''),
                      selectedCompanie,
                      (CM>=0)?CM:0,
                      // scheduledDelivery ? scheduledDelivery.toLocaleString('pt-BR', { timeZone: companie?.time_zone }) : '',
                      scheduledDelivery,
                      cupom,
                      authState.tokenLogin)
                        .then((res) => {
                          dispatch({type: 'SET_CART', empresaId: _.toNumber(companie?.id.toString()), payload: []});
                          dispatch({type: 'SET_OBSERVACAO', empresaId: _.toNumber(companie?.id.toString()), payload: ""});
                          setDeliveryTax(0);
                          history.push(`/${companieId}/${res.id}/salefinished`)
                        })
                        .catch((error) => {
                          setIsLoading(false);
                          if(
                            error.response.data.message === "product-out-of-stock"
                            ||
                            error.response.data.message === "sub-product-out-of-stock"
                          ){
                            toast.error('Existem produtos fora de estoque no carrinho', toast_config);
                          } else if(error.response.data.message === "invalid-operation!") {
                            toast.error('O método de envio selecionado não está disponível no momento', toast_config);
                          }  else{
                            toast.error('Erro na conclusão da compra. Verifique seus dados ou tente novamente mais tarde', toast_config);
                          }
                          // if (error.response){

                          //   console.log("response");
                          //   console.log(error.response);

                          // }else if(error.request){

                          //   console.log("request");
                          //   console.log(error.request);

                          // }else if(error.message){

                          //   console.log("message")
                          //   console.log(error.message);

                          // }
                        }
                      )
    }
  }


  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: lighten(0.5, theme.primary),
      zIndex: 1,
      color: '#fff',
      width: 20,
      height: 20,
      display: '-webkit-flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundColor: theme.primary,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundColor: theme.primary,
    },
    });

    function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : <div />}
      </div>
    );
  }

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 10,
    },
    active: {
      '& $line': {
        backgroundColor: theme.primary,
      },
    },
    completed: {
      '& $line': {
        backgroundColor: theme.primary,
      },
    },
    line: {
      height: 1,
      border: 0,
      backgroundColor: lighten(0.5, theme.primary),
      borderRadius: 1,
    },
  })(StepConnector);

  return (
    <Container>
      <CustomAppBar />
      <Body>
        <div className={classes.root}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider  className={classes.divider} />
        <StepContent>
          {
            getStepContent(activeStep)
          }
          {
            (activeStep !== 0) && (
              <>
                <Divider className={classes.dividerStep} />

                <Price>
                  {underMinimum && (
                    <Typography
                      variant='body2'
                      className={classes.closed}
                    >
                      Compra minima R$ {companie?.valor_minimo_pedido}!
                    </Typography>
                  )}
                  {!isOpen && (
                    <Typography
                      variant='body2'
                      className={classes.closed}
                    >
                      A loja está fechada!
                    </Typography>
                  )}
                  <PricesValues>
                    <Typography
                      variant='body2'
                      className={classes.subtotal}
                    >
                      Subtotal
                    </Typography>
                    <Typography
                      variant='body2'
                      className={classes.subtotal}
                    >
                      R$ {Number(subtotal).toFixed(2).replace('.',',')}
                    </Typography>
                  </PricesValues>
                  <PricesValues>
                    <Typography
                      variant='body2'
                      className={classes.subtotal}
                    >
                      {selectedShipping ? `Frete ${selectedShipping.name}` : 'Taxa de entrega'}
                    </Typography>
                    <Typography
                      variant='body2'
                      className={classes.subtotal}
                    >
                      R$ {Number(deliveryTax).toFixed(2).replace('.',',')}
                    </Typography>
                  </PricesValues>
                  {
                    (promotionDelivery > 0) &&(
                      <PricesValues>
                        <Typography
                          variant='body2'
                          className={classes.subtotal}
                        >
                          Desconto na taxa de entrega
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.subtotal}
                        >
                          - R$ {Number(promotionDelivery).toFixed(2).replace('.',',')}
                        </Typography>
                      </PricesValues>
                    )
                  }
                  {
                    (desconto > 0) &&(
                      <PricesValues>
                        <Typography
                          variant='body2'
                          className={classes.subtotal}
                        >
                          Desconto
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.subtotal}
                        >
                          - R$ {Number(desconto).toFixed(2).replace('.',',')}
                        </Typography>
                      </PricesValues>
                    )
                  }
                  <PricesValues>
                    <Typography
                      variant='body1'
                      className={classes.total}
                    >
                      Total
                    </Typography>
                    <Typography
                      variant='body1'
                      className={classes.total}
                    >
                      R$ {Number(totalPrice).toFixed(2).replace('.',',')}
                    </Typography>
                  </PricesValues>
                </Price>
              </>
            )
          }
        </StepContent>
      </Body>
      {
        (
          openRequiredFields
          &&
          (userData !== undefined)
          &&
          (requiredFields !== undefined)
          &&
          (companie !== undefined)
        ) && (
          <RequiredFieldsModal
            handleCloseRequiredFieldsModal={handleCloseRequiredFields}
            openRequiredFieldsModal={openRequiredFields}
            registerSucess={handleRegisterSucess}
            companieId={companie.id}
            token={authState.token}
            userData={userData}
            requiredFields={requiredFields}
          />
        )
      }
      {openWarnDialog && (
        <Dialog
          open={openWarnDialog}
          onClose={handleCloseWarnDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            Atenção
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Caso encontre dificuldade para usar o seu endereço, tente excluí-lo 
              e cadastrá-lo novamente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWarnDialog} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div style={{ height: '20px', width: '100%' }} ></div>
      <Footer />
    </Container>
  );
}
