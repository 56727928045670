import { IItemCarrinho } from '../interfaces/itemCarrinho';

export const calcOrderPrice = (item: IItemCarrinho): number => {
  let baseProductsPrice = 0,
    sumComponentsPrices = 0,
    total = 0;

  if (item.produtosGrade.length || item.produtoGrade) {
    if (item.prodComposto.produto.controla_grade === 2) {
      baseProductsPrice =
        item.produtosGrade
          .map((pizza) =>
            pizza.produto.produto_grades[0].promocao &&
            pizza.produto.produto_grades[0].promocao.ativo
              ? pizza.produto.produto_grades[0].preco.valor * pizza.quantidade -
                pizza.produto.produto_grades[0].promocao.preco *
                  pizza.quantidade
              : pizza.produto.produto_grades[0].preco.valor * pizza.quantidade,
          )
          .reduce((total, next) => total + next) * item.quantidade;
    } else if (item.produtoGrade) {
      baseProductsPrice =
        item.produtoGrade.promocao && item.produtoGrade.promocao.ativo
          ? item.produtoGrade.preco.valor * item.quantidade -
            item.produtoGrade.promocao.preco * item.quantidade
          : item.produtoGrade.preco.valor * item.quantidade;
    }
  } else {
    baseProductsPrice =
      item.prodComposto.produto.promocao &&
      item.prodComposto.produto.promocao.ativo
        ? item.prodComposto.produto.preco.valor * item.quantidade -
          item.prodComposto.produto.promocao.preco * item.quantidade
        : item.prodComposto.produto.preco.valor * item.quantidade;
  }

  if (item.prodComposto.complementos.length) {
    sumComponentsPrices = item.prodComposto.complementos
      .map(
        (complemento) =>
          complemento.complemento.preco.valor *
          complemento.quantidade *
          item.quantidade,
      )
      .reduce((total, next) => total + next);
  }

  total = baseProductsPrice + sumComponentsPrices;

  if (
    item.prodComposto.produto.fracionado &&
    item.prodComposto.produto.fracao
  ) {
    total =
    (Math.ceil(total * item.prodComposto.produto.fracao * 100) / 100);
  }

  return Number(total.toFixed(2));
};
