import _ from 'lodash';

import { IRedoCartProduct } from '../interfaces/redoCartProduct';
import { IItemCarrinho } from '../interfaces/itemCarrinho';
import { IComplementoCarrinho } from "../interfaces/complementoCarrinho";

import produtosApi from '../services/produtosApi';
import { IProdutoGradeCarrinho } from '../interfaces/produtoGradeCarrinho';
import { IProduto } from '../interfaces/produto';
import { IItemComplemento } from '../interfaces/itemComplemento';

export async function RedoCart(products: IRedoCartProduct[]) {
  const ids = new Array<number>();
  const idsComplementos = new Array<number>();
  const idsProdutosGrade = new Array<number>();

  products.map((item) => {
    if (!ids.includes(item.product)) {
      ids.push(item.product);
    }
    item.complements.map((item) => {
      if (!idsComplementos.includes(item.complement)) {
        idsComplementos.push(item.complement);
      }
    });
    item.produtosGrade.map((produto) => {
      if (!idsProdutosGrade.includes(produto.produtoGrade)) {
        idsProdutosGrade.push(produto.produtoGrade);
      }
    });

    if (item.produtoGrade && !idsProdutosGrade.includes(item.produtoGrade)) {
      idsProdutosGrade.push(item.produtoGrade);
    }
  });

  const produtos: IProduto[] =
    ids.length > 0 ? await produtosApi.getProdutoByIds(ids) : [];
  const itensComplemento: IItemComplemento[] =
    idsComplementos.length > 0
      ? await produtosApi.getItemComplementoByIds(idsComplementos)
      : [];
  const produtosGrade: IProduto[] =
    idsProdutosGrade.length > 0
      ? await produtosApi.getProdutosGradeByIds(idsProdutosGrade)
      : [];

  let newCart = new Array<IItemCarrinho>();

  products.map((product, index) => {
    const complementos = new Array<IComplementoCarrinho>();
    const produtosG = new Array<IProdutoGradeCarrinho>();

    let produto = produtos.find((prod) => prod.id === product.product);

    if (produto) {
      product.complements.map((item) => {
        let complemento = itensComplemento.find(
          (itemComplemento) => itemComplemento.id === item.complement,
        );

        if (complemento) {
          complementos.push({
            complemento: complemento,
            quantidade: item.quantidade,
          });
        }
      });

      product.produtosGrade.map((prodGrade) => {
        let prodGradeTemp = produtosGrade.find(
          (produto) =>
            !!produto.produto_grades.find(
              (pg) => pg.id === prodGrade.produtoGrade,
            ),
        );

        if (prodGradeTemp) {
          let gradeTemp = prodGradeTemp.produto_grades.find(
            (pg) => pg.id === prodGrade.produtoGrade,
          );
          if (gradeTemp) {
            produtosG.push({
              produto: { ...prodGradeTemp, produto_grades: [gradeTemp] },
              quantidade: _.toNumber(prodGrade.quantidade),
            });
          }
        }
      });

      const quantidade = produto.fracionado && produto.fracao
        ? Math.floor(product.quantidade / produto.fracao)
        : _.toNumber(product.quantidade)

      const newCartItem: IItemCarrinho = {
        id: index,
        prodComposto: {
          produto: produto,
          complementos: complementos,
        },
        produtosGrade: produtosG,
        tamanho: product.tamanho,
        quantidade: quantidade,
        observacao: product.observacao !== undefined ? product.observacao : '',
      };

      if (product.produtoGrade) {
        const gradeProduct = newCartItem.prodComposto.produto.produto_grades.find((productG) => productG.id === product.produtoGrade);

        if (gradeProduct) {
          newCartItem.produtoGrade = gradeProduct;
        }
      }

      newCart.push(newCartItem);
    }
  });

  return newCart;
}