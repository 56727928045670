import React, { useContext, useState } from "react";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';

import userApi from '../../services/userApi';
import { AuthContext } from '../../hooks/provideAuth';
import { telefone2 } from '../masks';

import SigninWithToken from "../signin-with-token";

import {
  Container,
  Header,
  Wrapper,
  Body
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../hooks/useThemeState";

interface RequestTokenModalProps{
  handleCloseRequestTokenModal: () => void;
  openRequestTokenModal: boolean;
  handleCloseAll: () => void;
  loginForRequestToken?: string;
  setLoginForRequestToken?: (login: string) => void;
}

interface RequestTokenInfo {
  login: string;
}

const defaultValues = {
  login: ""
};

const RequestTokenModal: React.FC<RequestTokenModalProps> = ({
                                                            handleCloseRequestTokenModal,
                                                            openRequestTokenModal,
                                                            handleCloseAll,
                                                            loginForRequestToken,
                                                            setLoginForRequestToken
                                                          }) => {

  const { register, handleSubmit, errors } = useForm({ defaultValues: {
    login: loginForRequestToken || ""
  } });
  const { theme } = useTheme()
  const { state, dispatch } = useContext(AuthContext);

  const classes = stylesMaterial();

  const [open, setOpen] = useState(openRequestTokenModal);
  const [openSigninWithTokenModal, setOpenSigninWithTokenModal] = useState(false);
  const [userLogin, setUserLogin] = useState("");

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const onSubmit = (data: RequestTokenInfo) => {
    let user = data.login;
    setUserLogin(user);

    if (data.login.match("[a-zA-Z]+") === null ){
      user = data.login.replace(/\D/g, '');
    }

    user = user.toLowerCase();

    userApi.requestToken(user).then((res) => {
      toast.success("Chave de acesso enviada por sms e email com sucesso", toast_config);
      setTimeout(() => {
        handleOpenSigninWithTokenModal();
      }, 2000)
    }).catch((error) => {
      toast.error('Ocorreu um erro no envio da chave de acesso.', toast_config);
    });
  };

  const handleOpenSigninWithTokenModal = () => {
    setOpenSigninWithTokenModal(true);
  }

  const handleCloseSigninWithTokenModal = () => {
    setOpenSigninWithTokenModal(false);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(setLoginForRequestToken){
      setLoginForRequestToken("");
    }
    handleCloseRequestTokenModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography
                variant="h6"
                className={classes.loginTitle}
              >
                Pedir chave de acesso
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          <Body>
            <Typography
              variant="h6"
              className={classes.welcomeTitle}
            >
              Digite o telefone ou email cadastrado
            </Typography>

            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className={classes.form}
            >

              <TextField
                id="login-input"
                type="login"
                name="login"
                label="Telefone ou email"
                autoComplete="user-login"
                placeholder="Digite seu telefone ou email"
                variant="outlined"
                className={classes.emailInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.currentTarget.value.match("[a-zA-Z!@#$%^&*_+\\-=;':|,.<>?]+") === null ){
                    telefone2(event);
                  }
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu telefone ou email",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="login"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: theme.primary,
                  color: '#fefefe',
                  height: '45px',
                  marginTop: '60px'
                }}
                className={classes.buttonWithIcon}
              >
                <EmailIcon />
                Enviar
              </Button>
            </form>
          </Body>
        </Wrapper>
        {
          openSigninWithTokenModal
          ? <SigninWithToken
              login={userLogin}
              handleCloseSigninWithTokenModal={handleCloseSigninWithTokenModal}
              openSigninWithTokenModal={openSigninWithTokenModal}
              handleCloseAll={handleCloseAll}
            />
          : <div />
        }

      </Container>
    </Modal>
  )
}

export default RequestTokenModal;
