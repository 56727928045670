import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 320px;
  height: 100vh;
  padding: 25px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  img {
    width: 100%;
    height: 50vh;

  }

  p {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
  }

  button {
    width: 120px;
    height: 30px;
    border: 2px solid #0c65ea;
    border-radius: 4px;
    background: transparent;
    color: #0c65ea;
    font-size: 1rem;
    font-weight: 700;
    transition: 0.3s;

    &:hover {
      border-color: transparent;
      background: #0c65ea;
      color: #f5f5f5;
    }
  }
`
