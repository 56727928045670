import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  loginTitle: {
    fontSize: '1.2rem'
  },
  welcomeTitle: {
    color: '#D3D3D3', 
    marginTop: '20px',
    fontSize: '1.3rem',
    marginBottom: '30px',
    [theme.breakpoints.up(960)]: {
      marginTop: '30px',
      marginBottom: '50px',
    },
  },
  form: {
    width: '100%'
  },
  emailInput: {
    width: '100%',
    marginTop: '35px'
  },
  passwordInput: {
    width: '100%',
    marginTop: '20px',
  },
  link: {
    color: '#B22222',
    fontSize: '1rem',
    marginTop: '30px',
  },
  linkTransition:{
    transition: 'all 0.35s ease-in-out',
    '&:hover':{
      color: '#FF0000',
      textDecoration: 'none'
    },
    '&:after':{
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1px',

      height: '1px',
      width: 0,

      background: '#FF0000',

      transition: 'width 0.35s ease-in-out'
    },
    '&:hover::after':{
      width: '100%'
    }
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1
  },
  buttonWithIcon:{
    '& svg':{
      marginRight: '5px'
    }
  },
  facebookButton:{
    display: '-webkit-flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    marginLeft: 15,
    padding: 1,
    paddingRight: 10,
    backgroundColor: '#1877f2',

    color: '#FFF',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: 700,

    borderRadius: '2px',
    border: '1px solid transparent',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px'
  },
  facebookLogo: {
    width: 20,
    height: 20,
    marginRight: 20,
    marginLeft: 10
  },
    googleButton: {
      height: 45,
      width: 'auto'
    }
}));
