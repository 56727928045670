import React, { useState, useEffect, useContext } from "react";

import _ from 'lodash';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { IItemComplemento } from "../../../../../interfaces/itemComplemento";
import { IComplementoCarrinho } from "../../../../../interfaces/complementoCarrinho";

import { Informations, Quantidade} from './styles';
import stylesMaterial from './stylesMaterial';
import { ThemeContext } from "styled-components";

interface ProductComponentProps{
  complemento: IItemComplemento;
  setComponent: (cartComponent: IComplementoCarrinho) => void;
  full: boolean;
}

const ProductComponent: React.FC<ProductComponentProps> = ({
                                                            complemento,
                                                            setComponent,
                                                            full }) => {
  const classes = stylesMaterial();
  const [price, setPrice] = useState(complemento.preco.valor);
  const [complementFull, setComplementFull] = useState(false);
  const [complementMin, setComplementMin] = useState(false);
  const [quantidade, setQuantidade] = useState(complemento.qtd_min !== null ? complemento.qtd_min : 0);

  const { primary } = useContext(ThemeContext);

  useEffect(() => {
    setComponent({complemento, quantidade});

    if (quantidade >= complemento.qtd_max){
      setComplementFull(true);
    } else{
      setComplementFull(false);
    }
    if (quantidade <= complemento.qtd_min){
      setComplementMin(true);
    } else{
      setComplementMin(false);
    }
  }, [quantidade]);

  const handleAdition = () => {
    setQuantidade(_.toNumber(quantidade)+1);
  }
  const handleRemove = () => {
    if(quantidade>0)
      setQuantidade(_.toNumber(quantidade)-1);
  }

  return (
    <ListItem
      divider
      className={classes.listItem}
    >
      <Informations>
        <Typography
          variant='body1'
          className={classes.productTitle}
        >
          {complemento.descricao}
        </Typography>
        {/* <Typography variant='body2' className={classes.componentDesc} >
          {complemento.descricao}
        </Typography> */}
        {
          (_.toNumber(price) === 0) ?
          <div /> :
          <Typography
            variant='h6'
            className={classes.priceText}
          >
            + R$ {Number(price).toFixed(2).replace('.',',')}
          </Typography>
        }
      </Informations>
      <Quantidade>
        <IconButton
          aria-label="remove"
          disabled={complementMin}
          onClick={handleRemove}
        >
          <RemoveIcon
            style={{ color: complementMin ? '#9e9e9e' : primary }}
          />
        </IconButton>
        <label className={classes.quantityLabel}> {quantidade} </label>
        <IconButton
          aria-label="add"
          disabled={full || complementFull}
          onClick={handleAdition}
        >
          <AddIcon
            style={{ color: (full || complementFull) ? '#9e9e9e ' : primary }}
          />
        </IconButton>
      </Quantidade>
    </ListItem>
  )
}
export default ProductComponent;
