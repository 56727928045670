import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding: 20px 0px;

    background-color: #000;

    
    @media(max-width: 640px){
        padding: 10px 0px;
    }

`;

export const Paragraph = styled.div`
    display: flex;
    flex-direction: row;    

    text-align: center;
    color: #fff;
    font-weight: normal;
    font-size: 12px;

    @media(max-width: 640px){
        flex-direction: column;

        font-size: 9px;
    }
`;
export const Line = styled.div`
`;

export const Link = styled.a`
    cursor: pointer;

    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;