import React, { createContext, useReducer, useEffect, useState } from 'react';

import Reducer, { State } from './cartReducer';

import { ICarrinhoEmpresa } from '../interfaces/carrinhoEmpresa';

const storedCarts =
  localStorage.getItem('carts') === null
    ? new Array<ICarrinhoEmpresa>()
    : JSON.parse(localStorage.getItem('carts') || '');

const initialState = {
  carts: storedCarts,
  error: '',
};

const CartStore: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const [openCart, setOpenCart] = useState(false);

  useEffect(() => {
    localStorage.setItem('carts', JSON.stringify(state.carts));
  }, [state.carts]);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        openCart,
        setOpenCart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const Context = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
  openCart: boolean;
  setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  state: initialState,
  dispatch: () => null,
  openCart: false,
  setOpenCart: () => null,
});
export default CartStore;
