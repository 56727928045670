import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    justifyContent: 'flex-end',
    objectFit: 'cover',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    flexShrink: 0,
    height: '70%',
    width: '100%',
    
  },
  stripe: {
    fontSize: '1rem',
    lineHeight: 1,
    color: '#fff',
    fontWeight: 700,
    [theme.breakpoints.down(768)]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.9rem',
    },
  },
  outOfStock: {
    backgroundColor: '#898989 !important'
  },
  content: {
    padding: '10px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 768px)': {
      padding: '5px',
    },
    '@media (max-width: 425px)': {
      padding: '2px',
    },
  },
  clothingName: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.down(768)]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.9rem',
    },
  },
  infoPortion: {
    fontSize: '0.8rem',
    fontWeight: 400,
    lineHeight: 1,
    color: '#757575',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.7rem',
    },
  },
  price: {
    fontSize: '1rem',
    lineHeight: 1,
    color: '#0c65ea',
    fontWeight: 700,
    marginRight: '5px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.9rem',
    },
  },
  discount: {
    fontSize: '12px',
    fontFamily: 'Segoe UI, sans-serif',
    color: '#757575',
    fontWeight: 400,
    textDecoration: 'line-through',

    '@media (max-width: 425px)': {
      fontSize: '0.65rem',
    },
  },
  discountIconText: {
    position: 'absolute',
    color: '#FFF',
    fontWeight: 500,
    zIndex: 2,
  },
  cardAction: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    [theme.breakpoints.down(768)]: {
      gap: '2px',
    },
  },
  oldPrice: {
    fontSize: '0.9rem',
    lineHeight: 1,
    color: '#757575',
    fontWeight: 300,
    textDecoration: 'line-through',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.8rem',
    },
  },
}));
