import React, { useState, useEffect, useContext } from "react";

import clsx from 'clsx';
import { useParams, Link, Redirect} from "react-router-dom";
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from '../../hooks/provideAuth';
import AdressGroup from '../../components/adress-group';

import empresaApi from '../../services/empresaApi';
import userApi from "../../services/userApi";

import { IEmpresa } from '../../interfaces/empresa';
import { telefone } from '../../components/masks';

import stylesMaterial from './stylesMaterial';
import {
  Container,
  TopMenu,
  Body,
  Infos
} from './styles';
import { Footer } from "../../components/footer";
import { CustomAppBar } from "../../new-components/custom-appbar";
import { DefaultTheme } from "styled-components";
import { useTheme } from "../../hooks/useThemeState";

const UserInfo: React.FC = () => {
  const classes = stylesMaterial();

  const [cpfValue, setCpfValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [telValue, setTelValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const { companieId } = useParams<{ companieId: string }>();
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);

  const [companie, setCompanie] = useState<IEmpresa>();
  const { setTheme, theme } = useTheme()

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    empresaApi.getEmpresa(companieId).then((res) =>{
      setCompanie(res);
    }).catch((error) => {
      empresaApi.getEmpresaSlug(companieId).then((res) =>{
        setCompanie(res);
      }).catch((error) => {
        // console.log("Erro no slug");
        // console.log(error);
      })
    });

    getInfos();
  }, [companieId]);

  useEffect(() => {
    if (!!companie) {
      empresaApi
      .getParametro(`${companie.id}`, 'customizations')
      .then(res => {
        if (!!res) {
          const role: DefaultTheme = JSON.parse(res.role)
          if (role.title === 'custom') {
            setTheme({
              title: role.title,
              primary: role.primary,
              secondary: role.secondary
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }, [companie])

  const getInfos = () => {
    userApi.getUserInfo(authState.token).then((res) => {
      const {cnpjCpf, razaoSocial, fone, email} = res;
      setEmailValue(email.toLowerCase());
      setNameValue(razaoSocial);
      // setTelValue("("+fone.slice(0,2)+") "+fone.slice(2));
      if(fone !== null){
        setTelValue(fone);
      }
      if(cnpjCpf !== null){
        setCpfValue(cnpjCpf.slice(0,3)+"."
                  +cnpjCpf.slice(3,6)+"."
                  +cnpjCpf.slice(6,9)+"-"
                  +cnpjCpf.slice(9));
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const saveNewUserInfo = () => {
    userApi.updateUserInfo(nameValue, telValue.replace(/\D/g, ''), emailValue, cpfValue.replace(/\D/g, ''), authState.token).then((res) => {
      toast.success('Dados atualizados com sucesso.', toast_config);
      getInfos();
    }).catch((error) => {
      if (error.response.data.message === "user-email-already-exists"){
        toast.error('O email utilizado já está associado a uma conta existente, insira outro ou troque de conta', toast_config);
      } else if(error.response.data.message === "user-fone-already-exists"){
        toast.error('O telefone utilizado já está associado a uma conta existente, insira outro ou troque de conta', toast_config);
      } else if(error.response.data.message === "user-cpfcnpj-already-exists"){
        toast.error('O cpf utilizado já está associado a uma conta existente, insira outro ou troque de conta', toast_config);
      } else {
        toast.error('Erro na alteração dos dados. Verifique seus dados ou tente mais tarde.', toast_config);
      }
    })
  }

  return (
    authState.token !== ""
    ?
    <Container>
      <CustomAppBar />

      <Body>
        <Typography className={classes.title}>
          Informações
        </Typography>

        <Divider className={classes.divider} />

        <Infos>
          <TextField
            id="name"
            label="Nome"
            variant="outlined"
            value={nameValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNameValue(event.currentTarget.value);
            }}
          />
          <TextField
            id="telephone"
            label="Telefone"
            variant="outlined"
            value={telValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTelValue(telefone(event).currentTarget.value);
            }}
            onPaste={(event) =>{
              event.preventDefault()
              return false;
            }}
            autoComplete="off"
          />
          <TextField
            disabled
            id="email"
            label="Email"
            value={emailValue}
            variant="outlined"
          />
          <TextField
            disabled
            id="cpf"
            label="Cpf"
            value={cpfValue}
            variant="outlined"
          />
        </Infos>

        <Button
          variant="contained"
          color="primary"
          onClick={saveNewUserInfo}
          className={classes.saveButton}
          style={{ backgroundColor: theme.primary }}
        >
          Salvar
        </Button>

        <AdressGroup token={authState.token} />

      </Body>

      <Footer />
    </Container>
    :
    <Redirect to={`/${companieId}`} />
  )
}

export default UserInfo;
