import React, { useState, useContext } from "react";

import clsx from 'clsx';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {Divider, Drawer as MUIDrawer} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import StoreIcon from '@material-ui/icons/Store';
import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';
import PolicyIcon from '@material-ui/icons/Policy';
import HistoryIcon from '@material-ui/icons/History';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import LoginModal from '../../../login-modal';
import { ChangePasswordModal } from '../../../change-password-modal';

import { AuthContext } from '../../../../hooks/provideAuth';

import logo from "../../../../assets/atac_delivery.svg";

import stylesMaterial from './stylesMaterial';
import {
  LogoImage
} from './styles';

interface DrawerProps{
  handleCloseDrawer: () => void;
  openDrawer: boolean;
  empresaId: number;
}

export function Drawer(props: DrawerProps) {
  const {
    handleCloseDrawer,
    openDrawer,
    empresaId
  } = props;

  const classes = stylesMaterial();

  const { companieId } = useParams<{ companieId: string }>();
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(openDrawer);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openPolicyDropdown, setOpenPolicyDropdown] = useState(false);
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const { state, dispatch } = useContext(AuthContext);

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  }

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  }

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  }

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  }

  const logOut = () => {
    dispatch({type: 'REMOVE_TOKEN'});
    dispatch({type: 'REMOVE_LOGIN'});

    handleCloseDrawer();
    if(location.pathname.includes("sale")){
      window.location.reload();
    }
  }

  const handleChangePasswordSucess = () => {
    toast.success('Senha alterada com sucesso!', toast_config);
  }

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    handleCloseDrawer();
  };

  const handlePrivacyPolicyDropdown = () => {
    setOpenPolicyDropdown(!openPolicyDropdown);
  }

  const handleTermsOfUse = () => {
    window.open("https://retaguarda.atacdelivery.com/api/files/TERMOS_E_CONDICOES_GERAIS_DE_USO_E_DE_COMPRA_E_VENDA_C.pdf", "_blank");
  }

  const handleConsentTerms = () => {
    window.open("https://retaguarda.atacdelivery.com/api/files/TERMO_DE_CONSENTIMENTO.pdf", "_blank");
  }

  const list = () => (
    <List className={classes.list}>
      <ListItem
        button
        onClick={() => history.push('/')}
        key={1}
      >
        <StoreIcon />
        <ListItemText primary={'Lojas'} />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(`/${companieId}/contact`)}
        key={2}
      >
        <CallIcon />
        <ListItemText primary={'Fale Conosco'} />
      </ListItem>

      <ListItem
        button
        onClick={handlePrivacyPolicyDropdown}
        key={3}
      >
        <PolicyIcon />
        <ListItemText primary={"Política de Privacidade"} />
        {openPolicyDropdown ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={openPolicyDropdown}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem
            button
            onClick={handleTermsOfUse}
            className={classes.nested}
            key={1}
          >
            <ListItemText primary="Termos de Uso" />
          </ListItem>
          <ListItem
            button
            onClick={handleConsentTerms}
            className={classes.nested}
            key={2}
          >
            <ListItemText primary="Termos de Consentimento" />
          </ListItem>
        </List>
      </Collapse>

      <Divider className={classes.divider} />

      <ListItem
        button
        onClick={handleOpenLoginModal}
        className={clsx({[classes.hide]: state.token !== ""})}
        key={4}
      >
        <PersonIcon />
        <ListItemText primary={'Login'} />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(`/${companieId}/history`)}
        className={clsx({
          [classes.hide]: (
            state.token === "" && state.tokenLogin === "") || companieId === undefined
        })}
        key={5}
      >
        <HistoryIcon />
        <ListItemText primary={"Meus pedidos"} />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(`/${companieId}/user`)}
        className={clsx({[classes.hide]: state.token === ""})}
        key={6}
      >
        <AccountCircleIcon />
        <ListItemText primary={"Meus dados"} />
      </ListItem>

      <ListItem
        button
        onClick={handleOpenChangePasswordModal}
        className={clsx({[classes.hide]: state.token === ""})}
        key={7}
      >
        <VpnKeyIcon />
        <ListItemText primary={"Alterar senha"} />
      </ListItem>

      <div style={{ flex: 1 }} />

      <Divider className={`${clsx({[classes.hide]: state.token === ""})} ${classes.divider}`} />

      <ListItem
        button
        onClick={logOut}
        className={clsx({[classes.hide]: state.token === "" && state.tokenLogin === ""})}
        key={8}
      >
        <ExitToAppIcon />
        <ListItemText primary={"Sair"} />
      </ListItem>
    </List>
  );

  return (
    <>
      <MUIDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          className: classes.drawerPaper
        }}
      >
        <LogoImage src={ logo } alt="Logo Atac Delivery" />
        <Divider className={classes.divider} />
        {list()}
      </MUIDrawer>
      {openLoginModal && (
        <LoginModal
          handleCloseLoginModal={handleCloseLoginModal}
          openLoginModal={openLoginModal}
          handleCloseDrawer={handleCloseDrawer}
          companieId={empresaId}
        />
      )}
      {
        openChangePasswordModal
        ? <ChangePasswordModal
            handleCloseChangePasswordModal={handleCloseChangePasswordModal}
            openChangePasswordModal={openChangePasswordModal}
            changePasswordSucess={handleChangePasswordSucess}
          />
        : <div />
      }

    </>
  )
}
