import React, { useState } from "react";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import { 
  Container, 
  Header, 
  Wrapper, 
} from './styles';
import stylesMaterial from './stylesMaterial';
import { Button } from "@material-ui/core";
import { Content } from "../../autocomplete-google-modal/styles";
import { useTheme } from "../../../hooks/useThemeState";

interface WithoutNumberConfirmationProps{
  handleCloseWithoutNumberConfirmation: () => void;
  openWithoutNumberConfirmation: boolean;
  number: string;
  handleOpenMap: () => void;
}

export const WithoutNumberConfirmation: React.FC<WithoutNumberConfirmationProps> = ({
                                                                    handleCloseWithoutNumberConfirmation, 
                                                                    openWithoutNumberConfirmation,
                                                                    number,
                                                                    handleOpenMap
                                                                  }) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();
  const [open, setOpen] = useState(openWithoutNumberConfirmation);
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseWithoutNumberConfirmation();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton 
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon 
                fontSize='large'
                style={{ color: '#3f51b5' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography 
                variant="h6" 
                className={classes.title}
              >
                Confirmação do número
              </Typography>
            </div>
            <IconButton 
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon  
                style={{ color: '#3f51b5' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          <Typography 
            variant="subtitle1"
            className={classes.subtitle}
          >
            Deseja continuar sem informar um número?
          </Typography>
          
          <Content>
            <Button 
              variant="contained" 
              fullWidth
              onClick={() => {
                handleOpenMap();
                handleClose();
              }}
              style={{ 
                backgroundColor: '#3f51b5', 
                color: '#fefefe', 
                height: '45px',
                width: '90%'
              }}
            >
              Confirmar
            </Button>
            <Button 
              variant="contained"
              onClick={handleClose}
              fullWidth
              style={{ 
                background: 'transparent',
                border: `1px solid ${theme.primary}`,
                color: theme.primary, 
                height: '45px',
                marginTop: '10px',
                width: '90%'
              }}
            >
              Voltar
            </Button>
          </Content>
        </Wrapper>
      </Container>

    </Modal>
  )
}