import React from 'react';
import { OptionContainer, 
        RoundIconHolder,
        IconSpan } from './styles';
import { MdStoreMallDirectory } from "react-icons/md";   //MdPets is placeholder 
import { ImClipboard } from "react-icons/im";
import { FaHamburger } from "react-icons/fa";
import { IconType } from 'react-icons/lib';

interface CategoryOptionProps {
    value: string;
    label?: string;
    selected: boolean;
    onClick: (option: string) => void;   //?
}

const CategoryOption: React.FC<CategoryOptionProps> = ({value, label, selected, onClick}) => {
    const handleClick = () => {
        if(!selected){ 
            onClick(value);
        }
    };

    const icons: {[k: string]: IconType} = {
        alimentacao: FaHamburger,
        lojas: MdStoreMallDirectory,
        todos: ImClipboard,
    };

    const TestIcon = icons[value];

    return (
        <React.Fragment>
            <OptionContainer selected={selected}>
                <RoundIconHolder selected={selected} onClick={handleClick}>
                    <TestIcon size={30} color='#ffffff' />
                </RoundIconHolder>
                <IconSpan selected={selected} onClick={handleClick}>
                    {label}
                </IconSpan>
            </OptionContainer>
        </React.Fragment>
    )
}
export default CategoryOption;