import { makeStyles } from '@material-ui/core/styles';
import { lighten } from 'polished';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default makeStyles((theme) => {
  const { primary } = useContext(ThemeContext);

  return ({
    buttonArrow: {
      width: 40,
      height: 40,
      padding: 5,
      [theme.breakpoints.up(640)]: {
        height: 0
      },
    },
    iconArrow:{
      width: 30,
      height: 30,
      [theme.breakpoints.up(640)]: {
        height: 0
      },
    },
    buttonClose: {
      width: 40,
      height: 40,
      padding: 5,
      [theme.breakpoints.down(640)]: {
        height: 0
      },
    },
    iconClose:{
      [theme.breakpoints.down(640)]: {
        height: 0
      },
    },
    detailTitle: {
      fontSize: '18px'
    },
    itemTitle: {
      fontSize: '1.2rem',
      [theme.breakpoints.up(960)]: {
        fontSize: '1.4rem',
        marginTop: 12,
        lineHeight: '1.5'
      },
    },
    moreItems: {
      textDecoration: 'none',
    },
    showNoteLink: {
      color: primary,
      fontSize: '14px',
      fontWeight: 400
    },
    totalObs:{
      fontSize: '14px',
      fontWeight: 500
    },
    subDelTax:{
      fontSize: '14px',
      fontWeight: 300
    },
    itemList:{
      listStyleType: 'circle'
    },
    textArea:{
      width: '100%',
      marginTop: '5px',
      marginBottom: '5px',
      fontSize: '1.1rem',
      padding: '5px',
      borderRadius: '4px'
    },
    hide:{
      display: 'none !important',
    },
    scroll:{
      height: 'calc(80vh - 50px) !important'
    },
    linkButtonFinish:{
      textDecoration: 'none',
      height: '45px',
      width: '90%'
    },
    offset1: {
      minHeight: 90
    },
    moreItemsIcon:{
      width: '24px',
      height: '24px',
      color: 'inherit',
      transition: 'all 0.35s ease-in-out',
    },
    moreItemsButton:{
      color: primary,
      fontSize: '1rem',
      textDecoration: 'none',
      fontWeight: 500,
      transition: 'all 0.35s ease-in-out',
      '&:hover':{
        color: lighten(0.2, primary),
      },
    },
  })
});
