import React, { useContext, useEffect, useState } from "react";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import RegisterModal from '../../components/register-modal';
import RequestTokenModal from '../../components/request-token-modal';
import Signin from "./signin";

import userApi from '../../services/userApi';
import { AuthContext } from '../../hooks/provideAuth';

import {
  Container,
  Header,
  Wrapper,
  Body,
  SocialLogin
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from '../../hooks/useThemeState';
import { Link } from "@material-ui/core";

interface LoginModalProps{
  handleCloseLoginModal: () => void;
  openLoginModal: boolean;
  handleCloseDrawer: () => void;
  companieId: number;
}

const LoginModal: React.FC<LoginModalProps> = ({handleCloseLoginModal, openLoginModal, handleCloseDrawer, companieId}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();

  const [open, setOpen] = React.useState(openLoginModal);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openRequestTokenModal, setOpenRequestTokenModal] = useState(false);
  const [openSigninModal, setOpenSigninModal] = useState(false);
  const [loginForRequestToken, setLoginForRequestToken] = useState("");

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const { state, dispatch } = useContext(AuthContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseLoginModal();
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  }

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
  }

  const handleOpenRequestTokenModal = () => {
    setOpenRequestTokenModal(true);
  }

  const handleCloseRequestTokenModal = () => {
    setOpenRequestTokenModal(false);
  }

  const handleOpenSigninModal = () => {
    setOpenSigninModal(true);
  }

  const handleCloseSigninModal = () => {
    setOpenSigninModal(false);
  }

  const handleRegisterSucess = () => {
    toast.success('Cadastro concluido com sucesso!', toast_config);
  }

  const handleOpenRequestTokenModalFromSignup = (login: string) => {
    setLoginForRequestToken(login);
    setOpenRequestTokenModal(true);
  }

  const responseGoogle = (response: any) => {
    userApi.loginSocialMedia(response.tokenId, response.profileObj.email, 'google', 118).then((res) => {
      dispatch({type: 'SET_TOKEN',
                payload: res.token});
      handleCloseDrawer();
      handleClose();
    }).catch((error) => {
      if(error.response.data.message){
        toast.error(error.response.data.message, toast_config); 
      } else{
        toast.error('Ocorreu um erro no login', toast_config);
      }
    });
  }

  const responseFacebook = (response: any) => {
    userApi.loginSocialMedia(response.accessToken, response.email, 'facebook', 118).then((res) => {
      dispatch({type: 'SET_TOKEN',
                payload: res.token});
      handleCloseDrawer();
      handleClose();
    }).catch((error) => {
      if (error.response.data.message){
        toast.error(error.response.data.message, toast_config); 
      } else{
        toast.error('Ocorreu um erro no login', toast_config);
      }
});
  }

  const failureSocialLogin = () => {
    toast.error('Ocorreu um erro no login', toast_config);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography
                variant="h6"
                className={classes.loginTitle}
              >
                Entrar
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          <Body>
            <Typography
              variant="h6"
              className={classes.welcomeTitle}
            >
              Bem-vindo!
            </Typography>

            <Button
              variant="contained"
              fullWidth
              onClick={handleOpenSigninModal}
              style={{
                backgroundColor: theme.primary,
                color: '#fefefe',
                height: '45px',
                marginTop: '20px'
              }}
              className={classes.buttonWithIcon}
            >
              <LockIcon />
              Entrar com email e senha
            </Button>

            <Link
              onClick={handleOpenRegisterModal}
              style={{
                color: theme.primary,
                marginTop: '20px',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              Cadastre-se
            </Link>

            <Divider 
              style={{
                width: '100%',
                marginTop: '30px'
              }}
            />

            <Button
              variant="outlined"
              fullWidth
              onClick={handleOpenRequestTokenModal}
              style={{
                borderColor: theme.primary,
                color: theme.primary,
                minHeight: '45px',
                marginTop: '15px'
              }}
              className={classes.buttonWithIcon}
            >
              <VpnKeyIcon />
              Receber chave de acesso rápido
            </Button>

            <SocialLogin>
              <GoogleLogin
                clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                buttonText="Entrar com Google"
                onSuccess={responseGoogle}
                onFailure={failureSocialLogin}
                cookiePolicy={'single_host_origin'}
                theme="dark"
                className={classes.googleButton}
              />

              <FacebookLogin
                appId={`${process.env.REACT_APP_FACEBOOK_ID}`}
                fields="name,email"
                callback={responseFacebook}
                onFailure={failureSocialLogin}
                cssClass={classes.facebookButton}
                textButton="Entrar com facebook"
                isMobile={false}
                icon={
                  <img src="/images/facebookLogo.png" className={classes.facebookLogo} />
                }
              />
            </SocialLogin>

            {
              openRegisterModal
              ? <RegisterModal
                  handleCloseRegisterModal={handleCloseRegisterModal}
                  openRegisterModal={openRegisterModal}
                  registerSucess={handleRegisterSucess}
                  companieId={companieId}
                  handleOpenRequestTokenModalFromSignup={handleOpenRequestTokenModalFromSignup}
                />
              : <div />
            }
            {
              openRequestTokenModal
              ? <RequestTokenModal
                  handleCloseRequestTokenModal={handleCloseRequestTokenModal}
                  openRequestTokenModal={openRequestTokenModal}
                  handleCloseAll={() => {
                    handleCloseDrawer();
                    handleClose();
                  }}
                  loginForRequestToken={loginForRequestToken}
                  setLoginForRequestToken={setLoginForRequestToken}
                />
              : <div />
            }
            {
              openSigninModal
              ? <Signin
                  handleCloseSigninModal={handleCloseSigninModal}
                  openSigninModal={openSigninModal}
                  handleCloseAll={() => {
                    handleClose();
                  }}
                />
              : <div />
            }
          </Body>
        </Wrapper>
         
      </Container>

    </Modal>
  )
}

export default LoginModal;
