import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  @media (max-width: 425px) {
    align-self: flex-end;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  width: 100%;
`;

export const SegmentFilter = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: sticky;
  top: 64px;
  z-index: 101;

  @media (max-width: 425px) {
    align-self: flex-end;
  }
`;

export const LongLine = styled.hr`
  width: 90%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 10px 0 10px 0px;
  align-self: center;
  transform: rotate(-360deg);
`;
