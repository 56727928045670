import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  carouselRoot: {
    display: 'flex',
    width: '100%',
    padding: '25px 10px',
    gap: '20px',
    [theme.breakpoints.down(768)]: {
      gap: '15px',
      padding: '15px 10px',
    },
    [theme.breakpoints.down(580)]: {
      gap: '10px',
    },
    [theme.breakpoints.down(425)]: {
      gap: '8px',
    },
  },
}));
