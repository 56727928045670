import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import NotFoundImg from '../../assets/atac_delivery.svg';

export const PageNotFound: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <img src={NotFoundImg} alt="logo-atac-delivery" />
      <p>Empresa desativada temporariamente</p>
      <button type="button" onClick={() => history.push('/')}>
        Voltar
      </button>
    </Container>
  );
};
