import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawerPaper: {
    background: '#000',
    padding: '20px 15px',
    borderRadius: '0 10px 10px 0'
  },
  divider: {
    background: '#FFF',
    marginTop: '20px'
  },
  list: {
    display: '-webkit-flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: 0,
    width: 260,
    color: '#FFF',
    '& svg + div':{
      marginLeft: '10px'
    }
  },
  hide: {
    display: 'none'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));
