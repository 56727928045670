import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
`;

export const LongLine = styled.div`
  align-self: center;
  width: 100%;
  height: 1px;
  transform: rotate(-360deg);
  border-bottom: 1px solid #c4c4c4;

  @media (min-width: 680px) {
    display: none;
  }
`;
export const StorefrontProductList = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 680px) {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
  }

  @media (min-width: 840px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1250px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const ShortLine = styled.div`
  flex-grow: 1;
  transform: rotate(-360deg);
  border-bottom: 3px solid #c4c4c4;
  max-width: 80px;
`;

export const SegmentTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  white-space: nowrap;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5rem;
    padding-inline: 10px;
  }
`;

export const SegmentHeader = styled.div`
  margin: 30px 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SeeAll = styled.button`
  margin-top: 6px;
  margin-bottom: 22px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  text-decoration: underline;

  @media (max-width: 640px) {
    font-size: 14px;
  }

  &:hover {
    color: #000b90;
    cursor: pointer;
  }

  &:focus {
    color: #ea300c;
    cursor: pointer;
  }
`;
