import React, { createContext, useContext, useEffect, useReducer } from "react";
import Reducer from './authReducers';

import { State } from './authReducers';

let storedToken = localStorage.getItem("token")===null
                  ? ""
                  : JSON.parse(localStorage.getItem("token")||'');

let storedLogin = localStorage.getItem("token-login")===null
                  ? ""
                  : JSON.parse(localStorage.getItem("token-login")||'');

const initialState: State = {
  token: storedToken,
  tokenLogin: storedLogin
};

const ProvideAuth: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(state.token));
  }, [state.token]);

  useEffect(() => {
    localStorage.setItem("token-login", JSON.stringify(state.tokenLogin));
  }, [state.tokenLogin]);

  return (
    <AuthContext.Provider value={{state, dispatch}}>
      {children}
    </AuthContext.Provider>
  )
};

export const AuthContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});
export default ProvideAuth;

export const useAuth = () => {
  return useContext(AuthContext);
}
