import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '../pages/Home';
// import Sale from '../pages/Sale';
import History from '../pages/History';
import SaleFinished from '../pages/SaleFinished';
import Contact from '../pages/Contact';
import { Initial } from '../pages/Initial';
import { SaleNew } from '../pages/SaleNew';
import SegmentCompanies from '../pages/SegmentCompanies';
import UserInfo from '../pages/UserInfo';
import { BannerItems } from '../pages/BannerItems';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/banner/:bannerId" component={BannerItems} />
      <Route path="/:companieId" exact component={Initial} isPrivate />
      <Route path="/:companieId/sale" component={SaleNew} isPrivate />
      <Route path="/:companieId/history" component={History} isPrivate />
      <Route
        path="/:companieId/:numPedido/salefinished"
        component={SaleFinished}
        isPrivate
      />
      <Route path="/:companieId/contact" component={Contact} />
      <Route path="/:segmentId/segmentcompanies" component={SegmentCompanies} />
      <Route path="/:companieId/user" component={UserInfo} />
      <Route path="/:companieId/stores" component={Contact} />
    </Switch>
  );
};

export default Routes;
