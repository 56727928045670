import styled from 'styled-components';

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  justify-items: center;

  padding-block: 10px;
  width: 100%;
  grid-gap: 20px;

  @media (max-width: 680px) {
    grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
  }

  @media (max-width: 768px) {
    gap: 10px;
  }
`;
