import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  gridItem: {
    display: '-webkit-flex',
    flexDirection: 'row',
    textAlign: 'start',
    margin: 'auto',
    borderRadius: '0.3rem',
  },
  container: {
    position: 'relative',
  },
  card:{
    display: '-webkit-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    width: '370px',
    height: '240px',

    padding: '20px',
    
    [theme.breakpoints.between(340,400)]: {
      width: '300px'
    },
    [theme.breakpoints.down(341)]: {
      width: '250px',
      height: '170px',
      padding: '2px',
    },
    '&:last-child':{
      paddingBottom: '20px',
      [theme.breakpoints.down(341)]: {
        paddingBottom: '2px'
      },
    }
  },
  infos: {
    lineHeight: '1.2rem',
    fontSize: '1.2rem',
    [theme.breakpoints.down(341)]: {
      fontSize: '1rem'
    },
  },
  description: {
    lineHeight: '1.2rem',
    fontSize: '1.2rem',
    fontWeight: 500,
    [theme.breakpoints.down(341)]: {
      fontSize: '1rem'
    },
  },
  hide: {
    display: 'none'
  },
  locationLink: {
    position: 'absolute',
    right: '0.5rem',
    bottom: '0.5rem',
    textDecoration: 'none'
  },
  locationIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
