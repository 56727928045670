import styled from 'styled-components';


export const FirstStep = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 800px) / 2);
  width 800px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  align-items: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  display: -webkit-flex;
  flex-direction: column;
  height: calc(80vh - 181px);
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: calc(100vh - 181px);
  }
`;

export const UserInfo = styled.div`
  display: grid;

  grid-template-rows: 1fr 1fr 1fr 1fr 1fr; 
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 15px;
  grid-template-areas: 
    "currentLocation currentLocation currentLocation cep cep cep cep houseNumber houseNumber houseNumber"
    "address address address address address neighborhood neighborhood neighborhood neighborhood neighborhood"
    "complement complement complement complement complement complement complement complement complement complement"
    "uf uf uf uf uf city city city city city"
    "principal principal principal principal principal description description description description description";

  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;

  min-height: max-content;

  @media(max-width: 525px){
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-areas: 
    "currentLocation currentLocation currentLocation currentLocation currentLocation currentLocation currentLocation currentLocation currentLocation currentLocation"
    "cep cep cep cep cep cep houseNumber houseNumber houseNumber houseNumber"
    "address address address address address neighborhood neighborhood neighborhood neighborhood neighborhood"
    "complement complement complement complement complement complement complement complement complement complement"
    "uf uf uf uf uf city city city city city"
    "principal principal principal principal principal description description description description description";

  }
`;

export const CurrentLocationButton = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border-radius:  0 0 0.3rem 0.3rem;
  background-color: rgb(254, 254, 254);
  @media(max-width: 960px){
    position: fixed;
    bottom: 0;
  }
`;