import styled from 'styled-components';


export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 550px) / 2);
  width: 550px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Wrapper = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  height: calc(80vh - 130px);
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: calc(100vh - 130px);
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 90px;
  min-height: max-content;
`;

export const MoreItems = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 50px;
`;

export const Subtotal = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
  p + p {
    margin-left: 10px;
  }
`;

export const DeliveryTax = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  justify-content: flex-end;
  p + p {
    margin-left: 10px;
  }
`;

export const Total = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  justify-content: flex-end;
  p + p {
    margin-left: 10px;
  }
`;

export const Promocao = styled.div`
  width: 100%;
  margin: 10px 0px;
  padding: 0px 16px;
  text-align: center;
  color: red;
  font-weight: 500;
`;

export const Note = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #faf0ee;
  min-height: max-content;
  div{
    display: -webkit-flex;
    flex-direction: column;

    background-color: #fefefe;

    padding-right: 15px;
    padding-left: 15px;
    div{
      display: -webkit-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 0px;
      padding-left: 0px;
      height: 40px;
    } 
  }
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  border-radius:  0 0 0.3rem 0.3rem;
  background-color: rgb(254, 254, 254);
  @media(max-width: 960px){
    position: fixed;
    bottom: 0;
  }
`;