import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '2px',
    objectFit: 'cover',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    flexShrink: 0,
    height: '60%',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up(768)]: {
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
  },
  stripe: {
    fontSize: '0.8rem',
    lineHeight: 1,
    color: '#fff',
    fontWeight: 700,
    [theme.breakpoints.down(768)]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.7rem',
    },
  },
  outOfStock: {
    backgroundColor: '#898989 !important'
  },
  content: {
    padding: '0.75rem',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 768px)': {
      padding: '0.5rem',
    },
  },
  companyInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  companyLogo: {
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: '50%',
    position: 'absolute',
    left: '5px',
    top: '5px',
  },
  companyName: {
    fontSize: '0.8rem',
    fontWeight: 300,
    lineHeight: 0.875,
    textTransform: 'capitalize',
    marginLeft: '0.25rem',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.7rem',
    },
  },
  clothingName: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 0.875,
    textTransform: 'capitalize',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.9rem',
    },
  },
  price: {
    fontSize: '1rem',
    lineHeight: 1,
    color: '#0c65ea',
    fontWeight: 700,
    marginRight: '5px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.9rem',
    },
  },
  discount: {
    fontSize: '12px',
    fontFamily: 'Segoe UI, sans-serif',
    color: '#757575',
    fontWeight: 400,
    textDecoration: 'line-through',

    '@media (max-width: 425px)': {
      fontSize: '0.65rem',
    },
  },
  discountIconText: {
    position: 'absolute',
    color: '#FFF',
    fontWeight: 500,
    zIndex: 2,
  },
  cardAction: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    [theme.breakpoints.down(768)]: {
      gap: '2px',
    },
  },
  oldPrice: {
    fontSize: '0.9rem',
    lineHeight: 1,
    color: '#757575',
    fontWeight: 300,
    textDecoration: 'line-through',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.8rem',
    },
  },
}));
