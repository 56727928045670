import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import {
  useParams,
  useLocation,
  Link as LinkRD,
  useHistory,
} from 'react-router-dom';
import _ from 'lodash';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { CartItem } from './cart-item';

import empresaApi from '../../services/empresaApi';
import { ProductSuggestion } from '../product-suggestion';
import { Context } from '../../hooks/cartStore';
import { IItemCarrinho } from '../../interfaces/itemCarrinho';
import { calcOrderPrice } from '../../utils/calculator';

import {
  Container,
  Scroll,
  Header,
  Wrapper,
  Body,
  MoreItems,
  Subtotal,
  DeliveryTax,
  Total,
  Note,
  Footer,
  Promocao,
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from '../../hooks/useThemeState';
import api from '../../services/api';
import { IStorePromotion } from '../../interfaces/storePromotion';
import { ProductSuggestionModal } from '../product-suggestion-test';

interface CartProps {
  handleCloseCart: () => void;
  openCart: boolean;
  delTax?: number;
  closed: boolean;
  companyId: string | undefined;
}

const Cart: React.FC<CartProps> = ({
  handleCloseCart,
  openCart,
  delTax,
  closed,
  companyId,
}) => {
  const classes = stylesMaterial();
  const location = useLocation();
  const { companieId: empresaId } = useParams<{ companieId: string }>();

  const [open, setOpen] = useState(openCart);
  const [openProductSuggestion, setOpenProductSuggestion] = useState(false);
  const [obsArea, setObsArea] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [isInSalePage, setIsInSalePage] = useState(true);
  const [firstClick, setFirstClick] = useState(true);
  const [suggestedProduct, setSuggestedProduct] = useState(-1);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [promocoes, setPromocoes] = useState<IStorePromotion[]>([]);
  const [melhorPromocao, setMelhorPromocao] = useState<
    IStorePromotion | undefined
  >(undefined);
  const [sugestaoPromocao, setSugestaoPromocao] = useState<
    IStorePromotion | undefined
  >(undefined);
  const [hasInvalidPromo, setHasInvalidPromo] = useState(false);

  const { state, dispatch } = useContext(Context);

  const [cart, setCart] = useState(
    state.carts.find((_cart) => _.toString(_cart.id) === companyId) || {
      id: 0,
      cart: new Array<IItemCarrinho>(),
      observacao: '',
    },
  );
  const [observacao, setObservacao] = useState(cart.observacao);
  const [deliveryTax, setDeliveryTax] = useState(delTax || 0);

  const history = useHistory();

  const { theme } = useTheme();
  
  useEffect(() => {
    const req = async () => {
      const ids = state.carts
        .find((_cart) => _cart.id.toString() === companyId)
        ?.cart.map((_cart) => _cart.prodComposto.produto.id);

      if (ids?.length) {
        const res = await api.post(`/produto/promocao/cart/${companyId}`, {
          ids,
        });

        setPromocoes(res.data);
      } else {
        setPromocoes([]);
      }
    };
    req();
  }, [cart.cart]);

  useEffect(() => {
    if (promocoes.length) {
      const promocao = promocoes
        .filter(
          (_promocao) =>
            (_promocao.valor_minimo === null ||
              +_promocao.valor_minimo === 0 ||
              +_promocao.valor_minimo <= subtotal) &&
            (_promocao.valor_maximo === null ||
              +_promocao.valor_maximo === 0 ||
              +_promocao.valor_maximo >= subtotal),
        )
        .reduce(
          (
            acc: IStorePromotion | undefined,
            cur: IStorePromotion,
          ): IStorePromotion | undefined => {
            if (acc === undefined) return cur;

            if (acc.tipo_valor === 2) return acc;
            if (cur.tipo_valor === 2) return cur;

            return +acc.valor >= +cur.valor ? acc : cur;
          },
          undefined,
        );

      if (promocao !== undefined) {
        setMelhorPromocao(promocao);
        setSugestaoPromocao(undefined);
        return;
      }

      const sugestao = promocoes.reduce(
        (
          acc: IStorePromotion | undefined,
          cur: IStorePromotion,
        ): IStorePromotion | undefined => {
          if (acc === undefined) return cur;

          if (acc.tipo_valor === 2) return acc;

          return +acc.valor >= +cur.valor ? acc : cur;
        },
        undefined,
      );

      setSugestaoPromocao(sugestao);
      setMelhorPromocao(undefined);
    } else {
      setSugestaoPromocao(undefined);
      setMelhorPromocao(undefined);
    }
  }, [promocoes, subtotal]);

  useEffect(() => {
    if (cart.cart.length) {
      setSubtotal(
        cart.cart
          .map((item) => calcOrderPrice(item))
          .reduce((_total, next) => _total + next),
      );
    } else {
      setSubtotal(0);
    }
  }, [cart.cart, isInSalePage]);

  useEffect(() => {
    setIsLoading(true);

    const groups_ids = cart.cart.map((item) => {
      if (item.prodComposto.produto.grupo_produto) {
        return item.prodComposto.produto.grupo_produto[0].grupo_id;
      }
      return item.produtoGrade ? item.produtoGrade.grupo_produto_id : 0;
    });

    if (companyId !== undefined) {
      empresaApi
        .getParametro(companyId, 'grupos_sugestao')
        .then((res) => {
          if (!res) {
            setFirstClick(false);
            setIsLoading(false);
          } else {
            const haveGroup = groups_ids
              .map((id) => JSON.parse(res.role).grupos.includes(id))
              .includes(true);
            if (!haveGroup) {
              setFirstClick(false);
              setIsLoading(false);
            } else {
              empresaApi
                .getParametro(companyId, 'produto_sugestao')
                .then((_res) => {
                  if (!_res) {
                    setIsLoading(false);
                    setFirstClick(false);
                  } else {
                    setSuggestedProduct(JSON.parse(_res.role).id);
                    setIsLoading(false);
                  }
                });
            }
          }
        })
        .catch((error) => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    setIsInSalePage(
      location.pathname.split('/').pop()?.localeCompare('sale') === 0,
    );
  }, [companyId]);

  useEffect(() => {
    setTotal(subtotal + Number(deliveryTax));
  }, [subtotal, deliveryTax]);

  useEffect(() => {
    setCart(
      state.carts.find((cart) => _.toString(cart.id) === companyId) || {
        id: 0,
        cart: new Array<IItemCarrinho>(),
        observacao: '',
      },
    );
    const tempCart = state.carts.find((cart) => _.toString(cart.id) === companyId);
    
    if(tempCart) {
      const cartProdsIds = tempCart.cart.map((item) => item.prodComposto.produto.id);

      const prodOutOfPromo = tempCart.cart.find((item) => {
        item.prodComposto.produto.promocoes.find((promo) => {
          const prodsIds = promo.produto_promocao.map((prod) => prod.produto_id);

          if(cartProdsIds.find(id => !prodsIds.includes(id))) {
            return true;
          } else {
            return false;
          }
        })
      });

      if(prodOutOfPromo) {
        setHasInvalidPromo(true);
      } else {
        setHasInvalidPromo(false);
      }
    }
  }, [state.carts, companyId]);

  const handleConclusion = () => {
    if (firstClick) {
      setFirstClick(false);
      handleOpenProductSuggestion();
    } else {
      dispatch({
        type: 'SET_OBSERVACAO',
        empresaId: _.toNumber(companyId),
        payload: observacao,
      });

      history.push(`${empresaId}/sale`);
      handleCloseCart()
    }
  };

  const handleAddMore = () => {
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({
      type: 'SET_OBSERVACAO',
      empresaId: _.toNumber(companyId),
      payload: observacao,
    });
    setOpen(false);
    handleCloseCart();
  };

  const handleObsArea = () => {
    setObsArea(!obsArea);
  };

  const handleOpenProductSuggestion = () => {
    setOpenProductSuggestion(true);
  };

  const handleCloseProductSuggestion = () => {
    setOpenProductSuggestion(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <IconButton
            aria-label="arrow-down"
            onClick={handleClose}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon
              fontSize="large"
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography variant="h6" className={classes.detailTitle}>
              Meu pedido
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>
        <Scroll className={clsx(isInSalePage && classes.scroll)}>
          <Body>
            <Wrapper>
              <Divider />
              <List
                aria-label="cart items"
                disablePadding
                classes={{ root: classes.itemList }}
              >
                {cart.cart.map((item: IItemCarrinho) => (
                  <CartItem key={item.id} item={item} companieId={companyId} />
                ))}
              </List>
              <MoreItems>
                <LinkRD to={`/${empresaId}`} className={classes.moreItems}>
                  <Button
                    aria-label="add_more_itens"
                    onClick={handleAddMore}
                    startIcon={
                      <AddCircleOutlineIcon className={classes.moreItemsIcon} />
                    }
                    className={classes.moreItemsButton}
                  >
                    ADICIONAR MAIS ITENS
                  </Button>
                </LinkRD>
              </MoreItems>
              <Divider />
            </Wrapper>
            <Subtotal>
              <Typography variant="body2" className={classes.subDelTax}>
                Subtotal
              </Typography>
              <Typography variant="body2" className={classes.subDelTax}>
                R$ {subtotal.toFixed(2).replace('.', ',')}
              </Typography>
            </Subtotal>
            <DeliveryTax>
              <Typography variant="body2" className={classes.subDelTax}>
                Taxa de entrega
              </Typography>
              <Typography variant="body2" className={classes.subDelTax}>
                R$ {Number(deliveryTax).toFixed(2).replace('.', ',')}
              </Typography>
            </DeliveryTax>
            {/* {melhorPromocao && (
              <DeliveryTax>
                <Typography variant="body2" className={classes.subDelTax}>
                  Desconto na taxa de entrega
                </Typography>
                <Typography variant="body2" className={classes.subDelTax}>
                  {melhorPromocao.tipo_valor === 2
                    ? `R$ ${(Number(deliveryTax) * -1)
                        .toFixed(2)
                        .replace('.', ',')}`
                    : `R$ ${(+melhorPromocao.valor < +deliveryTax
                        ? +deliveryTax - +melhorPromocao.valor
                        : 0
                      )
                        .toFixed(2)
                        .replace('.', ',')}`}
                </Typography>
              </DeliveryTax>
            )} */}
            <Total>
              <Typography variant="body1" className={classes.totalObs}>
                Total
              </Typography>
              <Typography variant="body1" className={classes.totalObs}>
                R$ {Number(total).toFixed(2).replace('.', ',')}
              </Typography>
            </Total>
            {sugestaoPromocao ? (
              <Promocao>
                <p>
                  {+sugestaoPromocao.valor_minimo > subtotal
                    ? `Compra mínima de ${Number(sugestaoPromocao.valor_minimo)
                        .toFixed(2)
                        .replace('.', ',')} para atingir a promoção "${
                        sugestaoPromocao.nome
                      }". Adicione mais itens para se qualificar a promoção.`
                    : `Compra máxima de ${Number(sugestaoPromocao.valor_maximo)
                        .toFixed(2)
                        .replace(
                          '.',
                          ',',
                        )} foi ultrapassada para atingir a promoção "${
                        sugestaoPromocao.nome
                      }". Remova alguns itens para se qualificar a promoção.`}
                </p>
              </Promocao>
            ) : null}

            {hasInvalidPromo && (
              <Promocao>
                <p>Um dos itens no carrinho tornou a promoção inválida</p>
              </Promocao>
            )}
            <Note>
              <div>
                <div>
                  <Typography variant="body1" className={classes.totalObs}>
                    Observação
                  </Typography>
                  <Link
                    component="button"
                    onClick={handleObsArea}
                    className={classes.showNoteLink}
                  >
                    Incluir
                  </Link>
                </div>
                <TextareaAutosize
                  aria-label="observacoes"
                  minRows={5}
                  placeholder="Escreva observações sobre o produto"
                  value={observacao}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setObservacao((event.target as HTMLTextAreaElement).value);
                  }}
                  className={clsx(classes.textArea, !obsArea && classes.hide)}
                />
              </div>
            </Note>
            {/* {
              openProductSuggestion && (suggestedProduct !== -1)
              ? <ProductSuggestion
                  handleCloseProduct={handleCloseProductSuggestion}
                  openProduct={openProductSuggestion}
                  produtoId={suggestedProduct}
                  closed={closed}
                  companieId={companyId}
                />
              : <div style={{ display: 'none' }} />
            } */}
            {
              openProductSuggestion && (suggestedProduct !== -1) && companyId && (
                <ProductSuggestionModal
                  onClose={handleCloseProductSuggestion}
                  isOpen={openProductSuggestion}
                  productId={suggestedProduct}
                  companyId={Number(companyId)}
                  scheduledDays={null}
                />
              )
            }
          </Body>
        </Scroll>

        <>
          <Footer
            className={clsx(
              (isInSalePage || cart.cart.length === 0) && classes.hide,
            )}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={handleConclusion}
              disabled={closed || isLoading}
              style={{
                backgroundColor:
                  isLoading || closed ? '#757575' : theme.primary,
                color: '#fefefe',
                height: '55px',
                width: '80%',
              }}
            >
                Finalizar Pedido
              </Button>
          </Footer>
          <div className={clsx(classes.offset1 && width < 960)} />
        </>
      </Container>
    </Modal>
  );
};

export default Cart;
