import IBanner from '../interfaces/banner';
import { ICaixa } from '../interfaces/caixa';
import { ICupomInfo } from '../interfaces/cupom';
import { IEmpresa } from '../interfaces/empresa';
import { IFormaPagamentoEmpresa } from '../interfaces/formaPagamentoEmpresa';
import { IHorario } from '../interfaces/horario';
import { IParametro } from '../interfaces/parametro';
import { IProduto } from '../interfaces/produto';
import { ISchedule } from '../interfaces/schedule';
import { ISegmento } from '../interfaces/segmento';
import api from './api';

export type Banner = {
  id: number;
  slug: string;
  name: string;
  description: string;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  products: number[];
  companies: number[];
  image: string;
}

interface PagedReturn<T> {
  data: T[];
  total: number; 
  page: number; 
  limit: number;
}

const empresaApi = {
  getGrupos: async (): Promise<IEmpresa[]> => {
    const { data } = await api.get('empresa/grupo');
    return data;
  },
  getGruposByName: async (name: string, state: string): Promise<IEmpresa[]> => {
    const { data } = await api.get('empresa/by-name', {
      params: {
        name,
        state
      }
    });
    return data;
  },
  getGruposByState: async (state: string, category: string): Promise<IEmpresa[]> => {
    const { data } = await api.get(`empresa/by-state`, {
      params: {
        state,
        category
      }
    });
    return data;
  },
  getGruposByRadius: async (lat: number, lng: number, category: string | null): Promise<IEmpresa[]> => {
    const { data } = await api.get('empresa/by-radius', {
      params: {
        lat,
        lng,
        category,
        page: 1,
        limit: 30,
      }
    });
    return data;
  },
  getFiliais: async (id: number): Promise<IEmpresa[]> => {
    const { data } = await api.get(`empresa/grupo/${id}`);
    return data;
  },
  getEmpresa: async (id: number | string): Promise<IEmpresa> => {
    const { data } = await api.get(`empresa/id/${id}`);
    return data;
  },
  getEmpresaSlug: async (slug: string): Promise<IEmpresa> => {
      const { data } = await api.get(`empresa/slug/${slug}`);
      return data;
  },
  getSegmentos: async (category: String): Promise<ISegmento[]> => {
    const { data } = await api.get(`empresa/segmentos/by-categoria`, {
      params: {
        category: category
      }
    });
    return data;
  },
  getFormasPagamento: async (id: string): Promise<IFormaPagamentoEmpresa[]> => {
    const { data } = await api.get(`empresa/formas-pagamento/${id}`);
    return data;
  },
  getHorario: async (id: number): Promise<IHorario> => {
    const { data } = await api.get(`empresa/horario/${id}`);
    return data;
  },
  getParametro: async (id: string, descricao: string): Promise<IParametro> => {
    const { data } = await api.get(`empresa/parametro`, {
      params: {
        descricao,
        empresa_id: id,
      },
    });
    return data;
  },
  getRequiredFields: async (id: number): Promise<{ fields: String[] }> => {
    const { data } = await api.get(`empresa/required-fields/${id}`);
    return data;
  },
  getScheduledDelivery: async (id: number): Promise<ISchedule> => {
    const { data } = await api.get(`empresa/scheduled_delivery/${id}`);
    return data;
  },
  getCupomInfo: async (id: number, chave: string, token: string): Promise<ICupomInfo> => {
    const { data } = await api.get(`/empresa/cupom-desconto/${id}`, {
      params: {
        chave,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  },
  getCupomInfoWithoutToken: async (id: number, chave: string, token: string): Promise<ICupomInfo> => {
    const { data } = await api.get(`/empresa/cupom-desconto/without-auth/${id}`, {
      params: { chave },      
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  },
  getHasDeliveryFee: async (id: number): Promise<{ has: boolean }> => {
    const { data } = await api.get(`empresa/has-delivery-fee/${id}`);
    return data;
  },
  getBanners: async (empresaId: number): Promise<IBanner[]> => {
    const { data } = await api.get(`empresa/banner/${empresaId}`);
    return data;
  },
  getManifest: async (id: number): Promise<any> => {
    const { data } = await api.get(`empresa/manifest/${id}`);
    return data;
  },
  getProdutosWithPromoBySegment: async (segmentId: number, name?: string): Promise<IProduto[]> => {
    const { data } = await api.get(`empresa/segmentos/${segmentId}/oferta`, {
      params: {
        name: name ? name : null,
      }
    });
    return data;
  },
  getCashierParentCompany: async (companyId: number | string): Promise<ICaixa> => {
    const { data } = await api.get(`empresa/caixa/${companyId}`);
    return data;
  },
  getBannersHome: async (): Promise<Banner[]> => {
    const { data } = await api.get(`empresa/banners`);
    return data;
  },
  getAllOffers: async ({ category, name, state }: {
    category?: string;
    name?: string;
    state?: string;
  }): Promise<IProduto[]> => {
    const { data } = await api.get(`empresa/ofertas`, {
      params: {
        category,
        name: name ? name : null,
        state: state ? state : null,
      }
    });
    return data;
  },
  getCompaniesByIds: async (ids: number[]): Promise<IEmpresa[]> => {
    const { data } = await api.get(`empresa/by-ids?ids=${ids}`);
    return data;
  },
  getAllCompanies: async (params: {
    page: number; 
    category: String;
    name?: string;
    state?: string;
  }): Promise<PagedReturn<IEmpresa>> => {
    const { name, state, ...rest } = params;

    const { data } = await api.get(`empresa`, {
      params: {
        limit: 20,
        name: name ? name : null,
        state: state ? state : null,
        ...rest,
      }
    });
    return data;
  },
  getBairrosEmpresa: async (id: number | string): Promise<string[]> => {
    const { data } = await api.get(`empresa/${id}/bairros`);
    return data;
  },
  getGroupsWithFilters: async (
    params: {
      page?: number; 
      limit?: number;
      category?: String;
      name?: string;
      state?: string;
    }
  ): Promise<PagedReturn<IEmpresa>> => {
    const { page, limit, category, name, state } = params;
    const { data } = await api.get(`empresa/grupo/with-filters`, {
      params: {
        page,
        limit,
        category,
        name,
        state,
      }
    });
    return data;
  },
};

export default empresaApi;
