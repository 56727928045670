import React, { useContext, useState, useEffect, Dispatch } from "react";

import { useParams } from "react-router-dom";
import _ from 'lodash';

import Typography  from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { IProduto } from '../../interfaces/produto';
import { IItemPedido } from "../../interfaces/itemPedido";

import {
  Informations,
  Title,
  Body,
  Aditionals,
  Price,
  ComponentsList,
  Imagem,
  GridItem
} from './styles';
import stylesMaterial from './stylesMaterial';
import { Box, Button, IconButton, Modal } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import pedidoApi from "../../services/pedidoApi";
import { AuthContext } from "../../hooks/provideAuth";
import { ToastContainer, toast } from 'react-toastify';
import { IEmpresa } from "../../interfaces/empresa";
import { useTheme } from "../../hooks/useThemeState";

interface OrderDetailsItemProps{
  item: IProduto;
  itemPedido: IItemPedido;
  complementos: IItemPedido[];
  produtosGrade: IItemPedido[];
  setCompanie: Dispatch<React.SetStateAction<IEmpresa | undefined>>
  handleClose: () => void
}

const OrderDetailsItem: React.FC<OrderDetailsItemProps> = ({
  item,
  itemPedido,
  complementos,
  produtosGrade,
  setCompanie,
  handleClose
}) => {
  const classes = stylesMaterial();
  const { state: stateAuth } = useContext(AuthContext);

  const [price, setPrice] = useState(itemPedido.valorTotal);
  const [quantidade, setQuantidade] = useState(itemPedido.quantidade);
  const [qtdSabores, setQtdSabores] = useState(0);
  const [isOpenModalConfirmationDeleteItem, setIsOpenModalConfirmationDeleteItem] = useState(false)
  const { theme } = useTheme()

  const cancelItem = () => {
    setIsOpenModalConfirmationDeleteItem(false)

    pedidoApi.cancelItem(itemPedido.id, stateAuth.token)
    .then(() => {
      handleClose()
      setCompanie(prevState => {
        return _.clone(prevState)
      })
      toast.success('Item Removido!');
    })
    .catch(err => {
      if (err?.response?.data.message === 'item-already-deleted') {
        toast.error('Item já removido');
      } else if (err?.response?.data.message === 'order-stage-other-than-0') {
        toast.error('Este pedido não está mais em aguardo.');
      } else if (err?.response?.data.message === 'order-cannot-contain-less-than-one-item') {
        toast.error('O pedido precisa de pelo menos 1 item.');
      } else {
        toast.error('Erro ao cancelar item. Tente novamente mais tarde.');
      }
      console.log(err)
    })
  }

  const handleOpenModaConfirmationDeleteItem = () => {
    setIsOpenModalConfirmationDeleteItem(true)
  }

  const handleCloseModaConfirmationDeleteItem = () => {
    setIsOpenModalConfirmationDeleteItem(false)
  }

  useEffect(() => {
    let qtdTemp = 1/Math.min(...produtosGrade.map(produto => produto.quantidade));
    if((1 - qtdTemp) > 0.002){
      let i = 2;
      while(((qtdTemp*i) % 1) !== 0){
        i++;
      }
      qtdTemp = qtdTemp*i;
    }
    setQtdSabores(qtdTemp);
  }, []);

  return (
    <>
      <Modal
        open={isOpenModalConfirmationDeleteItem}
        onClose={handleCloseModaConfirmationDeleteItem}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        id="modalConfirmation"
        className={classes.modalConfimationDeleteItem}
      >
        <Box
          className={classes.containerModaConfirmationDeleteItem}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModaConfirmationDeleteItem}
            className={classes.modalConfimationDeleteItemButtonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
            />
          </IconButton>
          <Typography
            variant='h5'
            className={classes.modalConfimationDeleteItemItemTitle}
          >
            Você Tem Certeza?
          </Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={cancelItem}
            style={{
              backgroundColor: theme.primary,
              color: '#fefefe',
              height: '45px',
              flex: 1
            }}
          >
              CONFIRMAR
          </Button>
        </Box>
      </Modal>
      <GridItem>
        <Informations>
          <Title>
            <Typography
              variant='body1'
              className={classes.productTitle}
            >
              {
                (produtosGrade.length > 1) && (_.toNumber(itemPedido.produtoGradeId)!==null)
                ?
                (
                  itemPedido.nomeGradeTamanho !== null
                  ?
                  _.toNumber(quantidade)+"x Pizza "+itemPedido.nomeGradeTamanho
                  :
                  _.toNumber(quantidade)+"x Pizza"
                )
                :
                (
                  (produtosGrade.length > 0) && (itemPedido.nomeGradeTamanho !== null)
                  ?
                  _.toNumber(quantidade)+"x "+itemPedido.nomeProduto+" "+itemPedido.nomeGradeTamanho
                  : (
                    item.controla_grade === 1
                      ? `${_.toNumber(quantidade)}x ${itemPedido.nomeProduto} - Tam: ${itemPedido.nomeGradeTamanho}, Cor: ${itemPedido.nomeGradeCor}`
                      : (item.fracionado 
                          ? `${
                            quantidade >= 1
                              ? Number(quantidade).toFixed(3)
                              : quantidade * item.unidadeMedida.quantidadePorcao
                          }${
                            quantidade >= 1 
                              ? item.unidadeMedida.sigla
                              : item.unidadeMedida.siglaPorcao
                          } - `
                          : Number(quantidade) + 'x ')
                        + itemPedido.nomeProduto
                  )
                )
              }
            </Typography>
          </Title>
          <Aditionals>
            {
            complementos.length || (produtosGrade.length > 1)
            ?
            <ComponentsList >
              {
                produtosGrade.length > 1
                ?
                produtosGrade.map((produto) => (
                  <li
                    key={produto.id}
                  >
                    {Math.round(produto.quantidade*qtdSabores)+"/"+Math.round(qtdSabores)+"x "+produto.nomeProduto}

                  </li>
                ))
                :
                <div style={{ display: 'none' }} />
              }
              {
                complementos.map((complemento) => (
                  <li
                    key={complemento.id}
                  >
                    {_.toNumber(complemento.quantidade)+"x "+complemento.nomeProduto}
                  </li>
                ))
              }
            </ComponentsList>
            :
            <Typography
              variant='body2'
              className={classes.prodDescr}
            >
              {itemPedido.observacao}
            </Typography>
            }
          </Aditionals>
          <Price>
            <Typography
              variant='h6'
              className={classes.priceText}
              style={{ color: theme.primary }}
            >
              R$ {_.toNumber(price).toFixed(2).replace('.',',')}
            </Typography>
          </Price>
        </Informations>
        <Body>
          <Imagem image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}`+item.imagem}>
            <div />
          </Imagem>
        </Body>
      </GridItem>
      <Divider className={classes.divisor} />
    </>
  )
}

export default OrderDetailsItem;
