import React, { FormEvent, useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';

import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

import stylesMaterial from './stylesMaterial';
import _ from 'lodash';

interface SearchFieldProps {
  search: (name: string) => void;
  hide?: boolean;
}

export function SearchField(props: SearchFieldProps) {
  const { 
    search, 
    hide
  } = props;

  const classes = stylesMaterial();

  const [value, setValue] = useState('');

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    search(value);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={`${clsx({[classes.hide]: hide})} ${classes.searchForm}`}
    >
      <IconButton
        type='submit'
        className={classes.searchIcon}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        onClick={() =>{ 
          search('');
          setValue(''); 
        }}
        className={classes.cancelIcon}
      >
        <CancelIcon />
      </IconButton>
      <InputBase
        placeholder="Digite sua busca"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search', id: 'search-field' }}
        value={value}
        onChange={(event) => {
          search(event.target.value);
          setValue(event.target.value);
        }}
      />
    </form>
  );
}