import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mycarousel: {
    minWidth: '100%',
    width: 0,
    '& .slick-slide': {
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
    }
  },
  scroll_container: {
    height: '100%',
    position: 'relative',
    padding: '8px 0px',
    flex: 1
  },
  navbarList: {
    display: '-webkit-flex',
    flexDirection: 'row',
    padding: '0px',
    height: '100%',
  },
  groupLink: {
    display: '-webkit-flex',
    flex: 1,
    minWidth: 'max-content',
    boxSizing: 'border-box',
    '& div': {
      '& div': {
        '& span': {
          padding: '0px 10px 0px 10px',
        }
      }
    }
  },
  activeMenuItem: {
    opacity: 1,
    '& div': {
      marginTop: '0px',
      marginBottom: '0px',
      '& a': {
        '& div': {
          '& div': {
            '& p': {
              fontWeight: 900,
              fontSize: '1rem',
            }
          }
        }
      },
    }
  },
  navbarItems: {
    padding: 0,
    display: '-webkit-flex',
    flexDirection: 'column',
    height: '100%',
    fontSize: '1rem',
    fontWeight: 300,
    minWidth: 'max-content',
    color: '#616161',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 150ms ease 150ms',
    '&:hover': {
      color: '#212121',
      background: 'transparent'
    }
  },
}));