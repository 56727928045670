import { makeStyles } from '@material-ui/core/styles';

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default makeStyles((theme) => {
  const { primary } = useContext(ThemeContext);

  return {
    modalContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalMain: {
      display: 'flex',
      justifyContent: 'stretch',
      backgroundColor: '#fff',
      padding: '0px',
      height: '100%',
      width: '100%',
      [theme.breakpoints.up(700)]: {
        width: '90vw',
        maxHeight: '500px',
        borderRadius: '15px',
      },
      [theme.breakpoints.up(1000)]: {
        maxWidth: '800px',
        minHeight: '600px',
      },
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    modalContainerImage: {
      position: 'relative',
      width: '50%',
      minWidth: '300px',
      boxSizing: 'border-box',
      padding: '0px',
      margin: '0px',
      [theme.breakpoints.down(700)]: {
        display: 'none',
      },
    },
    modalContainerInfo: {
      width: '50%',
      maxWidth: '50%',
      maxHeight: '600px',
      minWidth: '300px',
      boxSizing: 'border-box',
      padding: '15px 15px 10px',
      justifyContent: 'space-between',
      paddingLeft: '12px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(700)]: {
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        minHeight: '100%',
      },
    },
    modalContainerInfoDescription: {
      border: '1px solid gray',
      borderRadius: '15px',
      padding: '12px 15px',
      width: '100%',
    },
    modalContainerInfoDescriptionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    modalContainerInfoDescriptionHeaderTitle: {
      paddingLeft: '15px',
      fontSize: '16px !important',
      fontWeight: 'bold',
    },
    modalContainerInfoDescriptionHeaderPrice: {
      fontWeight: 'bold',
      fontSize: '18px',
      color: primary,
    },
    modalContainerInfoDescriptionDescription: {
      width: '80%',
      margin: '8px 0px 10px 15px !important',
      fontSize: '14px',
    },
    modalContainerInfoDescriptionTotal: {
      display: 'flex',
      borderTop: '2px solid gray',
      justifyContent: 'space-between',
      marginTop: '15px',
      padding: '12px 0px 12px 15px',
    },
    modalContainerInfoDescriptionQuantity: {
      whiteSpace: 'nowrap',
    },
    modalContainerInfoDescriptionTotalButton: {
      display: 'flex',
      gap: '5px',
    },
    modalContainerInfoDescriptionTotalButtonButton: {
      fontSize: '16px',
    },
    observations: {
      width: '100%',
      height: '100px',
    },
    modalContainerInfoSession: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    buttonArrow: {
      width: 40,
      height: 40,
      padding: 5,
      [theme.breakpoints.up(700)]: {
        display: 'none',
      },
    },
    iconArrow: {
      width: 30,
      height: 30,
      padding: 5,
    },
    buttonClose: {
      width: 40,
      height: 40,
      padding: 5,
      [theme.breakpoints.down(700)]: {
        display: 'none',
      },
    },
    iconClose: {},
    detailTitle: {
      fontSize: '1.2rem',
      transform: 'translateX(-20px)',
      [theme.breakpoints.up(700)]: {
        transform: 'translateX(20px)',
      },
    },
    productTitle: {
      fontSize: '1.2rem',
      marginTop: '10px',

      [theme.breakpoints.up(960)]: {
        marginTop: 12,
        lineHeight: '1.3',
      },
    },
    infoPortion: {
      fontSize: '0.9rem',
      lineHeight: 1,
      color: '#757575',
    },
    pricePerUnit: {
      fontSize: '0.9rem',
      fontWeight: 400,
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 500,
      }
    },
    productDesc: {
      overflowWrap: 'break-word',
      color: '#757575',
      fontWeight: 300,
      marginTop: '5px',
      marginBottom: '5px',
      [theme.breakpoints.up(960)]: {
        fontSize: '1.1rem',
        lineHeight: '1.2rem',
      },
    },
    quantityLabel: {
      textAlign: 'center',
      fontSize: '1.4rem',
      minWidth: '40px',
    },
    textArea: {
      width: '100%',
      marginTop: '15px',
      fontSize: '16px',
      padding: '5px',
      borderRadius: '4px',
      minHeight: '100px',
    },
    modalWraper: {
      // overflow: 'hidden',
    },
    consultShipping: {
      fontSize: '1rem',
      marginTop: '15px',
    },
    shipping: {
      fontSize: '1rem',
      color: '#0c65ea',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      alignSelf: 'flex-end',
    },
  };
});
