import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  height: 100vh;
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  padding-right: 5vw;
  padding-left: 5vw;
  width: 100%;
  min-height: max-content;
  align-items: center;
  padding-bottom: 40px;
  flex: 1;
  & > h1 {
    font-size: 60px;
  }
  @media(min-width: 961px){
    padding-right: 100px;
    padding-left: 100px;
  }
`;

export const OrderSteps = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  width: 100%;
  min-height: max-content;

  align-items: center;
  justify-content: center;
  h4 {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }
  a {
    text-decoration: none;
  }
  h5 {
    margin-bottom: 10px;
    color: #b1b1b1;
    font-weight: 300;
    font-size: 14px;
  }
  @media(max-width: 640px){
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 10px;
    }
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;

  @media(min-width: 768px){
    width: 300px;
    height: 250px;
    border-radius: 4px;
  }

  img {
    width: 50px;
    margin-bottom: 10px;
    -webkit-animation: scale-up-center 0.8s both;
    animation: scale-up-center 0.8s both;

    @-webkit-keyframes scale-up-center {
      0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
      }
      100% {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }
    @keyframes scale-up-center {
      0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
      }
      100% {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  button {
    width: 180px;
    height: 30px;
    border: 1px solid transparent;
    border-radius: 8px;
  }
`;
