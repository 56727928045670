import { ITaxaEntrega } from '../interfaces/taxaEntrega';
import { IItemCarrinho } from '../interfaces/itemCarrinho';
import { IPedidoVenda } from '../interfaces/pedidoVenda';

import api from './api';
import { calcOrderPrice } from '../utils/calculator';

interface ShippingCompany {
  id: number;
  name: string;
  picture: string;
}

export interface Shipping {
  id: number;
  name: string;
  price: string;
  custom_price: string;
  discount: string;
  custom_delivery_time: number;
  custom_delivery_range: {
    min: number;
    max: number;
  };
  company: ShippingCompany;
  error?: string;
}

interface ShippingProduct {
  id: number | null;
  quantity: number;
  gridId: number | null;
}

const pedidoApi = {
  getTaxaEntrega: async (
    token: string,
    grupoId: number,
    enderecoId: number,
    cart: IItemCarrinho[],
  ): Promise<ITaxaEntrega> => {
    const produtoIds = cart.map((cartItem) => cartItem.prodComposto.produto.id);

    const { data } = await api.post(
      'pedido/calc-taxa',
      {
        produtoIds: [...produtoIds],
        precoCarrinho: cart
          .map((item) => calcOrderPrice(item))
          .reduce((total, next) => total + next),
      },
      {
        params: {
          grupoId,
          enderecoId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  },
  getTaxaEntregaWithoutToken: async (
    login: string,
    grupoId: number,
    enderecoId: number,
    cart: IItemCarrinho[],
  ): Promise<ITaxaEntrega> => {
    const produtoIds = cart.map((cartItem) => cartItem.prodComposto.produto.id);

    const { data } = await api.post(
      'pedido/calc-taxa/without-auth',
      {
        produtoIds,
        precoCarrinho: cart
          .map((item) => calcOrderPrice(item))
          .reduce((total, next) => total + next),
      },
      {
        params: {
          grupoId,
          enderecoId,
          login,
        },
      },
    );
    return data;
  },
  getQuoteOptions: async (
    products: ShippingProduct[],
    postalCode: string,
    companyId: number,
  ): Promise<Shipping | Shipping[]> => {
    const { data } = await api.post('pedido/calc-frete',{
      products,
      postalCode,
      companyId,
    });
    return data;
  },
  pedido: async (
    cart: IItemCarrinho[],
    receivingMethod: string,
    paymentMethod: number,
    deliveryAddressId: number,
    groupId: number,
    observacao: string,
    selectedCompanyId: number,
    changeMoney: number,
    // scheduledDelivery: string | null,
    scheduledDelivery: Date | null,
    coupon: string,
    freightData: Shipping | null,
    token: string,
  ): Promise<IPedidoVenda> => {
    const cartTemp = cart.map((item) => {
      return {
        produtoId: item.prodComposto.produto.id,
        complementos: item.prodComposto.complementos.map((complemento) => {
          return {
            complementoId: complemento.complemento.id,
            quantidade: complemento.quantidade,
          };
        }),
        subProdutos: item.produtoGrade
          ? []
          : item.produtosGrade.map((produto) => {
              return {
                produtoId: produto.produto.id,
                quantidade: produto.quantidade,
              };
            }),
        tamanhoId: item.produtosGrade.length ? item.produtosGrade[0].produto.produto_grades[0].tamanho_id : null,
        gradeId: item.produtoGrade ? item.produtoGrade.id : null,
        quantidade: item.quantidade,
        observacao: item.observacao
      }
    })
    const { data } = await api.post('/pedido', {
                                      cart: cartTemp,
                                      recevingMethod: receivingMethod,
                                      paymentMethod: "",
                                      paymentMethodId: paymentMethod,
                                      deliveryAddresId: receivingMethod==='retirada' ? null : deliveryAddressId,
                                      note: observacao,
                                      groupId,
                                      selectedCompanyId: receivingMethod==='retirada' ? selectedCompanyId : null,
                                      changeMoney,
                                      scheduledDelivery,
                                      coupon: coupon ? coupon : null,
                                      freightData
                                    }, {
                                      headers: {
                                        Authorization: `Bearer ${token}`
                                      }
                                    });
    return data;
  },
  getPedido: async (token: string, companieId: string) => {
    const res = await api.get(`/pedido/${companieId}`,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return res;
  },
  getOpenPedido: async (email: string, companieId: string) => {
    const res = await api.get(`/pedido/without-auth/${companieId}`, {
      params: {
        login: email
      }
    });
    return res;
  },
  getPedidoById: async (pedidoId: string): Promise<IPedidoVenda> => {
    const res = await api.get(`/pedido/pedido-venda/${pedidoId}`);
    return res.data;
  },
  pedidoWithoutToken: async (
                  cart: IItemCarrinho[],
                  receivingMethod: string,
                  paymentMethod: number,
                  deliveryAddressId: number,
                  groupId: number,
                  observacao: string,
                  selectedCompanyId: number,
                  changeMoney: number,
                  // scheduledDelivery: string | null,
                  scheduledDelivery: Date | null,
                  coupon: string,
                  tokenLogin: string
                ): Promise<IPedidoVenda>  => {

    let cartTemp = cart.map((item) => {
      return {
        produtoId: item.prodComposto.produto.id,
        complementos: item.prodComposto.complementos.map((complemento) => {
          return {
            complementoId: complemento.complemento.id,
            quantidade: complemento.quantidade
          }
        }),
        subProdutos:
          item.produtoGrade
          ?
          []
          :
          item.produtosGrade.map((produto) =>{
            return {
              produtoId: produto.produto.id,
              quantidade: produto.quantidade
            }
          })
        ,
        tamanhoId: item.produtosGrade.length ? item.produtosGrade[0].produto.produto_grades[0].tamanho_id : null,
        gradeId: item.produtoGrade ? item.produtoGrade.id : null,
        quantidade: item.quantidade,
        observacao: item.observacao
      }
    })
    const { data } = await api.post('/pedido/without-auth', {
                                      cart: cartTemp,
                                      recevingMethod: receivingMethod,
                                      paymentMethod: "",
                                      paymentMethodId: paymentMethod,
                                      deliveryAddresId: receivingMethod==='retirada' ? null : deliveryAddressId,
                                      note: observacao,
                                      groupId,
                                      selectedCompanyId: receivingMethod==='retirada' ? selectedCompanyId : null,
                                      changeMoney,
                                      scheduledDelivery,
                                      coupon: coupon ? coupon : null
                                    }, {
                                      headers: {
                                        Authorization: `Bearer ${tokenLogin}`
                                      }
                                    });
    return data;
  },

  cancelOrder: async (pedidoVendaId: number, token: string) => {
    const res = await api.put(`/pedido/pedido-venda/${pedidoVendaId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return res;
  },

  cancelItem: async (itemPedido: any,token: string) => {
    const res = await api.put(`/pedido/item-pedido/pedido/${itemPedido}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return res;
  },

  requestInTextFormatForWhatsapp: async (pedidoVenda: number) => await api.get(`/pedido/full-texto/${pedidoVenda}`)
};

export default pedidoApi;
