import React, { useState, useEffect } from "react";

import _ from 'lodash';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import RadioGroup from '@material-ui/core/RadioGroup';

import { IItemComplemento } from "../../interfaces/itemComplemento";
import { IGrupoComplemento } from "../../interfaces/grupoComplemento";
import { IComplementoCarrinho } from "../../interfaces/complementoCarrinho";

import ProductComponent from '../product-component';
import ProductComponentRadio from '../product-component-radio';

import { Container, Header } from './styles';
import stylesMaterial from './stylesMaterial';

interface ProductComponentGroupProps{
  grupoComplemento: IGrupoComplemento;
  setComponent: (cartComponent: IComplementoCarrinho) => void;
  complementos: IComplementoCarrinho[];
  isLoading: boolean;
  groupsUnderMinimum: { id: number; under: boolean; }[];
  handleGroupsUnderMinimum: (groups: { id: number; under: boolean; }[]) => void;
}

const ProductComponentGroup: React.FC<ProductComponentGroupProps> = ({
                                                                      grupoComplemento,
                                                                      setComponent,
                                                                      complementos,
                                                                      isLoading,
                                                                      groupsUnderMinimum,
                                                                      handleGroupsUnderMinimum
                                                                    }) => {
  const classes = stylesMaterial();

  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [full, setFull] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState((_.toNumber(grupoComplemento.qtd_min)===1)?
  _.toString(grupoComplemento.item_complemento[0].id):"");

  useEffect(() => {
    if(!isLoading){
      if ((_.toNumber(grupoComplemento.qtd_min)===1) && (_.toNumber(grupoComplemento.qtd_max)===1)){

        if (grupoComplemento.item_complemento[0] !== undefined){
          let complemento = grupoComplemento.item_complemento[0];
          setComponent({ complemento, quantidade: 1});
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    let temp: IComplementoCarrinho[] = complementos.filter((item) =>
                                          item.complemento.complemento_id === grupoComplemento.id);

    if (temp.length){
      let tempQuantity = 0;
      temp.map((item) => {
        tempQuantity += _.toNumber(item.quantidade)
      });
      setSelectedQuantity(tempQuantity);
    } else {
      setSelectedQuantity(0);
    }
  }, [complementos]);

  useEffect(() => {
    if (grupoComplemento.qtd_max !== null){
      if (selectedQuantity===(_.toNumber(grupoComplemento.qtd_max))){
        setFull(true);
      } else {
        setFull(false);
      }
    }

    if (grupoComplemento.qtd_min !== null){
      if(selectedQuantity < (_.toNumber(grupoComplemento.qtd_min))){
        let temp = groupsUnderMinimum.map((group) => {
          if (group.id === grupoComplemento.id){
            return {id: grupoComplemento.id, under: true};
          } else{
            return group;
          }
        })
        handleGroupsUnderMinimum(temp);
      } else{
        let temp = groupsUnderMinimum.map((group) => {
          if (group.id === grupoComplemento.id){
            return {id: grupoComplemento.id, under: false};
          } else{
            return group;
          }
        })
        handleGroupsUnderMinimum(temp);
      }
    }
  }, [selectedQuantity]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const complemento = grupoComplemento.item_complemento.find((complemento) =>
      _.toNumber((event.target as HTMLInputElement).value) === complemento.id
    );
    setSelectedComponent((event.target as HTMLInputElement).value);
    if (complemento !== undefined){
      setComponent({complemento, quantidade: 1});
    }
  };

  const handleClearRadioGroup = (value: string) => {
    if ((value === selectedComponent) && (_.toNumber(grupoComplemento.qtd_min)===0)){
      setSelectedComponent("");

      const complemento = grupoComplemento.item_complemento.find((complemento) =>
      _.toNumber(value) === complemento.id
      );
      if (complemento !== undefined){
        setComponent({complemento, quantidade: 0});
      }
    }else {
      const complemento = grupoComplemento.item_complemento.find((complemento) =>
      _.toNumber(value) === complemento.id
      );
      if (complemento !== undefined){
        setComponent({complemento, quantidade: 1});
        setSelectedComponent(value);
      }
    }
  }

  return (
    <Container id={`Grupo-Complemento-${grupoComplemento.id}`} >
      <Header>
        <Typography
          variant='subtitle1'
          className={classes.listTitle}
        >
          {grupoComplemento.descricao}
        </Typography>
        {
          (
            (grupoComplemento.qtd_max !== null)
            && ((_.toNumber(grupoComplemento.qtd_max)!==1) || (_.toNumber(grupoComplemento.qtd_min)!==1))
          )
          && (
            <Typography
              variant='subtitle1'
              className={classes.listQuantity}
              style={{
                color:
                _.toNumber(grupoComplemento.qtd_min) > 0
                ?
                selectedQuantity < (_.toNumber(grupoComplemento.qtd_min)) ? '#ff0000' : '#4caf50'
                :
                '#1a1a1a'
              }}
            >
              {
                (_.toNumber(grupoComplemento.qtd_min) > 0)
                ?
                `Escolhas ${selectedQuantity}/${_.toNumber(grupoComplemento.qtd_max)} - Mínimo ${_.toNumber(grupoComplemento.qtd_min)}`
                :
                `Escolhas ${selectedQuantity}/${_.toNumber(grupoComplemento.qtd_max)}`
              }
            </Typography>
          )
        }
      </Header>

      {
        (_.toNumber(grupoComplemento.qtd_max)!==1)
        ?
        <List
        component="nav"
        aria-label="group-of-components"
        >
          {
            grupoComplemento.item_complemento.map((complemento: IItemComplemento) => {
              return (
                <ProductComponent
                  setComponent={setComponent}
                  complemento={complemento}
                  full={full}
                  key={complemento.id}
                />
              );
            })
          }
        </List>
        :
        <RadioGroup
          aria-label="radio-group-of-components"
          name="radio-group-of-components"
          value={selectedComponent}
          onChange={handleRadioChange}
        >
          {
            grupoComplemento.item_complemento.map((complemento: IItemComplemento) => {
              return (
                <ProductComponentRadio
                  setComponent={setComponent}
                  complemento={complemento}
                  handleClearRadioGroup={handleClearRadioGroup}
                  key={complemento.id}
                />
              );
            })
          }
        </RadioGroup>
      }
    </Container>
  )
}

export default ProductComponentGroup;
