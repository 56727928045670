import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listTitle: {
    fontSize: '1.5rem',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 500
  },
  listItem: {
    paddingRight: '5px',
    paddingLeft: '5px'
  },
  divisor: {
    marginBottom: '10px',
    [theme.breakpoints.up(960)]: {
      marginBottom: '15px'
    },
  },
  addAddressIcon:{
    width: '50px',
    height: '50px',
    color: '#f57c00'
  }
}));
