import React, { useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/pt-br';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import Option from './option';

import {
  Container,
  Header,
  Scroll,
  Body,
  Wrapper,
  GridContainer,
  Footer
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../hooks/useThemeState";
import { Shipping } from "../../services/pedidoApi";

interface SelectShippingModalProps{
  handleCloseSelectShippingModal: () => void;
  openSelectShippingModal: boolean;
  selectedShipping: Shipping | null;
  setSelectedShipping: (value: Shipping) => void;
  shippingOptions: Shipping[];
}

export const SelectShippingModal: React.FC<SelectShippingModalProps> = ({
  handleCloseSelectShippingModal,
  openSelectShippingModal,
  selectedShipping,
  setSelectedShipping,
  shippingOptions
}) => {

  const classes = stylesMaterial();
  const { theme } = useTheme()

  const [open, setOpen] = useState(openSelectShippingModal);
  const [selectedShippingTemp, setSelectedShippingTemp] = useState<Shipping | null>(null);

  return (
    <Modal
      open={open}
      onClose={handleCloseSelectShippingModal}
      aria-labelledby="scheduled-delivery-modal"
      aria-describedby="scheduled-delivery-modal"
      id="modal"
    >
      <Container>
        <Header>
          <IconButton
            aria-label="arrow-down"
            onClick={handleCloseSelectShippingModal}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon
              fontSize='large'
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography
              variant="h6"
              className={classes.detailTitle}
            >
              Seleção de frete
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleCloseSelectShippingModal}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>
        <Scroll>
          <Body>
            <Wrapper>
              <Typography
                variant='h5'
                className={classes.title}
              >
                Selecione o frete
              </Typography>
              <Divider />
              
              <GridContainer>
                {shippingOptions.filter(option => !option.error).map((option, index) => {
                    return (
                      <Option
                        value={option}
                        checked={option===selectedShippingTemp}
                        setSelectedShipping={setSelectedShippingTemp}
                        key={index}
                      />
                    );
                })}
              </GridContainer>
            </Wrapper>
          </Body>
        </Scroll>

        <React.Fragment>
          <Footer>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if(selectedShippingTemp){
                  setSelectedShipping(selectedShippingTemp);
                }

                handleCloseSelectShippingModal();
              }}
              disabled={!selectedShippingTemp}
              style={{
                backgroundColor: theme.primary,
                color: '#fefefe',
                height: '45px',
                width: '90%'  }}
            >
                Confirmar
            </Button>
          </Footer>
        </React.Fragment>
      </Container>

    </Modal>
  )
}
