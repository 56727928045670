export type State = {
  token: string;
  tokenLogin: string;
}

type Action =
 | { type: 'SET_TOKEN', payload: string }
 | { type: 'REMOVE_TOKEN'}
 | { type: 'SET_LOGIN', payload: string }
 | { type: 'REMOVE_LOGIN'}

const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_TOKEN':
        return {
            ...state,
            token: action.payload
        };
    case 'REMOVE_TOKEN':
        return {
            ...state,
            token: ""
        };
    case 'SET_LOGIN':
      return {
        ...state,
        tokenLogin: action.payload
      };
    case 'REMOVE_LOGIN':
      return {
        ...state,
        tokenLogin: ""
      };
    default:
        return state;
  }
};

export default Reducer;
