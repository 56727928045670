import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listTitle: {
    fontSize: '1.2rem',
    color: '#1a1a1a',
    fontWeight: 500,
    lineHeight: 1.2
  },
  listQuantity: {
    fontSize: '0.813rem',
    lineHeight: 1.2
  }
}));
