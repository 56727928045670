import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { cep } from '../../utils/masks';
import { toast } from 'react-toastify';

import stylesMaterial from './stylesMaterial';
import { Container, DeliveryFeeContainer, Header } from './styles';
import pedidoApi, { Shipping } from '../../services/pedidoApi';
import { Button, Divider, IconButton, Modal, TextField, Typography } from '@material-ui/core';
import { SelectShippingModal } from '../select-shipping-modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

interface CalcFrete {
  cep: string;
}

interface ConsultShippingModalProps {
  openConsultShippingModal: boolean;
  handleCloseConsultShippingModal: () => void;
  productId?: number;
  quantity: number;
  gridId?: number;
  companyId: number;
  selectedShipping: Shipping | null;
  setSelectedShipping: (value: Shipping) => void;
}

export function ConsultShippingModal(props: ConsultShippingModalProps) {
  const { 
    openConsultShippingModal, 
    handleCloseConsultShippingModal,
    productId,
    gridId,
    quantity,
    companyId,
    selectedShipping,
    setSelectedShipping
  } = props;

  const classes = stylesMaterial();
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { cep: "" }
  });

  const [open, setOpen] = useState(openConsultShippingModal);
  const [cepInput, setCepInput] = useState("");
  const [openSelectShippingModal, setOpenSelectShippingModal] = useState(false);
  const [shippingOptions, setShippingOptions] = useState<Shipping[]>([]);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleClose = () => {
    handleCloseConsultShippingModal();
  };

  function onSubmit(data: CalcFrete) {
    const { cep } = data;

    pedidoApi.getQuoteOptions([{ 
      id: productId ? productId : null, 
      quantity,
      gridId: gridId ? gridId : null
    }], cep, companyId).then((res) => {
      console.log(res);
      if(Array.isArray(res)){
        setShippingOptions(res);
      } else {
        if(!res.error){
          setShippingOptions([res]);
        } else {
          toast.error(res.error, toast_config);
        }
      }

      handleOpenSelectShippingModal();
    }).catch((error) => console.log(error));
  }

  function handleOpenSelectShippingModal() {
    setOpenSelectShippingModal(true);
  }

  function handleCloseSelectShippingModal() {
    setOpenSelectShippingModal(false);
    handleClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="scheduled-delivery-modal"
      aria-describedby="scheduled-delivery-modal"
      id="modal"
      className={classes.modal}
    >
      <Container>
        <Header>
          <IconButton
            aria-label="arrow-down"
            onClick={handleClose}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon
              fontSize='large'
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography
              variant="h6"
              className={classes.title}
            >
              Consulta de frete
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>

        <Divider />

        <DeliveryFeeContainer
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div>
            <TextField
              id="cep"
              name="cep"
              type="cep"
              label="CEP"
              placeholder="Digite seu CEP"
              variant="outlined"
              value={cepInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let temp = cep(event);
                setCepInput(temp);
              }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Por favor digite seu cep",
                },
                minLength: {
                  value: 9,
                  message: "Por favor digite um cep válido"
                }
              })}
            />
            <ErrorMessage
              errors={errors}
              name="cep"
              render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
            />
          </div>

          <Button
            type='submit'
          >
            Consultar
          </Button>
        </DeliveryFeeContainer>

        {openSelectShippingModal && shippingOptions.length && (
          <SelectShippingModal 
            handleCloseSelectShippingModal={handleCloseSelectShippingModal}
            openSelectShippingModal={openSelectShippingModal}
            shippingOptions={shippingOptions}
            selectedShipping={selectedShipping}
            setSelectedShipping={setSelectedShipping}
          />
        )}
      </Container>
    </Modal>
  );
}