import React, { useEffect, useState, forwardRef } from 'react';

import clsx from 'clsx';
import _ from 'lodash';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/pt-br';
import pt from 'date-fns/locale/pt-BR';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import Option from './option';

import {
  Container,
  Header,
  Scroll,
  Body,
  Wrapper,
  GridContainer,
  DatePickerWrapper,
  Footer,
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from '../../hooks/useThemeState';

interface ScheduledDeliveryModalProps {
  handleCloseSDModal: () => void;
  openSDModal: boolean;
  handleConclusion: () => void;
  selectedHour: string;
  selectedDay: any;
  setSelectedDay: (value: any) => void;
  setSelectedHour: (value: string) => void;
  hoursOptions: string[];
  valueRM: string;
  deliveryTax: number;
  isOpen: boolean;
  hasDeliveryNow: boolean;
  hasWithdrawNow: boolean;
}

const ScheduledDeliveryModal: React.FC<ScheduledDeliveryModalProps> = ({
  handleCloseSDModal,
  openSDModal,
  handleConclusion,
  selectedHour,
  selectedDay,
  setSelectedDay,
  setSelectedHour,
  hoursOptions,
  valueRM,
  deliveryTax,
  isOpen,
  hasDeliveryNow,
  hasWithdrawNow,
}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();

  const moment = require('moment');

  const [width, setWidth] = useState(window.innerWidth);

  const [open, setOpen] = useState(openSDModal);
  type ButtonProps = JSX.IntrinsicElements['button'];

  useEffect(() => {
    setDefaultLocale('pt-br');
    registerLocale('pt-br', pt);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSelectedHour('');
    }
  }, [isOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleCloseSDModal();
  };

  const CustomButton = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ value, onClick }, ref) => {
      return (
        <Button
          variant="contained"
          onClick={onClick}
          ref={ref}
          className={classes.datepickerButton}
        >
          {value}
          <KeyboardArrowDownOutlinedIcon style={{ color: theme.primary }} />
        </Button>
      );
    },
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="scheduled-delivery-modal"
      aria-describedby="scheduled-delivery-modal"
      id="modal"
    >
      <Container>
        <Header>
          <IconButton
            aria-label="arrow-down"
            onClick={handleClose}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon
              fontSize="large"
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography variant="h6" className={classes.detailTitle}>
              Entrega programada
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>
        <Scroll>
          <Body>
            <Wrapper>
              <Typography variant="h5" className={classes.title}>
                Escolha o dia
              </Typography>
              <Divider />
              <DatePickerWrapper>
                <DatePicker
                  selected={selectedDay}
                  onChange={(date) => setSelectedDay(date)}
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomButton />}
                />
              </DatePickerWrapper>
              {isOpen &&
                ((valueRM === 'retirada' && hasWithdrawNow) ||
                  (valueRM === 'delivery' && hasDeliveryNow)) && (
                  <>
                    <Typography variant="h5" className={classes.title}>
                      Para agora
                    </Typography>
                    <Divider />
                    <DatePickerWrapper>
                      <Option
                        value="Padrão"
                        checked={'Padrão' === selectedHour}
                        setSelectedHour={setSelectedHour}
                        delivery={valueRM === 'delivery'}
                        deliveryTax={deliveryTax}
                      />
                    </DatePickerWrapper>
                  </>
                )}
              {hoursOptions.length > 0 && (
                <>
                  <Typography variant="h5" className={classes.title}>
                    Agendamento
                  </Typography>
                  <Divider />
                  <GridContainer>
                    {hoursOptions.map((option, index) => {
                      return (
                        <Option
                          value={option}
                          checked={option === selectedHour}
                          setSelectedHour={setSelectedHour}
                          delivery={valueRM === 'delivery'}
                          deliveryTax={deliveryTax}
                          key={index}
                        />
                      );
                    })}
                  </GridContainer>
                </>
              )}
            </Wrapper>
          </Body>
        </Scroll>

        <React.Fragment>
          <Footer>
            <Button
              variant="contained"
              fullWidth
              onClick={handleConclusion}
              style={{
                backgroundColor: theme.primary,
                color: '#fefefe',
                height: '45px',
                width: '90%',
              }}
              disabled={!selectedHour}
            >
              Confirmar
            </Button>
          </Footer>
          <div className={clsx(classes.offset1 && width < 960)} />
        </React.Fragment>
      </Container>
    </Modal>
  );
};

export default ScheduledDeliveryModal;
