// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import DefaultImage from '../../assets/no_image.png';
import {
  ItemContainer,
  ItemDetail,
  ItemImage,
  ItemDescription,
  Price,
  DetailHeader,
  ItemWrapper,
  ImageWrapper,
  FigureImg,
} from './styles';

import { IProduto } from '../../interfaces/produto';
import { CustomFigure } from './CustomFigure';
import { CustomDetails } from './CustomDetails';

export type ProductType = {
  image: any;
  name: string;
  description: string;
  origprice?: string;
  price: string;
  discount?: number;
};

interface StorefrontProductProps {
  product?: IProduto;
  handleProduct?: (product: IProduto, type: number) => void;
  isLoading: boolean;
}

const StorefrontProduct: React.FC<StorefrontProductProps> = ({
  product,
  handleProduct,
  isLoading,
}) => {

  const featuredProduct = product?.produto_grades.find(grid => grid.destaque)

  const isProductAvaiable = (): boolean => {
    if (!isLoading && product) {
      if (product.estoque) {
        if (product.estoque.ativo) {
          if (product.estoque.estoque_minimo < product.estoque.estoque_atual) {
            return true;
          }
        } else {
          return false;
        }
      }
    }

    return false;
  };

  return (
    <>
      {isLoading ? (
        <ItemWrapper isAvaiable={isLoading && isProductAvaiable()}>
          <ItemContainer tabIndex={0}>
            <ImageWrapper>
              <Skeleton variant="rect" width="100%">
                <FigureImg>
                  <ItemImage defaultImg={DefaultImage} />
                </FigureImg>
              </Skeleton>
            </ImageWrapper>
            <ItemDetail>
              <DetailHeader>
                <Skeleton variant="text" width={80} height={20} />
              </DetailHeader>
              <ItemDescription>
                <Skeleton variant="rect" width="100%" height={45} />
              </ItemDescription>
              <Price>
                <Skeleton variant="text" width={70} height={20} />
              </Price>
            </ItemDetail>
          </ItemContainer>
        </ItemWrapper>
      ) : (
        <>
          {product && (
            <ItemWrapper
              onClick={() => {
                if (handleProduct) handleProduct(product, product.tipo);
              }}
              isAvaiable={isProductAvaiable()}
            >
              <ItemContainer tabIndex={0}>
                <CustomFigure
                  product={product}
                  featuredProduct={featuredProduct}
                />

                <CustomDetails
                  product={product}
                  featuredProduct={featuredProduct}
                />
              </ItemContainer>
            </ItemWrapper>
          )}
        </>
      )}
    </>
  );
};

export default StorefrontProduct;
