import styled from 'styled-components';

export const HalfLine = styled.hr`
    width: 130px;
    height: 1px;
    background-color: #6a5d5d;

    @media(max-width: 640px){
        width: 18vw;
    }
`;
export const ItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    padding: 0 40px;

    @media(max-width: 649px) {
        padding: 0;
    }
`;

export const ItemHead = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px) {
        display: none;
    }
`;
export const ItemTitle = styled.h3`
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-family: Segoe UI, sans-serif;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin: 0px 20px;
    
    @media(max-width: 768px) {
        font-size: 1.3rem;
    }
`;

export const TitleAndShowAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media(max-width: 768px) {
    margin-top: 1rem;
    justify-content: space-between;
  }
`;

export const SeeMore = styled.p<{ showAll: boolean }>`
  color: #0c65ea;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #0c65ea;

  @media(max-width: 768px) {
    font-size: 0.75rem;
  }
`;