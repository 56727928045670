import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  clotheTitle: {
    fontSize: '1.2rem',
    marginTop: 10,
    fontWeight: 400,
  },
  description: {},
  price: {
    fontSize: '18px',
    color: '#0c65ea',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  oldPrice: {
    fontSize: '16px',
    color: '#757575',
    fontWeight: 300,
    textDecoration: 'line-through',
    whiteSpace: 'nowrap',
  },
  discount: {
    fontSize: '16px',
    color: '#ae414d',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  divider: {
    marginTop: '10px',
  },
  cores: {
    color: '#000',
    fontWeight: 400,
    fontSize: '1.05rem',
    marginBottom: '5px',
  },
  quantity: {
    fontSize: '18px',
    color: '#000',
    fontWeight: 400,
  },
  button: {
    marginTop: '20px',
    width: '80%',
    height: '46px',
    alignSelf: 'center',
  },
  modalContainerInfo: {
    width: '50%',
    maxWidth: '50%',
    maxHeight: '600px',
    minWidth: '300px',
    boxSizing: 'border-box',
    padding: '15px 15px 10px',
    justifyContent: 'space-between',
    paddingLeft: '12px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        minHeight: '100%',
    }
  },
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(700)]: {
        display: 'none'
    },
  },
  iconArrow: {
    width: 30,
    height: 30,
    padding: 5,
  },
  buttonClose: {
      width: 40,
      height: 40,
      padding: 5,
      [theme.breakpoints.down(700)]: {
              display: 'none'
      },
  },
  iconClose: {},
  detailTitle: {
    fontSize: '1.2rem',
    transform: 'translateX(-20px)',
    [theme.breakpoints.up(700)]: {
        transform: 'translateX(20px)',
    },
  },
  productDesc: {
    overflowWrap: 'break-word',
    color: '#757575',
    fontWeight: 300,
    marginTop: '5px',
    marginBottom: '5px',
    [theme.breakpoints.up(960)]: {
        fontSize: '1.1rem',
        lineHeight: '1.2rem'
    }
  },
  consultShipping: {
    fontSize: '1rem',
    marginTop: '15px'
  },
  shipping: {
    fontSize: '1rem',
    color: '#0c65ea',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));
