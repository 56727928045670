import React from 'react';
import { ColorForShow } from '../..';

import {
  Circle
} from './styles';

interface ColorCircleProps {
  selected: boolean;
  color: ColorForShow;
  setSelectedColor: (color: ColorForShow) => void;
  setSelectedSize: (size: string) => void;
}

export const ColorCircle: React.FC<ColorCircleProps> = (props) => {
  const { selected, color, setSelectedColor, setSelectedSize } = props;

  return (
    <Circle
      selected={selected}
      color={color.color.hex}
      onClick={() => {
        setSelectedColor(color);
        setSelectedSize(color.sizes[0]);
      }}
    />
  );
}
