import React, { useCallback, useEffect, useRef, useState } from 'react';

import Divider from '@material-ui/core/Divider';

import stylesMaterial from './stylesMaterial';

import CategorySelectList from '../../components/category-selectlist';
import { Footer } from '../../components/footer';
import ItemList from '../../components/item-list';
import SegmentCarousel from '../../new-components/segmentCarosel';
import { useTheme } from '../../hooks/useThemeState';
import { IProduto } from '../../interfaces/produto';
import { ISegmento } from '../../interfaces/segmento';
import { CustomAppBar } from '../../new-components/custom-appbar';
import { OfferGroup } from '../../new-components/offer-group';
import empresaApi from '../../services/empresaApi';
import defaultTheme from '../../styles/themes/default';
import defaultLogo from '../../assets/logo-atac.svg'
import {
  Body,
  CategoryFilter,
  Container,
  FilterTitle,
  LoadingContainer,
  SegmentFilter,
} from './styles';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { SelectLocationModal } from './SelectLocationModal';
import { Helmet } from 'react-helmet';
import { Banners } from './Banners';
import produtosApi from '../../services/produtosApi';
import { IEmpresa } from '../../interfaces/empresa';
import { ItemListLazyLoading } from '../../new-components/item-list-lazy-loading';

export interface ReducedState {
  longName: string;
  shortName: string;
  lat?: number;
  lng?: number;
}

const Home: React.FC = () => {
  const classes = stylesMaterial();
  const { theme, setTheme } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const categories = [
    {
      value: 'alimentacao',
      label: 'Alimentação',
    },
    {
      value: 'lojas',
      label: 'Lojas',
    },
    {
      value: 'todos',
      label: 'Todos',
    },
  ];

  const [selectedSegment, setSelectedSegment] = useState<ISegmento | null>(null);
  const [segments, setSegments] = useState<ISegmento[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(
    sessionStorage.getItem('selected-category-atacdelivery') 
    ? JSON.parse(sessionStorage.getItem('selected-category-atacdelivery') || "")
    : {
      name: 'alimentacao',
      fromSearch: false
    }
  );
  const [offers, setOffers] = useState<IProduto[]>([]);
  const [allOffers, setAllOffers] = useState<IProduto[]>([]);
  const [productsWithFreeDelivery, setProductsWithFreeDelivery] = useState<IProduto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSelectLocationModal, setOpenSelectLocationModal] = useState(false);
  const [state, setState] = useState<ReducedState | null>(null);
  const [allCompanies, setAllCompanies] = useState<IEmpresa[]>([]);
  const [closeCompanies, setCloseCompanies] = useState<IEmpresa[]>([]);
  const [allCompaniesTotal, setAllCompaniesTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const getAllCompanies = useCallback((page: number, reset: boolean = false) => {
    empresaApi.getAllCompanies({ 
      page, 
      category: selectedCategory.name,
      name: searchValue,
      state: state?.shortName,
    }).then((res) => {
      if (reset) {
        setAllCompanies(res.data);
        setAllCompaniesTotal(res.total);
      } else {
        setAllCompanies(prevState => [
          ...prevState, 
          ...res.data.filter((company) => prevState.findIndex((comp) => comp.id === company.id) === -1)
        ]);
        setAllCompaniesTotal(res.total);
      }
      
    }).catch((err) => console.log(err));
  }, [allCompanies, selectedCategory, searchValue, state]);

  useEffect(() => {
    if (theme.title !== 'default') {
      setTheme(defaultTheme);
    }

    const savedState = sessionStorage.getItem('client-state-atacdelivery');

    if (savedState) {
      setState(JSON.parse(savedState));
    } else {
      setOpenSelectLocationModal(true);
    }
  }, []);

  useEffect(() => {
    if (state) {
      sessionStorage.setItem('client-state-atacdelivery', JSON.stringify(state))
    }
  }, [state]);

  useEffect(() => {
    if (selectedCategory) {
      sessionStorage.setItem('selected-category-atacdelivery', JSON.stringify(selectedCategory));

      produtosApi.getProdutosWithFreeDelivery({
        category: selectedCategory.name,
        name: searchValue,
        state: state?.shortName,
      }).then((res) => {
        setProductsWithFreeDelivery(res);
      }).catch((err) => console.log(err));
      empresaApi.getAllOffers({
        category: selectedCategory.name,
        name: searchValue,
        state: state?.shortName,
      }).then((res) => {
        setAllOffers(res);
      }).catch((err) => console.log(err));

      getAllCompanies(1, true);
    }
  }, [selectedCategory, searchValue, state]);

  useEffect(() => {
    if (selectedSegment && selectedSegment.id !== -1) {
      empresaApi
        .getProdutosWithPromoBySegment(selectedSegment.id, searchValue)
        .then((res) => {
          setOffers(res);
        }).catch((err) => {
          console.log(err);
        });
    }

    setIsLoading(false);
  }, [selectedSegment, searchValue]);

  const handleSearch = useCallback(_.debounce((name: string) => {
    setSearchValue(name);
  }, 1000), []);

  function handleOpenSelectLocationModal() {
    setOpenSelectLocationModal(true);
  };

  function handleCloseSelectLocationModal() {
    setOpenSelectLocationModal(false);
  };

  useEffect(() => {
    if(state && state.lat && state.lng) {
      empresaApi.getGruposByRadius(state.lat, state.lng, selectedCategory.name).then((res) => {
        setCloseCompanies(res);
      }).catch((error) => console.log(error));
    }
  } , [state]);

  useEffect(() => {
    empresaApi.getGroupsWithFilters({
      category: selectedCategory.name,
      name: searchValue,
      state: state?.shortName
    }).then((res) => {
      let segments: ISegmento[];
      const tempSegments: ISegmento[] = res.data.map(grupo => grupo.seguimento);

      segments = tempSegments.filter((segment, index) => {
        return tempSegments.findIndex(tempSegment => tempSegment.id === segment.id) === index;
      }).map((segment) => {
        return {
          ...segment,
          empresa: res.data.filter(grupo => grupo.seguimento.id === segment.id)
        }
      });

      if (segments.length > 0) {
        setSegments([{
          id: -1,
          descricao: "Todos",
          ativo: true,
        }, ...segments]);

        setSelectedSegment({
          id: -1,
          descricao: "Todos",
          ativo: true,
        });
      } else {
        setSegments([]);
        setSelectedSegment(null);
      }
    }).catch(err => console.log("Erro na busca de grupos", err));
  }, [selectedCategory, state]);

  useEffect(() => {
    empresaApi.getGroupsWithFilters({
      category: selectedCategory.name,
      name: searchValue,
      state: state?.shortName
    }).then((res) => {
      let segments: ISegmento[];
      const tempSegments: ISegmento[] = res.data.map(grupo => grupo.seguimento);

      segments = tempSegments.filter((segment, index) => {
        return tempSegments.findIndex(tempSegment => tempSegment.id === segment.id) === index;
      }).map((segment) => {
        return {
          ...segment,
          empresa: res.data.filter(grupo => grupo.seguimento.id === segment.id)
        }
      });

      if (segments.length > 0) {
        setSegments([{
          id: -1,
          descricao: "Todos",
          ativo: true,
        }, ...segments]);

        setSelectedSegment({
          id: -1,
          descricao: "Todos",
          ativo: true,
        });
      } else {
        setSegments([]);
        setSelectedSegment(null);
      }
    }).catch(err => console.log("Erro na busca de grupos", err));
  }, [searchValue]);

  return (
    <Container ref={containerRef}>
      <Helmet>
        <title>Atac Delivery</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={defaultLogo}
        />
        <link
          rel="icon"
          sizes="32x32"
          type="image/png"
          href={defaultLogo}
        />
        <link
          rel="icon"
          sizes="16x16"
          type="image/png"
          href={defaultLogo}
        />
        <link rel="manifest" href={defaultLogo} />
      </Helmet>
      <CustomAppBar
        hasSearchField
        search={handleSearch}
        openLocationModal={handleOpenSelectLocationModal}
        state={state}
      />
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ): (
        <Body>
          <CategoryFilter>
            <FilterTitle>O que você está procurando?</FilterTitle>

            <CategorySelectList
              data={categories}
              chosen={selectedCategory.name}
              onChange={(category: string) => {
                setSelectedCategory({ name: category, fromSearch: false });
              }}
            />
          </CategoryFilter>

          <SegmentFilter>
            <Divider className={classes.divider} />
            <SegmentCarousel
              data={segments}
              chosen={selectedSegment}
              onChange={(segment: ISegmento) => {
                setSelectedSegment(segment);
              }}
            />
            <Divider className={classes.divider} />
          </SegmentFilter>

          {selectedSegment && selectedSegment.id === -1 && (<Banners />)}

          {selectedSegment && (
            <>
              {offers.length > 0 &&  selectedSegment.id !== -1 && (
                <OfferGroup title="Ofertas" products={offers} />
              )}

              {selectedSegment && selectedSegment.id === -1 && (
                <>
                  {allOffers.length > 0 && (
                    <OfferGroup title="Ofertas" products={allOffers} />
                  )}

                  {closeCompanies.length > 0 && (
                    <ItemList title="Lojas próximas" companies={closeCompanies} isCloseStores />
                  )}

                  {productsWithFreeDelivery.length > 0 && (
                    <OfferGroup 
                      title="Produtos com frete grátis" 
                      products={productsWithFreeDelivery} 
                    />
                  )}
                </>
              )}

              {selectedSegment.id !== -1 && (
                <ItemList title={selectedSegment.descricao} companies={selectedSegment.empresa || []} />
              )}

              {selectedSegment.id === -1 && (
                <ItemListLazyLoading 
                  title="Lojas" 
                  companies={allCompanies} 
                  loadFunction={getAllCompanies}
                  totalItems={allCompaniesTotal}
                />
              )}
            </>
          )}
        </Body>
      )}
      <Footer />
      {openSelectLocationModal && (
        <SelectLocationModal
          handleCloseSelectLocationModal={handleCloseSelectLocationModal}
          openSelectLocationModal={openSelectLocationModal}
          setState={setState}
        />
      )}
    </Container>
  );
};

export default Home;