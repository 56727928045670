import React, { useState } from "react";

import _ from 'lodash';

import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';

import { Drawer } from './drawer';

import stylesMaterial from './stylesMaterial';
import { 
  Container
} from './styles';

interface MenuProps {
  companieId: number;
}

export const Menu: React.FC<MenuProps> = ({ companieId }) => {
  const classes = stylesMaterial();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  }

  return (
    <Container>
      <IconButton 
        aria-label="menu"
        onClick={handleOpenDrawer}
        className={classes.menuButton}
      >
        <MenuIcon style={{ color: '#FFF' }} />
      </IconButton>
      
      {
        openDrawer 
        ? <Drawer 
            handleCloseDrawer={handleCloseDrawer} 
            openDrawer={openDrawer} 
            empresaId={companieId}
          /> 
        : <div style={{display: 'none'}} />
      }
    </Container>
  )
}