import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default makeStyles((theme) => {
  const { primary } = useContext(ThemeContext);


  return ({
    listItem: {
      paddingRight: '0px',
      paddingLeft: '10px',
      display: '-webkit-flex',
      flexDirection: 'row',
      gap: '5px'
    },
    buttonBase: {
      display: '-webkit-flex',
      flexDirection: 'row',
      flex: 1
    },
    productTitle: {
      fontSize: '1.05rem'
    },
    priceText: {
      fontSize: '1.05rem',
      color: primary
    },
    componentDesc: {
      paddingTop: '5px',
      paddingBottom: '5px',
      color: '#D3D3D3'
    }
  })
});
