import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import GlobalStyle from './styles/global';
import ScrollToTop from './components/scrollToTop';
import HelmetComponent from './components/helmet';

import AppProvider from './hooks';

import withClearCache from "./ClearCache";

import { ThemeProviderApp } from './hooks/useThemeState';

import { ToastContainer } from 'react-toastify';

function MainApp() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppProvider>
        <ThemeProviderApp>
          <Routes />
        </ThemeProviderApp>
      </AppProvider>
      <GlobalStyle />
      <HelmetComponent />
      <ToastContainer />
    </BrowserRouter>
  );
};

const MainAppWithClearCache = withClearCache(MainApp);

const App: React.FC = () => <MainAppWithClearCache />;

export default App;
