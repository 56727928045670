import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #FFF;
  height: 60vh;
  width: 90vw;
  max-width: 550px;
  max-height: 350px;

  border-radius: 10px;

  @media(max-width: 960px){
    
  }
  
  @media(max-height: 680px){
    
  }
`;