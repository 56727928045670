import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media (min-width: 700px){
    width: 90vw;
    max-height: 500px;
    border-radius: 15px;
  }
  @media (min-width: 1000px){
    max-width: 800px;
    min-height: 600px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;