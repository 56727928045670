import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  backButton:{
    backgroundColor: '#0c65ea',
    width: '200px',
    height: '40px',
    marginTop: '30px',
    alignSelf: 'flex-end',
    '&:hover':{
      backgroundColor: '#0c65ea'
    },
    [theme.breakpoints.between(640,1280)]: {
      width: '25% !important'
    },
    [theme.breakpoints.down(641)]: {
      width: '35% !important'
    },
  },
  nextButton:{
    backgroundColor: '#0c65ea',
    width: '200px',
    height: '40px',
    marginTop: '30px',
    alignSelf: 'flex-end',
    '&:hover':{
      backgroundColor: '#0c65ea'
    },
    [theme.breakpoints.down(640)]: {
      width: '30%'
    },
  },
  linkContinueButton:{
    marginTop: '50px',
    height: '40px',
    width: '500px',
    textDecoration: 'none',
    [theme.breakpoints.down(640)]: {
      width: '80%'
    },
  },
  scheduled:{
    fontSize: '18px',
    fontWeight: 'normal',
    marginTop: '10px',
  },
  sDInfosButton: {
    marginTop: '10px',
    width: '170px',
    height: '70px',
    justifyContent: 'space-between',
    backgroundColor: '#fefefe',
    '&:hover':{
      backgroundColor: '#fefefe',
    },
  },
  dayText: {
    fontSize: '0.9rem',
    fontWeight: 500
  },
  hourText: {
    fontSize: '0.8rem',
    fontWeight: 400
  },
  rMText:{
    fontSize: '18px',
    fontWeight: 'normal'
  },
  consumptionOnSite: {
    marginTop: '10px'
  },
  selectShipping: {
    marginTop: '15px',
    backgroundColor: '#269660',
    color: '#fff',
    '&:hover': {
      background: '#269660',
      opacity: 0.8
    }
  },
  scheduleLink:{
    color: '#269660',
    fontSize: '16px',
    fontWeight: 'normal'
  },
  divider: {
    width: '100%',
    backgroundColor: '#c4c4c4',
    [theme.breakpoints.up(760)]: {
      display: 'none'
    }
  },
  schedulingButton: {
    color: '#fefefe',
    background: '#269660',
    width: '170px',
    height: '40px',
    justifyContent: 'space-between'
  }
}));
