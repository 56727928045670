import React, { useContext, useState, useEffect, useCallback } from "react";

import { useParams } from "react-router-dom";
import _ from 'lodash';

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CloseIcon from '@material-ui/icons/Close';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import geographicApi from '../../services/geographicApi';
import userApi from '../../services/userApi';
import { cep } from '../../utils/masks';
import MapModal from '../map-modal';

import { AuthContext } from '../../hooks/provideAuth';

import { IEstado } from '../../interfaces/estado';
import { ICidade } from '../../interfaces/cidade';
import { IEmpresa } from "../../interfaces/empresa";
import { IEnderecoEntrega } from '../../interfaces/enderecoEntrega';

import {
  FirstStep,
  Header,
  UserInfo,
  Footer
} from './styles';
import stylesMaterial from './stylesMaterial';
import empresaApi from "../../services/empresaApi";
import { WithoutNumberConfirmation } from "./without-number-confirmation";
import { useTheme } from "../../hooks/useThemeState";
import { levenshteinDistance } from "../../utils/levenshteinAlgorithm";

interface IAddressInfo{
  address: string;
  complement: string;
  neighborhood: string;
}

interface DeliveryAddressModalProps{
  handleCloseDeliveryAddressModal: () => void;
  openDeliveryAddressModal: boolean;
  registerSucess: () => void;
  enderecoDefault?: IEnderecoEntrega;
  initialPlace?: google.maps.GeocoderResult;
  handleCloseAutoComplete?: () => void;
  isEmpty?: boolean;
  isCurrentLocation?: boolean;
  setIsCurrentLocation?: (value: boolean) => void;
  hasTaxByNeighborhoods?: boolean;
}

const defaultValues = {
  description: "",
  cep: "",
  address: "",
  complement: "",
  neighborhood: "",
  house_number: "",
  uf: {
    id: 21,
    sigla: "MA",
    nome: "Maranhão",
    regiao: {
      id: 2,
      sigla: "NE",
      nome: "Nordeste",
    }
  },
  city: {
    id: 2111300,
    nome: "São Luís",
    microrregiao:{
      id: 21002,
      nome: "Aglomeração Urbana de São Luís",
      mesorregiao: {
        id: 2101,
        nome: "Norte Maranhense",
        UF: {
          id: 21,
          sigla: "MA",
          nome: "Maranhão",
          regiao: {
            id: 2,
            sigla: "NE",
            nome: "Nordeste",
          }
        }
      }
    }
  },
  principal: "Não"
};

const DeliveryAddressModal: React.FC<DeliveryAddressModalProps> = ({
  handleCloseDeliveryAddressModal,
  openDeliveryAddressModal,
  registerSucess,
  enderecoDefault,
  initialPlace,
  handleCloseAutoComplete,
  isEmpty,
  isCurrentLocation,
  setIsCurrentLocation,
  hasTaxByNeighborhoods = false,
}) => {
  const classes = stylesMaterial();
  const { companieId } = useParams<{ companieId: string }>();

  defaultValues.description = enderecoDefault?.descricao ? enderecoDefault.descricao : "";
  defaultValues.house_number = (enderecoDefault?.endereco.numero !== undefined)?
                                _.toString(enderecoDefault.endereco.numero):
                                "" ;
  defaultValues.principal = (enderecoDefault?.principal) ?
                            "Sim":
                            "Não";
  const { register, handleSubmit, errors, setError, getValues } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues
  });
  const { state } = useContext(AuthContext);

  const [open, setOpen] = useState(openDeliveryAddressModal);
  const [cepInput, setCepInput] = useState("");
  const [openMapModal, setOpenMapModal] = useState(false);
  const [openWithoutNumberConfirmation, setOpenWithoutNumberConfirmation] = useState(false);
  const [companie, setCompanie] = useState<IEmpresa>();
  const [endEntrega, setEndEntrega] = useState<IEnderecoEntrega>();
  const [latLong, setLatLong] = useState<{lat: number, long: number}>({lat: -2.535286, long:  -44.226380});
  const [addressInfo, setAddressInfo] = useState<IAddressInfo>({
    address: "",
    complement: "",
    neighborhood: "",
  });
  const { theme } = useTheme()
  const [houseN, setHouseN] = useState("");
  const [selectedState, setSelectedState] = useState<IEstado>(defaultValues.uf);
  const [selectedCity, setSelectedCity] = useState<ICidade>(defaultValues.city);
  const [selectedPrincipal, setSelectedPrincipal] = useState(
    enderecoDefault?.principal
    ?
    "Sim"
    :
    ( isEmpty ? "Sim" : "Não")
  );
  const [selectedDescription, setSelectedDescription] = useState(
    enderecoDefault
    ?
    enderecoDefault.descricao
    :
    "Casa"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [stateOptions, setStateOptions] = useState<IEstado[]>(
    [
      {
        id: 21,
        sigla: "MA",
        nome: "Maranhão",
        regiao: {
          id: 2,
          sigla: "NE",
          nome: "Nordeste",
        }
      }
    ]
  );
  const [cityOptions, setCityOptions] = useState<ICidade[]>(
    [
      {
        id: 2111300,
        nome: "São Luís",
        microrregiao:{
          id: 21002,
          nome: "Aglomeração Urbana de São Luís",
          mesorregiao: {
            id: 2101,
            nome: "Norte Maranhense",
            UF: {
              id: 21,
              sigla: "MA",
              nome: "Maranhão",
              regiao: {
                id: 2,
                sigla: "NE",
                nome: "Nordeste",
              }
            }
          }
        }
      }
    ]
  );
  const [neighborhoodOptions, setNeighborhoodOptions] = useState<string[]>([]);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleClose = () => {
    setOpen(false);
    if(setIsCurrentLocation) {
      setIsCurrentLocation(false);
    }
    handleCloseDeliveryAddressModal();
  };

  const handleOpenMapModal = () => {
    if(houseN !== ""){
      setOpenMapModal(true);
    } else {
      handleOpenWithoutNumberConfirmation();
    }
  };

  const handleCloseMapModal = () => {
    setIsLoading(false);
    setOpenMapModal(false);
  };

  const handleOpenWithoutNumberConfirmation = () => {
    setIsLoading(false);
    setOpenWithoutNumberConfirmation(true);
  };

  const handleCloseWithoutNumberConfirmation = () => {
    setOpenWithoutNumberConfirmation(false);
    setError('house_number', { type: 'custom', message: 'Informe o número', shouldFocus: true })
  };

  const registerAddress = (lat: number, long: number) => {
    if(endEntrega !== undefined){
      if (enderecoDefault === undefined){
        if(state.token){
          userApi.saveEndereco({...endEntrega, lat: _.toNumber(lat), lon: _.toNumber(long)}, state.token).then((res) => {
            if (res.status>=200 && res.status<300){
              registerSucess();
              handleClose();
              if(handleCloseAutoComplete){
                handleCloseAutoComplete();
              }
            } else {
              throw new Error(res.status.toString());
            }
          }).catch((error) => {
            setIsLoading(false);
            toast.error('Erro no cadastro. Verifique seus dados ou tente mais tarde.', toast_config);
            console.log("Erro no cadastro de endereço");
          });
        } else {
          userApi.saveEnderecoWithoutLogin({...endEntrega, lat: _.toNumber(lat), lon: _.toNumber(long)}, state.tokenLogin).then((res) => {
            if (res.status>=200 && res.status<300){
              registerSucess();
              handleClose();
              if(handleCloseAutoComplete){
                handleCloseAutoComplete();
              }
            } else {
              throw new Error(res.status.toString());
            }
          }).catch((error) => {
            setIsLoading(false);
            toast.error('Erro no cadastro. Verifique seus dados ou tente mais tarde.', toast_config);
            console.log("Erro no cadastro de endereço sem token");
          });
        }
      } else {
        let temp = {...endEntrega, lat: lat, lon: long};
        userApi.updateEndereco({...temp, id: enderecoDefault.id}, state.token).then((res) => {
          if (res.status>=200 && res.status<300){
            registerSucess();
            handleClose();
            if(handleCloseAutoComplete){
              handleCloseAutoComplete();
            }
          } else {
            throw new Error(res.status.toString());
          }
        }).catch((error) => {
          setIsLoading(false);
          toast.error('Erro na alteração. Verifique seus dados ou tente mais tarde.', toast_config);

          console.log(error);
        });
      }
    }
  }

  const onSubmit = (data: any) => {
    const { house_number, address, neighborhood, city, uf} = data;
    setIsLoading(true);
    setEndEntrega({
      taxaEntregaId: 0,
      principal: (data.principal==="Sim")?true:false,
      descricao: data.description,
      endereco: {
        cep: data.cep,
        numero: house_number,
        logradouro: address,
        bairro: neighborhood,
        complemento: data.complement,
        cidade: city,
        estado: uf
      },
    });
    if ((enderecoDefault?.lat !== undefined) && (enderecoDefault.lon !== undefined)){
      setLatLong({lat: enderecoDefault.lat, long:  enderecoDefault.lon});
      handleOpenMapModal();
      setIsLoading(false);
    } else {
      const houserNumberFromInitial = initialPlace && initialPlace.address_components.find((component) => component.types.includes('street_number'))?.long_name;
      if(
        (
          initialPlace &&
          ((Math.abs(Number(houserNumberFromInitial) - Number(house_number)) <= 50) || house_number === '') &&
          (address === initialPlace.address_components.find((component) => component.types.includes('route'))?.long_name) && 
          (neighborhood === initialPlace.address_components.find((component) => (component.types.includes('sublocality_level_1') || component.types.includes('sublocality')))?.long_name)
        )
      ) {
        setLatLong({
          lat: initialPlace?.geometry.location.lat() || 0,
          long: initialPlace?.geometry.location.lng() || 0
        });
        handleOpenMapModal();
      } else {
        geographicApi.getLatLong(house_number,address,neighborhood,city,uf).then((res) =>{
          if(res.results[0]){
            setLatLong({
              lat: Number(res.results[0].geometry.location.lat),
              long: Number(res.results[0].geometry.location.lng)
            });
            handleOpenMapModal();
          } else {
            geographicApi.getLatLongBairro(data.neighborhood,data.city,data.uf).then((res) =>{
              if(res.results[0]){
                setLatLong({
                  lat: Number(res.results[0].geometry.location.lat),
                  long: Number(res.results[0].geometry.location.lng)
                });
                handleOpenMapModal();
              } else {
                geographicApi.getLatLongCidade(data.city, data.uf).then((res) => {
                  if(res.results[0]){
                    setLatLong({
                      lat: Number(res.results[0].geometry.location.lat),
                      long: Number(res.results[0].geometry.location.lng)
                    });
                    handleOpenMapModal();
                  } else {
                    handleOpenMapModal();
                  }
                }).catch((error) => {
                  handleOpenMapModal();
                });
              }
            }).catch((error) => {
              geographicApi.getLatLongCidade(data.city, data.uf).then((res) => {
                if(res.results[0]){
                  setLatLong({
                    lat: Number(res.results[0].geometry.location.lat),
                    long: Number(res.results[0].geometry.location.lng)
                  });
                  handleOpenMapModal();
                } else {
                  handleOpenMapModal();
                }
              }).catch((error) => {
                handleOpenMapModal();
              });
            });
          }
        }).catch((error) => {
          geographicApi.getLatLongBairro(data.neighborhood,data.city,data.uf).then((res) =>{
            if(res.results[0]){
              setLatLong({
                lat: Number(res.results[0].geometry.location.lat),
                long: Number(res.results[0].geometry.location.lng)
              });
              handleOpenMapModal();
            } else {
              geographicApi.getLatLongCidade(data.city, data.uf).then((res) => {
                if(res.results[0]){
                  setLatLong({
                    lat: Number(res.results[0].geometry.location.lat),
                    long: Number(res.results[0].geometry.location.lng)
                  });
                  handleOpenMapModal();
                } else {
                  handleOpenMapModal();
                }
              }).catch((error) => {
                handleOpenMapModal();
              });
            }
          }).catch((error) => {
            geographicApi.getLatLongCidade(data.city, data.uf).then((res) => {
              if(res.results[0].geometry.location.lat !== undefined){
                setLatLong({
                  lat: Number(res.results[0].geometry.location.lat),
                  long: Number(res.results[0].geometry.location.lng)
                });
                handleOpenMapModal();
              } else {
                handleOpenMapModal();
              }
            }).catch((error) => {
              handleOpenMapModal();
            });
          });
        });
      }
    }
  };

  useEffect(() =>{
    geographicApi.getEstados().then((res) =>{
      setStateOptions(res);
    }).catch((error) => console.log(error));

    if (enderecoDefault !== undefined){
        setCepInput(enderecoDefault.endereco.cep);
        setHouseN(enderecoDefault.endereco.numero);
        const { logradouro, bairro, complemento} = enderecoDefault.endereco;
        const tempLog = _.toString(logradouro);
        const tempBairro = _.toString(bairro);
        const tempComp = _.toString(complemento);
        setAddressInfo({
          address: tempLog,
          complement: tempComp,
          neighborhood: tempBairro
        });
    }

    if (initialPlace){
      setHouseN(
        initialPlace.address_components
          .find((component) => component.types.includes('street_number'))
          ?.long_name || ''
      );
      setCepInput(
        initialPlace.address_components
          .find((component) => component.types.includes('postal_code'))
          ?.long_name || ''
      );

      setAddressInfo({
        address:
          initialPlace.address_components
            .find((component) => component.types.includes('route'))
            ?.long_name || ''
        ,
        complement: "",
        neighborhood:
          initialPlace.address_components
            .find((component) => (
              component.types.includes('sublocality_level_1')
              ||
              component.types.includes('sublocality')
            ))
            ?.long_name || ''
      });
    }
  }, []);

  useEffect(() => {
    async function fetchCompanyInfo() {
      const isId = !Number.isNaN(Number(companieId));
      
      const resCompany = 
        isId 
        ? await empresaApi.getEmpresa(companieId) 
        : await empresaApi.getEmpresaSlug(companieId)
      ;
      setCompanie(resCompany);

      if (hasTaxByNeighborhoods) {
        try {
          const resNeighborhoods = await empresaApi.getBairrosEmpresa(resCompany.id);
          setNeighborhoodOptions(resNeighborhoods);
          
          // if (initialPlace) {
            // const initialNeighborhood = 
            //   initialPlace.address_components.find((component) => (
            //     component.types.includes('sublocality_level_1')
            //     ||
            //     component.types.includes('sublocality')
            //   ))?.long_name || ''
            // ;
  
            // const orderedByDistancesNeighborhoods = resNeighborhoods.sort((a, b) => levenshteinDistance(
            //   initialNeighborhood.toLowerCase(),
            //   a.toLowerCase()
            // ) - levenshteinDistance(
            //   initialNeighborhood.toLowerCase(),
            //   b.toLowerCase()
            // ));
  
            // setAddressInfo(prev => ({
            //   ...prev,
            //   neighborhood: orderedByDistancesNeighborhoods[0],
            // }));
  
            // const levenshteinDistances = resNeighborhoods.map(
            //   (neighborhood) => levenshteinDistance(
            //     neighborhood.toLowerCase(),
            //     initialNeighborhood.toLowerCase()
            //   )
            // );
            // const lowestDistanceIndex = levenshteinDistances.indexOf(Math.min(...levenshteinDistances));
            // const mostCloseNeighborhood = resNeighborhoods[
            //   lowestDistanceIndex !== -1 ? lowestDistanceIndex : 0
            // ];
  
            // setAddressInfo(prev => ({
            //   ...prev,
            //   neighborhood: mostCloseNeighborhood,
            // }));
          // }
  
          // if (enderecoDefault){
          //   const initialNeighborhood = enderecoDefault.endereco.bairro;
  
          //   const orderedByDistancesNeighborhoods = resNeighborhoods.sort((a, b) => levenshteinDistance(
          //     initialNeighborhood.toLowerCase(),
          //     a.toLowerCase()
          //   ) - levenshteinDistance(
          //     initialNeighborhood.toLowerCase(),
          //     b.toLowerCase()
          //   ));
  
          //   setAddressInfo(prev => ({
          //     ...prev,
          //     neighborhood: orderedByDistancesNeighborhoods[0],
          //   }));
          // }
  
          const initialNeighborhood = 
            enderecoDefault 
            ? enderecoDefault.endereco.bairro
            : initialPlace 
              ? initialPlace.address_components.find((component) => (
                  component.types.includes('sublocality_level_1')
                  ||
                  component.types.includes('sublocality')
                ))?.long_name || ""
              : ""
          ;
          
          if (initialNeighborhood) {
            const orderedByDistancesNeighborhoods = resNeighborhoods.sort((a, b) => levenshteinDistance(
              initialNeighborhood.toLowerCase(),
              a.toLowerCase()
            ) - levenshteinDistance(
              initialNeighborhood.toLowerCase(),
              b.toLowerCase()
            ));
  
            setAddressInfo(prev => ({
              ...prev,
              neighborhood: orderedByDistancesNeighborhoods[0],
            }));
          } else {
            setAddressInfo(prev => ({
              ...prev,
              neighborhood: "",
            }));
          }
        } catch(error) {
          console.log(error)
        }
      }
    }

    fetchCompanyInfo();
  }, [companieId]);

  useEffect(() => {
    if((stateOptions.length > 1) && companie){
      if(initialPlace){
        const longName = initialPlace.address_components
          .find((component) => component.types.includes('administrative_area_level_1'))
          ?.long_name || '';
        const shortName = initialPlace.address_components
          .find((component) => component.types.includes('administrative_area_level_1'))
          ?.short_name || '';

        const defaultState = stateOptions.find((state) => (
          state.nome === longName
          ||
          state.sigla === shortName
        ));
        if(defaultState){
          setSelectedState(defaultState);
        }
      } else if(enderecoDefault) {
        const defaultState = stateOptions.find((state) => state.nome === enderecoDefault.endereco.estado);

        if(defaultState){
          setSelectedState(defaultState);
        }
      } else {
        const defaultState = stateOptions.find((state) => (
          state.nome === companie.empresas[0].endereco.estado
          ||
          state.sigla === companie.empresas[0].endereco.estado
        ));
        if(defaultState){
          setSelectedState(defaultState);
        }
      }
    }
  }, [stateOptions, companie]);

  useEffect(() => {
    if((cityOptions.length > 0) && companie){
      if(initialPlace){
        const longName = initialPlace.address_components
          .find((component) => component.types.includes('administrative_area_level_2'))
          ?.long_name || '';


        const defaultCity = cityOptions.find((city) => city.nome === longName);
        if(defaultCity){
          setSelectedCity(defaultCity);
        }
      } else if(enderecoDefault && selectedState.nome === enderecoDefault.endereco.estado) {
        const defaultCity = cityOptions.find((city) => city.nome === enderecoDefault.endereco.cidade);

        if(defaultCity){
          setSelectedCity(defaultCity);
        }
      } else {
        const defaultCity = cityOptions.find((city) => city.nome === companie.empresas[0].endereco.cidade);
        if(defaultCity){
          setSelectedCity(defaultCity);
        }
      }
    }
  }, [cityOptions, companie]);

  useEffect(() =>{
    geographicApi.getCidades(selectedState.id.toString()).then((res) =>{
      setCityOptions(res);
    }).catch((error) => console.log(error));
  }, [selectedState]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <FirstStep>
        <Header>
          <span className={classes.span} />
          <div>
            <Typography
              variant="h6"
              className={classes.title}
            >
              {enderecoDefault !== undefined ? "Edição de endereço":"Criação de endereço"}
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#b22222' }}
            />
          </IconButton>
        </Header>
        <Divider className={classes.titleDivider} />
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
        >
          {
            enderecoDefault !== undefined
            ? "Altere os dados que deseja editar:"
            : "Preencha os dados para cadastrar um endereço de entrega:"
          }
        </Typography>
        <form
          id="envio-info-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={classes.form}
        >
          <UserInfo>
            <div className={`${classes.description} ${classes.fixDisplay}`}>
              <Autocomplete
                id="description-combo"
                options={['Casa', 'Trabalho', 'Outros']}
                getOptionLabel={(option) => option}
                getOptionSelected = {(option,value) => _.isEqual(option,value)}
                value={selectedDescription}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue !== null){
                    setSelectedDescription(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    name="description"
                    type="description"
                    label="Descrição"
                    variant="outlined"
                    inputRef={register}
                  />
                )}
              />
            </div>
            <div className={`${classes.cep} ${classes.fixDisplay}`}>
              <TextField
                required
                id="cep"
                name="cep"
                type="cep"
                label="CEP"
                placeholder="Digite seu CEP"
                variant="outlined"
                value={cepInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let temp = cep(event);
                  setCepInput(temp);
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu cep",
                  },
                  minLength: {
                    value: 9,
                    message: "Por favor digite um cep válido"
                  }
                })}
              />
              <ErrorMessage
                errors={errors}
                name="cep"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.address} ${classes.fixDisplay}`}>
              <TextField
                required
                id="address"
                name="address"
                type="address"
                label="Logradouro"
                placeholder="Rua/Avenida..."
                variant="outlined"
                value={addressInfo.address}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAddressInfo({...addressInfo, address: (event.target as HTMLInputElement).value});
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite o logradouro",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="address"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.complement} ${classes.fixDisplay}`}>
              <TextField
                id="complement"
                name="complement"
                type="complement"
                label="Complemento"
                placeholder="Apto/Bloco/Quadra"
                variant="outlined"
                value={addressInfo.complement}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAddressInfo({...addressInfo, complement: (event.target as HTMLInputElement).value});
                }}
                inputRef={register}
              />
            </div>
            <div className={`${classes.neighborhood} ${classes.fixDisplay}`}>
              {
                neighborhoodOptions.length !== 0 && hasTaxByNeighborhoods ? (
                  <Autocomplete
                    id="combo-box-neighborhood"
                    options={neighborhoodOptions}
                    defaultValue={""}
                    getOptionLabel={(option) => option}
                    getOptionSelected = {(option,value) => _.isEqual(option,value)}
                    value={addressInfo.neighborhood}
                    onChange={(event: any, newValue: string | null) => {
                      if (newValue !== null){
                        setAddressInfo({...addressInfo, neighborhood: newValue});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField 
                        {...params}
                        name="neighborhood"
                        type="neighborhood"
                        label="Bairro"
                        placeholder="Digite o bairro"
                        variant="outlined"
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Bairro não selecionado",
                          },
                        })}
                      />
                    )}
                    noOptionsText={
                      <Typography variant="body1" color="secondary">
                        Bairro fora da área de entrega
                      </Typography>
                    }
                  />
                ) : (
                  <TextField
                    required
                    id="neighborhood"
                    name="neighborhood"
                    type="neighborhood"
                    label="Bairro"
                    placeholder="Digite o bairro"
                    variant="outlined"
                    value={addressInfo.neighborhood}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressInfo({...addressInfo, neighborhood: (event.target as HTMLInputElement).value});
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Por favor digite o bairro",
                      },
                    })}
                    InputProps={{
                      readOnly: !!isCurrentLocation,
                    }}
                  />
                )
              }
              
              <ErrorMessage
                errors={errors}
                name="neighborhood"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.houseNumber} ${classes.fixDisplay}`}>
              <TextField
                id="house_number"
                name="house_number"
                type="house_number"
                label="Número"
                placeholder="Digite o número"
                variant="outlined"
                value={houseN}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHouseN((event.target as HTMLInputElement).value);
                }}
                inputProps={{
                  maxLength: 15
                }}
                inputRef={register({
                  required: {
                    value: false,
                    message: "Por favor digite o número",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="house_number"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <Autocomplete
              id="combo-box-uf"
              options={stateOptions}
              getOptionLabel={(option) => option.nome}
              value={selectedState}
              onChange={(event: any, newValue: IEstado | null) => {
                if (newValue !== null){
                  setSelectedState(newValue);
                }
              }}
              getOptionSelected = {(option,value) => _.isEqual(option,value)}
              className={classes.uf}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  name="uf"
                  type="uf"
                  label="Estado"
                  variant="outlined"
                  inputRef={register}
                  InputProps={{
                    readOnly: !!isCurrentLocation,
                  }}
                />
              )}
            />
            <Autocomplete
              id="combo-box-city"
              options={cityOptions}
              getOptionLabel={(option) => option.nome}
              value={selectedCity}
              onChange={(event: any, newValue: ICidade | null) => {
                if (newValue !== null){
                  setSelectedCity(newValue);
                }
              }}
              getOptionSelected = {(option,value) => _.isEqual(option,value)}
              className={classes.city}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  name="city"
                  type="city"
                  label="Cidade"
                  variant="outlined"
                  inputRef={register}
                />
              )}
            />
            <div className={`${classes.principal} ${classes.fixDisplay}`}>
              <Autocomplete
                id="combo-box-principal"
                options={["Sim", "Não"]}
                disableClearable
                defaultValue={isEmpty===true?"Sim":"Não"}
                getOptionLabel={(option) => option}
                getOptionSelected = {(option,value) => _.isEqual(option,value)}
                value={selectedPrincipal}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue !== null){
                    setSelectedPrincipal(newValue);
                  }
                }}
                renderInput={(params) => <TextField {...params}
                                                    name="principal"
                                                    type="principal"
                                                    label="Principal"
                                                    variant="outlined"
                                                    inputRef={register({
                                                      required: {
                                                        value: true,
                                                        message: "Por favor selecione uma opção",
                                                      },
                                                    })}
                                                    />}
              />
              <ErrorMessage
                errors={errors}
                name="principal"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
          </UserInfo>
        </form>

        <React.Fragment>
          <Footer >
            <Button
              type="submit"
              form="envio-info-form"
              variant="contained"
              fullWidth
              disabled={isLoading}
              style={{
                backgroundColor: theme.primary,
                color: '#fefefe',
                height: '45px',
                width: '90%'
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              fullWidth
              style={{
                background: 'transparent',
                border: `1px solid ${theme.primary}`,
                color: theme.primary,
                height: '45px',
                marginTop: '10px',
                width: '90%'
              }}
            >
              Voltar
            </Button>
          </Footer>
          <div className={classes.offset1} />
        </React.Fragment>


        {
          openMapModal && (
            <MapModal
              handleCloseMapModal={handleCloseMapModal}
              openMapModal={openMapModal}
              latLong={latLong}
              register={registerAddress}
            />
          )
        }
        {
          openWithoutNumberConfirmation && (
            <WithoutNumberConfirmation
              handleCloseWithoutNumberConfirmation={handleCloseWithoutNumberConfirmation}
              handleOpenMap={() => setOpenMapModal(true)}
              number={houseN}
              openWithoutNumberConfirmation={openWithoutNumberConfirmation}
            />
          )
        }
      </FirstStep>

    </Modal>
  )
}

export default DeliveryAddressModal;
