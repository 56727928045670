import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Container } from './styles';
import stylesMaterial from './stylesMaterial';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { googleLoader } from '../../../utils/googleLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IEstado } from '../../../interfaces/estado';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import geographicApi from '../../../services/geographicApi';
import { ReducedState } from '..';

interface SelectLocationModalProps {
  handleCloseSelectLocationModal: () => void;
  openSelectLocationModal: boolean;
  setState: (state: ReducedState | null) => void;
}

export function SelectLocationModal(props: SelectLocationModalProps) {
  const {
    handleCloseSelectLocationModal,
    openSelectLocationModal,
    setState
  } = props;

  const classes = stylesMaterial();

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [open, setOpen] = useState(openSelectLocationModal);
  const [selectStateFromComboBox, setSelectStateFromComboBox] = useState(false);
  const [selectedState, setSelectedState] = useState<IEstado | null>();
  const [stateOptions, setStateOptions] = useState<IEstado[]>([]);

  let geocoder: google.maps.Geocoder;

  useEffect(() => {
    geographicApi.getEstados().then((res) =>{
      const withBrOption = [{
        id: 0,
        sigla: 'BR',
        nome: 'Brasil',
        regiao: {
          id: 0,
          sigla: 'BR',
          nome: 'Brasil'
        }
      }].concat(res);
      setStateOptions(withBrOption);
    }).catch((error) => console.log(error));
  }, []);

  const handleClose = () => {
    setOpen(false);
    handleCloseSelectLocationModal();
  };

  const useCurrentLocation = () => {
    googleLoader.load().then(() => {
      geocoder = new google.maps.Geocoder();

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            geocoder.geocode({ location: pos }, (res, status) =>{
              if(status === "OK"){
                if(res !== null){
                  const longName = res[0].address_components
                    .find((component) => component.types.includes('administrative_area_level_1'))
                    ?.long_name || '';
                  const shortName = res[0].address_components
                    .find((component) => component.types.includes('administrative_area_level_1'))
                    ?.short_name || '';
                  setState({
                    longName,
                    shortName,
                    lat: res[0].geometry.location.lat(),
                    lng: res[0].geometry.location.lng(),
                  });
                  handleClose();
                }
              }
            });
          },
          () => {
            toast.error('Ative a localização e dê permissão no navagador para usar', toast_config);
          }
        );
      } else {
        // Browser doesn't support Geolocation
        toast.error('Ative a localização e dê permissão no navagador para usar', toast_config);
      }
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
    >
      <Container>
        {!selectStateFromComboBox ? (
          <>
            <Typography className={classes.welcomeText}>
              Bem-vindo
            </Typography>

            <Typography className={classes.infoText}>
              Informe sua localização para visualizar as lojas que estão mais próximas de você
            </Typography>

            <Button
              onClick={useCurrentLocation}
              className={`${classes.currentLocation} ${classes.buttonWithIcon}`}
            >
              <LocationOnIcon />
              Usar minha localização
            </Button>

            <Button 
              onClick={() => setSelectStateFromComboBox(true)}
              className={`${classes.selectState} ${classes.buttonWithIcon}`}
            >
              <SearchIcon />
              Selecione seu estado
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.infoText}>
              Selecione o estado
            </Typography>

            <Autocomplete
              id="combo-box-uf"
              options={stateOptions}
              getOptionLabel={(option) => option.nome}
              value={selectedState}
              onChange={(event: any, newValue: IEstado | null) => {
                if (newValue !== null){
                  if(newValue.nome !== 'Brasil'){
                    setSelectedState(newValue);
                  } else {
                    setSelectedState(null);
                  }
                }
              }}
              getOptionSelected = {(option,value) => _.isEqual(option,value)}
              className={classes.comboUf}
              renderInput={(params) => (
                <TextField {...params}
                  name="uf"
                  type="uf"
                  label="Estado"
                  variant="outlined" 
                />
              )}
            />

            <Button
              onClick={() => {
                setState(selectedState ? {
                  longName: selectedState.nome || '',
                  shortName: selectedState.sigla || ''
                } : null);
                handleClose();
              }}
              className={classes.confirm}
            >
              Confirmar
            </Button>

            <Button 
              onClick={() => setSelectStateFromComboBox(false)}
              className={classes.back}
            >
              Voltar
            </Button>
          </>
        )}
      </Container>
    </Modal>
  );
}