import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  backButton:{
    backgroundColor: '#0c65ea',
    width: '200px',
    height: '40px',
    marginTop: '30px',
    alignSelf: 'flex-end',
    '&:hover':{
      backgroundColor: '#0c65ea'
    },
    [theme.breakpoints.between(640,1280)]: {
      width: '25% !important'
    },
    [theme.breakpoints.down(641)]: {
      width: '35% !important'
    },
  },
  finishButton:{
    backgroundColor: '#0c65ea',
    width: '200px',
    height: '40px',
    marginTop: '30px',
    alignSelf: 'flex-end',
    '&:hover':{
      backgroundColor: '#0c65ea'
    },
    [theme.breakpoints.between(640,1280)]: {
      width: '25%'
    },
    [theme.breakpoints.down(641)]: {
      width: '35%'
    },
  },
  legend:{
    fontSize: '18px',
    color: '#000'
  },
  couponText: {
    fontSize: '18px',
    color: '#000',
    lineHeight: 1
  }
}));
