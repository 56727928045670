import React, { useContext, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { GridItem } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../../hooks/useThemeState";

interface OptionProps{
  value: string;
  checked: boolean;
  setSelectedHour: (value: string) => void;
  delivery: boolean;
  deliveryTax: number;
}

const Option: React.FC<OptionProps> = ({value, checked, setSelectedHour, delivery, deliveryTax}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme()

  const handleSelect = () => {
    setSelectedHour(value);
  }

  return (
    <GridItem>
      <Card
        className={classes.card}
        style={{ border: checked ? `1px solid ${theme.primary}` : ""}}
      >
        <CardActionArea
          onClick={handleSelect}
          className={classes.cardAA}
        >
          <Typography className={classes.value}>
            {value}
          </Typography>
          <Typography
            className={classes.value}
            style={{ color: delivery ? 'inherit' : '#50a773' }}
          >
            {delivery ? `R$ ${String(deliveryTax).replace('.',',')}` : 'Grátis'}
          </Typography>
        </CardActionArea>
      </Card>
    </GridItem>
  )
}

export default Option;
