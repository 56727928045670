import React, { useState, useEffect } from "react";

import _ from 'lodash';

import Typography  from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { IProduto } from '../../interfaces/produto';
import { IItemPedido } from "../../interfaces/itemPedido";

import {
  Informations,
  Title,
  Body,
  Aditionals,
  Price,
  ComponentsList,
  Imagem,
  GridItem
} from './styles';
import stylesMaterial from './stylesMaterial';

interface OrderDetailsItemDeletedProps{
  item: IProduto;
  itemPedido: IItemPedido;
  complementos: IItemPedido[];
  produtosGrade: IItemPedido[];
}

const OrderDetailsItemDeleted: React.FC<OrderDetailsItemDeletedProps> = ({
  item,
  itemPedido,
  complementos,
  produtosGrade,
}) => {
  const classes = stylesMaterial();

  const [qtdSabores, setQtdSabores] = useState(0);

  useEffect(() => {
    let qtdTemp = 1/Math.min(...produtosGrade.map(produto => produto.quantidade));
    if((1 - qtdTemp) > 0.002){
      let i = 2;
      while(((qtdTemp*i) % 1) !== 0){
        i++;
      }
      qtdTemp = qtdTemp*i;
    }
    setQtdSabores(qtdTemp);
  }, []);

  return (
    <>
      <GridItem>
        <Informations>
          <Title>
            <Typography
              variant='body1'
              className={classes.productTitle}
            >
              {
                (produtosGrade.length > 1) && (_.toNumber(itemPedido.produtoGradeId)!==null)
                ?
                (
                  itemPedido.nomeGradeTamanho !== null
                  ?
                  _.toNumber(itemPedido.quantidade)+"x Pizza "+itemPedido.nomeGradeTamanho
                  :
                  _.toNumber(itemPedido.quantidade)+"x Pizza"
                )
                :
                (
                  (produtosGrade.length > 0) && (itemPedido.nomeGradeTamanho !== null)
                  ?
                  _.toNumber(itemPedido.quantidade)+"x "+itemPedido.nomeProduto+" "+itemPedido.nomeGradeTamanho
                  :
                  _.toNumber(itemPedido.quantidade)+"x "+itemPedido.nomeProduto
                )
              }
            </Typography>
          </Title>
          <Aditionals>
            {
            complementos.length || (produtosGrade.length > 1)
            ?
            <ComponentsList >
              {
                produtosGrade.length > 1
                ?
                produtosGrade.map((produto) => (
                  <li
                    key={produto.id}
                  >
                    {Math.round(produto.quantidade*qtdSabores)+"/"+Math.round(qtdSabores)+"x "+produto.nomeProduto}

                  </li>
                ))
                :
                <div style={{ display: 'none' }} />
              }
              {
                complementos.map((complemento) => (
                  <li
                    key={complemento.id}
                  >
                    {_.toNumber(complemento.quantidade)+"x "+complemento.nomeProduto}
                  </li>
                ))
              }
            </ComponentsList>
            :
            <Typography
              variant='body2'
              className={classes.prodDescr}
            >
              {itemPedido.observacao}
            </Typography>
            }
          </Aditionals>
          <Price>
            <Typography
              variant='h6'
              className={classes.priceText}
            >
              R$ {_.toNumber(itemPedido.valorTotal).toFixed(2).replace('.',',')}
            </Typography>
          </Price>
        </Informations>
        <Body>
          <Imagem image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}`+item.imagem}>
            <div />
          </Imagem>
        </Body>
      </GridItem>
      <Divider className={classes.divisor} />
    </>
  )
}

export default OrderDetailsItemDeleted;
