import React, { useEffect, useRef, useState } from "react";

import { Banner } from "../../../components/banner";
import { Container } from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import stylesMaterial from "./stylesMaterial";
import empresaApi, { Banner as BannerType } from "../../../services/empresaApi";

interface BannersProps {}

export function Banners({}: BannersProps) {
  const scrollContainerRef: any = useRef(null);
  const classes = stylesMaterial();

  const [banners, setBanners] = useState<BannerType[]>([]);

  useEffect(() => {
    empresaApi.getBannersHome().then((res) => setBanners(res))
      .catch((err) => console.log(err))
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  
  return (
    <ScrollContainer
      horizontal
      hideScrollbars
      nativeMobileScroll
      innerRef={scrollContainerRef}
      className={classes.scroll_container}
    >
      <Container>
        {banners.map((banner) => (
          <Banner banner={banner} key={banner.id} />
        ))}
      </Container>
    </ScrollContainer>
  );
}