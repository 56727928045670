import pt from 'date-fns/locale/pt-BR';
import { utcToZonedTime } from 'date-fns-tz';
import { format, isWithinInterval } from 'date-fns';

import { IEmpresa } from "../interfaces/empresa";
import { ITurno } from '../interfaces/diasHorarios';

export function checkStoreOpen(company: IEmpresa) {
  if(company.aberto && company.horario) {
    const dias = company.horario.dias;
    let schedule: ITurno | undefined;

    const zonedToday = utcToZonedTime(new Date(), company.time_zone);
    const weekDay = format(zonedToday, 'iiiiii' , {
      locale: pt
    });

    if(dias[weekDay]) {
      schedule = dias[weekDay].find((time) => {
        const start = time.start.split(':');
        const end = time.end.split(':');

        return isWithinInterval(zonedToday, {
          start: new Date(zonedToday.getFullYear(), zonedToday.getMonth(), zonedToday.getDate(), Number(start[0]), Number(start[1])),
          end: new Date(zonedToday.getFullYear(), zonedToday.getMonth(), zonedToday.getDate(), Number(end[0]), Number(end[1]))
        });
      });

      return !!schedule;
    } else {
      return false;
    }
  } else {
    return company.aberto
  }
}