import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  title: {
    marginTop: '10px'
  },
  subtitle: {
    color: '#9e9e9e',
    marginTop: '20px',
    marginLeft: '25px',
    alignSelf: 'flex-start'
  },
  titleDivider: {
    width: '100%'
  },
  fixDisplay:{
    display: '-webkit-flex',
    flexDirection: 'column'
  },
  form: {
    display: '-webkit-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '25px',
    padding: '0 25px 0 25px',
    justifyContent: 'space-between',
    flex: 1,
    [theme.breakpoints.down(960)]: {
      marginTop: '10px',
      padding: '0 10px 0 10px',
    },
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1
  },
  offset1: {
    minHeight: 90
  },
  link: {
    color: '#B22222',
    fontSize: '1.2rem',
    marginBottom: 30
  },
  linkTransition:{
    transition: 'all 0.35s ease-in-out',


    '&:hover':{
      color: '#FF0000',
      textDecoration: 'none'
    },
    '&:after':{
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1px',

      height: '1px',
      width: 0,

      background: '#FF0000',

      transition: 'width 0.35s ease-in-out'
    },
    '&:hover::after':{
      width: '100%'
    }
  },
  hide: {
    display: 'none'
  },
}));
