import React, { useState } from 'react';
import { ColorForShow } from '..';

import { ColorCircle } from './ColorCircle';

import { 
  Container
} from './styles';

interface ColorGroupProps {
  colors: ColorForShow[];
  selectedColor: ColorForShow | null;
  setSelectedColor: (color: ColorForShow) => void;
  setSelectedSize: (size: string) => void;
}

export const ColorGroup: React.FC<ColorGroupProps> = (props) => {
  const { colors, selectedColor, setSelectedColor, setSelectedSize } = props;

  return (
    <Container>
      {
        colors.sort((a,b) => a.color.ordem - b.color.ordem).map((color) => {
          return (
            <ColorCircle 
              key={color.color.id}
              color={color}
              selected={selectedColor?.color.id === color.color.id}
              setSelectedColor={setSelectedColor}
              setSelectedSize={setSelectedSize}
            />
          );
        })
      }
    </Container>
  );
}