import React, { useEffect, useState } from 'react';

import Modal from '@material-ui/core/Modal';

import stylesMaterial from './stylesMaterial';
import { IProduto } from '../../interfaces/produto';

import { ClotheProduct } from './components/ClotheProduct';
import { SimpleProduct } from './components/SimpleProduct';
import { IEmpresa } from '../../interfaces/empresa';
import { Content, Header } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, IconButton } from '@material-ui/core';
import { PizzaProduct } from './components/PizzaProduct';
import produtosApi from '../../services/produtosApi';
import empresaApi from '../../services/empresaApi';
import { checkStoreOpen } from '../../utils/checkStoreOpen';
import { ISchedule } from '../../interfaces/schedule';

interface ProductSuggestionModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
  companyId: number;
  scheduledDays: ISchedule | null;
}

export function ProductSuggestionModal(props: ProductSuggestionModalProps) {
  const {
    isOpen,
    onClose,
    productId,
    companyId,
    scheduledDays,
  } = props;

  const [productWithComplements, setProductWithComplements] = useState<IProduto | null>(null);
  const classes = stylesMaterial();

  const [hasMelhorEnvio, setHasMelhorEnvio] = useState(false);
  const [isStoreOpen, setIsStoreOpen] = useState(false);
  const [company, setCompany] = useState<IEmpresa | null>(null);

  useEffect(() => {
    produtosApi.getProduto(productId).then((resP) =>{
      setProductWithComplements(resP);

      empresaApi.getParametro(companyId.toString(), 'best_shipping_services').then((res) => {
        const role = JSON.parse(res.role);
        if (role && resP.produto_dimensao && resP.produto_dimensao.altura && resP.produto_dimensao.comprimento && resP.produto_dimensao.largura && resP.produto_dimensao.peso) {
          if (role.active) {
            setHasMelhorEnvio(true);
          }
        }
      }).catch(() => {
        setHasMelhorEnvio(false);
      });
    }).catch((error) => console.log(error));

    empresaApi.getEmpresa(companyId).then((res) => {
      setCompany(res);

      empresaApi.getScheduledDelivery(companyId).then((days) => {
        if(days){
          setIsStoreOpen(true);
        } else {
          setIsStoreOpen(checkStoreOpen(res));
        }
      }).catch((error) => {
        setIsStoreOpen(checkStoreOpen(res));
      });
    })
  }, []);

  useEffect(() => {
    if(isOpen && company) {
      if(scheduledDays){
        setIsStoreOpen(true);
      } else {
        setIsStoreOpen(checkStoreOpen(company));
      }
    }
  }, [isOpen, company]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.container}
    >
      <Content>
        <Header>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.buttonClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </Header>

        {
          productWithComplements ? (
            productWithComplements.controla_grade === 0 ? (
              <SimpleProduct
                product={productWithComplements}
                isStoreOpen={isStoreOpen}
                companyId={companyId}
                onClose={onClose}
                hasMelhorEnvio={hasMelhorEnvio}
              />
            ) : (
              productWithComplements.controla_grade === 1 ? (
                <ClotheProduct
                  product={productWithComplements}
                  isStoreOpen={isStoreOpen}
                  companyId={companyId}
                  onClose={onClose}
                  hasMelhorEnvio={hasMelhorEnvio}
                />
              ) : (
                <PizzaProduct
                  product={productWithComplements}
                  isStoreOpen={isStoreOpen}
                  companyId={companyId}
                  onClose={onClose}
                />
              )
            )
          ) : (
            <CircularProgress />
          )
        }
      </Content>
    </Modal>
  );
}
