import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 550px) / 2);
  width: 550px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
  @media(max-height: 680px){
    height: 100vh;
    margin-top: 0;
  }
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 1px;
  padding-top: 1px;
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  align-items: center;
`;