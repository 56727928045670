import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  seeallitem: {
    textAlign: 'center',
    margin: '8px 0px 45px',
  },
  seeallsegment: {
    alignSelf: 'flex-end',
    margin: '8px 12px 0px 0px',
  },
  divider: {
    width: '100%',
  },
  linedivider2: {
    margin: '25.5px 0px 0px 0px',
    '@media (max-width: 650px)': {
      margin: '18.5px 0px 0px 0px',
    },
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartButton: {
    background: '#B22222',
    color: '#fefefe',
    height: '50px',
    width: '95%',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
    padding: '0 10px',
    zIndex: 2,
    '&:hover': {
      background: '#B22222',
      textDecoration: 'none',
    },
    [theme.breakpoints.up(960)]: {
      padding: '0 100px',
    },
  },
  cartLength: {
    padding: '6px',
    backgroundColor: '#8c0e0e',
  },
  whatsappButton: {
    position: 'fixed',
    right: '15px',
    bottom: '70px',
    width: '50px',
    height: '50px',
    padding: '5px',
    boxSizing: 'border-box', 
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: 'rgb(0 0 0 / 40%) 2px 2px 6px',
    transition: 'all 0.5s ease 0s',
    zIndex: 200,
    background: 'rgb(77, 194, 71)',
    '&:hover': {
      background: 'rgb(77, 194, 71)'
    }
  },
  whatsappIcon: {
    color: '#FFF'
  }
}));
