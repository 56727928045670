import React, { useContext, useEffect, useState } from "react";

import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { Context } from '../../../hooks/cartStore';
import SalePaymentMethod  from './sale-payment-method';

import { IItemCarrinho } from "../../../interfaces/itemCarrinho";
import { IFormaPagamentoEmpresa } from "../../../interfaces/formaPagamentoEmpresa";

import empresaApi from '../../../services/empresaApi';

import stylesMaterial from './stylesMaterial';
import {
  Container,
  Content,
  PaymentMethod,
  ButtonRow,
  Coupon,
  CouponCode
} from './styles';
import { useTheme } from "../../../hooks/useThemeState";

interface PagamentoProps{
  handleBack: () => void;
  valuePM: number;
  selectPM: (PM: number) => void;
  handleConclusion: () => void;
  isLoading: boolean;
  selectedCompanie: number;
  changeMoney: string;
  handleChangeMoney: (value: string) => void;
  handleDesconto: (value: number) => void;
  cupom: string;
  handleCupom: (value: string) => void;
  token: string;
  login: string;
  subtotal: number;
  companieId: string | undefined;
}

const defaultValues = {
  cupomField: ""
};

export const Pagamento: React.FC<PagamentoProps> = (props) => {
  const {
    handleBack,
    valuePM,
    selectPM,
    handleConclusion,
    isLoading,
    selectedCompanie,
    changeMoney,
    handleChangeMoney,
    handleDesconto,
    cupom,
    handleCupom,
    token,
    login,
    subtotal,
    companieId
  } = props;

  const classes = stylesMaterial();
  const { theme } = useTheme()

  const { state, dispatch } = useContext(Context);
  const { register, handleSubmit } = useForm({ defaultValues });

  const [selectedPM, setSelectedPM] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<IFormaPagamentoEmpresa[]>();
  const [loading, setLoading] = useState(true);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (selectedCompanie !== undefined){
      empresaApi.getFormasPagamento(_.toString(selectedCompanie)).then((res) => {
        setPaymentMethods(res);
        selectPM(res[0].id);
        setLoading(false);
      }).catch((error) => {
          // if (error.response){
          //   console.log("response");
          //   console.log(error.response);

          // }else if(error.request){

          //   console.log("request");
          //   console.log(error.request);

          // }else if(error.message){

          //   console.log("message")
          //   console.log(error.message);

          // }
      })
    }

  }, []);

  useEffect(() => {
    const sPM = paymentMethods?.find((pm) => pm.id === valuePM)
    if (sPM !== undefined){
      setSelectedPM(sPM.formaPagamento.descricao);
    }

  }, [valuePM, paymentMethods]);

  const handleChangePagamento = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectPM(_.toNumber((event.target as HTMLInputElement).value));
  };

  const handleCouponApply = (data: { cupomField: string}) => {
    const { cupomField } = data;
    if(companieId !== undefined){
      if(token){
        empresaApi.getCupomInfo(_.toNumber(selectedCompanie), cupomField.toUpperCase().trim(), token)
        .then((res) => {
          if(subtotal > res.compra_minima){
            if(res.tipo_desconto === 1){
              handleDesconto(res.valor_desconto);
            } else{
              handleDesconto(subtotal*(res.valor_desconto/100))
            }
            handleCupom(res.chave);
          } else {
            toast.error(`Compra mínima de R$ ${res.compra_minima} para ativar o cupom`, toast_config);
          }
        })
        .catch((error) => {
          if(error.response){
            if(error.response.data.message === 'coupon-not-found'){
              toast.error('Cupom não encontrado', toast_config);
            }
          }
        })
      } else {
        empresaApi.getCupomInfoWithoutToken(_.toNumber(selectedCompanie), cupomField.toUpperCase().trim(), login)
        .then((res) => {
          if(subtotal > res.compra_minima){
            if(res.tipo_desconto === 1){
              handleDesconto(res.valor_desconto);
            } else{
              handleDesconto(subtotal*(res.valor_desconto/100))
            }
            handleCupom(res.chave);
          } else {
            toast.error(`Compra mínima de R$ ${res.compra_minima} para ativar o cupom`, toast_config);
          }
        })
        .catch((error) => {
          if(error.response){
            if(error.response.data.message === 'coupon-not-found'){
              toast.error('Cupom não encontrado', toast_config);
            }
          }
        })
      }
    }
  }

  const handleClearCupom = () => {
    handleCupom("");
    handleDesconto(0);
  }

  return (
    <Container>
      <Content>
        <Coupon>
          <Typography
            className={classes.couponText}
          >
            Cupom de desconto
          </Typography>
          {
            cupom === ""
            ?
            <CouponCode
              onSubmit={handleSubmit(handleCouponApply)}
            >
              <TextField
                id="cupomField"
                name="cupomField"
                label="Código do cupom"
                variant="outlined"
                inputRef={register}
              />
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: theme.primary,
                  color: '#fefefe',
                  marginTop: '10px',
                  height: '45px'
                }}
              >
                Aplicar
              </Button>
            </CouponCode>
            :
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.primary,
                color: '#fefefe',
                marginTop: '10px'
              }}
              onClick={handleClearCupom}
            >
              Remover cupom
            </Button>
          }
        </Coupon>

        <PaymentMethod>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.legend}>Método de Pagamento</FormLabel>
            <RadioGroup
              aria-label="pagamento"
              name="pagamento"
              value={valuePM}
              onChange={handleChangePagamento}
            >
              {
                paymentMethods?.map((pm) => {
                  return (
                    <SalePaymentMethod
                      key={pm.id}
                      pm={pm}
                      selectedPM={selectedPM}
                      changeMoney={changeMoney}
                      handleChangeMoney={handleChangeMoney}
                    />
                  );
                })
              }
            </RadioGroup>
          </FormControl>
        </PaymentMethod>
      </Content>
      <ButtonRow>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          className={classes.backButton}
          style={{
            width: '200px',
            height: '40px',
            marginTop: '30px',
            alignSelf: 'flex-end',
            border: `1px solid ${theme.primary}`,
            color: theme.primary,
            background: 'transparent'
          }}
        >
          Voltar
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={((state.carts.find((cart) => _.toString(cart.id) === companieId)?.cart||new Array<IItemCarrinho>())
                    .length===0) || isLoading || loading}
          onClick={handleConclusion}
          className={classes.finishButton}
          style={{ backgroundColor: theme.primary }}
        >
          Concluir
        </Button>
      </ButtonRow>
    </Container>
  )
}
