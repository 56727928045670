import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 200px;

  border-radius: 8px;

  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
