import React from "react";
import { FooterContainer,
         Paragraph,
         Line,
         Link } from "./styles"; 

export const Footer = () => {
    return(
        <>
            <FooterContainer>
                <Paragraph>
                    <Line>
                        
                        Ajude-nos a manter o ATAC Delivery saudável! Reportar todos os erros&nbsp;
                    </Line>
                    <Line>
                        -&nbsp;
                        <Link>Termos de Uso      
                        </Link>
                        &nbsp;-&nbsp;
                        <Link>Política de Privacidade  
                        </Link>
                    </Line>
                </Paragraph>
                <Paragraph>
                    <Line>
                        Copyright &copy;2020-presente ATAC Sistemas.&nbsp;
                    </Line>
                    <Line>
                        Todos os direitos reservados.
                    </Line>
                </Paragraph>
            </FooterContainer>
        </>
    );
}