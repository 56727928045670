import React, { useState } from 'react';
import { OptionContainer, OptionLabel, OptionAnchor, LoadingLabel, BoxCard, Image } from './styles';

import Skeleton from '@material-ui/lab/Skeleton';
import stylesMaterial from './stylesMaterial';
import { IGrupoProduto } from '../../interfaces/grupoProduto';
import DefaultImage from '../../assets/product-group-default.svg';

interface SegmentOptionProps {
    value?: number;
    label?: string;
    selected: boolean;
    onClick: any;
    isLoading:boolean;
    group: IGrupoProduto;
}

const SegmentOption: React.FC<SegmentOptionProps> = (props) => {
    const { value,
        label,
        selected,
        onClick,
        isLoading,
        group 
    } = props;

    const classes = stylesMaterial();

    const [imagem, setImagem] = useState(process.env.REACT_APP_RETAGUARDA_API_FILES+group.imagem);

    const handleClick = () => {
        if(!selected){ 
            onClick(value);
        }

        handleScrollToGroup()
    }

    const handleScrollToGroup = () => {
        const element = document.getElementById(`Grupo-${value}`)
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const truncate = (str: string | undefined) => {
        if (str !== undefined) {
            return str.length > 15 ? str.substring(0, 11) + "..." : str
        }
    }

    return (
        <React.Fragment>
            { isLoading ?
                (
                  <LoadingLabel>
                    <Skeleton variant="rect" width={"120px"} height={"20px"}/>
                  </LoadingLabel>
                )
                :
                (<OptionAnchor >
                    <OptionContainer onClick={handleClick}>
                        <BoxCard
                            className={classes.image}
                        >
                            <Image
                                src={
                                    group.descricao === 'Ofertas'
                                    ? group.imagem
                                    : imagem
                                }
                                onError={() => setImagem(DefaultImage)}
                            />
                        </BoxCard>
                        <OptionLabel >   
                            <p>{ truncate(label) }</p>
                        </OptionLabel>    
                    </OptionContainer>
                </OptionAnchor>)
            }
            
        </React.Fragment>
    )
}
export default SegmentOption;