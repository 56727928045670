// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { Box, Modal, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Context } from '../../hooks/cartStore';

import produtosApi from '../../services/produtosApi';

import stylesMaterial from './stylesMaterial';
import { CarouselContainer } from './styles';

import { IProduto } from '../../interfaces/produto';
import { IComplementoCarrinho } from '../../interfaces/complementoCarrinho';

import { IItemCarrinho } from '../../interfaces/itemCarrinho';

import PizzaType from './pizzaType';
import AmplifiedImageModal from '../amplified-image-modal';
import { ClotheProduct } from './ClotheProductFood';
import SimpleType from './simpleType';
import { ProductCarousel } from '../../new-components/product-carousel-big-food';
import { calcOrderPrice } from '../../utils/calculator';
import empresaApi from '../../services/empresaApi';
import { IEmpresa } from '../../interfaces/empresa';
import { ISchedule } from '../../interfaces/schedule';
import { checkStoreOpen } from '../../utils/checkStoreOpen';

interface IModalProductDetail {
  isOpenModal: boolean;
  closeModal: () => void;
  selectedProduct: IProduto;
  companieId: number;
  selectedType: number;
  company: IEmpresa;
  scheduledDays: ISchedule | null;
}

const ModalProductDetail = ({
  isOpenModal,
  closeModal,
  selectedProduct,
  companieId,
  company,
  scheduledDays,
}: IModalProductDetail): any => {
  const [quantProdToCart, setQuantProdToCart] = useState(1);
  const [groupsUnderMinimum, setGroupsUnderMinimum] = useState<
    { id: number; under: boolean }[]
  >([]);
  const [complementos, setComplementos] = useState(
    new Array<IComplementoCarrinho>(),
  );
  const [anyGroupUnder, setAnyGroupUnder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [produto, setProduto] = useState<IProduto>();
  const [openAmplifiedImageModal, setOpenAmplifiedImageModal] = useState(false);
  const [observacao, setObservacao] = useState('');
  const [price, setPrice] = useState<number>();
  const [hasMelhorEnvio, setHasMelhorEnvio] = useState(false);
  const [isStoreOpen, setIsStoreOpen] = useState(false);

  const classes = stylesMaterial();

  const { dispatch, state } = useContext(Context);

  let id = 0;
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    setComplementos([]);
  }, []);

  useEffect(() => {
    if(isOpenModal) {
      if(scheduledDays){
        setIsStoreOpen(true);
      } else {
        setIsStoreOpen(checkStoreOpen(company));
      }
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (
      !(selectedProduct.tipo === 1) ||
      !(selectedProduct.controla_grade === 1)
    ) {
      if (produto && produto.controla_grade === 0) {
        setPrice(
          calcOrderPrice({
            id,
            prodComposto: {
              produto,
              complementos,
            },
            produtosGrade: [],
            tamanho: null,
            quantidade: quantProdToCart,
            observacao,
          }),
        );
      }
    }
  }, [quantProdToCart, complementos, produto]);

  const addItemToCart = (cart: IItemCarrinho[], empresaId: number) => {
    if (cart.length) {
      id = cart[cart.length - 1].id + 1;
    }

    dispatch({
      type: 'ADD_ITEM',
      empresaId: _.toNumber(empresaId),
      payload: {
        id,
        prodComposto: {
          produto,
          complementos,
        },
        produtosGrade: [],
        tamanho: null,
        quantidade: quantProdToCart,
        observacao,
      },
    });
  };

  const addToCarts = async () => {
    if (!isStoreOpen) {
      toast.error('Ops, no momento estamos fechados!', toast_config);
      return;
    }

    if (anyGroupUnder) {
      const firstGroupUnder = groupsUnderMinimum.find(group => group.under);

      if(firstGroupUnder) {
        const groupElement = document.getElementById(`Grupo-Complemento-${firstGroupUnder.id}`);

        if (groupElement) {
          groupElement.scrollIntoView({ behavior: 'smooth' });
          toast.error('Ops, selecione seu complemento', toast_config);
        }
      }
      return;
    }

    const cart = state.carts.find(
      (_cart) => _.toNumber(_cart.id) === companieId,
    );

    if (cart === undefined) {
      dispatch({
        type: 'ADD_CART',
        empresaId: companieId,
        payload: [
          {
            id,
            prodComposto: {
              produto,
              complementos,
            },
            produtosGrade: [],
            tamanho: null,
            quantidade: quantProdToCart,
            observacao,
          },
        ],
      });
    } else {
      addItemToCart(cart.cart, companieId);
    }

    closeModal();
    setProduto(undefined);
  };

  const handleAdition = () => {
    if (produto) {
      if (produto.estoque) {
        if (produto.estoque.ativo) {
          if (quantProdToCart < produto.estoque.estoque_atual) {
            setQuantProdToCart((prev) => prev + 1);
          } else {
            toast.error('Limite de estoque atingido!');
          }
        } else {
          setQuantProdToCart((prev) => prev + 1);
        }
      } else {
        setQuantProdToCart((prev) => prev + 1);
      }
    }
  };

  const handleRemove = () => {
    if (quantProdToCart > 1) {
      setQuantProdToCart((prev) => prev - 1);
    } else {
      toast.error('Quantidade mínima é 1');
    }
  };

  const handleGroupsUnderMinimum = (
    groups: { id: number; under: boolean }[],
  ) => {
    setGroupsUnderMinimum(groups);
  };

  const setComponents = (cartComponent: IComplementoCarrinho) => {
    let temp: IComplementoCarrinho[];

    if (produto) {
      const grupo = produto.complemento.find(
        (group) => cartComponent.complemento.complemento_id === group.id,
      );

      if (grupo) {
        setComplementos((prevState) => {
          if (_.toNumber(grupo.qtd_max) === 1) {
            temp = prevState.filter((item: IComplementoCarrinho) => {
              return (
                item.complemento.id !== cartComponent.complemento.id &&
                item.complemento.complemento_id !==
                  cartComponent.complemento.complemento_id
              );
            });
          } else {
            temp = prevState.filter(
              (item: IComplementoCarrinho) =>
                item.complemento.id !== cartComponent.complemento.id,
            );
          }

          if (cartComponent.quantidade > 0) {
            temp.push(cartComponent);
          }

          return temp;
        });
      }
    }
  };

  const handleCloseAmplifiedImageModal = () => {
    setOpenAmplifiedImageModal(false);
  };

  const handleOpenAmplifiedImageModal = () => {
    setOpenAmplifiedImageModal(true);
  };

  useEffect(() => {
    setQuantProdToCart(1);
  }, [isOpenModal]);

  useEffect(() => {
    if (selectedProduct?.id) {
      setIsLoading(true);
      produtosApi
        .getProduto(selectedProduct.id)
        .then((res) => {
          setProduto(res);

          empresaApi.getParametro(companieId.toString(), 'best_shipping_services').then((resBSS) => {
            const role = JSON.parse(resBSS.role);
            if (role && res.produto_dimensao && res.produto_dimensao.altura && res.produto_dimensao.comprimento && res.produto_dimensao.largura && res.produto_dimensao.peso) {
              if (role.active) {
                setHasMelhorEnvio(true);
              }
            }
          }).catch(() => {
            setHasMelhorEnvio(false);
          });
          
          setIsLoading(false);
          
          setObservacao('');
        })
        .catch((error) => console.log(error));
    }
  }, [selectedProduct.id]);

  useEffect(() => {
    if(produto){
      produto.complemento.map((comp) => {
        if (groupsUnderMinimum !== undefined) {
          const tempGroupsUnder = groupsUnderMinimum;
          if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
            tempGroupsUnder.push({ id: comp.id, under: true });
          } else {
            tempGroupsUnder.push({ id: comp.id, under: false });
          }
          setGroupsUnderMinimum(tempGroupsUnder);
        } else if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
          setGroupsUnderMinimum([{ id: comp.id, under: true }]);
        } else {
          setGroupsUnderMinimum([{ id: comp.id, under: false }]);
        }
      });
    }
  }, [produto]);

  useEffect(() => {
    let counter = 0;

    if (groupsUnderMinimum !== undefined) {
      groupsUnderMinimum.map((group) => {
        if (group.under) {
          setAnyGroupUnder(true);
        } else {
          counter += 1;
        }
      });
      if (counter === groupsUnderMinimum.length) {
        setAnyGroupUnder(false);
      }
    }
  }, [groupsUnderMinimum]);

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={isOpenModal}
      onClose={() => {
        closeModal();
        setProduto(undefined);
      }}
      className={classes.modalContainer}
    >
      {produto && !isLoading ? (
        <Box className={classes.modalMain}>
          {openAmplifiedImageModal && (
            <AmplifiedImageModal
              handleCloseAmplifiedImageModal={handleCloseAmplifiedImageModal}
              openAmplifiedImageModal={openAmplifiedImageModal}
              image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${selectedProduct.imagem}`}
              name={selectedProduct.descricao}
            />
          )}

          {!(
            selectedProduct.tipo === 1 && selectedProduct.controla_grade === 1
          ) && (
            <CarouselContainer>
              <ProductCarousel
                images={
                  produto && produto.imagens.length > 0
                    ? produto.imagens.map((imagem) => imagem.imagem)
                    : [produto.imagem]
                }
              />
            </CarouselContainer>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedProduct.controla_grade === 2 ? (
            <PizzaType
              companieId={companieId}
              isStoreOpen={isStoreOpen}
              handleClose={() => {
                closeModal();
                setProduto(undefined);
              }}
              product={produto}
            />
          ) : selectedProduct.controla_grade === 1 ? (
            <ClotheProduct
              companyId={companieId}
              isStoreOpen={isStoreOpen}
              onClose={() => {
                closeModal();
                setProduto(undefined);
              }}
              product={produto}
              hasMelhorEnvio={hasMelhorEnvio}
            />
          ) : (
            <SimpleType
              addToCarts={addToCarts}
              anyGroupUnder={anyGroupUnder}
              closeModal={() => {
                closeModal();
                setProduto(undefined);
              }}
              companieClosed={isStoreOpen}
              complementos={complementos}
              groupsUnderMinimum={groupsUnderMinimum}
              handleAdition={handleAdition}
              handleGroupsUnderMinimum={handleGroupsUnderMinimum}
              handleOpenAmplifiedImageModal={handleOpenAmplifiedImageModal}
              handleRemove={handleRemove}
              isLoading={isLoading}
              price={price}
              produto={produto}
              quantProdToCart={quantProdToCart}
              setComponents={setComponents}
              setObservacao={setObservacao}
              hasMelhorEnvio={hasMelhorEnvio}
              companyId={companieId}
            />
          )}
        </Box>
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Modal>
  );
};

export default ModalProductDetail;
