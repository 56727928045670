import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  title: {
    marginTop: '10px'
  },
  subtitle: {
    color: '#9e9e9e',
    marginTop: '20px',
    marginLeft: '25px',
    alignSelf: 'flex-start'
  },
  titleDivider: {
    width: '100%'
  },
  fixDisplay:{
    display: '-webkit-flex',
    flexDirection: 'column'
  },
  name:{
    gridArea: 'name'
  },
  surname:{
    gridArea: 'surname'
  },
  email: {
    
    gridArea: 'email'
  },
  password: {
    gridArea: 'password'
  },
  confirmPassword: {
    gridArea: 'confirmPassword'
  },
  telephone:{
    gridArea: 'telephone'
  },
  cpf:{
    gridArea: 'cpf'
  },
  cep:{
    gridArea: 'cep'
  },
  address:{
    gridArea: 'address'
  },
  complement:{
    gridArea: 'complement'
  },
  neighborhood:{
    gridArea: 'neighborhood'
  },
  houseNumber: {
    gridArea: 'houseNumber'
  },
  uf:{
    gridArea: 'uf',
  },
  city:{
    gridArea: 'city',
  },
  currentLocation:{
    display: '-webkit-flex',
    justifyContent: 'center',
    gridArea: 'currentLocation',
  },
  form: {
    display: '-webkit-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '25px',
    padding: '0 25px 0 25px',
    justifyContent: 'space-between',
    flex: 1,
    [theme.breakpoints.down(960)]: {
      marginTop: '10px',
      padding: '0 10px 0 10px',
    },
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1
  },
  offset1: {
    minHeight: 90
  },
  buttonCL: {
  },
  iconCL:{
    height: 30,
    width: 'auto'
  },
  terms: {
    paddingBottom: 100
  },
}));
