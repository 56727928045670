import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
// import Slider from "react-slick";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-scroll';
import ScrollContainer from 'react-indiana-drag-scroll';
// import SegmentOption from '../segmentOptionClothes';

import stylesMaterial from './stylesMaterial';
import { SliderContainer, FastMenu, LoadingContainer } from './styles';

// import { Swiper, SwiperSlide } from 'swiper/react';

// import { IProdutoPreco } from '../../interfaces/produtoPreco';
import { IGrupoProduto } from '../../interfaces/grupoProduto';

import SegmentOption from '../segment-option';
import { ISegmento } from '../../interfaces/segmento';

export type SegmentData = {
  value: number;
  label: string;
};

interface SegmentCarouselProps {
  data: ISegmento[];
  chosen: ISegmento | null;
  onChange: (active: ISegmento) => any;
  isLoading?: boolean;
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <MdKeyboardArrowRight
      size={30}
      className={className}
      style={{
        ...style,
        color: '#c4c4c4',
        width: '30px',
        height: '30px',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <MdKeyboardArrowLeft
      size={30}
      className={className}
      style={{
        ...style,
        color: '#c4c4c4',
        width: '30px',
        height: '30px',
      }}
      onClick={onClick}
    />
  );
}

const SegmentCarousel: React.FC<SegmentCarouselProps> = ({
  data,
  chosen,
  onChange,
  isLoading = false,
}) => {
  const classes = stylesMaterial();
  const scrollContainerRef: any = useRef(null);
  const idsArray = new Array<number>();
  const navbarLinkRefArray = useRef(new Array<any>());

  let slidesnumber = data.length < 7 ? data.length : 7;
  const slidesarray = [];

  for (let i = 1; i <= slidesnumber; i += 1) {
    slidesarray.push(i);
  }

  useEffect(() => {
    slidesnumber = data.length < 7 ? data.length : 7;
  }, []);

  useEffect(() => {
    data.map((segment) => {
      navbarLinkRefArray.current[segment.id || 0] = React.createRef();
    });

    if (data.length && data[0].id) onChange(data[0]);
  }, [data]);

  const updateActiveOption = (activeOption: ISegmento) => {
    onChange(activeOption);
  };

  const handleScrollMovement = (id: string) => {
    const indexElement = idsArray.indexOf(_.toNumber(id.split('-')[1]));
    let widthToScroll = 0;

    for (let k = 0; k < indexElement; k += 1) {
      if (navbarLinkRefArray.current[idsArray[k]].current !== null) {
        widthToScroll +=
          navbarLinkRefArray.current[idsArray[k]].current.scrollWidth;
      }
    }
    const scrollOptions = {
      left: widthToScroll,
      top: 0,
      behavior: 'smooth',
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(scrollOptions);
    }

    onChange(data[_.toNumber(id.split('-')[1])]);
  };

  return (
    <>
      {isLoading ? (
        <>
          <LoadingContainer>
            {/* <PrevArrow width="30" height="30" /> */}
            <Skeleton variant="rect" width="100%" height="40px" />
            {/* <NextArrow width="30" height="30" /> */}
          </LoadingContainer>
        </>
      ) : (
        <FastMenu>
          <PrevArrow width="30" height="30" />
          <ScrollContainer
            horizontal
            hideScrollbars
            nativeMobileScroll
            innerRef={scrollContainerRef}
            className={classes.scroll_container}
          >
            <List id="NavbarContainerScroll" className={classes.navbarList}>
              {data.map((segment) => {
                navbarLinkRefArray.current[segment.id || 0] = React.createRef();
                idsArray.push(segment.id || 0);
                return (
                  <Link
                    id={`Link-${segment.id}`}
                    to={`Grupo-${segment.id}`}
                    spy
                    smooth
                    offset={-100}
                    duration={800}
                    key={segment.id}
                    onSetActive={handleScrollMovement}
                    className={classes.groupLink}
                    activeClass={classes.activeMenuItem}
                  >
                    <ListItem
                      button
                      ref={navbarLinkRefArray.current[segment.id || 0]}
                      className={classes.navbarItems}
                    >
                      <SegmentOption
                        key={segment.id}
                        value={segment}
                        label={segment.descricao}
                        selected={chosen?.id === segment.id}
                        onClick={(option: ISegmento) =>
                          updateActiveOption(option)}
                        isLoading={isLoading}
                      />
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </ScrollContainer>
          <NextArrow width="30" height="30" />
        </FastMenu>
      )}
    </>
  );
};

export default SegmentCarousel;
