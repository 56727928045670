import styled from "styled-components";

export const Container = styled.div<{ closed: boolean }>`
  display: flex;
  align-items: center;

  svg + p, svg + a {
    margin-left: 10px;
  }

  p {
    color: ${(props) => props.closed ? '#fa0e0e':'inherit'};
  }
`;