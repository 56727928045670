import React from 'react';
import CategoryOption from '../category-option';
import {Options} from './styles';

type CategoryData = {
    value: string;
    label: string;
}

interface CategorySelectListProps {
    data: CategoryData[];   // { value:int, label:string}
    chosen: string;      // selected option(s); not send: assume none selected
    onChange: (active: string)=> void;  // set new selected values in useState
}

const CategorySelectList: React.FC<CategorySelectListProps> = (props) => {
    const {data, chosen, onChange} = props;
    
    const updateActiveOption = (activeOption: string) => {
        onChange(activeOption);
    };

    return (
        <React.Fragment>
            <Options>
                 {data.map((category, index) => (
                        <CategoryOption value={category.value}
                            key={index} 
                            label={category.label}
                            selected={chosen === category.value} 
                            onClick={(option: string)=> updateActiveOption(option)}/>
                    
                    ))
                }
            </Options>
        </React.Fragment>
    )
}

export default CategorySelectList;