import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appbar: {
    paddingInline: '24px',
    backgroundColor: '#424242',
    position: 'sticky',
    top: 0,
    minHeight: '64px',
    [theme.breakpoints.between(380, 450)]: {
      paddingInline: '8px',
    },
    [theme.breakpoints.down(380)]: {
      paddingInline: '5px',
    },
  },
  toolbar: {
    minHeight: '64px'
  },
  imgLink: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-30px'
  },
  img: {
    height: '60px',
    width: '60px',
    borderRadius: '50%'
  },
  grow: {
    flexGrow: 1
  },
  stateLong: {
    [theme.breakpoints.down(640)]: {
      display: 'none'
    },
  },
  stateShort: {
    [theme.breakpoints.up(640)]: {
      display: 'none'
    },
  },
  buttonSection: {
    display: 'flex',
  },
  searchButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  badge: {
    position: 'relative',
    '& > span': {
      backgroundColor: '#FFF',
      color: 'red'
    }
  },
  freeDeliveryIcon: {
    color: '#35BA83', 
    position: 'absolute'
  },
  retractSearchField: {
    borderRadius: '1.25rem',
    height: '100%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  location: {
    '& > span': {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      '& > span': {
        flexDirection: 'column'
      }
    },
  }
}));
