import React, { useContext, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { AuthContext } from '../../hooks/provideAuth';

import { IEnderecoEntrega } from "../../interfaces/enderecoEntrega";

import userApi from "../../services/userApi";

import DeliveryAddressModal from '../../new-components/delivery-address-modal';

import { GridItem, EnderecoCard } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../hooks/useThemeState";

interface AdressCardProps{
  endereco: IEnderecoEntrega;
  getAdresses: () => void;
}

const AdressCard: React.FC<AdressCardProps> = ({ endereco, getAdresses }) => {
  const classes = stylesMaterial();

  const [openDeliveryAddressModal, setOpenDeliveryAddressModal] = useState(false);
  const { theme } = useTheme()

  const { state, dispatch } = useContext(AuthContext);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleRemoveAddress = () => {
    userApi.removeEndereco(_.toNumber(endereco.id), state.token).then(() => {
      getAdresses();
    }).catch((erro) => {
      toast.error('Ocorreu um erro na remoção do endereço.', toast_config);
    })
  }

  const handleOpenDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(true);
  }

  const handleCloseDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(false);
    getAdresses();
  }

  const handleRegisterSucess = () => {
    toast.success('Endereço alterado com sucesso!', toast_config);
  }

  return (
    <GridItem>
      <Card>
        <CardContent
          className={classes.card}
        >
          <EnderecoCard>
            <Typography
              variant="body1"
              className={classes.description}
            >
              {endereco.descricao}
            </Typography>
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {endereco.endereco.logradouro}, {endereco.endereco.numero}, {endereco.endereco.bairro}, {endereco.endereco.cep}
            </Typography>
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {endereco.endereco.complemento}
            </Typography>
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {endereco.endereco.cidade}, {endereco.endereco.estado}
            </Typography>
          </EnderecoCard>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={handleOpenDeliveryAddressModal}
            className={classes.editButton}
          >
            Editar
          </Button>

          <Button
            size="small"
            color="primary"
            onClick={handleRemoveAddress}
            className={classes.removeButton}
          >
            Remover
          </Button>
        </CardActions>
      </Card>

      {
        openDeliveryAddressModal
        ? <DeliveryAddressModal
            handleCloseDeliveryAddressModal={handleCloseDeliveryAddressModal}
            openDeliveryAddressModal={openDeliveryAddressModal}
            registerSucess={handleRegisterSucess}
            enderecoDefault={endereco}
          />
        : <div />
      }

    </GridItem>
  )
}

export default AdressCard;
