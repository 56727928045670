import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  distanceToCostumer: {
    display: 'flex',
    alignItems: 'center',
    color: '#0c65ea',
    marginTop: '0.25rem',
    fontSize: '0.8rem',
    fontWeight: 400
  }
}));
