import styled from 'styled-components';
import { LinearProgress, Paper } from '@material-ui/core';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { darken, lighten } from 'polished'

export const CustomPaper = styled(Paper)<{
  efect?: boolean
}>`
  width: 150px;
  animation: ${p => p.efect === true ? 'float 2s ease-in-out infinite': 'initial'};

  @keyframes float{
    0%,100%{
      transform:translatey(0)
    }
    50%{
      transform:translatey(-5px)
    }
  }
`;

export const CustomTimelineDot = styled(TimelineDot)<{
  efect?: boolean
}>`
  animation: ${p => p.efect === true ? 'slidein 2s ease-in-out infinite':'initial'};
  background: ${p => p.theme.primary};

  @keyframes slidein {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
`

export const CustomLinearProgress = styled(LinearProgress)`
  background: ${props => lighten(0.3, props.theme.primary)};

  div {
    background: ${props => props.theme.primary};
  }
`
