import React, { useContext, useState, useEffect } from 'react';

import _, { isNumber } from 'lodash';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import { Loader } from '@googlemaps/js-api-loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../../hooks/provideAuth';

import { Container, Header, Scroll, MapWrapper, Footer } from './styles';
import stylesMaterial from './stylesMaterial';

interface MapModalProps {
  handleCloseMapModal: () => void;
  openMapModal: boolean;
  latLong: { lat: number; long: number };
  verifyAddress: (uf: string, city: string, neighborhood: string) => void;
  register: (latLong: { lat: number; long: number }) => void;
  changeAddressInfo: (
    streetNumber: string,
    route: string,
    sublocality: string,
    postalCode: string,
  ) => void;
}

const MapModal: React.FC<MapModalProps> = ({
  handleCloseMapModal,
  openMapModal,
  latLong,
  register,
  verifyAddress,
  changeAddressInfo,
}) => {
  const classes = stylesMaterial();

  const { state, dispatch } = useContext(AuthContext);

  const [open, setOpen] = useState(openMapModal);
  const [isLoading, setIsLoading] = useState(true);

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_KEY || '',
    version: 'weekly',
  });
  let map: google.maps.Map;
  let marker: google.maps.Marker;
  let infoWindow: google.maps.InfoWindow;
  let geocoder: google.maps.Geocoder;
  const [lat, setLat] = useState(latLong.lat || undefined);
  const [lng, setLng] = useState(latLong.long || undefined);
  const [canSave, setCanSave] = useState(true);

  function measure(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number {
    const R = 6378.137;
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d * 1000;
  }

  function handleLocationError(
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng,
  ) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? 'Erro: Ative a localização do dispositivo para usar esta função.'
        : 'Erro: Seu navegador não dá suporte a geolocalização.',
    );
    infoWindow.open(map);
  }

  useEffect(() => {
    loader.load().then(() => {
      const myLatlng = new google.maps.LatLng(latLong.lat, latLong.long);

      map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: myLatlng,
        zoom: 18,
      });

      marker = new google.maps.Marker({
        position: myLatlng,
        map,
        draggable: true,
        title: 'Localização',
      });

      infoWindow = new google.maps.InfoWindow();

      geocoder = new google.maps.Geocoder();

      const locationButton = document.createElement('button');
      locationButton.textContent = 'Usar localização atual';
      locationButton.classList.add(classes.locationButton);

      map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

      marker.addListener('dragend', () => {
        setLat(marker.getPosition()?.lat());
        setLng(marker.getPosition()?.lng());
      });

      locationButton.addEventListener('click', () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              setLat(pos.lat);
              setLng(pos.lng);

              geocoder.geocode({ location: pos }, (res, status) => {
                if (status === 'OK') {
                  if (res !== null) {
                    const streetN =
                      res[0].address_components.find((info) =>
                        info.types.includes('street_number'),
                      )?.long_name || '';
                    const route =
                      res[0].address_components.find((info) =>
                        info.types.includes('route'),
                      )?.long_name || '';
                    const sublocality =
                      res[0].address_components.find((info) =>
                        info.types.includes('sublocality'),
                      )?.long_name || '';
                    const postalCode =
                      res[0].address_components.find((info) =>
                        info.types.includes('postal_code'),
                      )?.long_name || '';

                    changeAddressInfo(streetN, route, sublocality, postalCode);
                  }
                }
              });

              infoWindow.setPosition(pos);
              infoWindow.setContent('Localização encontrada.');
              infoWindow.open(map);
              map.setCenter(pos);
              marker.setPosition(pos);
            },
            () => {
              handleLocationError(true, infoWindow, map.getCenter()!);
            },
          );
        } else {
          // Browser doesn't support Geolocation
          handleLocationError(false, infoWindow, map.getCenter()!);
        }
      });

      setIsLoading(false);
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseMapModal();
  };

  const confirmAddress = () => {
    if (lat !== undefined && lng !== undefined) {
      new google.maps.Geocoder().geocode(
        { location: { lat, lng } },
        (res, status) => {
          if (status === 'OK') {
            if (res !== null) {
              const uf =
                res[0].address_components.find((info) =>
                  info.types.includes('administrative_area_level_1'),
                )?.long_name || '';
              const neighborhood =
                res[0].address_components.find((info) =>
                  info.types.includes('sublocality'),
                )?.long_name || '';
              const city =
                res[0].address_components.find((info) =>
                  info.types.includes('administrative_area_level_2'),
                )?.long_name || '';

              handleClose();
              register({ lat, long: lng });
              verifyAddress(uf, city, neighborhood);
            }
          }
        },
      );
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <span className={classes.span} />
          <div>
            <Typography variant="h6" className={classes.title}>
              Confirmar endereço
            </Typography>
          </div>
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon style={{ color: '#B22222' }} />
          </IconButton> */}
        </Header>
        <Divider className={classes.titleDivider} />
        <Typography variant="subtitle1" className={classes.subtitle}>
          Mova o marcador para o endereço certo antes de confirmar:
        </Typography>

        <Scroll>
          <MapWrapper id="map" />
        </Scroll>

        <>
          <Footer>
            <Button
              variant="contained"
              fullWidth
              disabled={isLoading || !canSave}
              onClick={confirmAddress}
              style={{
                backgroundColor: '#B22222',
                color: '#fefefe',
                height: '45px',
                width: '90%',
              }}
            >
              Confirmar
            </Button>
          </Footer>
          <div className={classes.offset1} />
        </>

         
      </Container>
    </Modal>
  );
};

export default MapModal;
