import styled from 'styled-components';

interface ImageContainerProps {
  paddingTop?: string;
  isClicked: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  min-height: 150px;
  cursor: ${(p) => (p.isClicked ? 'pointer' : 'default')};
  width: 100vw;
  height: calc(calc(100vw * 9) / 19); // proporção 16/9
  position: relative;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '0px')};
  background-color: #222;

  @media (min-width: 1024px) {
    height: 50vh;
  }

  img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: initial;
    left: 0;
    top: 0;
  }
`;
