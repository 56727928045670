import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mycarousel: {
    minWidth:'100%',
    width: 0,
  },
  discounticon: {
    fontSize: "53px",
    zIndex: 0,
    "@media (max-width: 680px)": {
      fontSize: "38px",
    }
  },
  linedivider1: {
    margin: "21px 0px 22.5px 0px",
    "@media (max-width: 650px)": {
      margin: "21.5px 0px 17.5px 0px",
    }
  },
  linedivider2: {
    margin: "0px 0px 0px 0px",
    "@media (max-width: 650px)": {
      margin: "0px 0px 0px 0px",
    }
  }
}));