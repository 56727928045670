import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  min-height: calc(100vh - 64px);
`;
