import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  flex-grow: 1;

  @media (max-width: 760px){
    width: 95%;
  }

  @media (min-width: 761px) and (max-width: 1380px){
    width: 90%;
  }
`;

export const StepContent = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 760px){
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const Price = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
`;

export const PricesValues = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: max-content;
  p + p {
    margin-left: 10px;
  }

  @media (max-width: 760px){
    justify-content: center;
  }
`;