import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchForm: {
    position: 'relative',
    borderRadius: '20px',
    backgroundColor: "#FFF",
    '&:hover': {
      backgroundColor: "#fafafa",
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0.313rem',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    borderRadius: '20px',
    height: '100%',
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#6a5d5d',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cancelIcon: {
    padding: theme.spacing(0, 2),
    borderRadius: '20px',
    height: '100%',
    position: 'absolute',
    right: 0,
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#6a5d5d',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  inputRoot: {
    color: '#6a5d5d',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    paddingLeft: '20px',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  hide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
