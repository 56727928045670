import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  width: 95%;
  margin: 0 auto;
`;

export const BannerName = styled.h1`
  font-family: SegoeUI, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;
`;

export const CompanyItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
`;

export const ProductItems = styled.div`
  padding: 25px 10px;

  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 1.75rem;

  @media(max-width: 768px) {
    padding: 15px 10px;
    gap: 15px;
  }
  @media(max-width: 580px) {
    gap: 10px;
  }
  @media(max-width: 425px) {
    column-gap: 5px;
    row-gap: 15px;
    padding-inline: 5px;
  }
`;