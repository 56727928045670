import React, { useState, useContext } from "react";

import _ from 'lodash';
import clsx from 'clsx';

import { useLocation } from 'react-router-dom';

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { cpf as cpfValidator } from 'cpf-cnpj-validator'; 

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../../hooks/provideAuth';
import userApi from "../../services/userApi";
import { cep, cpf, telefone } from '../masks';

import { 
  Container,
  Header,
  Scroll,
  UserInfo,
  Footer 
} from './styles';
import stylesMaterial from './stylesMaterial';

interface RequiredFieldsModalProps{
  handleCloseRequiredFieldsModal: () => void;
  openRequiredFieldsModal: boolean;
  registerSucess: () => void;
  companieId: number;
  token: string;
  userData: {razaoSocial: string, fone: string, email: string, cnpjCpf: string}
  requiredFields: string[];
}

const defaultValues = {
  email: "",
  telephone: "",
  cpf: ""
};

const RequiredFieldsModal: React.FC<RequiredFieldsModalProps> = ({
                                                                  handleCloseRequiredFieldsModal, 
                                                                  openRequiredFieldsModal, 
                                                                  registerSucess, 
                                                                  companieId,
                                                                  token,
                                                                  userData,
                                                                  requiredFields
                                                                }) => {
  const classes = stylesMaterial();
  const location = useLocation();

  const { register, handleSubmit, errors } = useForm({ defaultValues });
  const { state, dispatch } = useContext(AuthContext);

  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(openRequiredFieldsModal);
  const [isLoading, setIsLoading] = useState(false);
  const [repeatedData, setRepeatedData] = useState(false);
  const toast_config = {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseRequiredFieldsModal();
  };

  const logOut = () => {
    dispatch({type: 'REMOVE_TOKEN'});
    if(location.pathname.includes("sale")){
      window.location.reload();
    }
  }
  
  const onSubmit = (data: any) => {
    setIsLoading(true);
    const emailTemp = requiredFields.includes("email") ? data.email.toLowerCase() : userData.email;
    const telephoneTemp = requiredFields.includes("telefone") ? data.tel.replace(/\D/g, '') : userData.fone;
    const cnpjCpfTemp = requiredFields.includes("cpf") ? data.cpf.replace(/\D/g, '') : userData.cnpjCpf;

    userApi.updateUserInfo(userData.razaoSocial, telephoneTemp, emailTemp, cnpjCpfTemp, token).then((response) => {
      registerSucess();
      handleClose(); 
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.data.message === "user-email-already-exists"){
        setRepeatedData(true);
        toast.error('O email utilizado já está associado a uma conta existente, insira outro ou troque de conta', toast_config);
      } else if(error.response.data.message === "user-fone-already-exists"){
        setRepeatedData(true);
        toast.error('O telefone utilizado já está associado a uma conta existente, insira outro ou troque de conta', toast_config);
      } else if(error.response.data.message === "user-cpfcnpj-already-exists"){
        setRepeatedData(true);
        toast.error('O cpf utilizado já está associado a uma conta existente, insira outro ou troque de conta', toast_config);
      } else {
        toast.error('Erro no cadastro. Verifique seus dados ou tente mais tarde.', toast_config);
      }
      // if (error.response){
      //   console.log("response");
      //   console.log(error.response);
      // }else if(error.request){
      //   console.log("request");
      //   console.log(error.request);
      // }else if(error.message){
      //   console.log("message")
      //   console.log(error.message);
      // }
      setIsLoading(false);
    });
  };
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <IconButton 
            aria-label="arrow-down"
            onClick={handleClose}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon 
              fontSize='large'
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography 
              variant="h6" 
              className={classes.title}
            >
              Dados necessários
            </Typography>
          </div>
          <IconButton 
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon  
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>
        <Divider className={classes.titleDivider} />
        <Typography 
          variant="subtitle1"
          className={classes.subtitle}
        >
          Preencha os campos para prosseguir com a compra, estes dados ficarão salvos na sua conta:
        </Typography>
        <Scroll>
        <form 
          id="envio-info-form" 
          onSubmit={handleSubmit(onSubmit)} 
          noValidate 
          className={classes.form}
        >
          <UserInfo>
            {
              requiredFields.includes("email")
              ?
              <div className={`${classes.fixDisplay}`}>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Digite seu email"
                  variant="outlined"
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Por favor digite seu email",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
                />
              </div>
              :
              <div style={{display: "none"}} />
            }
            {
              requiredFields.includes("telefone")
              ?
              <div className={`${classes.fixDisplay}`}>
                <TextField
                  required
                  id="tel"
                  name="tel"
                  type="tel"
                  label="Telefone"
                  placeholder="Digite seu telefone"
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    telefone(event);
                  }}
                  onPaste={(event) =>{
                    event.preventDefault()
                    return false;
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Por favor digite seu telefone",
                    },
                  })}
                  autoComplete="off"
                />
                <ErrorMessage
                  errors={errors}
                  name="tel"
                  render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
                />
              </div>
              :
              <div style={{display: "none"}} />
            }
            {
              requiredFields.includes("cpf")
              ?
              <div className={`${classes.fixDisplay}`}>
                <TextField
                  required
                  id="cpf"
                  name="cpf"
                  type="cpf"
                  label="CPF"
                  placeholder="Digite seu CPF"
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    cpf(event);
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Por favor digite seu cpf",
                    },
                    validate: value => cpfValidator.isValid(value.replace(/\D/g, '')) || "CPF inválido!"
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="cpf"
                  render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
                />
              </div>
              :
              <div style={{display: "none"}} />
            }
          </UserInfo>
        </form>
        </Scroll>
        <React.Fragment>
          <Footer 
            style={{ height: !repeatedData ? 90 : 150}}
          >
            <Link
              component="button"
              onClick={logOut}
              className={`${classes.link} ${classes.linkTransition} ${clsx({[classes.hide]: !repeatedData})}`}
            >
              Trocar de conta
            </Link>
            <Button 
              type="submit"
              form="envio-info-form"
              variant="contained" 
              fullWidth  
              disabled={isLoading}
              style={{ 
                backgroundColor: (isLoading) ? '#757575' :'#B22222', 
                color: '#fefefe', 
                height: '45px',
                width: '90%',
              }}
            >
              Salvar
            </Button>
          </Footer>
          <div className={clsx(classes.offset1&&(width<960))} />
        </React.Fragment>
         
      </Container>
    </Modal>
  )
}

export default RequiredFieldsModal;