import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { DefaultTheme } from 'styled-components';

import Divider from '@material-ui/core/Divider';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Button, Modal } from '@material-ui/core';

import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Check from '@material-ui/icons/Check';

import SaleConclusion from '../../components/sale-conclusion';
import { Footer } from '../../components/footer';

import { IEmpresa } from '../../interfaces/empresa';

import empresaApi from '../../services/empresaApi';
import pedidoApi from '../../services/pedidoApi';

import stylesMaterial from './stylesMaterial';
import { Container, Body, ModalContainer } from './styles';

import CheckedIcon from '../../assets/checked.png';

import { useTheme } from '../../hooks/useThemeState';
import { IPedidoVenda } from '../../interfaces/pedidoVenda';
import { CustomAppBar } from '../../new-components/custom-appbar';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#f57c00',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#f57c00',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: '-webkit-flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#f57c00',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#f57c00',
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <FormatAlignJustifyIcon />,
    2: <AttachMoneyIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check /> : icons[String(icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
      marginTop: 20,
      width: 150,
      height: 40,
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Remessa', 'Revisão e Pagamento'];
}

const SaleFinished: React.FC = () => {
  const classes = stylesMaterial();
  const { theme, setTheme } = useTheme();

  const classes1 = useStyles();
  const steps = getSteps();

  const { companieId, numPedido } = useParams<{
    companieId: string;
    numPedido: string;
  }>();

  const [companie, setCompanie] = useState<IEmpresa>();
  const [activeStep, setActiveStep] = useState(2);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [order, setOrder] = useState<IPedidoVenda>();

  useEffect(() => {
    empresaApi
      .getEmpresa(companieId)
      .then((res) => {
        setCompanie(res);

        pedidoApi
          .requestInTextFormatForWhatsapp(Number(numPedido))
          .then(({ data: _msg }: any) => {
            setMsg(_msg);
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        empresaApi
          .getEmpresaSlug(companieId)
          .then((res) => {
            setCompanie(res);
          })
          .catch((err) => {
            console.log(err);
          });

        pedidoApi
          .requestInTextFormatForWhatsapp(Number(numPedido))
          .then(({ data: _msg }: any) => {
            setMsg(_msg);
          })
          .catch((err) => console.log(err));
      });

    pedidoApi.getPedidoById(numPedido.toString())
    .then((res) => {
      setOrder(res);
    })
  }, []);

  useEffect(() => {
    if (companie) {
      empresaApi
        .getParametro(`${companie.id}`, 'customizations')
        .then((res) => {
          if (res) {
            const role: DefaultTheme = JSON.parse(res.role);
            if (role.title === 'custom') {
              setTheme({
                title: role.title,
                primary: role.primary,
                secondary: role.secondary,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [companie]);

  useEffect(() => {
    if(order && order.estagio === 0) {
      empresaApi.getParametro(`${order.empresaId}`, 'mensagem_cliente').then((res) => {
        if (res && res.role) {
          if(JSON.parse(res.role).status) {
            setOpen(true);
          }
        } else {
          setOpen(true)
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [order]);

  const handleClose = () => {
    setOpen(false);
  };

  const closeModal = () => {
    setOpen(false);
    if (msg) {
      window.open(msg, '_blank');
    }
  };

  return (
    <Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        id="modal"
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModalContainer>
            <img src={CheckedIcon} alt="approved" />
            <h3>Pedido finalizado com sucesso!</h3>
            {msg ? (
              <p>Acompanhe seu pedido pelo whatsapp.</p>
            ) : (
              <p>Acompanhe seu pedido nesta página.</p>
            )}
            <Button
              variant="contained"
              className={classes.continueButton}
              style={{ backgroundColor: theme.primary }}
              onClick={closeModal}
            >
              {msg ? 'CLIQUE AQUI' : 'FECHAR'}
            </Button>
          </ModalContainer>
        </div>
      </Modal>
      
      <CustomAppBar />

      <Body>
        <div className={classes1.root}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <Divider className={classes.dividerStep} />

        <SaleConclusion 
          numPedido={_.toNumber(numPedido)} 
          msg={msg}
          order={order}
          setOrder={setOrder}
        />
      </Body>

      <Footer />
    </Container>
  );
};

export default SaleFinished;
