import styled from 'styled-components';


export const Options = styled.div`  
    display: flex;
    display-direction: row;
    justify-content: space-around;
    
    width:350px;

    @media(max-width:435px) {
        width: 80vw;
    }
`;