export const getEstagio = (estagio: number) => {
  switch(estagio){
    case 0:
      return ('Aguardando Confirmação');
    case 1:
      return ('Aceito');
    case 2:
      return ('Negado');
    case 3:
      return ('Em Preparação');
    case 4:
      return ('Pronto');
    case 5:
      return ('Em Rota de Entrega');
    case 6:
      return ('Entregue');
    case 7:
      return ('Faturado');
    case 8:
      return ('Separado');
    case 9:
      return ('Cancelado');
    case 10:
      return ('Cancelado pelo Cliente');
    case 11:
      return ('Pronto para retirada');
    default:
      return ('Estagio inválido');
  }                                                  
} 