import React, { useContext, useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import _ from 'lodash';
import clsx from 'clsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { Context } from '../../../../hooks/cartStore';

import { IProduto } from "../../../../interfaces/produto";
import { IItemComplemento } from "../../../../interfaces/itemComplemento";
import { IGrupoComplemento } from "../../../../interfaces/grupoComplemento";
import { IComplementoCarrinho } from "../../../../interfaces/complementoCarrinho";
import { IItemCarrinho } from "../../../../interfaces/itemCarrinho";
import { IProdutoGrade } from "../../../../interfaces/produtoGrade";
import { IProdutoGradeCarrinho } from '../../../../interfaces/produtoGradeCarrinho';

import ProductComponentGroup from '../../../../components/product-component-group';
import PizzaComponent from '../../../../components/pizza-component';
import AmplifiedImageModal from '../../../../components/amplified-image-modal';

import produtosApi from '../../../../services/produtosApi';

import {
  Wrapper,
  Container,
  Scroll,
  Header,
  Body,
  Imagem,
  Quantidade,
  Footer } from './styles';
import stylesMaterial from './stylesMaterial';
import { ThemeContext } from "styled-components";
import empresaApi from "../../../../services/empresaApi";
import { calcOrderPrice as calcWoPromo } from "../../../../utils/calcOrderPriceWOPromo";
import { calcOrderPrice } from "../../../../utils/calculator";
import { Box } from "@material-ui/core";
import { PizzaComponentCheckbox } from "../../../../new-components/clothe-details-modal/components/PizzaProduct/pizza-component-checkbox";

interface PizzaComplementsProps{
  handleCloseProduct: () => void;
  handleCloseSizesModal: () => void;
  openProduct: boolean;
  product: IProduto;
  selectedSize: IProdutoGrade;
  closed: boolean;
  companieId: number;
  handleCloseModalPai: () => void;
}

const PizzaComplements: React.FC<PizzaComplementsProps> = ({
  handleCloseProduct,
  handleCloseSizesModal,
  openProduct,
  product,
  selectedSize,
  closed,
  companieId,
  handleCloseModalPai
}) => {

  const classes = stylesMaterial();
  const [open, setOpen] = useState(openProduct);
  const [isLoading, setIsLoading] = useState(true);
  const [produto, setProduto] = useState<IProduto>(product);
  const [quantidade, setQuantidade] = useState(1);
  const [price, setPrice] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [complementos, setComplementos] = useState(new Array<IComplementoCarrinho>());
  const [pizzaOptions, setPizzaOptions] = useState<IProduto[]>([]);
  const [selectedPizzaOptions, setSelectedPizzaOptions] = useState<IProdutoGradeCarrinho[]>([]);
  const [selectedPizzaOptionsTemp, setSelectedPizzaOptionsTemp] = useState<IProdutoGradeCarrinho[]>();
  const [numSelectedFlavors, setNumSelectedFlavors] = useState(0);
  const [maxFlavorsSelected, setMaxFlavorsSelected] = useState(false);
  const [openAmplifiedImageModal, setOpenAmplifiedImageModal] = useState(false);
  const [groupsUnderMinimum, setGroupsUnderMinimum] = useState<{id: number, under: boolean}[]>([]);
  const [anyGroupUnder, setAnyGroupUnder] = useState(false);
  const [priceWithOffer, setPriceWithOffer] = useState(0);
  const [hasPromo, setHasPromo] = useState(false);

  const { primary } = useContext(ThemeContext);
  const afterFlavorsRef = useRef<HTMLSpanElement>(null);

  const toast_config = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const { state, dispatch } = useContext(Context);

  let id = 0;

  useEffect(() => {
    produtosApi.getProdutosByTamanhos(selectedSize.tamanho_id).then((res) =>{
      setPizzaOptions(res);
      let temp = res.find((prod) => prod.id === produto.id);

      if (temp) {
        setSelectedPizzaOptions([{ produto: temp, quantidade: 1 }]);
      }

      setIsLoading(false);
    }).catch((erro) =>{
      console.log(erro);
    })
  }, []);

  useEffect(() => {
    if((produto) && (selectedPizzaOptionsTemp!==undefined)){
      setPrice(calcWoPromo({
        id,
        prodComposto: {
          produto,
          complementos
        },
        produtosGrade: selectedPizzaOptionsTemp,
        tamanho: null,
        quantidade,
        observacao
      }));
      setPriceWithOffer(calcOrderPrice({
        id,
        prodComposto: {
          produto,
          complementos
        },
        produtosGrade: selectedPizzaOptionsTemp,
        tamanho: null,
        quantidade,
        observacao
      }));

      setHasPromo(selectedPizzaOptionsTemp.findIndex((option) => option.produto.produto_grades[0].promocao?.ativo) >= 0);
    }
  }, [quantidade, complementos, produto, selectedPizzaOptionsTemp]);

  useEffect(() => {
    produto.complemento.map((comp) =>{
      if (groupsUnderMinimum !== undefined){
        let temp = groupsUnderMinimum;
        if ((_.toNumber(comp.qtd_min) > 0) && (_.toNumber(comp.qtd_max) > 1)){
          temp.push({id: comp.id, under: true});
        } else{
          temp.push({id: comp.id, under: false});
        }
        setGroupsUnderMinimum(temp);
      } else{
        if ((_.toNumber(comp.qtd_min) > 0) && (_.toNumber(comp.qtd_max) > 1)){
          setGroupsUnderMinimum([{id: comp.id, under: true}]);
        } else{
          setGroupsUnderMinimum([{id: comp.id, under: false}]);
        }
      }
    });
    if(selectedSize.qtd_sabores > 1){
      toast.info('Você pode adicionar sabores', toast_config);
    }
  }, [product]);

  useEffect(() =>{
    if(produto !== undefined){
      produto.complemento.map((comp) =>{
        if (groupsUnderMinimum !== undefined){
          let temp = groupsUnderMinimum;
          if ((_.toNumber(comp.qtd_min) > 0) && (_.toNumber(comp.qtd_max) > 1)){
            temp.push({id: comp.id, under: true});
          } else{
            temp.push({id: comp.id, under: false});
          }
          setGroupsUnderMinimum(temp);
        } else{
          if ((_.toNumber(comp.qtd_min) > 0) && (_.toNumber(comp.qtd_max) > 1)){
            setGroupsUnderMinimum([{id: comp.id, under: true}]);
          } else{
            setGroupsUnderMinimum([{id: comp.id, under: false}]);
          }
        }
      });
    }
  }, [produto])

  useEffect(() => {
    let counter = 0;

    if (groupsUnderMinimum!==undefined){
      groupsUnderMinimum.map((group) => {
        if (group.under){
          setAnyGroupUnder(true)
        } else{
          counter+=1;
        }
      });
      if (counter === groupsUnderMinimum.length){
        setAnyGroupUnder(false);
      }
    }
  }, [groupsUnderMinimum]);

  useEffect(() => {
    if (selectedPizzaOptions.length > 0) {
      setNumSelectedFlavors(selectedPizzaOptions
        .map(option => option.quantidade)
        .reduce((prev, next) => prev + next)  
      );
    } else {
      setNumSelectedFlavors(0);
    }
  }, [selectedPizzaOptions]);

  useEffect(() => {
    if (
      product.vitrine
        ? numSelectedFlavors === selectedSize.qtd_sabores
        : numSelectedFlavors === selectedSize.tamanho.qtd_sabores
    ) {
      setMaxFlavorsSelected(true);
    }else{
      setMaxFlavorsSelected(false);
    }

    if (selectedPizzaOptions.length > 0) {
      setSelectedPizzaOptionsTemp(selectedPizzaOptions.map((option) =>{
        return {...option, quantidade: option.quantidade/numSelectedFlavors}
      }));
    }

    if (product.vitrine && numSelectedFlavors === selectedSize?.qtd_sabores) {
      if (afterFlavorsRef) {
        afterFlavorsRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [numSelectedFlavors]);

  const handleOpenAmplifiedImageModal = () => {
    setOpenAmplifiedImageModal(true);
  }

  const handleCloseAmplifiedImageModal = () => {
    setOpenAmplifiedImageModal(false);
  }

  const addToCarts = async () => {
    if (!closed) {
      toast.error('Ops, no momento estamos fechados!', toast_config);
      return;
    }

    if (anyGroupUnder) {
      const firstGroupUnder = groupsUnderMinimum.find(group => group.under);

      if(firstGroupUnder) {
        const groupElement = document.getElementById(`Grupo-Complemento-${firstGroupUnder.id}`);

        if (groupElement) {
          groupElement.scrollIntoView({ behavior: 'smooth' });
          toast.error('Ops, selecione seu complemento', toast_config);
        }
      }
      return;
    }

    let cart = state.carts.find((cart) => _.toNumber(cart.id)=== companieId);

    if (cart===undefined){
      dispatch({
        type: 'ADD_CART', empresaId: companieId, payload: [{
          id,
          prodComposto: {
            produto,
            complementos
          },
          produtosGrade: selectedPizzaOptionsTemp,
          tamanho: selectedSize.tamanho.descricao,
          quantidade,
          observacao
        }]});
    } else{
      addItemToCart(cart.cart, companieId);
    }
    handleCloseSizesModal();
    handleCloseModalPai()
  }

  const addItemToCart = (cart: IItemCarrinho[], empresaId: number) => {
    if(cart.length){
      id = cart[cart.length - 1].id + 1;
    }

    dispatch({
      type: 'ADD_ITEM',
      empresaId: _.toNumber(empresaId),
      payload: {
        id,
        prodComposto: {
          produto,
          complementos
        },
        produtosGrade: selectedPizzaOptionsTemp,
        tamanho: selectedSize.tamanho.descricao,
        quantidade,
        observacao
      }});
  }

  const setComponents = (cartComponent: IComplementoCarrinho) => {
    let temp: IComplementoCarrinho[];
    if(produto !== undefined){
      const grupo = produto.complemento.find((group) =>
        cartComponent.complemento.complemento_id === group.id
      );
      if (grupo !== undefined){
        if (_.toNumber(grupo.qtd_max) === 1){
          temp = complementos.filter((item: IComplementoCarrinho) => {
          return ((item.complemento.id !== cartComponent.complemento.id)
                    &&
                  (item.complemento.complemento_id !== cartComponent.complemento.complemento_id));
          });
        } else {
          temp = complementos.filter((item: IComplementoCarrinho) => {
            return item.complemento.id !== cartComponent.complemento.id;
          });
        }

        if (cartComponent.quantidade>0){
          temp.push(cartComponent);
        }
        setComplementos(temp);
      }
    }
  }

  const setPizzaComponent = (cartPizzaComponent: IProdutoGradeCarrinho) => {
    setSelectedPizzaOptions(prev => {
      const existingPizza = prev.find(pizza => pizza.produto.id === cartPizzaComponent.produto.id);

      if (existingPizza) {
        if (cartPizzaComponent.quantidade > 0) {
          return prev.map(pizza => {
            if (pizza.produto.id === cartPizzaComponent.produto.id) {
              return cartPizzaComponent;
            } else {
              return pizza;
            }
          })
        } else {
          return prev.filter(pizza => pizza.produto.id !== cartPizzaComponent.produto.id);
        }
      } else {
        if (cartPizzaComponent.quantidade > 0) {
          return [...prev, cartPizzaComponent];
        } else {
          return prev;
        }
      }
    })
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseProduct();
    handleCloseModalPai()
  };

  const handleAdition = () => {
    setQuantidade(quantidade+1);
  }
  const handleRemove = () => {
    if(quantidade>1)
      setQuantidade(quantidade-1);
  }

  const handleGroupsUnderMinimum = (groups: {id: number; under: boolean;}[]) => {
    setGroupsUnderMinimum(groups);
  }

  return (
    <Box className={classes.modalContainerInfo}>
      <Wrapper>
        <Scroll>
          <Header>
            <IconButton
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography
                variant="button"
                className={classes.detailTitle}
              >
                Detalhes
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          {
            produto !== undefined
            ?
            <Body>
              {(produto.descricao && selectedSize.tamanho.descricao) && (
                <Typography
                  variant='subtitle1'
                  className={classes.productTitle}
                >
                  {produto.descricao} {selectedSize.tamanho.descricao} ({selectedSize.qtd_pedacos} pedaços)
                </Typography>
              )}

              {
                produto.observacao && (
                  <Typography
                    variant='body1'
                    className={classes.productDesc}
                  >
                    {produto.observacao}
                  </Typography>
                )
              }

              {selectedSize && (selectedSize.tamanho.qtd_sabores > 1 || product.vitrine) && (
                <>
                  <Typography
                    variant='subtitle1'
                    className={classes.maxFlavors}
                  >
                    {product.vitrine 
                      ? `Você deve selecionar ${selectedSize.qtd_sabores} sabores`
                      : `Você pode selecionar até ${selectedSize.tamanho.qtd_sabores} sabores`
                    }
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    className={classes.pizzaOptions}
                  >
                    Sabores
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    className={classes.listQuantity}
                  >
                    Sabores selecionados {numSelectedFlavors}
                  </Typography>
                </>
              )}
              <Divider />

              {produto.vitrine ? (
                pizzaOptions.map((option) =>{
                  return (
                    <PizzaComponentCheckbox
                      key={option.id}
                      pizza={option}
                      setPizzaComponent={setPizzaComponent}
                      base={option.produto_grades[0].id === selectedSize.id}
                      full={maxFlavorsSelected}
                      numSelectedFlavors={numSelectedFlavors}
                    />
                  );
                })
              ) : (
                selectedSize && selectedSize.tamanho.qtd_sabores > 1 && (
                  pizzaOptions.map((option) =>{
                    return (
                      <PizzaComponent
                        key={option.id}
                        pizza={option}
                        setPizzaComponent={setPizzaComponent}
                        base={option.produto_grades[0].id === selectedSize.id}
                        full={maxFlavorsSelected}
                        numSelectedFlavors={numSelectedFlavors}
                      />
                    );
                  })
                )
              )}
              <span ref={afterFlavorsRef} />
                
              {
                (
                  produto.complemento?.length &&
                  (groupsUnderMinimum!==undefined)
                )
                ?
                produto.complemento.map((grupoComplemento: IGrupoComplemento) => {
                  return (
                    <ProductComponentGroup
                      grupoComplemento={grupoComplemento}
                      setComponent={setComponents}
                      complementos={complementos}
                      isLoading={isLoading}
                      groupsUnderMinimum={groupsUnderMinimum}
                      handleGroupsUnderMinimum={handleGroupsUnderMinimum}
                      key={grupoComplemento.id}
                    />
                  );
                })
                :
                <div />
              }
              <Typography variant='body1' >
                Observações
              </Typography>
              <TextareaAutosize
                aria-label="observacoes"
                rowsMin={5}
                rowsMax={5}
                placeholder="Escreva observações sobre o produto"
                onChange={event => setObservacao(event.target.value)}
                className={classes.textArea}
              />
            </Body>
            :
            <div />
          }
        </Scroll>
      </Wrapper>

      <Footer>
        <Quantidade>
          <IconButton
            aria-label="remove"
            onClick={handleRemove}
          >
            <RemoveIcon
              style={{ color: primary }}
            />
          </IconButton>
          <label className={classes.quantityLabel}> {quantidade} </label>
          <IconButton
            aria-label="add"
            onClick={handleAdition}
          >
            <AddIcon
              style={{ color: primary }}
            />
          </IconButton>
        </Quantidade>
        
        <Button
          variant="contained"
          fullWidth
          onClick={addToCarts}
          disabled={
            isLoading || 
            anyGroupUnder ||
            (
              product.vitrine && 
              selectedSize && 
              (
                product.vitrine
                  ? numSelectedFlavors < selectedSize.qtd_sabores
                  : numSelectedFlavors < selectedSize.tamanho.qtd_sabores
              )
            )
          }
          style={{
            backgroundColor: (
              isLoading || 
              anyGroupUnder ||
              (
                product.vitrine && 
                selectedSize && 
                (
                  product.vitrine
                    ? numSelectedFlavors < selectedSize.qtd_sabores
                    : numSelectedFlavors < selectedSize.tamanho.qtd_sabores
                )
              )
            ) ? '#757575' : primary,
            color: '#fefefe',
            height: '45px',
            width: '90%' 
          }}
        >
          Adicionar R$ {
            hasPromo
            ? priceWithOffer.toFixed(2).replace('.',',')
            : price.toFixed(2).replace('.',',')
          }
        </Button>
      </Footer>
    </Box>
  )
}

export default PizzaComplements;
