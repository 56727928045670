import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  info:{
    marginTop: '25px'
  },
  formSignup: {
    display: '-webkit-flex',
    flexDirection: 'column',
    minHeight: 'max-content',
    marginTop: '60px',
    alignItems: 'flex-start',
    [theme.breakpoints.down(700)]: {
      width: '80%'
    },
  },
  loginSignupInput: {
    height: '60px',
    width: '500px',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    },
  },
  tokenInput: {
    width: '500px',
    marginTop: '20px',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    },
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1
  },
  continueButton:{
    color: '#fefefe', 
    backgroundColor: '#ef9a9a ',
    height: '100%',
    width: '100%', 
    '&:hover': {
      backgroundColor: '#B22222',
      transition: 'all 150ms ease 150ms'
    }
  },
  linkContinueButton:{
    height: '40px',
    width: '500px',
    marginTop: '50px',
    textDecoration: 'none',
    [theme.breakpoints.down(700)]: {
      width: '80%'
    },
  },
  nextButton:{
    [theme.breakpoints.down(700)]: {
      width: '100%'
    },
  },
  terms: {
    marginTop: '20px',
  },
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  loginTitle: {
    fontSize: '1.2rem'
  },
}));
