import styled from 'styled-components';


export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 550px) / 2);
  width: 550px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Wrapper = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  height: calc(80vh - 130px);
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: calc(100vh - 130px);
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 90px;
  min-height: max-content;
`;

export const Subtotal = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  div{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #D3D3D3;
    height: 40px;
  }
  
`;

export const DeliveryTax = styled.div`
  padding-right: 15px;
  padding-left: 15px;

  div{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #D3D3D3;
    height: 20px;
  }
  
`;

export const Total = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  
  div{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  
`;

export const Note = styled.div`
  padding-top: 15px;
  background-color: #faf0ee;
  min-height: max-content;
  div{
    display: -webkit-flex;
    flex-direction: column;

    background-color: #fefefe;

    padding-right: 15px;
    padding-left: 15px;
    div{
      display: -webkit-flex;
      flex-direction: column;
      padding: 5px 0px 5px 0px;
    } 
  }
`;

export const Location = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #faf0ee;
  min-height: max-content;
  div{
    display: -webkit-flex;
    flex-direction: column;

    background-color: #fefefe;

    padding-right: 15px;
    padding-left: 15px;
    div{
      display: -webkit-flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0px 5px 0px;

      div{
        display: -webkit-flex;
        flex-direction: column;

        svg + button{
          margin-top: 5px;
        }
      }
      div + div{
        display: -webkit-flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
        flex: 1;
        min-height: 55px;
        align-items: center;
      }
    } 
  }
`;

export const PaymentMethod = styled.div`
  padding-bottom: 15px;
  background-color: #faf0ee;
  min-height: max-content;
  div{
    display: -webkit-flex;
    flex-direction: column;

    background-color: #fefefe;

    padding-right: 15px;
    padding-left: 15px;
    div{
      display: -webkit-flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0px 5px 0px;

      div + div{
        align-items: center;
        flex: 1;
      }
    } 
  }
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  border-radius:  0 0 0.3rem 0.3rem;
  background-color: rgb(254, 254, 254);
  @media(max-width: 960px){
    position: fixed;
    bottom: 0;
  }
`;