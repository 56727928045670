import React, { useState, useContext, useEffect } from "react";

import { useForm } from 'react-hook-form';
import { useParams } from "react-router-dom"
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';

import NewPasswordModal from '../../new-password-modal';


import userApi from '../../../services/userApi';
import { AuthContext } from '../../../hooks/provideAuth';
import { telefone2 } from '../../masks';

import { 
  Container, 
  Header, 
  Wrapper, 
  Body 
} from './styles';
import stylesMaterial from './stylesMaterial';

interface SigninInfo {
  login: string;
  password: string;
}

interface SigninProps{
  handleCloseSigninModal: () => void;
  openSigninModal: boolean;
  handleCloseAll: () => void;
}

const Signin: React.FC<SigninProps> = ({ 
  handleCloseSigninModal, 
  openSigninModal,
  handleCloseAll
}) => {

  const classes = stylesMaterial();

  const { register, handleSubmit, errors } = useForm({ defaultValues: { login: "", password: ""} });
  const { state, dispatch } = useContext(AuthContext);

  const [open, setOpen] = useState(openSigninModal);
  const [openNewPasswordModal, setOpenNewPasswordModal] = useState(false);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const onSubmit = (data: SigninInfo) => {
    let user = data.login;
    if (data.login.match("[a-zA-Z]+") === null ){
      user = data.login.replace(/\D/g, '');
    } 

    user = user.toLowerCase().trim();

    userApi.login(user, data.password).then((res) => {
      dispatch({type: 'SET_TOKEN',
                payload: res.token});
      handleCloseAll();
      handleClose();
    }).catch((error) => {
      toast.error('Usuário e/ou senha incorreto(s).', toast_config);
    });
  };

  const handleOpenNewPasswordModal = () => {
    setOpenNewPasswordModal(true);
  }

  const handleCloseNewPasswordModal = () => {
    setOpenNewPasswordModal(false);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseSigninModal();
  };

  const handleRequestSucess = () => {
    toast.success('Nova senha solicitada por sms e email com sucesso!', toast_config);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton 
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon 
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography 
                variant="h6" 
                className={classes.loginTitle}
              >
                Entrar
              </Typography>
            </div>
            <IconButton 
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon  
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />
        
          <Body>
          <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className={classes.form}
            >
              <TextField
                id="login-input"
                type="login"
                name="login"
                label="Email ou telefone"
                autoComplete="user-login"
                placeholder="Digite seu email ou telefone"
                variant="outlined"
                className={classes.emailInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.currentTarget.value.match("[a-zA-Z!@#$%^&*_+\\-=;':|,.<>?]+") === null ){
                    telefone2(event);
                  }      
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu email ou telefone",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="login"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <TextField
                id="password-input"
                type="password"
                name="password"
                label="Senha"
                autoComplete="current-password"
                placeholder="Digite sua senha"
                variant="outlined"
                className={classes.passwordInput}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite sua senha",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: '#B22222',
                  color: '#fefefe',
                  height: '45px',
                  marginTop: '60px'
                }}
                className={classes.buttonWithIcon}
              >
                <LockIcon />
                Entrar
              </Button>
            </form>

            <Link
              component="button"
              onClick={handleOpenNewPasswordModal}
              className={`${classes.link} ${classes.linkTransition}`}
            >
              Esqueceu a senha?
            </Link>
          </Body>  
          {
            openNewPasswordModal
            ? <NewPasswordModal
                handleCloseNewPasswordModal={handleCloseNewPasswordModal}
                openNewPasswordModal={openNewPasswordModal}
                requestSucess={handleRequestSucess}
              />
            : <div />
          }  
        </Wrapper>
      </Container>
    </Modal>
  )
}

export default Signin;
