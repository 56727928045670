import styled from 'styled-components';


export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 500px) / 2);
  width: 500px;
  height: 60vh;
  border: '2px solid #000';
  align-self: center;
  align-items: center;
  background-color: #fefefe;
  border-radius: 0.3rem;

  @media(max-width: 960px){
    border-radius: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
  width: 100%;
  margin-top: 2rem;
`;