import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  detailTitle: {
    fontSize: '1.2rem'
  },
  title: {
    fontSize: '1.2rem',
    marginTop: 12,
    fontWeight: 500,
    [theme.breakpoints.up(960)]: {
      fontSize: '1.4rem',
      lineHeight: '1.5'
    },
  },
  hide:{
    display: 'none !important',
  },
  linkButtonFinish:{
    textDecoration: 'none',
    height: '45px', 
    width: '90%'
  },
  offset1: {
    minHeight: 90
  },
  datepickerButton: {
    marginTop: '20px',
    width: '150px',
    height: '60px',
    justifyContent: 'space-between',
    backgroundColor: '#fefefe',
    '&:hover':{
      backgroundColor: '#fefefe',
    },
  },
}));
