import React, { useEffect, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';

import OrderTimelineComponent from '../order-timeline-component';
import { getEstagio } from '../estagio';

import { IPedidoVenda } from '../../interfaces/pedidoVenda';

import { 
  Container, 
  Scroll, 
  Header, 
  Wrapper, 
  Body,
  Address,
  AddressInfos
} from './styles';
import stylesMaterial from './stylesMaterial';
import { Button } from "@material-ui/core";
import { useTheme } from "../../hooks/useThemeState";

interface OrderTimelineProps{
  handleCloseTimeline: () => void;
  openTimeline: boolean;
  order: IPedidoVenda;
}

const OrderTimeline: React.FC<OrderTimelineProps> = ({ 
                                              handleCloseTimeline, 
                                              openTimeline, 
                                              order 
                                            }) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();
  const moment = require('moment');


  const [open, setOpen] = useState(openTimeline);
  const [estagio, setEstagio] = useState('Aguardo');
  const [elapsedDeliveryTime, setElapsedDeliveryTime] = useState<any>();

  useEffect(() => {
    setEstagio(getEstagio(order.estagio));

    if (order.estagio === 6){
      const diff = moment(order.entregueEm ? order.entregueEm : order.dataFechamento).diff(moment(order.dataAbertura));
      const duration = moment.duration(diff);

      setElapsedDeliveryTime(duration);
    }
  }, [])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseTimeline();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <IconButton 
            aria-label="arrow-down"
            onClick={handleClose}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon 
              fontSize='large'
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography 
              variant="h6" 
              className={classes.detailTitle}
            >
              Detalhes da entrega
            </Typography>
          </div>
          <IconButton 
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon  
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>
        <Scroll>
            
          <Body>
            <Wrapper>
              {order.logisticaEntrega && order.etiquetas && (order.etiquetas.findIndex((etiqueta) => !!etiqueta.codigo_rastreio) > -1) && (
                <Typography 
                  variant="body1"
                  className={classes.tracking}
                >
                  {order.logisticaEntrega.nome_servico_transportadora} - {order.logisticaEntrega.nome_transportadora}: {order.etiquetas.map((etiqueta) => etiqueta.codigo_rastreio).join(', ')}
                </Typography>
              )}

              {order.logisticaEntrega && !order.etiquetas && (
                <Typography 
                  variant="body1"
                  className={classes.tracking}
                >
                  O rastreio aparecerá aqui assim que disponível
                </Typography>
              )}
              
              <Typography className={classes.status} >
                {
                  (order.estagio !== 6) 
                  ?
                  estagio
                  :
                  (
                    elapsedDeliveryTime!==undefined
                    ?
                    (
                      elapsedDeliveryTime.days()!==0
                      ?
                      `Entregue em ${elapsedDeliveryTime.days()} dia(s) e ${elapsedDeliveryTime.hours()} hora(s)`
                      :
                      (
                        elapsedDeliveryTime.hours()!==0
                        ?
                        `Entregue em ${elapsedDeliveryTime.hours()} hora(s) e ${elapsedDeliveryTime.minutes()} minuto(s)`
                        :
                        `Entregue em ${elapsedDeliveryTime.minutes()} minuto(s)`
                      )
                    )
                    :
                    ''
                  )
                }
              </Typography>
              <OrderTimelineComponent order={order} />
              { 
                order.endereco
                ?
                <Address>
                  <RoomIcon 
                    fontSize="large"
                  />
                  <AddressInfos>
                    <Typography 
                      variant="body1"
                    >
                      {order.endereco.logradouro}, {order.endereco.numero}
                    </Typography>
                    <Typography 
                      variant="body1"
                    >
                      {order.endereco.bairro}, {order.endereco.cep}
                    </Typography>
                    <Typography 
                      variant="body1"
                    >
                      {order.endereco.complemento}
                    </Typography>
                    <Typography 
                      variant="body1"
                    >
                      {order.endereco.cidade}, {order.endereco.estado}
                    </Typography>
                  </AddressInfos>
                </Address>
                :
                <div />
              }
              
            </Wrapper>
          </Body>

        </Scroll>
      
      </Container>

    </Modal>
  )
}

export default OrderTimeline;