import React, { useContext, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { GridItem, LeftInformation } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../../hooks/useThemeState";
import { Shipping } from "../../../services/pedidoApi";

interface OptionProps{
  value: Shipping;
  checked: boolean;
  setSelectedShipping: (value: Shipping) => void;
}

const Option: React.FC<OptionProps> = ({value, checked, setSelectedShipping}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();

  const handleSelect = () => {
    setSelectedShipping(value);
  }

  return (
    <GridItem>
      <Card
        className={classes.card}
        style={{ border: checked ? `1px solid ${theme.primary}` : ""}}
      >
        <CardActionArea
          onClick={handleSelect}
          className={classes.cardAA}
        >
          <LeftInformation>
            <Typography className={classes.value}>
              {value.name}
            </Typography>

            <Typography className={classes.eta}>
              Tempo estimado: {value.custom_delivery_range.min}-{value.custom_delivery_range.max} dia(s)
            </Typography>
          </LeftInformation>
          
          <Typography
            className={classes.value}
          >
            R$ {value.custom_price.replace('.', ',')}
          </Typography>
        </CardActionArea>
      </Card>
    </GridItem>
  )
}

export default Option;
