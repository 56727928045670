import React, { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import DefaultImage from '../../assets/no_image.png';
import { IEmpresa } from '../../interfaces/empresa';
import {
  CustomFigure,
  CustomImage,
  ItemContainer,
  ItemName,
  ItemWorkingHours,
} from './styles';
import { Box, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import stylesMaterial from './stylesMaterial';
import { ResponsiveEllipsis } from '../../new-components/offer-group/offer-card';

interface BusinessItemProps {
  data: IEmpresa;
}

const BusinessItem: React.FC<BusinessItemProps> = (props) => {
  const { data } = props;

  const history = useHistory();
  const classes = stylesMaterial();
  const storeName = useMemo(() => {
    return data.nome_fantasia?.toLowerCase().split(" ").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" ") || "";
  }, [data]);
  
  return (
    <ItemContainer
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={1}
      onClick={() => history.push(`/${data.slug || data.id}`)}
    >
      <CustomFigure urlImage={DefaultImage}>
        {data.logomarca && (
          <CustomImage
            urlImage={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${data.logomarca}`}
          />
        )}
      </CustomFigure>

      <ItemName>
        <ResponsiveEllipsis
          text={storeName}
          maxLine='2'
          ellipsis='...'
          trimRight
          basedOn='letters'
        />
      </ItemName>
      <ItemWorkingHours isOpen={!!data.isAnyBranchOpen}>
        {data.isAnyBranchOpen ? 'Aberto' : 'Fechado'}
      </ItemWorkingHours>
      {data.distanceToCustomer && (
        <Box className={classes.distanceToCostumer}>
          <LocationOnIcon fontSize='inherit' />
          <Typography variant='inherit'>
            {
              data.distanceToCustomer < 1 ? 
              `${(data.distanceToCustomer).toFixed(1)}km` :
              `${data.distanceToCustomer.toFixed(0)}km`
            } 
          </Typography>
        </Box>
      )}
    </ItemContainer>
  );
};

export default BusinessItem;
