import React, { useContext, useState } from "react";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';

import userApi from '../../services/userApi';
import { AuthContext } from '../../hooks/provideAuth';

import { telefone2 } from '../../utils/masks';


import {
  Container,
  Header,
  Wrapper,
  Body } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../hooks/useThemeState";

interface NewPasswordModalProps{
  handleCloseNewPasswordModal: () => void;
  openNewPasswordModal: boolean;
  requestSucess: () => void;
}

interface NewPasswordInfo {
  login: string;
}

const defaultValues = {
  login: ""
};

const NewPasswordModal: React.FC<NewPasswordModalProps> = ({
                                                            handleCloseNewPasswordModal,
                                                            openNewPasswordModal,
                                                            requestSucess
                                                          }) => {

  const classes = stylesMaterial();
  const { theme } = useTheme()
  const [open, setOpen] = React.useState(openNewPasswordModal);
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const { register, handleSubmit, errors } = useForm({ defaultValues });
  const { state, dispatch } = useContext(AuthContext);

  const onSubmit = (data: NewPasswordInfo) => {
    let user = data.login;
    if (data.login.match("[a-zA-Z]+") === null ){
      user = data.login.replace(/\D/g, '');
    }

    user = user.toLowerCase();

    userApi.requireNewPassword(user).then((res) => {
      requestSucess();
      handleClose();
    }).catch((error) => {
      toast.error('Ocorreu um erro na recuperação da senha.', toast_config);
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseNewPasswordModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography
                variant="h6"
                className={classes.loginTitle}
              >
                Recuperar senha
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          <Body>
            <Typography
              variant="h6"
              className={classes.welcomeTitle}
            >
              Digite o email ou telefone cadastrado
            </Typography>

            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className={classes.form}
            >

              <TextField
                id="login-input"
                type="login"
                name="login"
                label="Login"
                autoComplete="user-login"
                placeholder="Digite seu email ou telefone"
                variant="outlined"
                className={classes.emailInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.currentTarget.value.match("[a-zA-Z!@#$%^&*_+\\-=;':|,.<>?]+") === null ){
                    telefone2(event);
                  }
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu email ou telefone",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="login"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: theme.primary,
                  color: '#fefefe',
                  height: '45px',
                  marginTop: '60px'
                }}
                className={classes.buttonWithIcon}
              >
                <EmailIcon />
                Enviar
              </Button>
            </form>
          </Body>
        </Wrapper>

      </Container>

    </Modal>
  )
}

export default NewPasswordModal;
