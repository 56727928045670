import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  flex: 2;
  min-height: max-content;

  padding: 0 10px;

  align-items: center;
`;

export const Content = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  min-height: max-content;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 760px){
    flex-direction: column;
    align-items: center;
  }
`;

export const Coupon = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  margin-top: 20px;
`;


export const CouponCode = styled.form`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const PaymentMethod = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  min-height: max-content;
  align-items: center;
  margin-top: 20px;
  flex: 1;

  @media (max-width: 760px){
    margin-top: 30px;
  }
`;

export const ButtonRow = styled.div`
  display: -webkit-flex;
  flex-direction: row;

  width: 100%;
  min-height: max-content;
  margin-top: 25px;

  justify-content: space-between;
`;
