import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  span: {
    width: 40,
    height: 0
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
  },
  title: {
    marginTop: '10px'
  },
  subtitle: {
    color: '#9e9e9e',
    margin: '20px 25px 0 25px',
    alignSelf: 'flex-start'
  },
  titleDivider: {
    width: '100%'
  },
  fixDisplay:{
    display: '-webkit-flex',
    flexDirection: 'column'
  },
  description:{
    gridArea: 'description',
  },
  cep:{
    gridArea: 'cep'
  },
  address:{
    gridArea: 'address'
  },
  complement:{
    gridArea: 'complement'
  },
  neighborhood:{
    gridArea: 'neighborhood'
  },
  houseNumber: {
    gridArea: 'houseNumber'
  },
  uf:{
    gridArea: 'uf',
  },
  city:{
    gridArea: 'city',
  },
  principal:{
    gridArea: 'principal',
  },
  currentLocation:{
    display: '-webkit-flex',
    justifyContent: 'center',
    gridArea: 'currentLocation',
  },
  form: {
    overflowY: 'scroll',
    width: '100%',
    flex: 1,
    marginTop: '25px',
    padding: '0 35px 0 35px',
    [theme.breakpoints.down(960)]: {
      marginTop: '10px',
      padding: '0 20px 0 20px',
    },
    '& $input[readonly]': {
      opacity: 0.5
    }
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1
  },
  offset1: {
    minHeight: 90,
    [theme.breakpoints.up(960)]: {
      display: 'none'
    },
  },
  buttonCL: {
    padding: '8px'
  },
  iconCL:{
    height: 30,
    width: 'auto'
  },
  currentLocationText: {
    color: '#2196f3',
  },
}));
