import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  groupTitle: {
    width: 'max-content',
    fontFamily: 'Segoe UI, sans-serif',
    whiteSpace: 'nowrap',
    margin: '0px 8px',
    fontSize: '1.5rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupTitleSmallScreens: {
    width: 'max-content',
    fontFamily: 'Segoe UI, sans-serif',
    whiteSpace: 'nowrap',
    margin: '0px 8px',
    fontSize: '1.3rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingInline: '0.313rem',
    borderRadius: '16px',

    [theme.breakpoints.up(769)]: {
      display: 'none'
    },
  },
  link: {
    fontSize: '16px',
    width: 'max-content',
    fontFamily: 'Segoe UI, sans-serif',
    fontWeight: 600,
    lineHeight: 1.2,
    textDecoration: 'underline',
    marginTop: '10px',
  },
  carouselRoot: {
    display: 'flex',
    width: '100%',
    padding: '25px 10px',
    gap: '20px',
    [theme.breakpoints.down(768)]: {
      gap: '15px',
      padding: '15px 10px',
    },
    [theme.breakpoints.down(580)]: {
      gap: '10px',
    },
    [theme.breakpoints.down(425)]: {
      gap: '5px',
    },
  },
}));
