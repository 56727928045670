import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  detailTitle: {
    fontSize: '1.2rem'
  },
  status:{
    fontSize: '1.3rem',
    fontWeight: 500,
    textAlign: 'center',
    marginTop: '15px'
  },
  deliveryTime:{
    lineHeight: '1.4'
  },
  tracking: {
    fontSize: '1.2rem',
    fontWeight: 400,
    marginTop: '10px'
  }
}));
