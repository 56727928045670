import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import ligth from '../styles/themes/default'

interface AuthProviderProps {
  children: ReactNode
}

interface AuthContextData {
  theme: DefaultTheme
  setTheme: Dispatch<React.SetStateAction<DefaultTheme>>
}

const AuthContext = createContext({} as AuthContextData)

const useTheme = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useTheme must be used within an ThemeProvider')
  }

  return context
}

const ThemeProviderApp = ({ children }: AuthProviderProps) => {
  const [theme, setTheme] = useState<DefaultTheme>(ligth)

  return (
    <AuthContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <ThemeProvider theme={theme} >
        {children}
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export { ThemeProviderApp, useTheme }
