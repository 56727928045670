import styled from 'styled-components';

interface CircleProps {
  selected: boolean;
  color: string;
}

export const Circle = styled.div<CircleProps>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: ${props => props.selected ? '0.15rem' : '0px'};
  border: 2px solid ${p => p.selected ? '#000' : 'transparent'};
  background-color: ${props => props.color};
  background-clip: content-box;
  cursor: pointer;
`;
