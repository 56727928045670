import styled from 'styled-components';

export const GridItem = styled.div`
  justify-content: center;
  margin-top: 12px;
  width: 400px;
  height: 80px;
  @media(max-width: 480px){
    width: 300px;
  }
`;

export const LeftInformation = styled.div`
  display: flex;
  flex-direction: column;
`;