import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(700)]: {
      display: 'none',
    },
  },
  iconArrow: {
    width: 30,
    height: 30,
    padding: 5,
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  iconClose: {},

  textArea: {
    width: '100%',
    marginTop: '15px',
    fontSize: '16px',
    padding: '5px',
    borderRadius: '4px',
  },
  listTitle: {
    fontSize: '1.2rem',
    marginBottom: '13px',
    marginTop: '30px',
  },
  listItem: {
    paddingRight: '0',
    paddingLeft: '0',
  },
  detailTitle: {
    fontSize: '1.2rem',
    transform: 'translateX(-20px)',
    [theme.breakpoints.up(700)]: {
      transform: 'translateX(20px)',
    },
  },
  productTitle: {
    fontSize: '1.2rem',
    [theme.breakpoints.up(960)]: {
      marginTop: 12,
      lineHeight: '1.3',
    },
  },
  productDesc: {
    overflowWrap: 'break-word',
    color: '#757575',
    fontWeight: 300,
    marginTop: '20px',
    marginBottom: '5px',
    [theme.breakpoints.up(960)]: {
      fontSize: '1.1rem',
      lineHeight: '1.2rem',
    },
  },
  numSabores: {
    display: '-webkit-flex',
    alignItems: 'center',
    flex: 1,
    fontWeight: 400,
    [theme.breakpoints.up(960)]: {
      fontSize: '1.1rem',
      lineHeight: '1.2rem',
    },
  },
  quantityLabel: {
    textAlign: 'center',
    fontSize: '1.4rem',
    width: '40px',
  },
  offset1: {
    minHeight: 90,
  },
  modalContainerInfo: {
    width: '50%',
    maxWidth: '50%',
    maxHeight: '600px',
    minWidth: '300px',
    boxSizing: 'border-box',
    padding: '15px 15px 10px',
    justifyContent: 'space-between',
    paddingLeft: '12px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      maxWidth: '100%',
      position: 'relative',
      minHeight: '100%',
    },
  },
}));
