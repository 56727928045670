import React, { useState } from "react";

import { Link } from 'react-router-dom';

import { IEmpresa } from '../../interfaces/empresa';

import { Imagem } from './styles';

interface CompanyLogoHomeProps{
  company: IEmpresa;
}

const CompanyLogoHome: React.FC<CompanyLogoHomeProps> = ({company}) => {
  const [srcImg,setSrcImage] = useState(company.logomarca);

  return (
    <Link to={`/${(company.slug!==null) ? company.slug : company.id}`} >
      <Imagem
        image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}`+srcImg} 
        title={company.razao_social} 
      >
        {/* <div /> */}
        <img 
          src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}`+srcImg}
          onError={() => setSrcImage('no_image.png')} 
          alt={company?.nome_fantasia} 
          title={company?.nome_fantasia}
        />
      </Imagem>
    </Link>
  )
}

export default CompanyLogoHome;