import React, { useState, useContext, useEffect } from "react";

import { useForm } from 'react-hook-form';
import { useParams } from "react-router-dom"
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import userApi from '../../services/userApi';
import { AuthContext } from '../../hooks/provideAuth';

import { telefone2 } from '../masks';

import { 
  Container, 
  Header, 
  Wrapper, 
  Body 
} from './styles';
import stylesMaterial from './stylesMaterial';

interface SignupInfo {
  login: string;
  token: string;
}

interface SigninWithTokenProps{
  login: string;
  handleCloseSigninWithTokenModal: () => void;
  openSigninWithTokenModal: boolean;
  handleCloseAll: () => void;
}

const SigninWithToken: React.FC<SigninWithTokenProps> = ({ 
  login,
  handleCloseSigninWithTokenModal, 
  openSigninWithTokenModal,
  handleCloseAll
}) => {

  const classes = stylesMaterial();

  const { register, handleSubmit, errors } = useForm({ defaultValues: { login, token: ""} });
  const { state, dispatch } = useContext(AuthContext);

  const [open, setOpen] = useState(openSigninWithTokenModal);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const onSubmitSignup = (data: SignupInfo) => {
    let user = data.login;

    user = user.toLowerCase().trim();

    if (data.login.match("[a-zA-Z]+") === null ){
      user = data.login.replace(/\D/g, '');
    } 

    userApi.loginWithToken(user, data.token).then((res) => {
      toast.success('Login realizado com sucesso', toast_config);
      dispatch({type: 'SET_TOKEN', payload: res.token});
      handleCloseAll();
      handleClose();
    }).catch((error) => {
      toast.error('Houve um erro no login', toast_config);
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseSigninWithTokenModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton 
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon 
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography 
                variant="h6" 
                className={classes.loginTitle}
              >
                Entrar com chave de acesso
              </Typography>
            </div>
            <IconButton 
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon  
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />
        
          <Body>
            <form
              onSubmit={handleSubmit(onSubmitSignup)}
              noValidate
              className={classes.formSignup}
            >
              <TextField
                id="login-input"
                type="login"
                name="login"
                label="Telefone ou email"
                autoComplete="user-login"
                placeholder="Digite seu telefone ou email"
                variant="outlined"
                className={classes.loginSignupInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.currentTarget.value.match("[a-zA-Z!@#$%^&*_+\\-=;':|,.<>?]+") === null ){
                    telefone2(event);
                  }      
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu telefone ou email",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="login"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <TextField
                id="token-input"
                type="token"
                name="token"
                label="Chave de acesso"
                autoComplete="user-token"
                placeholder="Digite sua chave de acesso"
                variant="outlined"
                className={classes.tokenInput}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite sua chave de acesso",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="token"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
              
              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{ 
                  backgroundColor: '#B22222', 
                  color: '#fefefe', 
                  height: '45px',
                  marginTop: '60px',
                }}
                className={classes.nextButton}
              >
                Entrar
              </Button>
            </form>
          </Body>    
        </Wrapper>
      </Container>

    </Modal>
  )
}

export default SigninWithToken;
