import {
  Box,
  Button,
  Divider,
  TextareaAutosize,
  Typography,
  Link,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import stylesMaterial from './stylesMaterial';
import ProductComponentGroup from '../../product-component-group';
import {
  Body,
  CarouselContainer,
  Footer,
  Header,
  Imagem,
  Quantidade,
  Scroll,
  Wrapper,
} from './styles';

import { IProduto } from '../../../interfaces/produto';
import { IGrupoComplemento } from '../../../interfaces/grupoComplemento';
import { IComplementoCarrinho } from '../../../interfaces/complementoCarrinho';
import { useTheme } from '../../../hooks/useThemeState';
import produtosApi from '../../../services/produtosApi';
import { ProductCarousel } from '../../../new-components/product-carousel-food';
import { ConsultShippingModal } from '../../../new-components/consult-shipping-modal';
import { Shipping } from '../../../services/pedidoApi';

interface SimpleTypeProps {
  closeModal: () => void;
  handleOpenAmplifiedImageModal: () => void;
  setComponents: (cartComponent: IComplementoCarrinho) => void;
  handleGroupsUnderMinimum: (groups: { id: number; under: boolean }[]) => void;
  handleRemove: () => void;
  handleAdition: () => void;
  addToCarts: () => Promise<void>;
  setObservacao: React.Dispatch<React.SetStateAction<string>>;
  produto: IProduto;
  groupsUnderMinimum: { id: number; under: boolean }[];
  price: number | undefined;
  complementos: IComplementoCarrinho[];
  isLoading: boolean;
  quantProdToCart: number;
  companieClosed: boolean;
  anyGroupUnder: boolean;
  hasMelhorEnvio: boolean;
  companyId: number;
}

const SimpleType: React.FC<SimpleTypeProps> = ({
  closeModal,
  produto,
  groupsUnderMinimum,
  price,
  setComponents,
  handleRemove,
  complementos,
  isLoading,
  handleGroupsUnderMinimum,
  quantProdToCart,
  handleAdition,
  addToCarts,
  setObservacao,
  hasMelhorEnvio,
  companyId,
}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();

  const [productWithComplements, setProductWithComplements] =
    useState<IProduto>(produto);

  const [openConsultShippingModal, setOpenConsultShippingModal] =
    useState(false);
  const [selectedShipping, setSelectedShipping] = useState<Shipping | null>(
    null,
  );

  function handleOpenConsultShippingModal() {
    setOpenConsultShippingModal(true);
  }

  function handleCloseConsultShippingModal() {
    setOpenConsultShippingModal(false);
  }

  return (
    <Box className={classes.modalContainerInfo}>
      <Wrapper>
        <Scroll>
          <Header>
            <IconButton
              aria-label="arrow-down"
              onClick={closeModal}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon
                fontSize="large"
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography variant="button" className={classes.detailTitle}>
                Detalhes
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={closeModal}
              className={classes.buttonClose}
            >
              <CloseIcon
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          {productWithComplements && (
            <Body>
              <CarouselContainer>
                <ProductCarousel
                  images={
                    productWithComplements &&
                    productWithComplements.imagens.length > 0
                      ? productWithComplements.imagens.map(
                          (imagem) => imagem.imagem,
                        )
                      : [productWithComplements.imagem]
                  }
                />
              </CarouselContainer>

              <Typography variant="subtitle1" className={classes.productTitle}>
                {productWithComplements.descricao}
              </Typography>

              {productWithComplements.fracionado && (
                <Typography className={classes.infoPortion}>
                  {`Porção de ${
                    productWithComplements.fracao &&
                    (productWithComplements.fracao >= 1
                      ? productWithComplements.fracao
                      : productWithComplements.unidadeMedida.quantidadePorcao *
                        productWithComplements.fracao)
                  }${
                    productWithComplements.fracao &&
                    productWithComplements.fracao >= 1
                      ? productWithComplements.unidadeMedida.sigla
                      : productWithComplements.unidadeMedida.siglaPorcao
                  }`}
                </Typography>
              )}

              {productWithComplements.fracionado && (
                <Typography className={classes.pricePerUnit}>
                  Preço por {productWithComplements.unidadeMedida.sigla}:{' '}
                  <span>
                    R${' '}
                    {Number(
                      productWithComplements.promocao
                        ? productWithComplements.preco.valor -
                            Number(productWithComplements.promocao.preco)
                        : productWithComplements.preco.valor,
                    )
                      .toFixed(2)
                      .replace('.', ',')}
                  </span>
                </Typography>
              )}

              <Typography variant="body1" className={classes.productDesc}>
                {productWithComplements.observacao}
              </Typography>

              {productWithComplements.produto_promocao &&
                productWithComplements.produto_promocao.filter(
                  (produto_promocao) => produto_promocao.produto !== null,
                ).length > 0 && (
                  <MotorcycleIcon
                    onClick={() => {
                      //
                    }}
                    titleAccess={productWithComplements.produto_promocao
                      .filter(
                        (produto_promocao) => produto_promocao.produto !== null,
                      )
                      .map((produto_promocao, index) => {
                        let text = '';

                        if (index !== 0) {
                          text += '\n\n';
                        }

                        text += `${produto_promocao.promocao?.nome}\n`;
                        text += produto_promocao.promocao?.descricao;

                        return text;
                      })
                      .reduce((prev, next) => prev.concat(next))}
                    style={{ fontSize: 28 }}
                  />
                )}
              {productWithComplements.estoque &&
                productWithComplements.estoque.ativo &&
                productWithComplements.estoque.estoque_atual == 0 && (
                  <Typography
                    // className={classes.price}
                    style={{ color: theme.primary }}
                  >
                    Sem estoque
                  </Typography>
                )}
              {selectedShipping && (
                <Typography className={classes.shipping}>
                  {selectedShipping.name}: +R${' '}
                  {selectedShipping.price.replace('.', ',')}
                </Typography>
              )}
              {productWithComplements.complemento.length > 0 &&
                productWithComplements.complemento.map(
                  (grupoComplemento: IGrupoComplemento) => {
                    if (grupoComplemento.item_complemento.length > 0) {
                      return (
                        <ProductComponentGroup
                          grupoComplemento={grupoComplemento}
                          setComponent={setComponents}
                          complementos={complementos}
                          isLoading={isLoading}
                          groupsUnderMinimum={groupsUnderMinimum}
                          handleGroupsUnderMinimum={handleGroupsUnderMinimum}
                          key={grupoComplemento.id}
                        />
                      );
                    }
                  },
                )}

              {hasMelhorEnvio && (
                <Link
                  component="button"
                  onClick={handleOpenConsultShippingModal}
                  className={classes.consultShipping}
                >
                  Consultar frete
                </Link>
              )}

              <Typography variant="body1">Observações</Typography>
              <TextareaAutosize
                aria-label="observacoes"
                placeholder="Escreva observações sobre o produto"
                onChange={(event) => setObservacao(event.target.value)}
                className={classes.textArea}
              />
            </Body>
          )}
        </Scroll>
      </Wrapper>

      <Footer>
        <Quantidade>
          <IconButton aria-label="remove" onClick={handleRemove}>
            <RemoveIcon
              style={
                produto.estoque &&
                produto.estoque.ativo &&
                produto.estoque.estoque_atual &&
                quantProdToCart <= 1
                  ? { color: '#999' }
                  : { color: theme.primary }
              }
            />
          </IconButton>
          <p className={classes.quantityLabel}>
            {produto.fracionado && produto.fracao
              ? `${
                  quantProdToCart * produto.fracao >= 1
                    ? (quantProdToCart * produto.fracao).toFixed(3)
                    : quantProdToCart *
                      produto.unidadeMedida.quantidadePorcao *
                      produto.fracao
                }${
                  quantProdToCart * produto.fracao >= 1
                    ? produto.unidadeMedida.sigla
                    : produto.unidadeMedida.siglaPorcao
                }`
              : quantProdToCart}
          </p>
          <IconButton aria-label="add" onClick={handleAdition}>
            <AddIcon
              style={
                produto.estoque &&
                produto.estoque.ativo &&
                produto.estoque.estoque_atual &&
                quantProdToCart >= produto.estoque.estoque_atual
                  ? { color: '#999' }
                  : { color: theme.primary }
              }
            />
          </IconButton>
        </Quantidade>

        <Button
          variant="contained"
          fullWidth
          onClick={addToCarts}
          disabled={
            isLoading ||
            (produto.estoque &&
              produto.estoque.ativo &&
              produto.estoque.estoque_atual < quantProdToCart)
          }
          style={{
            backgroundColor:
              isLoading ||
              (produto.estoque &&
                produto.estoque.ativo &&
                produto.estoque.estoque_atual < quantProdToCart)
                ? '#757575'
                : theme.primary,
            color: '#fefefe',
            height: '45px',
            width: '90%',
          }}
        >
          Adicionar R${' '}
          {price !== undefined ? Number(price).toFixed(2).replace('.', ',') : 0}
        </Button>

        {openConsultShippingModal && (
          <ConsultShippingModal
            openConsultShippingModal={openConsultShippingModal}
            handleCloseConsultShippingModal={handleCloseConsultShippingModal}
            productId={produto.id}
            quantity={quantProdToCart}
            companyId={companyId}
            selectedShipping={selectedShipping}
            setSelectedShipping={setSelectedShipping}
          />
        )}
      </Footer>
    </Box>
  );
};

export default SimpleType;
