import React, { useState, useContext } from "react";

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, Link as LinkRD, useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import userApi from '../../../services/userApi';
import empresaApi from "../../../services/empresaApi";
import { AuthContext } from '../../../hooks/provideAuth';

import { telefone2 } from '../../../components/masks';

import stylesMaterial from './stylesMaterial';

import { Container, UserInfo } from './styles';
import { IUserInfoWithoutLogin } from "../../../interfaces/userInfoWithoutLogin";
import LoginModal from '../../../components/login-modal';
import Signup from "./signup";
import { useTheme } from "../../../hooks/useThemeState";

const defaultValues = {
  login: ""
};

interface IdentificacaoProps{
  empresaId: number;
}

export const Identificacao: React.FC<IdentificacaoProps> = ({ empresaId }) => {
  const classes = stylesMaterial();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({ defaultValues });
  const { state, dispatch } = useContext(AuthContext);
  const { companieId } = useParams<{ companieId: string }>();

  const [checkedUser, setCheckedUser] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFone, setUserFone] = useState("");
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [isLoadingRF, setIsLoadingRF] = useState(true);
  const [userLoginTreated, setUserLoginTreated] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfoWithoutLogin>();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { theme } = useTheme()

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const checkUser = (data: { login: string }) => {
    let user = data.login;
    let tempEmail = "", tempFone = "";

    if (data.login.match("[a-zA-Z]+") === null ){
      setUserFone(user);
      user = data.login.replace(/\D/g, '');
      tempFone = user;
    } else {
      setUserEmail(user);
      tempEmail = user;
    }

    user = user.toLowerCase().trim();
    setUserLoginTreated(user);

    userApi.getCheckUser(user).then((res) => {
      if(res === "does-not-exist"){
        empresaApi.getRequiredFields(Number(empresaId)).then((res1) => {
            let rF: string[] = [];

            if (res1.fields.includes("cpf")){
              rF.push("cpf");
            }
            if (res1.fields.includes("email")){
              if(tempEmail === "" || tempEmail === null){
                rF.push("email");
              }
            }
            if (res1.fields.includes("telefone")){
              if(tempFone === "" || tempFone === null){
                rF.push("telefone");
              }
            }

            setRequiredFields(rF);
            setHasAccount(false);
            setCheckedUser(true);
            setIsLoadingRF(false);
        }).catch((erro) => console.log("Erro ao buscar required fields", erro))

      } else {
        setHasAccount(true);
        userApi.getUserInfoLogin(user).then((res) => {
          setUserInfo(res);
          setCheckedUser(true);
        }).catch((error) => console.log("Erro ao checar informações", error));
        toast.info('Encontramos dados associados a este login', toast_config);
      }
    }).catch((error) => {
      console.log(error)
      toast.error('Usuário e/ou senha incorreto(s).', toast_config);
    });
  }

  const handleOpenLoginModal = () => {
    toast.warning("Entre para alterar seus dados", toast_config);
    setOpenLoginModal(true);
  }

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  }

  const partialLogin = () => {
    userApi.partialLogin(userLoginTreated).then(res => {
      dispatch({ type: 'SET_LOGIN', payload: res.token });
    })
  }

  return (
    <>
      {
        checkedUser
        ?
        (
          hasAccount
          ?
          (
            <Container>
              <Typography
                variant="h6"
                className={classes.info}
              >
                Confirme seus dados para prosseguir
              </Typography>

              {
                userInfo && (
                  <UserInfo>
                    <Typography
                      variant="body1"
                      className={classes.userInfo}
                    >
                      Nome: {userInfo.razaoSocial}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.userInfo}
                    >
                      Telefone: {userInfo.fone}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.userInfo}
                    >
                      E-mail: {userInfo.email}
                    </Typography>
                  </UserInfo>
                )
              }

              <Link
                href="#"
                onClick={handleOpenLoginModal}
                className={classes.link}
                style={{ color: theme.primary }}
              >
                Editar
              </Link>

              <Button
                variant="contained"
                fullWidth
                onClick={partialLogin}
                disabled={userLoginTreated === ""}
                className={classes.confirmButton}
                style={{ backgroundColor: theme.primary }}
              >
                Confirmar
              </Button>

              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setCheckedUser(false);
                }}
                style={{
                  height: '45px',
                  width: '500px',
                  marginTop: '20px',
                  border: `1px solid ${theme.primary}`,
                  color: theme.primary,
                  background: 'transparent'
                }}
                className={classes.backButton}
              >
                Voltar
              </Button>
            </Container>
          )
          :
          (
            <Container>
              <Typography
                variant="h6"
                className={classes.info}
              >
                Preencha seus dados para prosseguir com a finalização da compra
              </Typography>

              {
                (( userEmail || userFone ) && (!isLoadingRF)) && (
                  <Signup
                    userEmail={userEmail}
                    userFone={userFone}
                    empresaId={empresaId}
                    needEmail={requiredFields.includes("email")}
                    needFone={requiredFields.includes("telefone")}
                    needCpf={requiredFields.includes("cpf")}
                    setHasAccount={setHasAccount}
                    setUserInfo={setUserInfo}
                    setCheckedUser={setCheckedUser}
                    setUserLoginTreated={setUserLoginTreated}
                  />
                )
              }

              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setCheckedUser(false);
                }}
                style={{
                  height: '45px',
                  width: '500px',
                  marginTop: '20px',
                  border: `1px solid ${theme.primary}`,
                  color: theme.primary,
                  background: 'transparent'
                }}
                className={classes.backButton}
              >
                Voltar
              </Button>
            </Container>
          )
        )
        :
        (
          <Container>
            <Typography
              variant="h6"
              className={classes.info}
            >
              Informe seu telefone para finalizar a compra
            </Typography>

            <form
              onSubmit={handleSubmit(checkUser)}
              noValidate
              className={classes.form}
            >
              <TextField
                id="login-input"
                type="login"
                name="login"
                label={<label><strong>Telefone</strong> ou email</label>}
                autoComplete="user-login"
                placeholder="Digite seu telefone ou email"
                variant="outlined"
                className={classes.loginInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.currentTarget.value.match("[a-zA-Z!@#$%^&*_+\\-=;':|,.<>?]+") === null ){
                    telefone2(event);
                  }
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu telefone ou email",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="login"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                className={classes.continueButtonLogin}
                style={{
                  backgroundColor: theme.primary
                }}
              >
                Continuar
              </Button>
              <Button
                onClick={() => history.push(`/${companieId}`)}
                variant="contained"
                fullWidth
                style={{
                  height: '56px',
                  marginTop: '20px',
                  border: `1px solid ${theme.primary}`,
                  color: theme.primary,
                  background: 'transparent'
                }}
              >
                Voltar
              </Button>
            </form>
          </Container>
        )
      }

      {
        openLoginModal
        ? <LoginModal
            handleCloseLoginModal={handleCloseLoginModal}
            openLoginModal={openLoginModal}
            handleCloseDrawer={() => {}}
            companieId={empresaId}
          />
        : <div />
      }
    </>
  )
}
