import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import {
  Button,
  Divider,
  IconButton,
  Typography,
  Link,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import { toast } from 'react-toastify';
import { IProduto } from '../../../../interfaces/produto';
import { calcOrderPrice } from '../../../../utils/calculator';
import { calcOrderPrice as calcWoPromo } from '../../../../utils/calcOrderPriceWOPromo';
import { IComplementoCarrinho } from '../../../../interfaces/complementoCarrinho';

import {
  CarouselContainer,
  Container,
  InformationsContainer,
  PriceContainer,
  QuantityContainer,
} from './styles';

import stylesMaterial from './stylesMaterial';
import ProductComponentGroup from '../ProductComponentGroup';
import { IGrupoComplemento } from '../../../../interfaces/grupoComplemento';
import { Context } from '../../../../hooks/cartStore';
import { IItemCarrinho } from '../../../../interfaces/itemCarrinho';
import { useTheme } from '../../../../hooks/useThemeState';
import { ProductCarousel } from '../../../product-carousel';
import { ConsultShippingModal } from '../../../consult-shipping-modal';
import { Shipping } from '../../../../services/pedidoApi';

interface SimpleProductProps {
  product: IProduto;
  isStoreOpen: boolean;
  companyId: number;
  onClose: () => void;
  hasMelhorEnvio: boolean;
}

export function SimpleProduct({
  product,
  isStoreOpen,
  companyId,
  onClose,
  hasMelhorEnvio,
}: SimpleProductProps) {
  const classes = stylesMaterial();
  const { state, dispatch, setOpenCart } = useContext(Context);

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [priceWithoutDiscount, setPriceWithoutDiscount] = useState(1);
  const [observacao, setObservacao] = useState('');
  const [complementos, setComplementos] = useState(
    new Array<IComplementoCarrinho>(),
  );
  const [groupsUnderMinimum, setGroupsUnderMinimum] = useState<
    { id: number; under: boolean }[]
  >([]);
  const [anyGroupUnder, setAnyGroupUnder] = useState(false);
  const [openConsultShippingModal, setOpenConsultShippingModal] =
    useState(false);
  const [selectedShipping, setSelectedShipping] = useState<Shipping | null>(
    null,
  );

  const { theme } = useTheme();

  let id = 0;
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (product) {
      setPrice(
        calcOrderPrice({
          id: product.id,
          prodComposto: {
            produto: product,
            complementos,
          },
          produtosGrade: [],
          tamanho: null,
          quantidade: quantity,
          observacao,
        }),
      );
      setPriceWithoutDiscount(
        calcWoPromo({
          id: product.id,
          prodComposto: {
            produto: product,
            complementos,
          },
          produtosGrade: [],
          tamanho: null,
          quantidade: quantity,
          observacao,
        }),
      );
    }
  }, [quantity, complementos, product]);

  useEffect(() => {
    product.complemento.map((comp) => {
      if (groupsUnderMinimum !== undefined) {
        const tempGroupsUnder = groupsUnderMinimum;
        if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
          tempGroupsUnder.push({ id: comp.id, under: true });
        } else {
          tempGroupsUnder.push({ id: comp.id, under: false });
        }
        setGroupsUnderMinimum(tempGroupsUnder);
      } else if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
        setGroupsUnderMinimum([{ id: comp.id, under: true }]);
      } else {
        setGroupsUnderMinimum([{ id: comp.id, under: false }]);
      }
    });
  }, [product]);

  useEffect(() => {
    let counter = 0;

    if (groupsUnderMinimum !== undefined) {
      groupsUnderMinimum.map((group) => {
        if (group.under) {
          setAnyGroupUnder(true);
        } else {
          counter += 1;
        }
      });
      if (counter === groupsUnderMinimum.length) {
        setAnyGroupUnder(false);
      }
    }
  }, [groupsUnderMinimum]);

  const setComponents = (cartComponent: IComplementoCarrinho) => {
    let temp: IComplementoCarrinho[];

    if (product) {
      const grupo = product.complemento.find(
        (group) => cartComponent.complemento.complemento_id === group.id,
      );

      if (grupo) {
        setComplementos((prev) => {
          if (_.toNumber(grupo.qtd_max) === 1) {
            temp = prev.filter((item: IComplementoCarrinho) => {
              return (
                item.complemento.id !== cartComponent.complemento.id &&
                item.complemento.complemento_id !==
                  cartComponent.complemento.complemento_id
              );
            });
          } else {
            temp = prev.filter((item: IComplementoCarrinho) => {
              return item.complemento.id !== cartComponent.complemento.id;
            });
          }

          if (cartComponent.quantidade > 0) {
            temp.push(cartComponent);
          }

          return temp;
        });
      }
    }
  };

  const handleGroupsUnderMinimum = (
    groups: { id: number; under: boolean }[],
  ) => {
    setGroupsUnderMinimum(groups);
  };

  const addItemToCart = (cart: IItemCarrinho[]) => {
    if (cart.length) {
      id = cart[cart.length - 1].id + 1;
    }

    dispatch({
      type: 'ADD_ITEM',
      empresaId: companyId,
      payload: {
        id,
        prodComposto: {
          produto: product,
          complementos,
        },
        produtosGrade: [],
        tamanho: null,
        quantidade: quantity,
        observacao,
      },
    });
    setOpenCart(true);
  };

  const addToCarts = () => {
    if (!isStoreOpen) {
      toast.error('Ops, no momento estamos fechados!', toast_config);
      return;
    }

    if (anyGroupUnder) {
      const firstGroupUnder = groupsUnderMinimum.find((group) => group.under);

      if (firstGroupUnder) {
        const groupElement = document.getElementById(
          `Grupo-Complemento-${firstGroupUnder.id}`,
        );

        if (groupElement) {
          groupElement.scrollIntoView({ behavior: 'smooth' });
          toast.error('Ops, selecione seu complemento', toast_config);
        }
      }
      return;
    }

    const cart = state.carts.find((_cart) => _cart.id === companyId);

    if (cart) {
      addItemToCart(cart.cart);
    } else {
      dispatch({
        type: 'ADD_CART',
        empresaId: companyId,
        payload: [
          {
            id,
            prodComposto: {
              produto: product,
              complementos,
            },
            produtosGrade: [],
            tamanho: null,
            quantidade: quantity,
            observacao,
          },
        ],
      });
    }
    onClose();
    setOpenCart(true);
  };

  function handleOpenConsultShippingModal() {
    setOpenConsultShippingModal(true);
  }

  function handleCloseConsultShippingModal() {
    setOpenConsultShippingModal(false);
  }

  return (
    <Container>
      <CarouselContainer>
        <ProductCarousel
          images={
            product && product.imagens.length > 0
              ? [
                  product.imagem,
                  ...product.imagens
                    .sort((a, b) => a.ordem - b.ordem)
                    .map((imagem) => imagem.imagem),
                ]
              : [product.imagem]
          }
        />
      </CarouselContainer>

      <InformationsContainer>
        <Typography className={classes.clotheTitle}>
          {product.descricao}
        </Typography>

        <div>
          {product.fracionado && (
            <Typography className={classes.infoPortion}>
              {`Porção de ${
                product.fracao &&
                (product.fracao >= 1
                  ? product.fracao
                  : product.unidadeMedida.quantidadePorcao * product.fracao)
              }${
                product.fracao && product.fracao >= 1
                  ? product.unidadeMedida.sigla
                  : product.unidadeMedida.siglaPorcao
              }`}
            </Typography>
          )}

          <Typography className={classes.description}>
            {product.observacao}
          </Typography>
          {product.estoque &&
          product.estoque.ativo &&
          product.estoque.estoque_atual == 0 ? (
            <Typography
              className={classes.price}
              style={{ color: theme.primary }}
            >
              Sem estoque
            </Typography>
          ) : (
            <PriceContainer>
              {product.produto_promocao &&
                product.produto_promocao.filter(
                  (produto_promocao) => produto_promocao.produto !== null,
                ).length > 0 && (
                  <MotorcycleIcon
                    titleAccess={product.produto_promocao
                      .filter(
                        (produto_promocao) => produto_promocao.produto !== null,
                      )
                      .map((produto_promocao, index) => {
                        let text = '';

                        if (index !== 0) {
                          text += '\n\n';
                        }

                        text += `${produto_promocao.promocao?.nome}\n`;
                        text += produto_promocao.promocao?.descricao;

                        return text;
                      })
                      .reduce((prev, next) => prev.concat(next))}
                    style={{ fontSize: 28 }}
                  />
                )}

              <Typography
                className={classes.price}
                style={{
                  color: product.promocao?.ativo ? '#0c65ea' : theme.primary,
                }}
              >
                R$ {price.toFixed(2).replace('.', ',')}
              </Typography>

              {product.promocao?.ativo && (
                <>
                  <Typography className={classes.oldPrice}>
                    R$ {priceWithoutDiscount.toFixed(2).replace('.', ',')}
                  </Typography>
                  <Typography className={classes.discount}>
                    -{Math.round(product.promocao.fator * 100).toFixed(0)}%
                  </Typography>
                </>
              )}
              {selectedShipping && (
                <Typography className={classes.shipping}>
                  {selectedShipping.name}: +R${' '}
                  {selectedShipping.price.replace('.', ',')}
                </Typography>
              )}

              {product.fracionado && (
                <Typography className={classes.pricePerUnit}>
                  Preço por {product.unidadeMedida.sigla}:{' '}
                  <span>
                    R${' '}
                    {Number(
                      product.promocao
                        ? product.preco.valor - Number(product.promocao.preco)
                        : product.preco.valor,
                    )
                      .toFixed(2)
                      .replace('.', ',')}
                  </span>
                </Typography>
              )}
            </PriceContainer>
          )}
        </div>
        <Divider className={classes.divider} />

        {product.complemento.map((grupoComplemento: IGrupoComplemento) => {
          return (
            <ProductComponentGroup
              grupoComplemento={grupoComplemento}
              setComponent={setComponents}
              complementos={complementos}
              isLoading={false}
              groupsUnderMinimum={groupsUnderMinimum}
              handleGroupsUnderMinimum={handleGroupsUnderMinimum}
              key={grupoComplemento.id}
            />
          );
        })}

        <QuantityContainer>
          <IconButton
            color="primary"
            aria-label="remover
            item"
            component="span"
            disabled={quantity === 1}
            onClick={() => setQuantity(quantity - 1)}
            style={{ color: theme.primary }}
          >
            <RemoveCircleIcon />
          </IconButton>
          <Typography className={classes.quantity}>
            {product.fracionado && product.fracao
              ? `${
                  quantity * product.fracao >= 1
                    ? (quantity * product.fracao).toFixed(3)
                    : quantity *
                      product.unidadeMedida.quantidadePorcao *
                      product.fracao
                }${
                  quantity * product.fracao >= 1
                    ? product.unidadeMedida.sigla
                    : product.unidadeMedida.siglaPorcao
                }`
              : quantity}
          </Typography>
          <IconButton
            color="primary"
            aria-label="adicionar item"
            component="span"
            disabled={
              product.estoque &&
              product.estoque.ativo &&
              quantity == product.estoque.estoque_atual
            }
            onClick={() => setQuantity(quantity + 1)}
            style={{ color: theme.primary }}
          >
            <AddCircleIcon />
          </IconButton>
        </QuantityContainer>

        {hasMelhorEnvio && (
          <Link
            component="button"
            onClick={handleOpenConsultShippingModal}
            className={classes.consultShipping}
          >
            Consultar frete
          </Link>
        )}

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={addToCarts}
          disabled={
            product.estoque &&
            product.estoque.ativo &&
            product.estoque.estoque_atual < quantity
          }
          style={{
            marginTop: '20px',
            width: '80%',
            height: '46px',
            alignSelf: 'center',
            backgroundColor:
              product.estoque &&
              product.estoque.ativo &&
              product.estoque.estoque_atual < quantity
                ? '#757575'
                : theme.primary,
          }}
        >
          COLOCAR NA SACOLA
        </Button>
      </InformationsContainer>
      {openConsultShippingModal && (
        <ConsultShippingModal
          openConsultShippingModal={openConsultShippingModal}
          handleCloseConsultShippingModal={handleCloseConsultShippingModal}
          productId={product.id}
          quantity={quantity}
          companyId={companyId}
          selectedShipping={selectedShipping}
          setSelectedShipping={setSelectedShipping}
        />
      )}
    </Container>
  );
}
