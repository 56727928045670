import React, { useCallback, useContext, useEffect, useState } from 'react';

import _load from 'lodash';
import { useParams } from 'react-router-dom';

import { CircularProgress, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { darken } from 'polished';
import { DefaultTheme } from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Body,
  CartLength,
  CartPrice,
  Container,
  SegmentFilter,
} from './styles';
import stylesMaterial from './stylesMaterial';

import SegmentCarousel from '../../../components/segmentCaroselStore';

import StoreDetail from '../../../components/store-detail';
import Storefront from '../../../components/storefront';

import { IGrupoProduto } from '../../../interfaces/grupoProduto';

import { Footer } from '../../../components/footer';
import ModalProductDetail from '../../../components/modalProductDetail';
import IBanner from '../../../interfaces/banner';
import { IProduto } from '../../../interfaces/produto';
import { CustomAppBar } from '../../../new-components/custom-appbar';
import { CustomCarousel } from '../../../new-components/custom-carousel';
import empresaApi from '../../../services/empresaApi';
import produtosApi from '../../../services/produtosApi';

import { Context } from '../../../hooks/cartStore';
import { IEmpresa } from '../../../interfaces/empresa';
import { IItemCarrinho } from '../../../interfaces/itemCarrinho';
import Cart from '../../../new-components/cart';
import { calcOrderPrice } from '../../../utils/calculator';

import offerIcon from '../../../assets/specialoff.png';
import { useQuery } from '../../../hooks/useQuery';
import { useTheme } from '../../../hooks/useThemeState';
import { CompanyInfo } from '../../../new-components/company-info';
import { checkStoreOpen } from '../../../utils/checkStoreOpen';
import { ISchedule } from '../../../interfaces/schedule';

interface IStore {
  company: IEmpresa;
}

const Store: React.FC<IStore> = ({ company }) => {
  const classes = stylesMaterial();

  const [segments, setSegments] = useState([] as Array<IGrupoProduto>);
  const [gruposTemp, setGruposTemp] = useState(new Array<IGrupoProduto>());
  const [selectedSegment, setSelectedSegment] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({} as IProduto);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedType, setSelectedType] = useState<number>(0);
  const [showCartButton, setShowCartButton] = useState(false);
  const [cart, setCart] = useState({ id: 0, cart: new Array<IItemCarrinho>() });
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [banners, setBanners] = useState(
    [] as {
      url: string;
      product_id?: number;
      product?: any;
      ordem: number | null;
    }[],
  );
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [scheduledDays, setScheduledDays] = useState<ISchedule | null>(null);

  const query = useQuery();

  const { companieId } = useParams<{ companieId: string }>();
  const { state } = useContext(Context);
  const { setTheme, theme } = useTheme();

  const handleOpenCart = () => {
    setIsCartOpen(true);
  };

  const handleCloseCart = () => {
    setIsCartOpen(false);
  };

  const handleProduct = (product: IProduto, type: number): void => {
    setSelectedType(type);
    setSelectedProduct(product);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setSelectedProduct({} as IProduto);
  };

  const handleSearch = useCallback(
    _load.debounce((name: string) => {
      let groupsTemp: IGrupoProduto[] = segments
        .map((grupo) => {
          const prodTemp: IProduto[] = grupo.produtos.filter((produto) =>
            produto.descricao
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                name
                  .toUpperCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, ''),
              ),
          );

          return { ...grupo, produtos: prodTemp };
        })
        .filter((grupo) => grupo.produtos.length > 0);

      if (groupsTemp.length === 0) {
        groupsTemp = segments.filter((grupo) =>
          grupo.descricao
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              name
                .toUpperCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            ),
        );
      }

      setGruposTemp(groupsTemp);
    }, 1000),
    [segments],
  );

  const handleSelectedProduct = (productId: number) => {
    produtosApi.getProduto(productId).then((res) => {
      setSelectedProduct(res);
      setSelectedType(res.tipo);
    });
    setIsOpenModal(true);
  };

  useEffect(() => {
    setLoading(true);

    const prodId = query.get('selectedProduct');

    if (prodId) {
      produtosApi.getProduto(prodId).then((res) => {
        setSelectedProduct(res);
        setSelectedType(res.tipo);
      });
      setIsOpenModal(true);
    }

    empresaApi.getBanners(company.id).then((data) => {
      setBanners(
        data.map((banner: IBanner) => ({
          url: `${process.env.REACT_APP_RETAGUARDA_API_FILES}${banner.name}`,
          product_id: banner.product_id,
          product: banner.product,
          ordem: banner.ordem,
        })),
      );
    });

    empresaApi.getParametro(company.id.toString(), 'whatsapp').then((res) => {
      if (res) {
        if (res.role) {
          const tempObj = JSON.parse(res.role);
          if (tempObj.number && tempObj.status) {
            setWhatsappNumber(tempObj.number);
          }
        }
      }
    });

    empresaApi.getParametro(`${company.id}`, 'customizations').then((res) => {
      if (res) {
        const role: DefaultTheme = JSON.parse(res.role);
        if (role.title === 'custom') {
          setTheme({
            title: role.title,
            primary: role.primary,
            secondary: role.secondary,
          });
        }
      }
    });

    empresaApi.getBanners(company.id).then((res) => {
      setBanners(
        res.map((banner: IBanner) => ({
          url: `${process.env.REACT_APP_RETAGUARDA_API_FILES}${banner.name}`,
          product_id: banner.product_id,
          product: banner.product,
          ordem: banner.ordem,
        })),
      );
    });

    const groups = produtosApi.getGrupos(company.id);
    const productsWPromo = produtosApi.getProdutosWithPromo(company.id);

    Promise.allSettled([groups, productsWPromo]).then((values) => {
      if (values[1].status === 'fulfilled' && values[1].value.length > 0) {
        const tempGroup: IGrupoProduto = {
          id: 0,
          empresa_id: company.id,
          grupo_id: 0,
          ordem: 0,
          descricao: 'Ofertas',
          tipo: 0,
          imagem: offerIcon,
          produtos: values[1].value,
        };

        setSegments([tempGroup, ...values[0].value]);
        setGruposTemp([tempGroup, ...values[0].value]);
        setSelectedSegment(0);
      } else {
        setSegments(values[0].value);
        setGruposTemp(values[0].value);
        setSelectedSegment(values[0].value[0].id || 0);
      }
    }).catch((error) => {
      console.log(error)
    });

    empresaApi.getScheduledDelivery(company.id).then((days) => {
      setScheduledDays(days);
    }).catch((error) => {});
    setLoading(false);
  }, []);

  useEffect(() => {
    setCart(
      state.carts.find(
        (cartItem) => _load.toString(cartItem.id) === company?.id.toString(),
      ) || { id: 0, cart: new Array<IItemCarrinho>() },
    );
  }, [state.carts, company]);

  useEffect(() => {
    if (cart.cart.length) {
      setShowCartButton(true);
      setSubtotal(
        cart.cart
          .map((item) => calcOrderPrice(item))
          .reduce((total, next) => total + next),
      );
    } else {
      setShowCartButton(false);
      setSubtotal(0);
    }
  }, [cart.cart]);

  return (
    <Container>
      <Helmet>
        <title>{company.slug}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
        <link
          rel="icon"
          sizes="32x32"
          type="image/png"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
        <link
          rel="icon"
          sizes="16x16"
          type="image/png"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
        <link
          rel="shortcut icon"
          type="imagem/x-icon"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
      </Helmet>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <CustomAppBar hasSearchField search={handleSearch} />

          {isOpenModal && (
              <ModalProductDetail
                isOpenModal={isOpenModal}
                closeModal={closeModal}
                selectedProduct={selectedProduct}
                selectedType={selectedType}
                companieId={company.id}
                scheduledDays={scheduledDays}
                company={company}
              />
          )}

          {banners.length > 0 && (
            <CustomCarousel
              paddingTop="30%"
              banners={banners}
              handleSelectedProduct={handleSelectedProduct}
            />
          )}

          <Body>
            <CompanyInfo company={company} />

            {gruposTemp.length > 0 ?  (
              <>
                <SegmentFilter>
                  <Divider className={classes.divider} />
                  <SegmentCarousel
                    data={gruposTemp}
                    chosen={selectedSegment}
                    onChange={(n: number) => {
                      setSelectedSegment(n);
                    }}
                    isLoading={false}
                  />
                  <Divider className={classes.divider} />
                </SegmentFilter>

                {loading ? (
                  <Storefront isLoading={loading} id="Store Front" />
                ) : (
                  gruposTemp.map((grupo) => {
                    return (
                      <Storefront
                        key={grupo.id}
                        grupo_title={grupo.descricao}
                        group={grupo}
                        isLoading={loading}
                        handleProduct={handleProduct}
                        id={`Grupo-${grupo.id}`}
                      />
                    );
                  })
                )}
              </>
            ) : (
              <CircularProgress style={{ alignSelf: "center" }} />
            )}
          </Body>

          {showCartButton && (
            <Button
              variant="contained"
              fullWidth
              onClick={handleOpenCart}
              className={classes.cartButton}
              style={{ backgroundColor: theme.primary }}
            >
              <CartLength>
                <Typography
                  variant="button"
                  className={classes.cartLength}
                  style={{ backgroundColor: darken(0.2, theme.primary) }}
                >
                  {cart.cart.length}
                </Typography>
              </CartLength>
              <span>Ver sacola</span>
              <CartPrice>
                <Typography variant="button" style={{ whiteSpace: 'nowrap' }}>
                  R$ {subtotal.toFixed(2).replace('.', ',')}
                </Typography>
              </CartPrice>
            </Button>
          )}

          <Footer />
        </>
      )}

      {isCartOpen && (
        <Cart
          handleCloseCart={handleCloseCart}
          openCart={isCartOpen}
          closed={company?.aberto ? !company.aberto : true}
          companyId={company?.id.toString()}
        />
      )}

      {whatsappNumber && (
        <IconButton
          aria-label="start-conversation"
          onClick={() => {
            window.open(
              `https://api.whatsapp.com/send?phone=55${whatsappNumber}&text=${' '}`,
            );
          }}
          className={classes.whatsappButton}
        >
          <WhatsAppIcon fontSize="large" className={classes.whatsappIcon} />
        </IconButton>
      )}
    </Container>
  );
};

export default Store;
