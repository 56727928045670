import React from 'react';
import { OptionContainer, OptionLabel, OptionAnchor, LoadingLabel } from './styles';

import Skeleton from '@material-ui/lab/Skeleton';

interface SegmentOptionProps {
    value?: number;
    label?: string;
    selected: boolean;
    onClick: any;
    isLoading: boolean;
}

const SegmentOption: React.FC<SegmentOptionProps> = ({ value, label, selected, onClick, isLoading }) => {
    const handleClick = () => {
        if (!selected) {
            onClick(value);
        }
    }

    return (
        <React.Fragment>
            {isLoading && isLoading ?
                (
                    <>

                        <LoadingLabel>
                            <Skeleton variant="rect" width={"120px"} height={"20px"} />
                        </LoadingLabel>
                    </>
                )
                :
                (<OptionAnchor href={`#Grupo-${label}`}>
                    <OptionContainer selected={selected} onClick={handleClick}>
                        <OptionLabel>
                            {label?.toUpperCase()}
                        </OptionLabel>
                    </OptionContainer>
                </OptionAnchor>)
            }

        </React.Fragment>
    )
}
export default SegmentOption;