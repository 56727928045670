/* eslint-disable */
import React, { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import { useTheme } from '../../../hooks/useThemeState';
import { IProduto } from '../../../interfaces/produto';
import {
  Card,
  DiscountContainer,
  OutOfStockTag,
  PriceContainer,
} from './styles';
import stylesMaterial from './stylesMaterial';

interface OfferCardProps {
  product: IProduto;
}

export const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const OfferCard: React.FC<OfferCardProps> = (props) => {
  const { theme } = useTheme();
  const { product } = props;

  const prodName = useMemo(() => {
    return product.descricao
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }, [product]);
  const history = useHistory();
  const classes = stylesMaterial();

  return (
    <Card>
      <CardActionArea
        onClick={() =>
          history.push(`/${product.empresa_id}?selectedProduct=${product.id}`)
        }
        className={classes.cardAction}
      >
        <CardMedia
          image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${product.imagem}`}
          className={classes.media}
        >
          <img
            src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${product.empresa?.logomarca}`}
            alt="Company Logo"
            title={product.empresa?.nome_fantasia}
            className={classes.companyLogo}
          />
          {product.estoque &&
            product.estoque.ativo &&
            (product.controla_grade === 0
              ? product.estoque.estoque_atual == 0
              : product.produto_grades
                  .map((product) => product.estoque.estoque_atual == 0)
                  .reduce((prev, next) => prev && next)) && (
              <OutOfStockTag className={classes.outOfStock}>
                <Typography className={classes.stripe}>ESGOTADO</Typography>
              </OutOfStockTag>
            )}

          {product.produto_promocao &&
            product.produto_promocao.filter(
              (produto_promocao) =>
                produto_promocao.primario &&
                Number(product.preco?.valor || product?.minGradePreco?.valor) >=
                  +produto_promocao.promocao.valor_minimo,
            ).length > 0 &&
            (product.produto_promocao.find(
              ({ promocao }) => promocao.tipo_valor === 2,
            ) ? (
              <>
                <OutOfStockTag>
                  <Typography className={classes.stripe}>
                    FRETE GRÁTIS
                  </Typography>
                </OutOfStockTag>
                {product.produto_promocao.find(
                  ({ promocao }) => promocao.tipo_valor === 2,
                )?.promocao.primeira_compra && (
                  <OutOfStockTag>
                    <Typography className={classes.stripe}>
                      NA PRIMEIRA COMPRA
                    </Typography>
                  </OutOfStockTag>
                )}
              </>
            ) : (
              <>
                <OutOfStockTag>
                  <Typography className={classes.stripe}>
                    DESCONTO NO FRETE
                  </Typography>
                </OutOfStockTag>
                {product.produto_promocao[0].promocao.primeira_compra && (
                  <OutOfStockTag>
                    <Typography className={classes.stripe}>
                      NA PRIMEIRA COMPRA
                    </Typography>
                  </OutOfStockTag>
                )}
              </>
            ))}
        </CardMedia>
        <CardContent className={classes.content}>
          {/* <div className={classes.companyInfo}>
            <img
              // src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company?.logomarca}`}
              src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}56e60fa7aee72b367854-specialoff3.jpg`}
              alt="Company Logo"
              title="Nome da empresa"
              className={classes.companyLogo}
            />

            <Typography className={classes.companyName}>
              <ResponsiveEllipsis
                text={"Nome da empresa"}
                maxLine='1'
                ellipsis='...'
                trimRight
                basedOn='letters'
              />
            </Typography>
          </div> */}
          <div className={classes.info}>
            <Typography className={classes.clothingName}>
              <ResponsiveEllipsis
                text={prodName}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </Typography>
          </div>
          <PriceContainer>
            {product.controla_grade === 0
              ? product.promocao &&
                product.promocao.ativo && (
                  <Typography className={classes.oldPrice}>
                    R${' '}
                    {Number(product.preco.valor).toFixed(2).replace('.', ',')}
                  </Typography>
                )
              : product.minGradePromocao &&
                product.minGradePromocao.ativo && (
                  <Typography className={classes.oldPrice}>
                    R${' '}
                    {Number(product.minGradePreco?.valor)
                      .toFixed(2)
                      .replace('.', ',')}
                  </Typography>
                )}
            <Typography
              className={classes.price}
              style={{ color: theme.primary }}
            >
              {product.controla_grade === 1 || product.controla_grade === 2
                ? 'R$ ' +
                  (
                    Math.round(
                      (product?.minGradePreco
                        ? product.minGradePromocao &&
                          product.minGradePromocao.ativo
                          ? product.minGradePreco.valor -
                            Number(product.minGradePromocao.preco)
                          : product.minGradePreco.valor
                        : 0) * 100,
                    ) / 100
                  )
                    .toFixed(2)
                    .replace('.', ',')
                : `R$ ${Number(
                    product.promocao?.ativo
                      ? product.preco.valor - Number(product.promocao.preco)
                      : product.preco.valor,
                  )
                    .toFixed(2)
                    .replace('.', ',')}`}
            </Typography>
          </PriceContainer>
        </CardContent>
      </CardActionArea>
      {product.controla_grade === 0
        ? product.promocao &&
          product.promocao.fator > 0 && (
            <DiscountContainer>
              <Typography className={classes.discountIconText}>
                {`-${Math.round(product.promocao.fator * 100).toFixed(0)}%`}
              </Typography>
            </DiscountContainer>
          )
        : product.produto_grades
            ?.map((prod) => !!prod.promocao?.ativo)
            .reduce((prev, next) => prev || next) && (
            <DiscountContainer>
              <Typography className={classes.discountIconText}>Off</Typography>
            </DiscountContainer>
          )}
    </Card>
  );
};
