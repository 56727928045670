import React, { useState, useEffect, useCallback } from "react";

import _ from 'lodash';
import io from "socket.io-client";
import { useParams, Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  ButtonWhatsapp,
  Container,
  ContainerButtons,
} from './styles';
import stylesMaterial from './stylesMaterial';

import { IPedidoVenda } from "../../interfaces/pedidoVenda";

import pedidoApi from "../../services/pedidoApi";
import { useTheme } from "../../hooks/useThemeState";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Status } from "./status";
import { InfoPedido } from "./info";
import produtosApi from "../../services/produtosApi";
import { IProduto } from "../../interfaces/produto";
import { NotifyOrderValueChange } from "../../pages/SaleFinished/NotifyOrderValueChange";
import { IItemPedido } from "../../interfaces/itemPedido";
interface ISaleFirstStepLoggedProps{
  numPedido: number;
  msg: string;
  order: IPedidoVenda | undefined;
  setOrder: (order: IPedidoVenda) => void;
}

const SaleConclusion: React.FC<ISaleFirstStepLoggedProps> = ({numPedido, msg, order, setOrder}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme()
  const { companieId } = useParams<{ companieId: string }>();

  const [produtos, setProdutos] = useState<IProduto[]>();
  const [openNotifyOrderValueChange, setOpenNotifyOrderValueChange] = useState(false);
  const [excludedItems, setExcludedItems] = useState<IItemPedido[]>([]);
  const [fractionedItems, setFractionedItems] = useState('');

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL || "", {
      query: {
        orderId: numPedido
      }
    });

    socket.on('order_update', (data: IPedidoVenda) => {
      setOrder(data);
    });

    socket.on('order_update_fraction_setted', (data: IPedidoVenda) => {
      if (order) {
        const itemsIds = data.itensPedido.map(item => item.id);
        const excludedItemsTemp = order.itensPedido.filter(item => !itemsIds.includes(item.id));
        setExcludedItems(excludedItemsTemp);
      }

      const fractionedItemsTemp = data.itensPedido.filter(item => item.fracionado).map(item => `${item.nomeProduto}: ${
        item.fracionado 
          ? `${
            item.quantidade >= 1
              ? Number(item.quantidade).toFixed(3)
              : item.quantidade * item.unidadeMedida[0].quantidadePorcao
          }${
            item.quantidade >= 1 
              ? item.unidadeMedida[0].sigla
              : item.unidadeMedida[0].siglaPorcao
          }`
          : Number(item.quantidade)}
      `).join('\n');
      setFractionedItems(fractionedItemsTemp);

      setOrder(data);
      handleOpenNotififyOrderValueChange();
    });

    return () => { socket.disconnect() };
  }, [order]);

  useEffect(() => {
    if (order) {
      const ids = order.itensPedido.map((item) => item.produtoId);
      produtosApi.getProdutoByIds(ids).then((res) => {
        setProdutos(res);
      }).catch((erro) => {
        // console.log(erro);
      });

    }
  }, [order]);

  function handleOpenNotififyOrderValueChange() {
    setOpenNotifyOrderValueChange(true);
  };

  const handleCloseNotififyOrderValueChange = useCallback(() => {
    setOpenNotifyOrderValueChange(false);
  }, []);

  return (
    <Container>
      <Typography
        variant="body1"
        className={classes.acompanhe}
        style={{
          color: theme.primary,
          marginBottom: '10px'
        }}
      >
        Status do pedido
      </Typography>

    {order && produtos && (
      <>
        <Status order={order} />

        <InfoPedido order={order} produtos={produtos} />
      </>
    )}

      <Typography
        variant="body1"
        className={classes.additionalInfo}
      >
        Em breve você também receberá informações do pedido através do email e no WhatsApp no telefone cadastrado.
      </Typography>

      <ContainerButtons>
        <Link
          to={`/${companieId}` }
          className={classes.linkContinueButton}
        >
          <Button
            variant="contained"
            className={classes.continueButton}
            style={{ backgroundColor: theme.primary }}
          >
              Continuar comprando
          </Button>
        </Link>

        {
          !!msg && (
            <ButtonWhatsapp
              variant="outlined"
              startIcon={<WhatsAppIcon />}
              onClick={() => {
                window.open(msg, "_blank")
              }}
            >
              Enviar Novamente
            </ButtonWhatsapp>
          )
        }
      </ContainerButtons>
      
      {openNotifyOrderValueChange && (!!fractionedItems || excludedItems.length > 0) && (
        <NotifyOrderValueChange 
          isOpen={openNotifyOrderValueChange}
          onClose={handleCloseNotififyOrderValueChange}
          fractionedItemsText={fractionedItems}
          excludedItems={excludedItems}
        />
      )}
    </Container>
  )
}

export default SaleConclusion;
