import styled from 'styled-components';

export const SliderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
`;
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const FastMenu = styled.div`
  width: 100%;

  @media (min-width: 961px) {
    padding-right: 100px;
    padding-left: 100px;
  }
`;
