import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  span: {
    width: 40,
    height: 0
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
  },
  title: {
    marginTop: '10px'
  },
  subtitle: {
    color: '#9e9e9e',
    marginTop: '20px',
    marginLeft: '5px',
    alignSelf: 'flex-start'
  },
  titleDivider: {
    width: '100%'
  },
  offset1: {
    minHeight: 90,
    [theme.breakpoints.up(960)]: {
      display: 'none'
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  button:{
    backgroundColor: '#3f51b5',
    width: '300px',
    height: '50px',
    marginTop: '30px',
    color: '#fff',
    '&:hover':{
      backgroundColor: '#3f51b5'
    },
  },
  addAddressButton:{
    backgroundColor: '#269660',
    width: '300px',
    height: '50px',
    marginTop: '1rem',
    color: '#fff',
    '&:hover':{
      backgroundColor: '#269660'
    },
  },
}));
