import styled from 'styled-components';


export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 800px) / 2);
  width 800px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  align-items: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  display: -webkit-flex;
  flex-direction: column;
  height: calc(80vh - 181px);
  width: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: calc(100vh - 181px);
  }
`;

export const MapWrapper = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  height: 500px;
  margin-top: 10px;
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border-radius:  0 0 0.3rem 0.3rem;
  background-color: rgb(254, 254, 254);
  @media(max-width: 960px){
    position: fixed;
    bottom: 0;
  }
`;