import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  whatsappButton: {
    position: 'fixed',
    right: '15px',
    bottom: '70px',
    width: '50px',
    height: '50px',
    padding: '5px',
    boxSizing: 'border-box', 
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: 'rgb(0 0 0 / 40%) 2px 2px 6px',
    transition: 'all 0.5s ease 0s',
    zIndex: 200,
    background: 'rgb(77, 194, 71)',
    '&:hover': {
      background: 'rgb(77, 194, 71)'
    }
  },
  whatsappIcon: {
    color: '#FFF'
  }
}));
