import React, { useState } from 'react';
import { SizeForShow } from '..';
import { ICor } from '../../../../../interfaces/cor';

import { SizeItem } from './SizeItem';

import { 
  Container
} from './styles';

interface SizeGroupProps {
  sizes: SizeForShow[];
  selectedSize: string;
  setSelectedSize: (size: string) => void;
}

export const SizeGroup: React.FC<SizeGroupProps> = (props) => {
  const { sizes, selectedSize, setSelectedSize } = props;

  return (
    <Container>
      {
        sizes.sort((a,b) => a.ordem - b.ordem).map((size) => {
          return (
            <SizeItem 
              key={size.name}
              size={size.name}
              selected={selectedSize ? selectedSize === size.name : false}
              disabled={size.estoque && size.estoque.ativo && size.estoque.estoque_atual == 0}
              setSelectedSize={setSelectedSize}
            />
          );
        })
      }
    </Container>
  );
}