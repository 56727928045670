import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import { ThemeContext } from 'styled-components';
import { Box } from '@material-ui/core';
import { IProduto } from '../../../interfaces/produto';
import { IProdutoGrade } from '../../../interfaces/produtoGrade';
import PizzaComplements from './pizzaComplements';
import PizzaSizesRadio from '../../pizza-sizes-radio';
import { ProductCarousel } from '../../../new-components/product-carousel-food';
import produtosApi from '../../../services/produtosApi';

import {
  Container,
  Scroll,
  Header,
  Body,
  Footer,
  CarouselContainer,
  Wrapper,
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from '../../../hooks/useThemeState';

interface SizesModalProps {
  companieId: number;
  handleClose: () => void;
  isStoreOpen: boolean;
  product: IProduto;
}

const PizzaType: React.FC<SizesModalProps> = ({
  companieId,
  handleClose,
  isStoreOpen,
  product,
}) => {
  const classes = stylesMaterial();
  const [isLoading, setIsLoading] = useState(false);
  const [produto, setProduto] = useState<IProduto>(product);
  const [selectedSizeId, setSelectedSizeId] = useState('');
  const [selectedSize, setSelectedSize] = useState<IProdutoGrade>();
  const [openPizzaDetail, setOpenPizzaDetail] = useState(false);

  const { primary } = useContext(ThemeContext);
  const { theme } = useTheme();

  useEffect(() => {
    if (product.produto_grades.length === 1) {
      setSelectedSizeId(product.produto_grades[0].id.toString());
      setSelectedSize(product.produto_grades[0]);

      handleOpenPizzaDetail();
    }
  }, [produto])

  const handleClearRadioGroup = (value: string) => {
    if (value === selectedSizeId) {
      setSelectedSizeId('');
    } else {
      setSelectedSizeId(value);
      if (produto) {
        const size = produto.produto_grades.find(
          (size) => _.toNumber(value) === size.id,
        );
        if (size) {
          setSelectedSize(size);
        }
      }
    }
  };

  const handleOpenPizzaDetail = () => {
    setOpenPizzaDetail(true);
  };

  const handleClosePizzaDetail = () => {
    setOpenPizzaDetail(false);
  };

  const buttonDisabled = (): boolean => {
    if (
      selectedSizeId === '' ||
      (selectedSize?.estoque &&
        selectedSize.estoque.ativo &&
        Number(selectedSize.estoque.estoque_atual) == 0)
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {!openPizzaDetail ? (
        <Box className={classes.modalContainerInfo}>
          <Wrapper>
            <Scroll>
              <Header>
                <IconButton
                  aria-label="arrow-down"
                  onClick={handleClose}
                  className={classes.buttonArrow}
                >
                  <ArrowBackIosIcon
                    fontSize="large"
                    style={{ color: '#B22222' }}
                    className={classes.iconArrow}
                  />
                </IconButton>
                <div>
                  <Typography variant="button" className={classes.detailTitle}>
                    Tamanhos
                  </Typography>
                </div>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  className={classes.buttonClose}
                >
                  <CloseIcon
                    style={{ color: '#B22222' }}
                    className={classes.iconClose}
                  />
                </IconButton>
              </Header>
              <Divider />

              {produto !== undefined ? (
                <Body>
                  <CarouselContainer>
                    <ProductCarousel
                      images={
                        produto && produto.imagens.length > 0
                          ? produto.imagens.map((imagem) => imagem.imagem)
                          : [produto.imagem]
                      }
                    />
                  </CarouselContainer>
                  {produto.descricao && (
                    <Typography
                      variant="subtitle1"
                      className={classes.productTitle}
                    >
                      {produto.descricao}
                    </Typography>
                  )}

                  {produto.observacao && (
                    <Typography variant="body1" className={classes.productDesc}>
                      {produto.observacao}
                    </Typography>
                  )}
                  {produto?.produto_promocao &&
                    produto.produto_promocao.filter(
                      (produto_promocao) => produto_promocao.produto !== null,
                    ).length > 0 && (
                      <MotorcycleIcon
                        titleAccess={produto.produto_promocao
                          .filter(
                            (produto_promocao) =>
                              produto_promocao.produto !== null,
                          )
                          .map((produto_promocao, index) => {
                            let text = '';

                            if (index !== 0) {
                              text += '\n\n';
                            }

                            text += `${produto_promocao.promocao?.nome}\n`;
                            text += produto_promocao.promocao?.descricao;

                            return text;
                          })
                          .reduce((prev, next) => prev.concat(next))}
                        style={{ fontSize: 28 }}
                      />
                    )}

                  {selectedSize?.estoque &&
                    selectedSize.estoque.ativo &&
                    selectedSize.estoque.estoque_atual == 0 && (
                      <Typography style={{ color: theme.primary }}>
                        Tamanho selecionado não possui estoque
                      </Typography>
                    )}

                  <RadioGroup
                    aria-label="radio-group-of-sizes"
                    name="radio-group-of-sizes"
                    value={selectedSizeId}
                    style={{ marginTop: 20 }}
                  >
                    {produto.produto_grades
                      .sort((a, b) => a.preco.valor - b.preco.valor)
                      .map((size) => {
                        return (
                          <PizzaSizesRadio
                            tamanho={size}
                            handleClearRadioGroup={handleClearRadioGroup}
                            key={size.id}
                          />
                        );
                      })}
                  </RadioGroup>
                </Body>
              ) : (
                <div />
              )}
            </Scroll>
          </Wrapper>

          <Footer>
            <Button
              variant="contained"
              fullWidth
              onClick={handleOpenPizzaDetail}
              disabled={buttonDisabled()}
              style={{
                backgroundColor: buttonDisabled() ? '#757575' : primary,
                color: '#fefefe',
                height: '45px',
                width: '90%',
              }}
            >
              Avançar
            </Button>
          </Footer>
        </Box>
      ) : (
        <PizzaComplements
          handleCloseProduct={handleClosePizzaDetail}
          handleCloseSizesModal={() => {}}
          openProduct={openPizzaDetail}
          product={produto}
          selectedSize={selectedSize!}
          closed={isStoreOpen}
          companieId={companieId}
          handleCloseModalPai={handleClose}
        />
      )}
    </>
  );
};

export default PizzaType;
