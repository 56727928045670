import { makeStyles } from '@material-ui/core/styles';
import { lighten } from 'polished';

export default makeStyles((theme) => ({
  productTitle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  priceText: {
    fontSize: '14px',
    color: '#000',
    fontWeight: 300,
    whiteSpace: 'nowrap'
  },
  divisor: {
    marginTop: '10px',
    [theme.breakpoints.up(960)]: {
      marginTop: '15px',
    },
  },
  prodDescr:{
    color: '#D3D3D3',
    fontSize: '0.95rem',
    lineHeight: '1rem',
  },
  prodObs:{
    color: '#fa0e0e',
    fontSize: '14px',
    lineHeight: 'normal',
    marginTop: '5px'
  },
  editLink: {
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0c65ea',
    '&:hover':{
      color: '#0c65ea',
    },
  },
  removeLink: {
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: lighten(0.2, '#fa0e0e'),
    '&:hover':{
      color: '#fa0e0e',
    },
  }
}));
