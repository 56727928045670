import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  span: {
    width: 40,
    height: 0,
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
  },
  title: {
    marginTop: '10px',
  },
  subtitle: {
    color: '#9e9e9e',
    marginTop: '20px',
    marginLeft: '25px',
    alignSelf: 'flex-start',
  },
  titleDivider: {
    width: '100%',
  },
  fixDisplay: {
    display: '-webkit-flex',
    flexDirection: 'column',
  },
  description: {
    gridArea: 'description',
  },
  cep: {
    gridArea: 'cep',
  },
  address: {
    gridArea: 'address',
  },
  complement: {
    gridArea: 'complement',
  },
  neighborhood: {
    gridArea: 'neighborhood',
  },
  houseNumber: {
    gridArea: 'houseNumber',
  },
  uf: {
    gridArea: 'uf',
    borderWidth: '2px',
    borderColor: 'orange !important',
  },
  city: {
    gridArea: 'city',
  },
  principal: {
    gridArea: 'principal',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '2px',
    borderColor: 'orange !important',
  },
  currentLocation: {
    display: '-webkit-flex',
    justifyContent: 'center',
    gridArea: 'currentLocation',
  },
  form: {
    display: '-webkit-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '25px',
    padding: '0 25px 0 25px',
    justifyContent: 'space-between',
    flex: 1,
    [theme.breakpoints.down(960)]: {
      marginTop: '10px',
      padding: '0 10px 0 10px',
    },
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1,
  },
  offset1: {
    minHeight: 90,
    [theme.breakpoints.up(960)]: {
      display: 'none',
    },
  },
  buttonCL: {
    padding: '8px',
  },
  iconCL: {
    height: 30,
    width: 'auto',
  },
  currentLocationText: {
    color: '#2196f3',
  },
}));
