import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  productTitle: {
    fontSize: '1.05rem',
    letterSpacing: '0',
    lineHeight: '1.1rem',
    maxWidth: '342px',
    [theme.breakpoints.down(960)]: {
      maxWidth: 'calc(100vw - 165px)'
    },
  },
  moreIconButton:{
    padding: '6px',
  },
  priceText: {
    fontSize: '1.05rem',
    color: '#B22222'
  },
  divisor: {
    marginTop: '10px',
    [theme.breakpoints.up(960)]: {
      marginTop: '15px',
    },
  },
  prodDescr:{
    color: '#D3D3D3',
    fontSize: '0.95rem',
    lineHeight: '1rem',
  },
  containerButtons: {
    display: 'flex',
    gap: '5px',
    paddingTop: '8px'
  },
  containerModaConfirmationDeleteItem: {
    padding: '15px 10px',
    background: '#f5f5f5',
    borderRadius: '4px'
  },
  modalConfimationDeleteItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalConfimationDeleteItemItemTitle: {
    fontSize: '1.2rem',
    [theme.breakpoints.up(960)]: {
      fontSize: '1.4rem',
      marginTop: 12,
      lineHeight: '1.5'
    },
    margin: '12px 0px'
  },
  modalConfimationDeleteItemButtonClose :{
    width: 25,
    height: 25,
    padding: 0,
  }
}));
