import styled from "styled-components";

export const Container = styled.img`
  width: 380px;
  height: 190px;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-right: 1rem;

  @media(max-width: 1024px){
    width: 320px;
    height: 150px;
  }

  @media(max-width: 480px){
    width: 280px;
  }
`;