import React from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import BusinessItem from '../business-item';
import { IEmpresa } from '../../interfaces/empresa';

import { Cards } from './styles';
import stylesMaterial from './stylesMaterial';

interface BusinessListProps {
  data: IEmpresa[];
  showAll: boolean;
  isCloseStores?: boolean;
}

const BusinessList: React.FC<BusinessListProps> = ({ data, showAll, isCloseStores }) => {
  const classes = stylesMaterial();

  return (
    showAll ? (
      <Cards>
        {data.map((empresa) => (
          <BusinessItem key={empresa.id} data={empresa} />
        ))}
      </Cards>
    ) : (
      <ScrollContainer
        horizontal
        hideScrollbars
        nativeMobileScroll
        className={classes.carouselRoot}
      >
        {isCloseStores && data.length >= 15 ? (
          data.slice(0,15).map((company) => {
            return <BusinessItem key={company.id} data={company} />;
          })
        ) : (
          data.map((company) => {
            return <BusinessItem key={company.id} data={company} />;
          })
        )}
      </ScrollContainer>
    )
  );
};

export default BusinessList;
