import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  gridItem: {
    display: '-webkit-flex',
    flexDirection: 'row',
    textAlign: 'start',
    margin: 'auto',
    borderRadius: '0.3rem',
  },
  card:{
    width: '100%',
    height: '100%'
  },
  cardAA:{
    display: '-webkit-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: '10px 20px 10px 20px',
  },
  value:{
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 400
  }
}));
