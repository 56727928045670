import React, { Dispatch, useContext, useEffect, useState } from "react";
import clsx from 'clsx';
import { useParams, useHistory } from "react-router-dom";
import _ from 'lodash';
import 'moment/locale/pt-br';

import { ToastContainer, toast } from 'react-toastify';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';

import OrderTimeline from "../order-timeline";
import OrderDetailsItem from '../order-details-item';
import { getEstagio } from '../estagio';

import { Context } from '../../hooks/cartStore';
import { IPedidoVenda } from '../../interfaces/pedidoVenda';
import { IProduto } from '../../interfaces/produto';

import produtosApi from '../../services/produtosApi';

import {
  Container,
  Scroll,
  Header,
  Wrapper,
  Body,
  Subtotal,
  DeliveryTax,
  Total,
  Note,
  Location,
  PaymentMethod,
  Footer
} from './styles';
import stylesMaterial from './stylesMaterial';
import pedidoApi from "../../services/pedidoApi";
import { AuthContext } from "../../hooks/provideAuth";
import { IEmpresa } from "../../interfaces/empresa";
import OrderDetailsItemDeleted from "../order-details-item-deleted";
import { useTheme } from "../../hooks/useThemeState";
import { IItemPedido } from "../../interfaces/itemPedido";
import { CircularProgress } from "@material-ui/core";
import { RedoCart } from "../../utils/redoCartHistory";
import { IRedoCartProduct } from "../../interfaces/redoCartProduct";

interface OrderDetailsProps{
  handleCloseDetails: () => void;
  openDetails: boolean;
  orderId: number;
  empresaId: string | undefined;
  setCompanie: Dispatch<React.SetStateAction<IEmpresa | undefined>>
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
                                              handleCloseDetails,
                                              openDetails,
                                              orderId,
                                              empresaId,
                                              setCompanie
                                            }) => {
  const classes = stylesMaterial();

  const { state, dispatch } = useContext(Context);
  const { theme } = useTheme()

  const [open, setOpen] = useState(openDetails);
  const [openTimeline, setOpenTimeline] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [estagio, setEstagio] = useState('Aguardo');
  const [produtos, setProdutos] = useState<IProduto[]>();
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [elapsedDeliveryTime, setElapsedDeliveryTime] = useState<any>();
  const [orderProducts, setOrderProducts] = useState<IItemPedido[] | null>(null);
  const [complements, setComplements] = useState<IItemPedido[] | null>(null);
  const [produtosGrade, setProdutosGrade] = useState<IItemPedido[] | null>(null);
  const [order, setOrder] = useState<IPedidoVenda | null>(null);
  const [promotionDelivery, setPromotionDelivery] = useState(0);

  const history = useHistory();
  const moment = require('moment');

  const { state: stateAuth, dispatch: dispatchAuth } = useContext(AuthContext);
  const { companieId } = useParams<{ companieId: string }>();

  useEffect(() => {
    pedidoApi.getPedidoById(orderId.toString()).then((res) => {
      setOrder(res);

      if(res.pedido_promocao && res.pedido_promocao.length > 0) {
        let value = 0;

        res.pedido_promocao.map((promo) => {
          if(promo.tipo_valor===1) {
            value += promo.valor;
          } else if(promo.tipo_valor===2) {
            value += (promo.valor/100)*res.valorEntrega;
          } else {
            value = promo.valor;
          }
        })
        
        if(value >= res.valorEntrega) {
          setPromotionDelivery(res.valorEntrega);
        } else {
          setPromotionDelivery(value);
        }
      }
    }).catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if(order) {
      setOrderProducts(order.itensPedido.filter((item) => item.itemPedidoPai===null));
      setComplements(order.itensPedido.filter((item) => (
        (item.itemPedidoPai!==null) && ((item.produtoGradeId===null)||(item.produtoGradeId===undefined))
      )));
      setProdutosGrade(order.itensPedido.filter((item) => (
        (item.itemPedidoPai!==null) && ((item.produtoGradeId!==null)&&(item.produtoGradeId!==undefined))
      )));

      const ids = order.itensPedido.map((item) => item.produtoId);
      produtosApi.getProdutoByIds(ids).then((res) => {
        setProdutos(res);
      }).catch((erro) => {
        // console.log(erro);
      });

      setEstagio(getEstagio(order.estagio));
      let tempDateTime =
        (
          (order.estagio !== 2)
          && (order.estagio !== 6)
          && (order.estagio !== 9)
          && (order.estagio !== 10)
        )
        ?
          (
            ((order.entregaprogramadaem !== undefined) && (order.entregaprogramadaem !== null))
            ? new Date(order.entregaprogramadaem)
            :
            (
              ((order.entregaPrevistaEm !== undefined) && (order.entregaPrevistaEm !== null))
              ? new Date(order.entregaPrevistaEm)
              : new Date(2020, 1)
            )
          )
        :
        (
          order.estagio === 6
          ?
          (
            order.dataFechamento
            ? new Date(order.dataFechamento)
            : new Date(2020, 1)
          )
          :
          (
            ((order.entregueEm !== undefined) && (order.entregueEm !== null))
            ? new Date(order.entregueEm)
            : new Date(2020, 1)
          )
        )
      ;
      if (order.estagio === 6){
        const diff = moment(order.entregueEm ? order.entregueEm : order.dataFechamento).diff(moment(order.dataAbertura));
        const duration = moment.duration(diff);

        setElapsedDeliveryTime(duration);
      }

      moment.locale();

      setDeliveryDate(moment(tempDateTime).format('L'));
      setDeliveryTime(moment(tempDateTime).format('LT'));
    }
  }, [order]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseDetails();
  };

  const handleOpenTimeline = () => {
    setOpenTimeline(true);
  }

  const handleCloseTimeline = () => {
    setOpenTimeline(false);
  }

  const handleBuyAgain = () => {
    const products = new Array<{
      product: number, 
      quantidade: number, 
      item: number, 
      tamanho: string,
      produtoGrade?: number,
      observacao: string | undefined,
    }>();

    if(order) {
      order.itensPedido.map((item) => {
        if (!item.itemPedidoPai){
          products.push({
            product: item.produtoId,
            quantidade: item.quantidade,
            item: item.id,
            tamanho: item.nomeGradeTamanho,
            produtoGrade: item.produtoGradeId,
            observacao: item.observacao,
          });
        }
      });
  
      const productsRedoCart = products.map((product) => {
        const complements = new Array<{complement: number, quantidade: number}>();
        const produtosGrade = new Array<{produtoGrade: number, quantidade: number}>();
  
        order.itensPedido.map((item) => {
          if(item.itemPedidoPai === product.item){
            if (!!item.produtoGradeId) {
              produtosGrade.push({
                produtoGrade: item.produtoGradeId, 
                quantidade: item.quantidade
              });
            } else {
              complements.push({
                complement: item.itemComplementoId, 
                quantidade: item.quantidade
              });
            }
          }
        });
  
        const redoCartItem: IRedoCartProduct = {
          product: product.product,
          complements: complements,
          produtosGrade: produtosGrade,
          tamanho: product.tamanho,
          quantidade: product.quantidade,
          produtoGrade: product.produtoGrade,
          observacao: product.observacao,
        };
    
        return redoCartItem;
      });
      
      if(empresaId !== undefined){
        RedoCart(productsRedoCart).then((cart) => {
          dispatch({type: 'SET_CART', empresaId: _.toNumber(empresaId), payload: cart});
  
          dispatch({
            type: 'SET_OBSERVACAO',
            empresaId: _.toNumber(empresaId),
            payload: !!order.observacao ? order.observacao : ""
          });
          history.push(`/${companieId}`);
        });
      }
    }
  }

  return (
  <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        {order ? (
          <>
            <Header>
              <IconButton
                aria-label="arrow-down"
                onClick={handleClose}
                className={classes.buttonArrow}
              >
                <ArrowBackIosIcon
                  fontSize='large'
                  style={{ color: '#B22222' }}
                  className={classes.iconArrow}
                />
              </IconButton>
              <div>
                <Typography
                  variant="h6"
                  className={classes.detailTitle}
                >
                  Detalhes do pedido #{order.id}
                </Typography>
              </div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.buttonClose}
              >
                <CloseIcon
                  style={{ color: '#B22222' }}
                  className={classes.iconClose}
                />
              </IconButton>
            </Header>
            <Scroll>
              <Body>
                <Wrapper>
                  <Typography
                    variant='h5'
                    className={classes.itemTitle}
                  >
                    Itens
                  </Typography>
                  <Divider />
                  <List
                    aria-label="cart items"
                    disablePadding
                    classes={{ root: classes.itemList }}
                  >
                    {orderProducts && complements && produtosGrade && (
                      orderProducts.map((item) => {
                        if(produtos !== undefined){
                          const produto = produtos.find((prod) => prod.id === item.produtoId);
                          const comp = complements.filter((complement) => complement.itemPedidoPai === item.id);
                          const prodsGrade = produtosGrade.filter((prodGrade) => prodGrade.itemPedidoPai === item.id);
                          if(produto !== undefined){
                            return !item.data_exclusao ? (
                              <OrderDetailsItem
                                key={item.id}
                                setCompanie={setCompanie}
                                handleClose={handleClose}
                                item={produto}
                                itemPedido={item}
                                complementos={comp}
                                produtosGrade={prodsGrade}
                              />

                            ) : (
                              <OrderDetailsItemDeleted
                                key={item.id}
                                item={produto}
                                itemPedido={item}
                                complementos={comp}
                                produtosGrade={prodsGrade}
                              />
                            )
                          }
                        }
                      })
                    )}
                  </List>
                  <Divider />
                </Wrapper>
                <Subtotal>
                  <div>
                    <Typography
                      variant='body2'
                      className={classes.subDelTax}
                    >
                      Subtotal
                    </Typography>
                    <Typography
                      variant='body2'
                      className={classes.subDelTax}
                    >
                      R$ {(_.toNumber(order.valorTotalItens)).toFixed(2).replace('.',',')}
                    </Typography>
                  </div>
                </Subtotal>
                <DeliveryTax>
                  <div>
                    <Typography
                      variant='body2'
                      className={classes.subDelTax}
                    >
                      Taxa de entrega
                    </Typography>
                    <Typography
                      variant='body2'
                      className={classes.subDelTax}
                    >
                      R$ {(_.toNumber(order.valorEntrega)).toFixed(2).replace('.',',')}
                    </Typography>
                  </div>
                  
                  {promotionDelivery > 0 && (
                    <div>
                      <Typography
                        variant='body2'
                        className={classes.subDelTax}
                      >
                        Promoção
                      </Typography>
                      <Typography
                        variant='body2'
                        className={classes.descValue}
                      >
                        - R$ {(_.toNumber(promotionDelivery)).toFixed(2).replace('.',',')}
                      </Typography>
                    </div>
                  )}
                </DeliveryTax>
                {
                  (order.totalDesconto ? _.toNumber(order.totalDesconto) !== 0 : false) && (
                    <Subtotal>
                      <div>
                        <Typography
                          variant='body2'
                          className={classes.subDelTax}
                        >
                          Desconto
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.descValue}
                        >
                          - R$ {(_.toNumber(order.totalDesconto)).toFixed(2).replace('.',',')}
                        </Typography>
                      </div>
                    </Subtotal>
                  )
                }


                <Total>
                  <div>
                    <Typography
                      variant='body1'
                      className={classes.total}
                    >
                      Total
                    </Typography>
                    <Typography
                      variant='body1'
                      className={classes.total}
                    >
                      R$ {
                        (_.toNumber(order.valorEntrega) +
                        _.toNumber(order.valorTotalItens) -
                        _.toNumber(order.totalDesconto ? order.totalDesconto : 0)).toFixed(2).replace('.',',')
                      }
                    </Typography>
                  </div>
                </Total>
                {
                  order.observacao !== ""
                  ?
                  <Note>
                    <div>
                      <div>
                        <Typography
                          variant='body1'
                          className={classes.note}
                        >
                          <span>Oberservação: </span>{order.observacao}
                        </Typography>
                      </div>
                    </div>
                  </Note>
                  :
                  <div />
                }
                <Location>
                  <div>
                    <div>
                      <div>
                        <RoomIcon
                          fontSize="large"
                        />
                        <Link
                          component="button"
                          onClick={handleOpenTimeline}
                          className={classes.showNoteLink}
                          style={{ color: theme.primary }}
                        >
                          Ver detalhes
                        </Link>
                      </div>

                      <div>
                        <Typography className={classes.status} >
                        {
                          (order.estagio !== 6)
                          ?
                          estagio
                          :
                          (
                            elapsedDeliveryTime!==undefined
                            ?
                            (
                              elapsedDeliveryTime.days()!==0
                              ?
                              `Entregue em ${elapsedDeliveryTime.days()} dia(s) e ${elapsedDeliveryTime.hours()} hora(s)`
                              :
                              (
                                elapsedDeliveryTime.hours()!==0
                                ?
                                `Entregue em ${elapsedDeliveryTime.hours()} hora(s) e ${elapsedDeliveryTime.minutes()} minuto(s)`
                                :
                                `Entregue em ${elapsedDeliveryTime.minutes()} minuto(s)`
                              )
                            )
                            :
                            ''
                          )
                        }
                        </Typography>

                        <Typography className={classes.deliveryTime}>
                          {
                            (
                              (order.estagio !== 2)
                              && (order.estagio !== 6)
                              && (order.estagio !== 9)
                              && (order.estagio !== 10)
                            )
                            ?
                            (
                              order.estagio !== 11
                              ? `Chegará às ${deliveryTime} do dia ${deliveryDate}`
                              : `Estará pronto para retirada às ${deliveryTime} do dia ${deliveryDate}`
                            )
                            :
                            (
                              order.estagio === 6
                              ? `Entregue às ${deliveryTime} do dia ${deliveryDate}`
                              : ""
                            )
                          }
                        </Typography>
                      </div>

                    </div>
                  </div>
                </Location>

                <PaymentMethod>
                  <div>
                    <div>

                      <div>

                      </div>

                      <div>

                      </div>

                    </div>
                  </div>
                </PaymentMethod>
                {
                  openTimeline
                  ? <OrderTimeline
                      handleCloseTimeline={handleCloseTimeline}
                      openTimeline={openTimeline}
                      order={order}
                    />
                  : <div />
                }
              </Body>
            </Scroll>

            <React.Fragment>
              <Footer >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleBuyAgain}
                  style={{
                    backgroundColor: theme.primary,
                    color: '#fefefe',
                    height: '45px',
                    width: '90%' }}
                >
                    Comprar novamente
                </Button>
              </Footer>
              <div className={clsx(classes.offset1&&(width<960))} />
            </React.Fragment>
          </>
        ) : (
          <CircularProgress className={classes.loading} />
        )}
        <ToastContainer/>
      </Container>
    </Modal>
  </>
  )
}

export default OrderDetails;
