import React, { useState } from 'react';
import { ItemContainer, ItemHead, ItemTitle, HalfLine, TitleAndShowAll, Cards } from './styles';
import { CircularProgress, Typography } from '@material-ui/core';
import stylesMaterial from './stylesMaterial';
import { IEmpresa } from '../../interfaces/empresa';
import BusinessItem from '../../components/business-item';
import InfiniteScroll from 'react-infinite-scroller';

interface ItemListProps {
    title: string;
    companies: IEmpresa[];
    loadFunction: (page: number) => void;
    totalItems: number;
}

export const ItemListLazyLoading = ({ title, companies, loadFunction, totalItems }:ItemListProps) => {
    const classes = stylesMaterial();

    return (
        <ItemContainer id="item-container">
            <ItemHead>
                <HalfLine />
                <ItemTitle> 
                    {title}
                </ItemTitle>
                <HalfLine />
            </ItemHead>

            <TitleAndShowAll>
                <Typography className={classes.groupTitleSmallScreens}>{title}</Typography>
            </TitleAndShowAll>

            <InfiniteScroll
                pageStart={0}
                loadMore={loadFunction}
                hasMore={companies.length !== totalItems}
                loader={<CircularProgress style={{ marginTop: '0.75rem' }} />}
                style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center' 
                }}
            >
                <Cards>
                    {companies.map((empresa) => (
                        <BusinessItem key={empresa.id} data={empresa} />
                    ))}
                </Cards>
            </InfiniteScroll>
        </ItemContainer>
    )
    
}