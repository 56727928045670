import React, { useState, useContext, useEffect } from "react";

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'; 

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import userApi from '../../../../services/userApi';
import { AuthContext } from '../../../../hooks/provideAuth';

import { cpf, telefone2 } from '../../../../components/masks';

import stylesMaterial from './stylesMaterial';
import { IUserInfoWithoutLogin } from "../../../../interfaces/userInfoWithoutLogin";

interface SignupInfo {
  login: string;
  name: string;
  email: string;
  fone: string;
  cnpjCpf: string;
}

interface SignupProps{
  userEmail: string;
  userFone: string;
  empresaId: number;
  needEmail: boolean;
  needFone: boolean;
  needCpf: boolean;
  setHasAccount: (value: boolean) => void;
  setUserInfo: (value: IUserInfoWithoutLogin) => void;
  setCheckedUser: (value: boolean) => void;
  setUserLoginTreated: (value: string) => void;
}

const CustomCheckbox = withStyles({
  root: {
    color: '#757575',
    '&$checked': {
      color: '#0c65ea',
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const Signup: React.FC<SignupProps> = ({
  userEmail,
  userFone,
  empresaId,
  needEmail,
  needFone,
  needCpf,
  setHasAccount,
  setUserInfo,
  setCheckedUser,
  setUserLoginTreated,
}) => {

  const classes = stylesMaterial();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      login: userFone ? userFone : userEmail,
      name: "",
      email: userEmail,
      fone: userFone,
      cnpjCpf: ""
    }
  });
  const { state, dispatch } = useContext(AuthContext);

  const [checkedTerms, setCheckedTerms] = useState(false);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const onSubmitSignup = (data: SignupInfo) => {
    let tempEmail = data.email ? data.email.toLowerCase().trim() : "";
    let tempFone = data.fone ? data.fone.replace(/\D/g, '') : "";

    userApi.simplifiedRegister(
      tempEmail,
      tempFone,
      data.name,
      data.cnpjCpf ? data.cnpjCpf : "",
      empresaId
    ).then((res) => {
      toast.success('Cadastro concluído', toast_config);
      dispatch({type: 'SET_LOGIN', payload: res.token});
    }).catch((error) => {
      if (error.response.data.message === "user-email-already-exists"){
        toast.info('Encontramos dados associados a este login', toast_config);
        userApi.getUserInfoLogin(tempEmail).then((res) => {
          setUserLoginTreated(tempEmail);
          setHasAccount(true);
          setUserInfo(res);
          setCheckedUser(true);
        }).catch((error) => console.log("Erro ao checar informações", error));
      } else if(error.response.data.message === "user-fone-already-exists"){
        toast.info('Encontramos dados associados a este login', toast_config);
        userApi.getUserInfoLogin(tempFone).then((res) => {
          setUserLoginTreated(tempFone);
          setHasAccount(true);
          setUserInfo(res);
          setCheckedUser(true);
        }).catch((error) => console.log("Erro ao checar informações", error));
      } else {
        toast.error('Erro no cadastro. Verifique seus dados ou tente mais tarde.', toast_config);
      }
    });
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setCheckedTerms(event.target.checked);
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitSignup)}
      noValidate
      className={classes.formSignup}
    >
      <TextField
        id="name-input"
        type="name"
        name="name"
        label="Nome completo"
        autoComplete="user-name"
        placeholder="Digite seu nome completo"
        variant="outlined"
        className={classes.nameInput}
        inputRef={register({
          required: {
            value: true,
            message: "Por favor digite seu nome completo",
          },
        })}
      />
      <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
      />

      {
        (needEmail || (!!userEmail)) && (
          <>
            <TextField
              id="email-input"
              type="email"
              name="email"
              label="Email"
              autoComplete="user-email"
              placeholder="Digite seu email"
              variant="outlined"
              className={classes.loginSignupInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

              }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Por favor digite seu email",
                },
              })}
            />

            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
            />
          </>
        )
      }

      {
        (needFone || (!!userFone)) && (
          <>
            <TextField
              id="fone-input"
              type="fone"
              name="fone"
              label="Telefone"
              autoComplete="user-fone"
              placeholder="Digite seu telefone"
              variant="outlined"
              className={classes.loginSignupInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                telefone2(event);
              }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Por favor digite seu telefone",
                },
                minLength: {
                  value: 13,
                  message: "Por favor digite seu telefone com DDD",
                },
                maxLength: {
                  value: 14,
                  message: "Por favor digite um telefone válido",
                }
              })}
            />

            <ErrorMessage
              errors={errors}
              name="fone"
              render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
            />
          </>
        )
      }

      {
        needCpf && (
          <>
            <TextField
              id="cnpjCpf-input"
              type="cnpjCpf"
              name="cnpjCpf"
              label="Cpf"
              autoComplete="user-cnpjCpf"
              placeholder="Digite seu cpf"
              variant="outlined"
              className={classes.loginSignupInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                cpf(event);
              }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Por favor digite seu cpf",
                },
                validate: value => cpfValidator.isValid(value.replace(/\D/g, '')) || cnpjValidator.isValid(value.replace(/\D/g, '')) || "CPF inválido!"
              })}
            />

            <ErrorMessage
              errors={errors}
              name="cnpjCpf"
              render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
            />
          </>
        )
      }

      <FormControlLabel
        control={<CustomCheckbox checked={checkedTerms} onChange={handleChangeCheckBox} name="checkedTerms" />}
        label={
          <Typography>
            Aceito os <a href="https://retaguarda.atacdelivery.com/api/files/TERMOS_E_CONDICOES_GERAIS_DE_USO_E_DE_COMPRA_E_VENDA_C.pdf" target="_blank">Termos e Condições</a> e a <a href="https://retaguarda.atacdelivery.com/api/files/TERMO_DE_CONSENTIMENTO.pdf" target="_blank">Política de Privacidade</a>
          </Typography>
        }
        className={classes.terms}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!checkedTerms}
        style={{
          backgroundColor: !checkedTerms ? '#757575' :'#0c65ea',
          color: '#fff',
          height: '45px',
          marginTop: '60px',
        }}
        className={classes.nextButton}
      >
        Prosseguir
      </Button>
    </form>
  )
}

export default Signup;
