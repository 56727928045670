import React, { useEffect } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { useTheme } from '../../hooks/useThemeState';
import { IProduto } from '../../interfaces/produto';
import { IProdutoGrade } from '../../interfaces/produtoGrade';
import { ResponsiveEllipsis } from '../offer-group/offer-card';
import { PriceContainer } from './styles';
import stylesMaterial from './stylesMaterial';

interface CustomDetailsProps {
  product: IProduto;
  featuredProduct?: IProdutoGrade;
}

export const CustomDetails: React.FC<CustomDetailsProps> = (props) => {
  const { product, featuredProduct } = props;

  const { theme } = useTheme();
  const classes = stylesMaterial();

  return (
    <CardContent className={classes.content}>
      <div className={classes.info}>
        <Typography className={classes.clothingName}>
          <ResponsiveEllipsis
            text={
              product.vitrine
                ? `${product.descricao} ${product.produto_grades[0].descricao}`
                : product.descricao
            }
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Typography>

        {product.fracionado && (
          <Typography className={classes.infoPortion}>
            {`Porção de ${
              product.fracao &&
              (product.fracao >= 1
                ? product.fracao
                : product.unidadeMedida.quantidadePorcao * product.fracao)
            }${
              product.fracao && product.fracao >= 1
                ? product.unidadeMedida.sigla
                : product.unidadeMedida.siglaPorcao
            }`}
          </Typography>
        )}
      </div>

      {featuredProduct ? (
        <PriceContainer>
          {featuredProduct.promocao && featuredProduct.promocao.ativo && (
            <Typography className={classes.oldPrice}>
              R${' '}
              {Number(featuredProduct.preco.valor).toFixed(2).replace('.', ',')}
            </Typography>
          )}
          <Typography
            className={classes.price}
            style={{ color: theme.primary }}
          >
            R${' '}
            {Number(
              featuredProduct.promocao
                ? Number(featuredProduct.preco.valor) -
                    Number(featuredProduct.promocao.preco)
                : Number(featuredProduct.preco.valor),
            )
              .toFixed(2)
              .replace('.', ',')}
          </Typography>
        </PriceContainer>
      ) : (
        <PriceContainer>
          {product.controla_grade === 0
            ? product.promocao &&
              product.promocao.ativo && (
                <Typography className={classes.oldPrice}>
                  R${' '}
                  {Number(
                    product.preco.valor *
                      (product.fracionado && product.fracao
                        ? product.fracao
                        : 1),
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </Typography>
              )
            : product.minGradePromocao &&
              product.minGradePromocao.ativo && (
                <Typography className={classes.oldPrice}>
                  R${' '}
                  {Number(product.minGradePreco?.valor)
                    .toFixed(2)
                    .replace('.', ',')}
                </Typography>
              )}
          <Typography
            className={classes.price}
            style={{ color: theme.primary }}
          >
            {product.controla_grade === 1 || product.controla_grade === 2
              ? `R$ ${(
                  Math.round(
                    (product?.minGradePreco
                      ? product.minGradePromocao &&
                        product.minGradePromocao.ativo
                        ? product.minGradePreco.valor -
                          Number(product.minGradePromocao.preco)
                        : product.minGradePreco.valor
                      : 0) * 100,
                  ) / 100
                )
                  .toFixed(2)
                  .replace('.', ',')}`
              : `R$ ${(
                  Math.ceil(
                    Number(
                      product.promocao?.ativo
                        ? product.preco.valor *
                            (product.fracionado && product.fracao
                              ? product.fracao
                              : 1) -
                            Number(product.promocao.preco)
                        : product.preco.valor *
                            (product.fracionado && product.fracao
                              ? product.fracao
                              : 1),
                    ) * 100,
                  ) / 100
                )
                  .toFixed(2)
                  .replace('.', ',')}`}
          </Typography>
        </PriceContainer>
      )}
    </CardContent>
  );
};
