import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import ReactPWAInstallProvider from "react-pwa-install";

ReactDOM.render(
  <React.StrictMode>
    <ReactPWAInstallProvider>
      <App />
    </ReactPWAInstallProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
