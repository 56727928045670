import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  loginTitle: {
    fontSize: '1.2rem'
  },
  welcomeTitle: {
    color: '#D3D3D3', 
    marginTop: '20px',
    fontSize: '1.3rem',
    marginBottom: '30px',
    [theme.breakpoints.up(960)]: {
      marginTop: '30px',
      marginBottom: '50px',
    },
  },
  zoomInButton: {
    position: 'absolute',
    padding: 8,
    right: 5,
    top: 5,
    opacity: 0.5,
    zIndex: 2
  },
  zoomOutButton: {
    position: 'absolute',
    padding: 8,
    right: 5,
    top: 50,
    opacity: 0.5,
    zIndex: 2
  }
}));
