import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default makeStyles((theme) => {
  const { primary } = useContext(ThemeContext);
  
  
  return (
    {
      listItem: {
        paddingRight: '0px',
        paddingLeft: '20px',
        display: '-webkit-flex',
        flexDirection: 'row'
      },
      productTitle: {
        fontSize: '1.05rem'
      },
      description: {
        fontSize: '0.95rem',
        color: '#757575',
      },
      priceText: {
        fontSize: '1.05rem',
        color: primary
      },
      componentDesc: {
        paddingTop: '5px', 
        paddingBottom: '5px',
        color: '#D3D3D3'
      },
      oldPrice: {
        marginLeft: '10px',
        fontSize: '16px',
        color: '#757575',
        fontWeight: 300,
        textDecoration: 'line-through',
        whiteSpace: 'nowrap',
      },
    }
  )
});