import { ContainerInfoStatus, ContainerStatus, OrderSteps, Steps } from "../styles"
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Collapse, Typography } from "@material-ui/core";
import React, { useState } from "react";
import stylesMaterial from "../stylesMaterial";
import { CustomLinearProgress } from "../../order-timeline-component-sale/styles";
import { IPedidoVenda } from "../../../interfaces/pedidoVenda";
import OrderTimelineComponentSale from "../../order-timeline-component-sale";

interface StatusProps {
  order: IPedidoVenda;
}

export const Status: React.FC<StatusProps> = (props) => {
  const { order } = props

  let options = {
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions
  const entregaPrevistaEm = order.entregaPrevistaEm && new Date(order.entregaPrevistaEm).toLocaleString('pt-BR', options)

  const classes = stylesMaterial();

  const [isOpenStatus, setIsOpenStatus] = useState(true)

  return (
    <>
      <ContainerStatus
        onClick={() => setIsOpenStatus(p => !p)}
      >
        <div className="icon" >
          <QueryBuilderIcon/>
        </div>
        <div className="conteudo" >
          Acompanhe o seu pedido
        </div>
      </ContainerStatus>

      <Collapse
        in={isOpenStatus}
        collapsedSize={0}
        style={{
          width: '100%',
          maxWidth: '500px'
        }}
      >
        <ContainerInfoStatus>
          <Typography
            variant="h4"
            className={classes.thanksForPurchase}
          >
            Status do pedido!
          </Typography>

          <OrderSteps>
            {
              !!order && (
                <OrderTimelineComponentSale order={order} />
              )
            }
          </OrderSteps>
        </ContainerInfoStatus>
        {
          entregaPrevistaEm && (
            <ContainerInfoStatus className="entrega-prevista" >
              <h2>Entrega prevista em:</h2>
              <p>{entregaPrevistaEm}</p>
            </ContainerInfoStatus>
          )
        }
      </Collapse>
    </>
  )
}
