import { makeStyles } from '@material-ui/core/styles';
import { lighten } from 'polished';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default makeStyles((theme) => {
  const { primary } = useContext(ThemeContext);

  return ({
    info:{
      marginTop: '25px',
      textAlign: 'center'
    },
    form: {
      display: '-webkit-flex',
      flexDirection: 'column',
      minHeight: 'max-content',
      marginTop: '60px',
      alignItems: 'flex-start',
      [theme.breakpoints.down(700)]: {
        width: '80%'
      },
    },
    formSignup: {
      display: '-webkit-flex',
      flexDirection: 'column',
      minHeight: 'max-content',
      marginTop: '60px',
      alignItems: 'flex-start',
      [theme.breakpoints.down(700)]: {
        width: '80%'
      },
    },
    loginInput: {
      height: '60px',
      width: '500px',
      [theme.breakpoints.down(600)]: {
        width: '100%'
      }
    },
    loginSignupInput: {
      height: '60px',
      width: '500px',
      marginTop: '20px',
    },
    nameInput: {
      width: '500px',
    },
    link: {
      color: primary,
      fontSize: '1rem',
      marginTop: '30px',
      transition: 'all 0.35s ease-in-out',
      '&:hover':{
        color: lighten(0.2, primary),
        textDecoration: 'none'
      }
    },
    errorMessage: {
      fontSize: '12px',
      letterSpacing: '0.05rem',
      paddingLeft: '0.25rem',
      color: '#B22222',
      marginTop: 1
    },
    continueButton:{
      color: '#fff',
      backgroundColor: lighten(0.2, primary),
      height: '100%',
      width: '100%',
      '&:hover': {
        backgroundColor: primary,
        transition: 'all 150ms ease 150ms'
      }
    },
    linkContinueButton:{
      height: '40px',
      width: '500px',
      marginTop: '50px',
      textDecoration: 'none',
      [theme.breakpoints.down(700)]: {
        width: '80%'
      },
    },
    buttonWithIcon:{
      '& svg':{
        marginRight: '5px'
      }
    },
    backButton:{
      [theme.breakpoints.down(700)]: {
        width: '80% !important'
      },
    },
    userInfo: {
      fontSize: '1.20rem',
      fontWeight: 400
    },
    continueButtonLogin: {
      backgroundColor: primary,
      color: '#fff',
      height: '56px',
      width: '100%',
      marginTop: '20px',
      '&:hover': {
        backgroundColor: lighten(0.2, primary)
      },
    },
    confirmButton: {
      backgroundColor: primary,
      color: '#fff',
      height: '45px',
      width: '500px',
      marginTop: '60px',
      '&:hover': {
        backgroundColor: lighten(0.2, primary)
      },
      [theme.breakpoints.down(700)]: {
        width: '80%',
      },
    }
  })
});
