import styled from 'styled-components';

export const Imagem = styled.div<{ image: string }>`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  margin-top: 12px;
  width: 180px;
  height: 150px;
  border-radius: 0.5rem;

  div{
    width: 180px;
    height: 150px;
    border-radius: 0.5rem;

    background-image: url(${props => props.image}), url(${process.env.REACT_APP_RETAGUARDA_API_FILES}no_image.png);
    background-position: center;
    background-size: cover;

    @media (max-width: 335px){
      width: 100px;
      height: 100px;
    }

    @media (min-width: 336px) and (max-width: 400px){
      width: 120px;
      height: 120px;
    }

    @media (min-width: 401px) and (max-width: 550px){
      width: 150px;
      height: 120px;
    }
  }

  img{
    width: 100%;
    height: auto;
    max-height: 100%;

    border-radius: 0.5rem;
  }

  @media (max-width: 335px){
    width: 100px;
    height: 100px;
  }

  @media (min-width: 336px) and (max-width: 400px){
    width: 120px;
    height: 120px;
  }

  @media (min-width: 401px) and (max-width: 550px){
    width: 150px;
    height: 120px;
  }

  @media(max-width: 960px){
    margin-top: 20px;
  }
`;