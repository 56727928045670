import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: row;

  align-items: center;

  min-height: max-content;

  label{
    margin-right: 10px;
  }

  img{
    width: 50px;
    height: 50px;
  }

  img + p {
    margin-left: 10px;
  }
`;

export const ContainerPix = styled.div`
  padding-left: 15px;
  display: flex;
  gap: 5px;
  input {
    height: 30px;
    width: auto;
    padding: 0px 5px;
    max-width: 300px;
    cursor: default;
  }
  div {
    width: 30px;
    height: 30px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      transform: translate(0px, 10px);
    }
  }
`;
