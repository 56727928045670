import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  groupTitleSmallScreens: {
    width: 'max-content',
    fontFamily: 'Segoe UI, sans-serif',
    whiteSpace: 'nowrap',
    margin: '0px 8px',
    fontSize: '1.3rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // background: '#c6dcfc59',
    color: '#0c65ea',
    paddingInline: '0.313rem',
    borderRadius: '16px',

    [theme.breakpoints.up(769)]: {
      display: 'none'
    },
  },
}));
