import React, { useContext, useState } from "react";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { AuthContext } from '../../hooks/provideAuth';

import { 
  Container, 
  Header, 
  Wrapper, 
  Body,
  Scroll,
  Imagem,
  ImageWrapper
} from './styles';
import stylesMaterial from './stylesMaterial';

interface AmplifiedImageModalProps{
  handleCloseAmplifiedImageModal: () => void;
  openAmplifiedImageModal: boolean;
  image: string;
  name?: string;
}

const AmplifiedImageModal: React.FC<AmplifiedImageModalProps> = ({
                                                                    handleCloseAmplifiedImageModal, 
                                                                    openAmplifiedImageModal,
                                                                    image,
                                                                    name
                                                                  }) => {
  const classes = stylesMaterial();
  const [open, setOpen] = React.useState(openAmplifiedImageModal);
  const [srcImg,setSrcImage] = useState(image);
  const { state, dispatch } = useContext(AuthContext);
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseAmplifiedImageModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton 
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon 
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography 
                variant="h6" 
                className={classes.loginTitle}
              >
                Imagem ampliada
              </Typography>
            </div>
            <IconButton 
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon  
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          <Scroll>
            <Body>
              <TransformWrapper
                initialScale={1}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <ImageWrapper>
                    <IconButton 
                      aria-label="zoom-in" 
                      onClick={() => zoomIn()}
                      className={classes.zoomInButton}
                    >
                      <ZoomInIcon fontSize='large' />
                    </IconButton>
                    <IconButton 
                      aria-label="zoom-out" 
                      onClick={() => zoomOut()}
                      className={classes.zoomOutButton}
                    >
                      <ZoomOutIcon fontSize='large' />
                    </IconButton>
                    <TransformComponent>
                      <Imagem
                        src={srcImg}
                        onError={() => setSrcImage(`${process.env.REACT_APP_RETAGUARDA_API_FILES}`+'no_image.png')} 
                        alt={name ? name : 'Imagem amplificada'}
                        title={name ? name : 'Imagem amplificada'}
                      />
                    </TransformComponent>
                  </ImageWrapper>
                )}
              </TransformWrapper>
            </Body> 
          </Scroll>
             
        </Wrapper>
      </Container>

    </Modal>
  )
}

export default AmplifiedImageModal;