import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  carouselRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '& .carousel.carousel-slider': {
      display: 'flex',
      height: '100%'
    }
  },
  zoomButton: {
    position: 'absolute',
    padding: 8,
    right: 28,
    top: 3,
    opacity: 0.5
  }
}));
