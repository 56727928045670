import { IItemCarrinho } from '../interfaces/itemCarrinho';

export const calcOrderPrice = (item: IItemCarrinho) => {
  let baseProductsPrice = 0,
    sumComponentsPrices = 0,
    total = 0;

  if(item.produtosGrade.length || item.produtoGrade){
    if(item.prodComposto.produto.controla_grade === 2){
      baseProductsPrice = item.produtosGrade
        .map((pizza) => pizza.produto.produto_grades[0].preco.valor*pizza.quantidade)
        .reduce((total, next) => total+next)*item.quantidade
      ;
    } else {
      baseProductsPrice = (item.produtoGrade?.preco.valor || 0) * item.quantidade;
    }
  } else{
    baseProductsPrice =  item.prodComposto.produto.preco.valor * item.quantidade;
  }

  if (item.prodComposto.complementos.length){
    sumComponentsPrices = item
                            .prodComposto
                            .complementos
                            .map((complemento) => complemento.complemento.preco.valor*complemento.quantidade*item.quantidade)
                            .reduce((total, next) => total+next);
  }

  total = baseProductsPrice + sumComponentsPrices;

  if (
    item.prodComposto.produto.fracionado &&
    item.prodComposto.produto.fracao
  ) {
    total =
      total * item.prodComposto.produto.fracao;
  }

  return Number((total).toFixed(2));                                                    
} 