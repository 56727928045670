import { Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { IPedidoVenda } from '../../../interfaces/pedidoVenda';
import { ContainerInfo, ContainerInfoPedido, Esboco } from '../styles';
import { IProduto } from '../../../interfaces/produto';
import OrderDetailsItem from '../orderDetailsItem';

interface InfoPedidoProps {
  order: IPedidoVenda;
  produtos: IProduto[];
}

const BRLMoneyFormat = {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
};

export const InfoPedido: React.FC<InfoPedidoProps> = (props) => {
  const { order, produtos } = props;
  const complements = order.itensPedido.filter(
    (item) =>
      item.itemPedidoPai !== null &&
      (item.produtoGradeId === null || item.produtoGradeId === undefined),
  );

  const produtosGrade = order.itensPedido.filter(
    (item) =>
      item.itemPedidoPai !== null &&
      item.produtoGradeId !== null &&
      item.produtoGradeId !== undefined,
  );

  const orderProducts = order.itensPedido.filter(
    (item) => item.itemPedidoPai === null,
  );

  const [isOpenInfoPedido, setIsOpenInfoPedido] = useState(false);

  return (
    <>
      <ContainerInfoPedido onClick={() => setIsOpenInfoPedido((p) => !p)}>
        <div className="icon">
          <HelpIcon />
        </div>
        <div className="conteudo">Informações sobre o pedido</div>
      </ContainerInfoPedido>

      <Collapse
        in={isOpenInfoPedido}
        collapsedSize={0}
        style={{
          width: '100%',
          maxWidth: '500px',
        }}
      >
        <ContainerInfo>
          <Esboco>
            <h2>
              Pedido <span>#{order.id}</span>
            </h2>

            <div className="divider" />

            {orderProducts.map((item) => {
              if (produtos !== undefined) {
                const produto = produtos.find(
                  (prod) => prod.id === item.produtoId,
                );
                const comp = complements.filter(
                  (complement) => complement.itemPedidoPai === item.id,
                );
                const prodsGrade = produtosGrade.filter(
                  (prodGrade) => prodGrade.itemPedidoPai === item.id,
                );
                if (produto !== undefined) {
                  return (
                    <OrderDetailsItem
                      key={item.id}
                      item={produto}
                      itemPedido={item}
                      complementos={comp}
                      produtosGrade={prodsGrade}
                    />
                  );
                }
              }
              return null;
            })}
          </Esboco>

          <Esboco>
            <h2>Pagamento:</h2>

            <p>
              Subtotal:{' '}
              <span className="price">
                R$ {Number(order.valorTotalItens).toFixed(2).replace('.', ',')}
              </span>
            </p>
            <p>
              {order.logisticaEntregaId ? `Frete` : 'Taxa de entrega'}:{' '}
              <span className="price">
                R$ {Number(order.valorEntrega).toFixed(2).replace('.', ',')}
              </span>
            </p>
            {order.pedido_promocao.length ? (
              <p>
                Desconto na taxa de entrega:{' '}
                <span className="price">
                  {(
                    order.pedido_promocao.reduce(
                      (acc, cur) => acc + +cur.valor,
                      0,
                    ) * -1
                  ).toLocaleString('pt-BR', BRLMoneyFormat)}
                </span>
              </p>
            ) : null}
            {order.cupom_desconto && order.descontoCupom ? (
              <p>
                Cupom de desconto: ({order.cupom_desconto.chave}){' '}
                <span className="price">
                  {(+order.descontoCupom * -1).toLocaleString(
                    'pt-BR',
                    BRLMoneyFormat,
                  )}
                </span>
              </p>
            ) : null}
            {order.descontoOutros && +order.descontoOutros ? (
              <p>
                Outros descontos:{' '}
                <span className="price">
                  {(+order.descontoOutros * -1).toLocaleString(
                    'pt-BR',
                    BRLMoneyFormat,
                  )}
                </span>
              </p>
            ) : null}
            <p>
              Total:{' '}
              <span className="price">
                R${' '}
                {(
                  +order.valorTotalItens +
                  +order.valorEntrega -
                  +(order.totalDesconto || 0)
                )
                  .toFixed(2)
                  .replace('.', ',')}
              </span>
            </p>
          </Esboco>
        </ContainerInfo>
      </Collapse>
    </>
  );
};
