import React from 'react';
import { Link } from 'react-router-dom';
import { IconType } from 'react-icons/lib';
import { Container } from './styles';
import { useTheme } from '../../../hooks/useThemeState';

interface InfoItemProps {
  Icon: IconType;
  text: string;
  link?: string;
  closed?: boolean;
}

export function InfoItem({ Icon, text, link, closed }: InfoItemProps) {
  const { theme } = useTheme();

  return (
    <Container closed={!!closed}>
      <Icon />
      {link ? (
        <Link 
          to={link}
          style={{
            color: theme.primary
          }}
        >
          {text}
        </Link>
      ) : (
        <p>{text}</p>
      )}
    </Container>
  );
}