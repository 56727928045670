import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listItem: {
    paddingRight: '0px',
    paddingLeft: '20px',
    display: '-webkit-flex',
    flexDirection: 'row'
  },
  productTitle: {
    fontSize: '1.05rem'
  },
  priceText: {
    fontSize: '1.05rem',
    color: '#B22222'
  },
  quantityLabel:{
    textAlign: 'center',
    fontSize: '1.4rem',
    width: '40px'
  },
  componentDesc: {
    paddingTop: '5px', 
    paddingBottom: '5px',
    color: '#D3D3D3'
  }
}));