import React, { useEffect, useState } from 'react';

import Modal from '@material-ui/core/Modal';

import stylesMaterial from './stylesMaterial';
import { IProduto } from '../../interfaces/produto';

import { ClotheProduct } from './components/ClotheProduct';
import { SimpleProduct } from './components/SimpleProduct';
import { IEmpresa } from '../../interfaces/empresa';
import { Content, Header } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, IconButton } from '@material-ui/core';
import { PizzaProduct } from './components/PizzaProduct';
import produtosApi from '../../services/produtosApi';
import empresaApi from '../../services/empresaApi';
import { checkStoreOpen } from '../../utils/checkStoreOpen';
import { ISchedule } from '../../interfaces/schedule';

interface ClotheDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: IProduto;
  company: IEmpresa;
  scheduledDays: ISchedule | null;
}

export function ClotheDetailsModal(props: ClotheDetailsModalProps) {
  const {
    isOpen,
    onClose,
    product,
    company,
    scheduledDays,
  } = props;

  const [productWithComplements, setProductWithComplements] = useState<IProduto | null>(null);
  const classes = stylesMaterial();

  const [hasMelhorEnvio, setHasMelhorEnvio] = useState(false);
  const [isStoreOpen, setIsStoreOpen] = useState(false);

  useEffect(() => {
    produtosApi.getProduto(product.id).then((resP) =>{
      setProductWithComplements(resP);

      empresaApi.getParametro(company.id.toString(), 'best_shipping_services').then((res) => {
        const role = JSON.parse(res.role);
        if (role && resP.produto_dimensao && resP.produto_dimensao.altura && resP.produto_dimensao.comprimento && resP.produto_dimensao.largura && resP.produto_dimensao.peso) {
          if (role.active) {
            setHasMelhorEnvio(true);
          }
        }
      }).catch(() => {
        setHasMelhorEnvio(false);
      });
    }).catch((error) => console.log(error));

    empresaApi.getScheduledDelivery(company.id).then((days) => {
      if(days){
        setIsStoreOpen(true);
      } else {
        setIsStoreOpen(checkStoreOpen(company));
      }
    }).catch((error) => {
      setIsStoreOpen(checkStoreOpen(company));
    });
  }, []);

  useEffect(() => {
    if(isOpen) {
      if(scheduledDays){
        setIsStoreOpen(true);
      } else {
        setIsStoreOpen(checkStoreOpen(company));
      }
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.container}
    >
      <Content>
        <Header>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.buttonClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </Header>

        {
          productWithComplements ? (
            product.controla_grade === 0 ? (
              <SimpleProduct
                product={productWithComplements}
                isStoreOpen={isStoreOpen}
                companyId={company.id}
                onClose={onClose}
                hasMelhorEnvio={hasMelhorEnvio}
              />
            ) : (
              product.controla_grade === 1 ? (
                <ClotheProduct
                  product={productWithComplements}
                  isStoreOpen={isStoreOpen}
                  companyId={company.id}
                  onClose={onClose}
                  hasMelhorEnvio={hasMelhorEnvio}
                />
              ) : (
                <PizzaProduct
                  product={productWithComplements}
                  isStoreOpen={isStoreOpen}
                  companyId={company.id}
                  onClose={onClose}
                />
              )
            )
          ) : (
            <CircularProgress />
          )
        }
      </Content>
    </Modal>
  );
}
