import React, { useEffect, useState } from 'react';

import 'moment/locale/pt-br';

import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { IPedidoVenda } from '../../interfaces/pedidoVenda';
import { useTheme } from '../../hooks/useThemeState';

const useStyles = makeStyles((theme) => ({
  timeline: {
    marginTop: '15px'
  },
  paper: {
    padding: '10px',
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
  title:{
    fontSize: '1rem'
  },
  text:{
    fontSize: '0.90rem'
  }
}));

interface OrderTimelineComponentProps{
  order: IPedidoVenda;
}

const OrderTimelineComponent: React.FC<OrderTimelineComponentProps> = ({ order }) => {
  const classes = useStyles();

  const [inicio, setInicio] = useState("");
  const [preparoIniciado, setPreparoIniciado] = useState("");
  const [saiuEntrega, setSaiuEntrega] = useState("");
  const [retiradoEm, setRetiradoEm] = useState("");
  const [entregueEm, setEntregueEm] = useState("");

  const moment = require('moment');

  const { theme } = useTheme()

  useEffect(() => {
    moment.locale();

    if((order.dataAbertura !== undefined) && (order.dataAbertura !== null)){
      setInicio(moment(new Date(order.dataAbertura)).format('LT L'));
      if((order.preparoIniciadoEm !== undefined) && (order.preparoIniciadoEm !== null)){
        setPreparoIniciado(moment(new Date(order.preparoIniciadoEm)).format('LT L'));
        if((order.saiuEntregaEm !== undefined) && (order.saiuEntregaEm !== null)){
          setSaiuEntrega(moment(new Date(order.saiuEntregaEm)).format('LT L'));
          if((order.entregueEm !== undefined) && (order.entregueEm !== null)){
            setEntregueEm(moment(new Date(order.entregueEm)).format('LT L'));
          }
        }
        if((order.retiradoEm !== undefined) && (order.retiradoEm !== null)){
          setRetiradoEm(moment(new Date(order.retiradoEm)).format('LT L'));
        }

      }
    }
  }, []);

  return (
    <Timeline align="alternate" className={classes.timeline} >
      {
        inicio !== ''
        ?
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {inicio}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" style={{ backgroundColor: theme.primary }} />
            <TimelineConnector className={classes.primaryColor} style={{ backgroundColor: theme.primary }} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1" className={classes.title} >
                Pedido feito
              </Typography>
              <Typography className={classes.text} >O pedido foi registrado</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        :
        <div />
      }

      {
        preparoIniciado !== ''
        ?
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {preparoIniciado}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector className={classes.primaryColor} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1" className={classes.title} >
                Preparo iniciado
              </Typography>
              <Typography className={classes.text} >O preparo do pedido foi inciado</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        :
        <div />
      }

      {
        saiuEntrega !== ''
        ?
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {saiuEntrega}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector className={classes.primaryColor} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1" className={classes.title} >
                Saiu para entrega
              </Typography>
              <Typography className={classes.text} >O entregador está com o seu pedido</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        :
        (
          retiradoEm !== ''
          ?
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {retiradoEm}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector className={classes.primaryColor} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" className={classes.title} >
                  Retirado
                </Typography>
                <Typography className={classes.text} >O pedido foi recebido na loja pelo cliente</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          :
          <div />
        )
      }

      {
        entregueEm !== ''
        ?
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {entregueEm}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1" className={classes.title} >
                Entregue
              </Typography>
              <Typography className={classes.text} >O pedido foi recebido pelo cliente</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        :
        <div />
      }

    </Timeline>
  );
}

export default OrderTimelineComponent;
