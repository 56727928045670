import { ICarrinhoEmpresa } from '../interfaces/carrinhoEmpresa';
import { IItemCarrinho } from '../interfaces/itemCarrinho';

export type State = {
  carts: ICarrinhoEmpresa[];
  error?: string;
}

type Action =
 | { type: 'SET_CART', empresaId: number, payload: IItemCarrinho[] }
 | { type: 'ADD_CART', empresaId: number, payload: IItemCarrinho[] }
 | { type: 'SET_OBSERVACAO', empresaId: number, payload: string }
 | { type: 'ADD_ITEM', empresaId: number, payload: IItemCarrinho }
 | { type: 'REMOVE_ITEM', empresaId: number, id: number }
 | { type: 'EDIT_ITEM', empresaId: number, id: number, obs: string, qtd: number }
 | { type: 'ERRO', error: string };

const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_CART':
      const existingCart = state.carts.find((cart) => cart.id === action.empresaId);

      if (existingCart) {
        return {
          ...state,
          carts: state.carts.map((cart) => {
            if (cart.id === action.empresaId){
              cart.cart = action.payload
              return cart
            } else{
              return cart
            }
          }), 
        };
      } else {
        return {
          ...state,
          carts: state.carts!.concat({id: action.empresaId, cart: action.payload, observacao: ""}),
        };
      }
      
    case 'ADD_CART':
      return {
          ...state,
          carts: state.carts!.concat({id: action.empresaId, cart: action.payload, observacao: ""})
      };
    case 'SET_OBSERVACAO':
      return {
          ...state,
          carts: state.carts.map((cart) => {
            if (cart.id === action.empresaId){
              cart.observacao = action.payload
              return cart
            } else{
              return cart
            }
          }) 
      };
    case 'ADD_ITEM':
        return {
            ...state,
            carts: state.carts.map((cart) => {
              if (cart.id === action.empresaId){
                cart.cart = cart.cart!.concat(action.payload)
                return cart
              } else{
                return cart
              }
            }) 
        };
    case 'REMOVE_ITEM':
        return {
            ...state,
            carts: state.carts.map((cart) => {
              if (cart.id === action.empresaId){
                cart.cart = cart.cart!.filter(item => item.id !== action.id)
                return cart
              } else{
                return cart
              }
            })
        };
    case 'EDIT_ITEM':
        return {
            ...state,
            carts: state.carts.map((cart) => {
              if (cart.id === action.empresaId){
                cart.cart = cart.cart!.map(item => {
                  if (item.id === action.id){
                      return {...item,
                              quantidade: action.qtd,
                              observacao: action.obs};
                  
                  } else{
                      return item;
                  }
                })
                return cart
              } else{
                return cart
              }
            })
        };
    case 'ERRO':
        return {
            ...state,
            error: action.error
        };
    default:
        return state;
  }
};

export default Reducer;