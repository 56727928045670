import React, { useEffect, useState } from "react";

import Typography  from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DeliveryAddressModal from '../delivery-address-modal';
import AdressCard from "../adress-card";

import { IEnderecoEntrega } from "../../interfaces/enderecoEntrega";

import userApi from "../../services/userApi";

import { Container, GridContainer, AddAddressButton } from './styles';
import stylesMaterial from './stylesMaterial';

interface AdressGroupProps{
  token: string;
}

const SaleAddressGroup: React.FC<AdressGroupProps> = ({ token }) => {
  const classes = stylesMaterial();

  const [enderecos, setEnderecos] = useState(new Array<IEnderecoEntrega>());
  const [isEmpty, setIsEmpty] = useState(true);

  const [openDeliveryAddressModal, setOpenDeliveryAddressModal] = useState(false);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    getAddresses();
  }, []);

  const getAddresses = () => {
    userApi.getEnderecos(token).then((res) => {
      setEnderecos(res.data);
      if(res.data.length === 0){
        setIsEmpty(true);
      } else{
        setIsEmpty(false);
      }
    }).catch((error) => {
    });
  }

  const handleOpenDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(true);
  }

  const handleCloseDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(false);
    getAddresses();
  }

  const handleRegisterSucess = () => {
    toast.success('Endereço registrado com sucesso!', toast_config);
  }

  return (
    <Container>
      <Typography className={classes.listTitle}>
        Endereços de entrega
      </Typography>

      <Divider />
      
      <GridContainer>
        {
          enderecos.map((endereco: IEnderecoEntrega) => {
              return (
                <AdressCard 
                  key={endereco.id}
                  endereco={endereco}
                  getAdresses={getAddresses}
                />
              )
          })
        }
        <AddAddressButton>
          <IconButton  
            aria-label="add_address" 
            onClick={handleOpenDeliveryAddressModal}
          >
            <AddCircleOutlineIcon className={classes.addAddressIcon} />
          </IconButton>
        </AddAddressButton>

      </GridContainer>

      {
        openDeliveryAddressModal
        ? <DeliveryAddressModal
            handleCloseDeliveryAddressModal={handleCloseDeliveryAddressModal} 
            openDeliveryAddressModal={openDeliveryAddressModal} 
            registerSucess={handleRegisterSucess}
            isEmpty={isEmpty}
          /> 
        : <div />
      }
      
       
    </Container>
  )
}

export default SaleAddressGroup;