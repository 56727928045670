import styled from 'styled-components';

interface SizeProps {
  selected: boolean;
}


export const Size = styled.div<SizeProps>`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  border-radius: 10px;
  border 2px solid transparent;
  border-color: ${props => props.selected ? 'black' : 'transparente'};
  background-color: #ddd;
  background-clip: content-box;
  cursor: pointer;
`;

export const SizeText = styled.p<SizeProps>`
  font-size: 16px;
  color: #000;
  padding: 0px 8px;
`;
