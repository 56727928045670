import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    top: 0,
    maxHeight: 104,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fefefe'
  },
  offset: theme.mixins.toolbar,
  offset1: {
    minHeight: 84
  },
  divider: {
    width: '100%'
  },
  paper: {
    padding: '2px 4px',
    display: '-webkit-flex',
    alignItems: 'center',
    maxWidth: 300,
    border: '1px solid #d4d4d4',
    [theme.breakpoints.down(640)]: {
      width: 200
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  segmentName:{
    fontWeight: 500,
    fontSize: '1.6rem'
  }
}));
