import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  position: relative;

  img {

  }
`;