import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  welcomeText: {
    fontSize: '1.6rem',
    color: '#269660',
    fontWeight: 400
  },
  infoText: {
    fontSize: '1.1rem',
    maxWidth: '320px',
    textAlign: 'center',
  },
  currentLocation: {
    background: '#269660',
    color: '#fff',
    marginTop: '2.5rem',
    '&:hover': {
      background: '#269660',
      opacity: 0.8
    }
  },
  selectState: {
    border: '1px solid #269660',
    color: '#269660',
    marginTop: '1rem'
  },
  buttonWithIcon:{
    borderRadius: '10px',
    maxWidth: '270px',
    width: '80%',
    '& svg':{
      marginRight: '5px'
    }
  },
  comboUf: {
    marginTop: '1rem',
    width: '40%',
    minWidth: '200px'
  },
  confirm: {
    marginTop: '1.5rem',
    background: '#269660',
    color: '#fff',
    width: '7.5rem',
    '&:hover': {
      background: '#269660',
      opacity: 0.8
    }
  },
  back: {
    border: '1px solid #269660',
    color: '#269660',
    marginTop: '1rem',
    width: '7.5rem',
  },
}));
