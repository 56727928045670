import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Informations = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  section {
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
  }
`;
