import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  span:{
    width: 40,
    height: 0
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
  },
  detailTitle: {
    fontSize: '1.2rem'
  },
  titles:{
    fontSize: '1.1rem'
  },
  textArea:{
    width: '100%',
    marginTop: '5px',
    marginBottom: '5px',
    fontSize: '1.1rem',
    padding: '5px',
    borderRadius: '4px'
  },
  quantityLabel:{
    display: '-webkit-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
    width: '40px'
  },
}));
