import styled from 'styled-components';

export const Container = styled.div`
  min-height: max-content;

  @media(max-width: 960px){
    width: 90vw;
  }
`;

export const Informations = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  flex: 1;

  @media(max-width: 960px){
    flex-direction: row;
  }
`;

export const TopInfos = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  min-height: max-content;

  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;

  text-align: left;
`;

export const ItensAndTotal = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  min-height: max-content;
  text-align: center;
  flex: 1;

  margin: 10px 0px 15px 0px;
`;

export const Itens = styled.div`
  min-height: max-content;
  color: #757575;
  flex: 1;
`;


export const Buttons = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  min-height: max-content;

  @media(max-width: 960px){
    display: none;
  }
`;

export const BuyAgainAndDetails = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  min-height: max-content;
  align-items: center;
`;

export const TopInfosMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  min-height: max-content;

  margin-top: 12px;

  width: 100%;
`;

export const IconAndTotalMobile = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  min-height: max-content;
  width: 100%;
`;
