import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  continueButton:{
    backgroundColor: '#ef9a9a ',
    height: '100%',
    width: '100%',
    color: '#fefefe',
    '&:hover': {
      backgroundColor: '#B22222',
      transition: 'all 150ms ease 150ms'
    }
  },
  linkContinueButton:{
    height: '40px',
    flex: 1,
    width: '100%',
    maxWidth: '300px',
    textDecoration: 'none',
    [theme.breakpoints.down(640)]: {
      width: '100%'
    },
  },
  thanksForPurchase:{
    marginTop: "10px",
    textAlign: 'center',
    [theme.breakpoints.down(960)]: {
      fontSize: "1.2rem"
    },
  },
  orderNum:{
    marginTop: "20px",
    fontSize: "1.4rem",
    textAlign: 'center',
    [theme.breakpoints.down(960)]: {
      fontSize: "1.2rem"
    },
  },
  additionalInfo:{
    marginTop: "20px",
    fontSize: "1.3rem",
    textAlign: 'center',
    [theme.breakpoints.down(960)]: {
      fontSize: "1.1rem"
    },
  },
  acompanhe:{
    marginTop: "20px",
    fontSize: "2rem",
    fontWeight: 500,
    [theme.breakpoints.down(960)]: {
      fontSize: "1.8rem"
    },
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}));
