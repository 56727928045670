import React, { useEffect } from 'react';

import _ from 'lodash';
import clsx from 'clsx';
import { ToastContainer, toast } from 'react-toastify';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import CopyToClipboard from 'react-copy-to-clipboard';
import { TextField } from '@material-ui/core';
import { IFormaPagamentoEmpresa } from '../../../../interfaces/formaPagamentoEmpresa';

import stylesMaterial from './stylesMaterial';
import { useTheme } from '../../../../hooks/useThemeState';

import { Container, ContainerPix } from './styles';
import CopyIcon from '../../../../assets/copia-cola.png';

interface ISalePaymentMethodProps {
  pm: IFormaPagamentoEmpresa;
  selectedPM: string;
  changeMoney: string;
  handleChangeMoney: (value: string) => void;
}

const SalePaymentMethod: React.FC<ISalePaymentMethodProps> = ({
  pm,
  selectedPM,
  changeMoney,
  handleChangeMoney,
}) => {
  const classes = stylesMaterial();
  const { theme } = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeMoney(event.target.value);
  };

  return (
    <>
      <FormControlLabel
        value={pm.id}
        control={<Radio color="primary" style={{ color: theme.primary }} />}
        label={
          <Container>
            <img
              src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${pm.formaPagamento.imagem}`}
              alt={pm.formaPagamento.descricao}
              title={pm.formaPagamento.descricao}
            />
            <Typography variant="body1">
              {pm.formaPagamento.descricao}
            </Typography>
          </Container>
        }
      />

      <FormControl
        fullWidth
        variant="outlined"
        className={clsx(classes.troco, {
          [classes.hide]:
            pm.formaPagamento.sigla !== 'dinheiro' || selectedPM !== 'Dinheiro',
        })}
      >
        <InputLabel htmlFor="changeMoney">
          Valor - Troco para quanto?
        </InputLabel>
        <OutlinedInput
          id="changeMoney"
          name="changeMoney"
          type="number"
          value={changeMoney}
          onChange={handleChange}
          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
          labelWidth={190}
        />
      </FormControl>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        {((pm.formaPagamento.descricao === 'Pix' &&
          selectedPM === 'Pix' &&
          pm.descricao) ||
          (pm.formaPagamento.descricao === 'BDM Digital' &&
            selectedPM === 'BDM Digital' &&
            pm.descricao)) &&
          Object.entries(JSON.parse(pm.descricao!) as object).map(
            ([key, value]: any) => {
              return (
                <ContainerPix>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <InputLabel
                      htmlFor={`chave-${pm.formaPagamento.descricao}`}
                    >
                      Chave Copia e Cola
                    </InputLabel>
                    <OutlinedInput
                      id={`chave-${pm.formaPagamento.descricao}`}
                      name={`chave-${pm.formaPagamento.descricao}`}
                      type="text"
                      value={`${value}`}
                      labelWidth={190}
                      style={{
                        width: 'calc(100% + 30px)',
                      }}
                    />
                  </FormControl>
                  <CopyToClipboard text={`${value}`}>
                    <div
                      onClick={() => {
                        toast.success('Chave copiada!');
                      }}
                    >
                      <img src={CopyIcon} alt="copiar chave" />
                    </div>
                  </CopyToClipboard>
                </ContainerPix>
              );
            },
          )}
      </div>
    </>
  );
};

export default SalePaymentMethod;
