import styled from 'styled-components';


export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 550px) / 2);
  width: 550px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: 100vh;
  }
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 90px;
  min-height: max-content;
`;

export const Wrapper = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;

  padding-right: 15px;
  padding-left: 15px;
`;

export const Address = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;

  width: 300px;

  margin-top: 25px;

  padding: 10px;

  boxShadow: 0px 1px 3px rgba(71,67,71,0.1);
  border: 1px solid #f5f5f5;
`;

export const AddressInfos = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-left: 10px;

  flex: 1;
`;