import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menuButton: {
    height: 64,
    width: 64
  },
  appBar: {
    top: 0,
    maxHeight: 107,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fefefe'
  },
  offset: theme.mixins.toolbar,
  offset1: {
    minHeight: 64
  },
  divider: {
    width: '100%'
  },
  dividerPrice: {
    height: 50
  },
  dividerStep: {
    width: '100%',
    marginTop: 20,
    backgroundColor: '#f5f5f5',
    height: '2px',
  },
  loginWarn: {
    fontSize: '1.3rem',
  },
  total:{
    fontSize: '1.4rem',
    marginRight: 10,
    [theme.breakpoints.down(300)]: {
      fontSize: '1.3rem'
    },
  },
  delTax:{
    fontSize: '1.2rem',
    marginRight: 10,
    [theme.breakpoints.down(300)]: {
      fontSize: '1.1rem'
    },
  },
  cartButton: {
    height: 64,
    width: 64
  },
  hide: {
    display: "none !important"
  },
  continueButton:{
    backgroundColor: "#B22222",
    height: '100%',
    width: '100%',
    color: '#fefefe',
    '&:hover': {
      backgroundColor: '#ef9a9a',
      transition: 'all 150ms ease 150ms'
    }
  },
}));
