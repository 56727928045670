import styled from 'styled-components';

export const ItemDiscount = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  padding: 5px 0px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;

  @media (max-width: 680px) {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const DiscountGroup = styled.div<{ iconcolor: string }>`
  position: absolute;
  margin-left: -80px;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.iconcolor} !important;

  ${ItemDiscount} {
    background-color: ${(props) => props.iconcolor} !important;

    ${(props) => {
      if (props.iconcolor === 'yellow') {
        return `color: black !important; `;
      }
      return '';
    }}
  }
`;

export const FigureImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  object-fit: cover;
  align-self: center;

  --debug: calc(calc(calc(100vw - 360px) / 4) + 90px);
  width: var(--debug);
  height: var(--debug);

  @media (min-width: 680px) {
    width: 100%;
    height: -webkit-fill-available;
    max-height: 200px;
    box-shadow: none;
    border-radius: 10px 10px 0px 0px;
  }
`;

export const ItemImage = styled.div<{ urlImage?: string, defaultImg: string }>`
  width: inherit;
  height: inherit;
  background-image: url(${(props) => props.urlImage}), url(${(props) => props.defaultImg});
  background-size: cover;
  border-radius: 10px;

  @media (min-width: 680px) {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0px 0px;
  }
`;

export const ImageWrapper = styled.div`
  @media (max-width: 680px) {
    max-width: 100px;
  }

  @media (min-width: 680px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ItemName = styled.h4`
  font-size: 16px;
`;

export const ItemDescription = styled.div`
  display: box;
  font-size: 13px;
  font-weight: 300;
  color: #000000;
  text-align: start;
  flex-grow: 1;
  max-height: 50px;
  width: 100%;
  line-height: 1.2rem;
`;

export const ItemOrigPrice = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  text-align: center;
  text-decoration: line-through;
`;

export const ItemPrice = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.primary};
  text-align: center;

  white-space: nowrap;
`;

export const Price = styled.div<{ simpleType?: boolean }>`
  display: flex;
  align-items: ${props => props.simpleType ? 'flex-end' : 'flex-start'};
  gap: ${props => props.simpleType ? '5px' : 'initial'};
  justify-content: space-around;
  margin-top: 7.5px;
  margin-bottom: 10px;
  flex-direction: ${props => props.simpleType ? 'row-reverse' : 'column'};

  flex-direction: column;
  align-items: flex-start;

`;

export const ItemStatus = styled.div`
  color: red;
  font-size: 16px;
  font-weight: 600;
`;

export const ItemDetail = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  padding-left: 25px;
  width: 100%;

  @media (min-width: 680px) {
    padding: 0px 10px;
  }
`;

export const DetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 5px;
  width: 100%;
  height: 100%;
  text-align: left;
  padding-top: 10px;
`;



export const ItemContainer = styled.div`
  align-self: center;
  cursor: pointer;
  background: transparent;
  border-width: thin;
  min-width: 80px;
  margin: 10px 0px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
  transition: 0.1s;

  @media (min-width: 680px) {
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    width: 100%;
    height: 350px;
    max-height: 300px;
    max-width: 300px;
    justify-content: start;
    border-radius: 10px;
    border-style: solid;
    border-color: #c4c4c4;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);

      ${ItemName} {
        font-weight: bold;
      }
    }

    &:focus {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      ${ItemName} {
        font-weight: bold;
      }
    }
  }
`;

export const ItemWrapper = styled.div<{ isAvaiable: boolean }>`
  padding: 5px;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ItemStatus} {
    ${(props) => (props.isAvaiable ? 'display:none;' : 'color:red;')}
  }

  &:hover {
    -webkit-animation: swing 1s ease;
    animation: swing 1s ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
`;

export const Toggle = styled.a`
  color: ${(props) => props.theme.primary};
`;

export const LoadingDiv = styled.div`
  background-color: gray;
  width: 100%;
  height: 100%;
`;

export const DiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 42px;
  background: #f4546a;
  height: 28px;
  border-radius: 3px;
  top: 5px;
  right: 5px;
`;

export const PromotionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 28px;
  width: 42px;
  top: 5px;
  left: 5px;
`;

export const ConteudoEsgotado = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f4546a;
  color: #fff;

  p {
    margin: 3px;
  }
`
