import React, { useState, useEffect, useContext, Dispatch } from 'react';

import _ from 'lodash';
import 'moment/locale/pt-br';
import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import { getEstagio } from '../estagio';

import { Context } from '../../hooks/cartStore';

import { IPedidoVenda } from '../../interfaces/pedidoVenda';

import {
  Container,
  Informations,
  TopInfos,
  ItensAndTotal,
  Itens,
  Buttons,
  BuyAgainAndDetails,
  TopInfosMobile,
  IconAndTotalMobile,
} from './styles';
import stylesMaterial from './stylesMaterial';

import OrderDetails from '../order-details';
import { IEmpresa } from '../../interfaces/empresa';
import { useTheme } from '../../hooks/useThemeState';
import { RedoCart } from '../../utils/redoCartHistory';
import { IRedoCartProduct } from '../../interfaces/redoCartProduct';

interface OrderProps {
  pedido: IPedidoVenda;
  empresaId: string | undefined;
  setCompanie: Dispatch<React.SetStateAction<IEmpresa | undefined>>;
}

const Order: React.FC<OrderProps> = ({ pedido, empresaId, setCompanie }) => {
  const classes = stylesMaterial();

  const { companieId } = useParams<{ companieId: string }>();
  const { state, dispatch } = useContext(Context);
  const { theme, setTheme } = useTheme();

  const [estagio, setEstagio] = useState('Aguardo');
  const [openDetails, setOpenDetails] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [elapsedDeliveryTime, setElapsedDeliveryTime] = useState<any>();
  const [products, setProducts] = useState(
    pedido.itensPedido.filter((item) => item.itemPedidoPai === null),
  );

  const history = useHistory();
  const moment = require('moment');

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  useEffect(() => {
    setEstagio(getEstagio(pedido.estagio));

    let tempDateTime =
      pedido.estagio !== 2 &&
      pedido.estagio !== 6 &&
      pedido.estagio !== 9 &&
      pedido.estagio !== 10
        ? pedido.entregaprogramadaem !== undefined &&
          pedido.entregaprogramadaem !== null
          ? new Date(pedido.entregaprogramadaem)
          : pedido.entregaPrevistaEm !== undefined &&
            pedido.entregaPrevistaEm !== null
          ? new Date(pedido.entregaPrevistaEm)
          : new Date(2020, 1)
        : pedido.estagio === 6
        ? pedido.dataFechamento
          ? new Date(pedido.dataFechamento)
          : new Date(2020, 1)
        : pedido.entregueEm !== undefined && pedido.entregueEm !== null
        ? new Date(pedido.entregueEm)
        : new Date(2020, 1);
    if (pedido.estagio === 6) {
      const diff = moment(
        pedido.entregueEm ? pedido.entregueEm : pedido.dataFechamento,
      ).diff(moment(pedido.dataAbertura));
      const duration = moment.duration(diff);

      setElapsedDeliveryTime(duration);
    }

    moment.locale();

    setDeliveryDate(moment(tempDateTime).format('L'));
    setDeliveryTime(moment(tempDateTime).format('LT'));
  }, [pedido]);

  const handleBuyAgain = () => {
    const products = new Array<{
      product: number;
      quantidade: number;
      item: number;
      tamanho: string;
      produtoGrade: number | undefined,
      observacao: string | undefined;
    }>();
    
    pedido.itensPedido.map((item) => {
      if (!item.itemPedidoPai){
        products.push({
          product: item.produtoId,
          quantidade: item.quantidade,
          item: item.id,
          tamanho: item.nomeGradeTamanho,
          produtoGrade: item.produtoGradeId,
          observacao: item.observacao,
        });
      }
    });

    const productsRedoCart = products.map((product) => {
      const complements = new Array<{complement: number, quantidade: number}>();
      const produtosGrade = new Array<{produtoGrade: number, quantidade: number}>();

      pedido.itensPedido.map((item) => {
        if(item.itemPedidoPai === product.item){
          if (!!item.produtoGradeId) {
            produtosGrade.push({
              produtoGrade: item.produtoGradeId, 
              quantidade: item.quantidade
            });
          } else {
            complements.push({
              complement: item.itemComplementoId, 
              quantidade: item.quantidade
            });
          }
        }
      });

      const redoCartItem: IRedoCartProduct = {
        product: product.product,
        complements: complements,
        produtosGrade: produtosGrade,
        tamanho: product.tamanho,
        quantidade: product.quantidade,
        produtoGrade: product.produtoGrade,
        observacao: product.observacao,
      };
  
      return redoCartItem;
    });

    if (empresaId) {
      RedoCart(productsRedoCart).then((cart) => {
        dispatch({
          type: 'SET_CART',
          empresaId: _.toNumber(empresaId),
          payload: cart,
        });

        dispatch({
          type: 'SET_OBSERVACAO',
          empresaId: _.toNumber(empresaId),
          payload: !!pedido.observacao ? pedido.observacao : '',
        });
        history.push(`/${companieId}`);
      });
    }
  };

  return (
    <Container>
      <Paper className={classes.paper}>
        <Informations>
          <TopInfos>
            <Typography className={classes.orderNumber}>
              <span>Pedido</span> #{pedido.id}
            </Typography>

            <Typography className={classes.status}>
              {pedido.estagio !== 6
                ? estagio
                : elapsedDeliveryTime !== undefined
                ? elapsedDeliveryTime.days() !== 0
                  ? `Entregue em ${elapsedDeliveryTime.days()} dia(s) e ${elapsedDeliveryTime.hours()} hora(s)`
                  : elapsedDeliveryTime.hours() !== 0
                  ? `Entregue em ${elapsedDeliveryTime.hours()} hora(s) e ${elapsedDeliveryTime.minutes()} minuto(s)`
                  : `Entregue em ${elapsedDeliveryTime.minutes()} minuto(s)`
                : ''}
            </Typography>

            <Typography className={classes.deliveryTime}>
              {pedido.estagio !== 2 &&
              pedido.estagio !== 6 &&
              pedido.estagio !== 9 &&
              pedido.estagio !== 10
                ? pedido.estagio !== 11
                  ? `Chegará às ${deliveryTime} do dia ${deliveryDate}`
                  : `Estará pronto para retirada às ${deliveryTime} do dia ${deliveryDate}`
                : pedido.estagio === 6
                ? `Entregue às ${deliveryTime} do dia ${deliveryDate}`
                : ''}
            </Typography>
          </TopInfos>

          <ItensAndTotal>
            <Itens>
              {products.map((item, index) => {
                if (index < 3) {
                  return (
                    <Typography
                      variant="body1"
                      key={item.id}
                      className={classes.item}
                    >
                      {(item.fracionado
                        ? `${
                            item.quantidade >= 1
                              ? Number(item.quantidade).toFixed(3)
                              : item.quantidade *
                                item.unidadeMedida[0].quantidadePorcao
                          }${
                            item.quantidade >= 1
                              ? item.unidadeMedida[0].sigla
                              : item.unidadeMedida[0].siglaPorcao
                          } - `
                        : Number(item.quantidade) + 'x ') + item.nomeProduto}
                    </Typography>
                  );
                } else if (index === 3) {
                  return (
                    <Typography
                      variant="body1"
                      key={item.id}
                      style={{
                        textAlign: 'center',
                        lineHeight: '0.5',
                        fontWeight: 'bold',
                      }}
                    >
                      ...
                    </Typography>
                  );
                }
              })}
            </Itens>

            <Typography
              className={classes.total}
              style={{ color: theme.primary }}
            >
              Total R${' '}
              {(
                _.toNumber(pedido.valorEntrega) +
                _.toNumber(pedido.valorTotalItens) -
                _.toNumber(pedido.totalDesconto ? pedido.totalDesconto : 0)
              )
                .toFixed(2)
                .replace('.', ',')}
            </Typography>
          </ItensAndTotal>
        </Informations>

        <Buttons>
          <BuyAgainAndDetails>
            <Button
              variant="contained"
              fullWidth
              onClick={handleBuyAgain}
              style={{
                backgroundColor: theme.primary,
                color: '#fefefe',
                height: '30px',
                width: '200px',
              }}
            >
              Comprar novamente
            </Button>

            <Link
              component="button"
              onClick={handleOpenDetails}
              className={`${classes.link} ${classes.linkTransition}`}
              style={{ color: theme.primary }}
            >
              Ver detalhes
            </Link>
          </BuyAgainAndDetails>
        </Buttons>
      </Paper>

      <ButtonBase onClick={handleOpenDetails} className={classes.buttonBase}>
        <TopInfosMobile>
          <Typography className={classes.orderNumber}>
            <span>Pedido</span> #{pedido.id}
          </Typography>

          <Typography className={classes.status}>
            {pedido.estagio !== 6
              ? estagio
              : elapsedDeliveryTime !== undefined
              ? elapsedDeliveryTime.days() !== 0
                ? `Entregue em ${elapsedDeliveryTime.days()} dia(s) e ${elapsedDeliveryTime.hours()} hora(s)`
                : elapsedDeliveryTime.hours() !== 0
                ? `Entregue em ${elapsedDeliveryTime.hours()} hora(s) e ${elapsedDeliveryTime.minutes()} minuto(s)`
                : `Entregue em ${elapsedDeliveryTime.minutes()} minuto(s)`
              : ''}
          </Typography>

          <Typography className={classes.deliveryTime}>
            {pedido.estagio !== 2 &&
            pedido.estagio !== 6 &&
            pedido.estagio !== 9 &&
            pedido.estagio !== 10
              ? pedido.estagio !== 11
                ? `Chegará às ${deliveryTime} do dia ${deliveryDate}`
                : `Estará pronto para retirada às ${deliveryTime} do dia ${deliveryDate}`
              : pedido.estagio === 6
              ? `Entregue às ${deliveryTime} do dia ${deliveryDate}`
              : ''}
          </Typography>
        </TopInfosMobile>

        <IconAndTotalMobile>
          <ZoomInIcon
            className={classes.zoomIcon}
            style={{ color: theme.primary }}
          />

          <Typography
            className={classes.total}
            style={{
              color: theme.primary,
            }}
          >
            Total R${' '}
            {(
              _.toNumber(pedido.valorEntrega) +
              _.toNumber(pedido.valorTotalItens) -
              _.toNumber(pedido.totalDesconto ? pedido.totalDesconto : 0)
            )
              .toFixed(2)
              .replace('.', ',')}
          </Typography>
        </IconAndTotalMobile>

        <Divider className={classes.divisor} />
      </ButtonBase>

      {openDetails ? (
        <OrderDetails
          setCompanie={setCompanie}
          handleCloseDetails={handleCloseDetails}
          openDetails={openDetails}
          orderId={pedido.id}
          empresaId={empresaId}
        />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default Order;
