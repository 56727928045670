import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  gridItem: {
    display: '-webkit-flex',
    flexDirection: 'row',
    textAlign: 'start',
    margin: 'auto',
    borderRadius: '0.3rem',
  },
  iconUnchecked:{
    color: 'rgba(0, 0, 0, 0.12)',
    width: '26px',
    height: '26px'
  },
  iconChecked:{
    color: '#f57c00',
    width: '26px',
    height: '26px'
  },
  card:{
    display: '-webkit-flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

    width: '350px',
    height: '150px',

    padding: '10px',

    [theme.breakpoints.between(340,400)]: {
      width: '300px'
    },
    [theme.breakpoints.down(341)]: {
      width: '250px',
      height: '170px',
      padding: '2px',
    }
  },
  infos: {
    lineHeight: '1.2rem'
  },
  description: {
    lineHeight: '1.2rem',
    fontWeight: 500
  },
  editButton: {
    color: '#0c65ea'
  },
  removeButton: {
    color: '#b22222'
  }

}));
