import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #fefefe;
    color: #1a1a1a;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea {
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  .external-buttons {
    overflow: visible;

    .swiper-button-prev, &.swiper-container-rtl .swiper-button-next {
        left: -16px;
        right: auto;

    }

    .swiper-button-next, &.swiper-container-rtl .swiper-button-prev {
        right: -16px;
        left: auto;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    top: 45%;
    width: 40px;
    height: 40px;
    background: #fff;
    border: 1px solid gray;
    border-radius: 50%;
    color: #1a1a1a;
    font-weight: 700;
    outline: 0;
    transition: background-color .2s ease, color .2s ease;

    &::after {
        font-size: 16px;
    }
  }

  .swiper-button-prev {
    &:after {
        position: relative;
        left: -1px;
    }
  }

  .swiper-button-next {
    &:after {
        position: relative;
        left: 1px;
    }
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
`;
