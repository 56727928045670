import React, { useState } from 'react';
import { ICor } from '../../../../interfaces/cor';

import { SizeItem } from './SizeItem';

import {
  Container
} from './styles';

interface SizeGroupProps {
  sizes: string[];
  selectedSize: string;
  setSelectedSize: (size: string) => void;
}

export const SizeGroup: React.FC<SizeGroupProps> = (props) => {
  const { sizes, selectedSize, setSelectedSize } = props;

  return (
    <Container>
      {
        sizes.map((size) => {
          return (
            <SizeItem
              key={size}
              size={size}
              selected={selectedSize ? selectedSize === size : false}
              setSelectedSize={setSelectedSize}
            />
          );
        })
      }
    </Container>
  );
}
