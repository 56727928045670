import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import { Carousel } from 'react-responsive-carousel';

import { ImageContainer } from './styles';
import stylesMaterial from './stylesMaterial';
import AmplifiedImageModal from '../../components/amplified-image-modal';
import { useTheme } from '../../hooks/useThemeState';

interface ProductCarouselProps {
  images: string[];
}

export const ProductCarousel: React.FC<ProductCarouselProps> = (props) => {
  const { images } = props;
  const { theme } = useTheme()
  const classes = stylesMaterial();

  const [openAmplifiedImageModal, setOpenAmplifiedImageModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageToAmplify, setImageToAmplify] = useState('');

  const handleCloseAmplifiedImageModal = () => {
    setOpenAmplifiedImageModal(false);
  };

  const handleOpenAmplifiedImageModal = (image: string) => {
    setImageToAmplify(image);
    setOpenAmplifiedImageModal(true);
  };

  useEffect(() => {
    setImageIndex(0);
  }, [images]);

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      preventMovementUntilSwipeScrollTolerance
      swipeable
      interval={5000}
      onChange={(event) => setImageIndex(event)}
      selectedItem={imageIndex}
      className={classes.carouselRoot}
      dynamicHeight={true}
    >
      {images.map((image) => {
        return (
          <ImageContainer
            key={image}
            style={{ borderColor: theme.primary }}
          >
            <IconButton
              aria-label="zoom"
              onClick={() => handleOpenAmplifiedImageModal(image)}
              className={classes.zoomButton}
            >
              <ZoomInIcon fontSize='large' />
            </IconButton>
            <img src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${image}`} alt="" />
            {openAmplifiedImageModal && (
              <AmplifiedImageModal
                handleCloseAmplifiedImageModal={handleCloseAmplifiedImageModal}
                openAmplifiedImageModal={openAmplifiedImageModal}
                image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}`+ imageToAmplify}
              />
            )}
          </ImageContainer>
        );
      })}
    </Carousel>
  );
};
