import styled from 'styled-components';
import { lighten } from 'polished'

export const OptionAnchor = styled.a`
    text-decoration: none;
    display: block;
`;

export const OptionLabel = styled.div`
    display: inline-box;
    text-transform: capitalize;
`;

export const OptionContainer = styled.div<{ selected: boolean }>`
    cursor: pointer;
    border-radius: 30px;
    background: ${(props) => props.selected ? lighten(0.5, props.theme.primary) : ''};
    text-align: center;
    font-size: 1.2rem;
    font-weight: ${(props) => props.selected ? 'bold' : 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    
    color: ${(props) => props.selected ? props.theme.primary : '#757575'};

    padding: 10px;

    margin-right:5px;
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.selected ? lighten(0.4, props.theme.primary) : '#e5e5e5'};
        ${OptionLabel} {
            opacity: 0.8;
            color: ${(props) => props.selected ? '' : '#757575'};
        }
    }
    @media(max-width:540px) {
        font-size: 1rem;
        margin-right:5px;
    }
`;

export const LoadingLabel = styled.div`
    margin: 0px 5px;
`;
