import styled from 'styled-components';

export const GridItem = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  text-align: start;
  justify-content: flex-start;
  margin: auto;
  margin-top: 5px;
  border-radius: 0.3rem;
`;

export const Top = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  text-align: start;
`;

export const Bottom = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5px;
`;

export const Informations = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  flex: 1;
  padding-right: 10px;
  margin-left: 10px;
  margin-top: 5px;

  & > div {
    display: flex;
    flex-direction: row;
  }
`;

export const Aditionals = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;

  flex: 1;
  margin-right: 5px;
`;

export const ComponentsList = styled.ul`
  list-style-type: none;
  padding-left: 17px;

  max-width: 325px;
  @media(max-width: 960px){
    max-width: calc(100vw - 148px);
  }
  
  li{
    color: #000;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const Price = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Imagem = styled.div<{ image: string }>`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  margin-right: 9px;

  div{
    height: 70px;
    width: 70px;
    border-radius: 10px;

    background-image: url(${props => props.image}), url(${process.env.REACT_APP_RETAGUARDA_API_FILES}no_image.png);
    background-position: center;
    background-size: cover;

    @media(max-width: 960px){
      height: 70px;
      width: 70px;
    }
  }
`;