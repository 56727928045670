import React, { useContext, useState, useEffect } from "react";

import _ from 'lodash';

import { useParams } from "react-router-dom";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash/debounce';

import CloseIcon from '@material-ui/icons/Close';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../../hooks/provideAuth';

import {
  Container,
  Header,
  Content
} from './styles';
import stylesMaterial from './stylesMaterial';
import { googleLoader } from "../../utils/googleLoader";
import DeliveryAddressModal from "../delivery-address-modal";
import { IEmpresa } from "../../interfaces/empresa";
import empresaApi from "../../services/empresaApi";
import { useTheme } from "../../hooks/useThemeState";

interface AutocompleteGoogleModalProps{
  handleCloseAutocompleteGoogleModal: () => void;
  openAutocompleteGoogleModal: boolean;
  hasTaxByNeighborhoods: boolean;
}

export const AutocompleteGoogleModal: React.FC<AutocompleteGoogleModalProps> = ({
                                                          handleCloseAutocompleteGoogleModal,
                                                          openAutocompleteGoogleModal,
                                                          hasTaxByNeighborhoods,
                                                        }) => {
  const classes = stylesMaterial();
  const { theme } = useTheme()

  const { state, dispatch } = useContext(AuthContext);
  const { companieId: companyId } = useParams<{ companieId: string }>();

  const notFoundOption = {
    structured_formatting:{
      main_text_matched_substrings: [{length: 26, offset: 0}],
      main_text: 'Não encontrei meu endereço',
      secondary_text: ''
    },
    description: ''
  } as unknown as google.maps.places.AutocompletePrediction;

  const [open, setOpen] = useState(openAutocompleteGoogleModal);
  const [openDeliveryAddressModal, setOpenDeliveryAddressModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [initialPlace, setInitialPlace] = useState<google.maps.GeocoderResult>();
  const [company, setCompany] = useState<IEmpresa>();
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  let service: google.maps.places.AutocompleteService;
  let geocoder: google.maps.Geocoder;

  const fetch = React.useMemo(
    () =>
      debounce((
        request: {
          input: string,
          location: google.maps.LatLng | undefined
        },
        callback: (results: google.maps.places.AutocompletePrediction[] | null, serviceStatus: google.maps.places.PlacesServiceStatus) => void) => {
          service.getPlacePredictions({
            componentRestrictions: { country: ["br"] },
            radius: 100000,
            ...request
          }, callback);
        }, 1000),
    [],
  );

  useEffect(() => {
    empresaApi.getEmpresa(companyId).then((res) =>{
      setCompany(res);
    }).catch((error) => {
      empresaApi.getEmpresaSlug(companyId).then((res) =>{
        setCompany(res);
      }).catch((error) => { })
    });
  }, [companyId]);

  useEffect(() => {
    googleLoader.load().then(() => {
      service = new google.maps.places.AutocompleteService();

      setIsLoading(false);
    });
  }, [])


  useEffect(() => {
    let active = true;
    let location: google.maps.LatLng | undefined;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if(company){
      const firstCompany = company.empresas.reduce((prev, current) => {
        return (prev.id < current.id) ? prev : current;
      });
      location = new google.maps.LatLng(firstCompany.lat, firstCompany.lon);
    }

    fetch({ input: inputValue, location }, (results: google.maps.places.AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions = [] as google.maps.places.AutocompletePrediction[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [notFoundOption, ...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, company]);

  useEffect(() => {
    if(initialPlace){
      handleOpenDeliveryAddressModal();
    }
  }, [initialPlace])
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseAutocompleteGoogleModal();
  };

  const handleOpenDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(true);
  };

  const handleCloseDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(false);
  };

  const handleRegisterSucess = () => {
    toast.success('Endereço registrado com sucesso!', toast_config);
  }

  function getLocationFromPlaceId(placeId: string) {
    googleLoader.load().then(() => {
        geocoder = new google.maps.Geocoder();

        if(placeId){
          geocoder.geocode({ placeId: placeId }, (res, status) =>{
            if(status === "OK"){
              if(res !== null){
                setInitialPlace(res[0]);
              }
            }
          });
        } else {
          setInitialPlace({
            address_components: []
          } as unknown as google.maps.GeocoderResult);
        }
    });
  }

  const useCurrentLocation = () => {
    googleLoader.load().then(() => {
      geocoder = new google.maps.Geocoder();
      
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            geocoder.geocode({ location: pos }, (res, status) =>{
              if(status === "OK"){
                if(res !== null){
                  setInitialPlace(res[0]);
                  setIsCurrentLocation(true);
                }
              }
            });
          },
          () => {
            toast.error('Ative a localização e dê permissão no navagador para usar', toast_config);
          }
        );
      } else {
        // Browser doesn't support Geolocation
        toast.error('Ative a localização e dê permissão no navagador para usar', toast_config);
      }
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <span className={classes.span} />
          <div>
            <Typography
              variant="h6"
              className={classes.title}
            >
              {hasTaxByNeighborhoods ? "Cadastrar Endereço" : "Digite seu endereço"}
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#b22222' }}
            />
          </IconButton>
        </Header>
        <Divider className={classes.titleDivider} />

        <Content>
          {!hasTaxByNeighborhoods && (
            <Autocomplete
              id="google-automcomplete"
              style={{ width: 300 }}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
              noOptionsText='Sem endereço'
              forcePopupIcon={false}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              clearOnBlur={false}
              value={value}
              onChange={(event: any, newValue: google.maps.places.AutocompletePrediction | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                if(newValue){
                  getLocationFromPlaceId(newValue.place_id);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Endereço"
                  placeholder="Ex: Av. Maranata, 234, Centro" 
                  variant="outlined" 
                  fullWidth
                />
              )}
              renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches && matches.length
                  ? matches.map((match: any) => [match.offset, match.offset + match.length])
                  : [[0,0]],
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon style={{ color: option.place_id ? 'inherit' : 'red' }} className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          )}
          

          <Button
            aria-label="currentLocation"
            onClick={useCurrentLocation}
            className={classes.button}
            style={{ backgroundColor: theme.primary }}
          >
            <LocationOnIcon />
            <Typography
              variant="body1"
            >
              Usar sua localização
            </Typography>
          </Button>

          {hasTaxByNeighborhoods && (
            <>
              <Typography
                variant="body1"
                style={{ marginTop: "1rem" }}
              >
                OU
              </Typography>

              <Button
                aria-label="createManual"
                onClick={() => handleOpenDeliveryAddressModal()}
                className={classes.addAddressButton}
              >
                <Typography
                  variant="body1"
                >
                  Adicionar endereço
                </Typography>
              </Button>
            </>
          )}
        </Content>

        {
          openDeliveryAddressModal && (
            <DeliveryAddressModal
              handleCloseDeliveryAddressModal={handleCloseDeliveryAddressModal}
              openDeliveryAddressModal={openDeliveryAddressModal}
              registerSucess={handleRegisterSucess}
              initialPlace={initialPlace}
              handleCloseAutoComplete={handleCloseAutocompleteGoogleModal}
              isEmpty={true}
              isCurrentLocation={isCurrentLocation}
              setIsCurrentLocation={setIsCurrentLocation}
              hasTaxByNeighborhoods={hasTaxByNeighborhoods}
            />
          )
        }

      </Container>

    </Modal>
  )
}
