import api from './api';

import { IEstado } from '../interfaces/estado';
import { ICidade } from '../interfaces/cidade';
import { IEnderecoCepApi } from '../interfaces/enderecoCepApi';
import { IInfosLatLongApi } from '../interfaces/infosLatLongApi';

const geographicApi = {
  // getBairros: async (value: string) => {
  //   const { data } = await api.get("https://maps.googleapis.com/maps/api/place/autocomplete/json");
  //   return data;
  // },
  getEstados: async (): Promise<IEstado[]> => {
    const { data } = await api.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados");
    return data;
  },
  getCidades: async (id: string): Promise<ICidade[]> => {
    const { data } = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`);
    return data;
  },
  getEndereco: async (cep: string): Promise<IEnderecoCepApi> => {
    const { data } = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
    return data;
  },
  getLatLong: async ( 
                      houseNumber: string,
                      address: string,
                      neighborhood: string,
                      city: string,
                      state: string
                    ): Promise<IInfosLatLongApi> => {

    const { data } = await api.get("cliente/endereco/lat-lng", {
      params: {
        address,
        houseNumber,
        neighborhood,
        city,
        state,
      }
    });
    return data;
  },
  getLatLongBairro: async ( neighborhood: string, city: string, state: string): Promise<IInfosLatLongApi> => {                         
    const { data } = await api.get("cliente/endereco/lat-lng", {
      params: {
        neighborhood,
        city,
        state,
      }
    });
    return data;
  },
  getLatLongCidade: async (city: string, state: string): Promise<IInfosLatLongApi> => { 
    const { data } = await api.get("cliente/endereco/lat-lng", {
      params: {
        city,
        state,
      }
    });
    return data;
  }
};

export default geographicApi;