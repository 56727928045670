import React, { useState, useEffect, useContext } from "react";

import clsx from 'clsx';
import { useParams, Redirect } from "react-router-dom";
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';

import { AuthContext } from '../../hooks/provideAuth';

import empresaApi from '../../services/empresaApi';
import pedidoApi from '../../services/pedidoApi';

import Order from '../../components/order';
import { handleResponse } from '../../helpers/handleResponse';

import { IEmpresa } from '../../interfaces/empresa';
import { IPedidoVenda } from '../../interfaces/pedidoVenda';

import stylesMaterial from './stylesMaterial';
import {
  Container,
  TopMenu,
  Body,
  Orders
} from './styles';
import { Footer } from "../../components/footer";
import { CustomAppBar } from "../../new-components/custom-appbar";
import { DefaultTheme } from "styled-components";
import { useTheme } from "../../hooks/useThemeState";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

const History: React.FC = () => {
  const classes = stylesMaterial();

  const { companieId } = useParams<{ companieId: string }>();

  const { state: authState } = useContext(AuthContext);
  const { theme, setTheme } = useTheme()

  const [companie, setCompanie] = useState<IEmpresa>();
  const [orders, setOrders] = useState(new Array<IPedidoVenda>());

  useEffect(() => {
    empresaApi.getEmpresa(companieId).then((res) => {
        setCompanie(res);
    }).catch((error) => {
      empresaApi.getEmpresaSlug(companieId).then((res) => {
        setCompanie(res);
      }).catch((error) => {
            // console.log("Erro no slug");
            // console.log(error);
      })
      });
  }, [companieId]);

  useEffect(() => {
    if (companie) {
      if (authState.token) {
        pedidoApi.getPedido(authState.token, companie?.id.toString()).then((response) => {
            const orders = response.data;
            setOrders(orders.reverse());
        }).catch((error) => {
            handleResponse(error);
            // console.log("Erro", error);
        })

      } else {
        // pedidoApi.getOpenPedido(authState.tokenLogin, companie?.id.toString()).then(response => {
        //     const orders = response.data;
        //     setOrders(orders.reverse());
        // }).catch((error) => {
        //     handleResponse(error);
        //     // console.log("Erro", error);
        // })
      }

      empresaApi
      .getParametro(`${companie.id}`, 'customizations')
      .then(res => {
        if (!!res) {
          const role: DefaultTheme = JSON.parse(res.role)
          if (role.title === 'custom') {
            setTheme({
              title: role.title,
              primary: role.primary,
              secondary: role.secondary
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }, [companie, authState]);

  useEffect(() => {
    if (!authState.token) {
      toast.error("Você precisa logar com senha para visualizar os pedidos!");
    }
  })

  return (
    authState.token ? (
      <Container>
        <CustomAppBar />

        <Body>
          {orders.length > 0 ? (
            <>
              <Typography variant='h6' className={classes.title}>
                Meus pedidos
              </Typography>

              <Orders>
                  {
                    orders.map((order) => {
                      return (
                        <Order
                          setCompanie={setCompanie}
                          pedido={order}
                          key={order.id}
                          empresaId={companie?.id.toString()}
                        />
                      )
                    })
                  }
              </Orders>
            </>
          ) : (
            <CircularProgress className={classes.circularProgress} />
          )}
        </Body>
        <Footer />
      </Container>
    ) : <Redirect to={`/${companieId}`}  />
  )
}

export default History;
