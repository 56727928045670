import React, { useEffect, useState } from 'react';

import pt from 'date-fns/locale/pt-BR';
import { utcToZonedTime } from 'date-fns-tz';
import { format, isWithinInterval, isBefore } from 'date-fns';

import { IEmpresa } from '../../interfaces/empresa';
import empresaApi from '../../services/empresaApi';

import { Container, Informations, Logo } from './styles';
import { InfoItem } from './info-item';
import { MdCheckCircle, MdDateRange, MdOutlineAlarm, MdOutlineAlarmOff, MdStore } from 'react-icons/md';
import { Typography } from '@material-ui/core';
import stylesMaterial from './stylesMaterial';
import { ITurno } from '../../interfaces/diasHorarios';

interface Infos {
  operatingDays: string;
  workingHours: string;
  closedDays: string;
  opensAt: string;
  deliveryMethods: string;
}

interface CompanyInfoProps {
  company: IEmpresa;
}

export function CompanyInfo({company}: CompanyInfoProps) {
  const classes = stylesMaterial();

  const [infos, setInfos] = useState<Infos>({} as Infos);

  const weekDays = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

  useEffect(() => {
    let deliveryMethods = '', closedDays = '', opensAt = '', operatingDays = '', workingHours = '';
    let hasDelivery = true, hasWithdraw = true;

    if(company.aberto && company.horario) {
      const dias = company.horario.dias;
      const daysKeys = Object.keys(dias);
      let schedule: ITurno | undefined;

      const zonedToday = utcToZonedTime(new Date(), company.time_zone);
      const weekDay = format(zonedToday, 'iiiiii' , {
        locale: pt
      });

      if(company.horario.dias[weekDay]) {
        schedule = company.horario.dias[weekDay].find((time) => {
          const start = time.start.split(':');
          const end = time.end.split(':');

          return isWithinInterval(zonedToday, {
            start: new Date(zonedToday.getFullYear(), zonedToday.getMonth(), zonedToday.getDate(), Number(start[0]), Number(start[1])),
            end: new Date(zonedToday.getFullYear(), zonedToday.getMonth(), zonedToday.getDate(), Number(end[0]), Number(end[1]))
          });
        });
      }

      if(company.horario.dias[weekDay] && schedule) {
        if(typeof(schedule.delivery) === 'boolean') {
          hasDelivery = schedule.delivery;
        }

        if(typeof(schedule.retirada) === 'boolean') {
          hasWithdraw = schedule.retirada;
        }

        let days = weekDays.filter(day => daysKeys.find(key => key.localeCompare(day) === 0));

        if(days.length !== 7) {
          days = days.map((day) => day[0].toUpperCase()+day.slice(1));
          const daysText = days.length > 1 ? (days.slice(0, -1).join(', ')+' e '+days[days.length - 1]) : days[0];

          operatingDays = 'Aberto '+daysText;
        } else {
          operatingDays = 'Aberto todos os dias';
        }

        workingHours = `Horário ${schedule.start}-${schedule.end}`;
      } else {
        let days = weekDays.filter(day => !daysKeys.find(key => key.localeCompare(day) === 0));

        if(days.length !== 7) {
          days = days.map((day) => day[0].toUpperCase()+day.slice(1));
          const daysText = days.length > 1 ? (days.slice(0, -1).join(', ')+' e '+days[days.length - 1]) : days[0];

          closedDays = 'Fechado '+ (daysText ? daysText : '');

          const todayIndex = weekDays.findIndex(day => day.localeCompare(weekDay)===0);
          const newWeekDays = weekDays.splice(todayIndex).concat(weekDays);

          const nextScheduleToday = dias[newWeekDays[0]]?.find(time => {
            const start = time.start.split(':');

            return isBefore(
              zonedToday, 
              new Date(zonedToday.getFullYear(), zonedToday.getMonth(), zonedToday.getDate(), Number(start[0]), Number(start[1]))
            );
          });

          if(nextScheduleToday) {
            opensAt = `Abre hoje às ${nextScheduleToday.start}`;
          } else {
            const nextDay = newWeekDays.splice(1).find(day => !!dias[day]);

            if(nextDay) {
              opensAt = `Abre ${nextDay[0].toUpperCase()+nextDay.slice(1)} às ${dias[nextDay][0].start}`;
            }
          }
        } else {
          closedDays = 'Fechado todos os dias';
        }
      }
    } else {
      if(company.aberto) {
        operatingDays = 'Aberto';
      } else {
        closedDays = 'Fechado';
      }
    }

    empresaApi.getScheduledDelivery(company.id).then((days) => {
      empresaApi.getHasDeliveryFee(company.id).then((res) => {
        if(res.has && hasDelivery && days && hasWithdraw) {
          deliveryMethods = 'Envio, retirada na loja e agendamento';
        } else if(res.has && hasDelivery && hasWithdraw) {
          deliveryMethods = 'Envio e retirada na loja';
        } else if(days && hasWithdraw) {
          deliveryMethods = 'Retirada na loja e agendamento';
        } else if(hasWithdraw) {
          deliveryMethods = 'Retirada na loja';
        }

        setInfos({
          closedDays,
          opensAt,
          operatingDays,
          workingHours,
          deliveryMethods
        });
      }).catch((error) => console.log(error));
    }).catch((error) => {
      console.log(error);
      empresaApi.getHasDeliveryFee(company.id).then((res) => {
        if(res.has) {
          deliveryMethods = 'Envio e retirada na loja';
        } else {
          deliveryMethods = 'Retirada na loja';
        }

        setInfos({
          closedDays,
          opensAt,
          operatingDays,
          workingHours,
          deliveryMethods
        });
      }).catch((error) => console.log(error));
    });
  }, []);

  return (
    <Container>
      <Logo
        src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        alt="Logomarca"
        title={company.nome_fantasia}
      />

      <Informations>
        <Typography
          variant='h1'
          className={classes.companyName}
        >
          {company.nome_fantasia}
        </Typography>
        {company.horario && company.horario.ativo && (
          infos.operatingDays ? (
            <>
              <InfoItem 
                Icon={MdDateRange}
                text={infos.operatingDays}
              />
              {infos.workingHours && (
                <InfoItem 
                  Icon={MdOutlineAlarm}
                  text={infos.workingHours}
                />
              )}
            </>
          ) : (
            <>
              <InfoItem 
                Icon={MdOutlineAlarmOff}
                text={infos.closedDays}
                closed={true}
              />
              {infos.opensAt && (
                <InfoItem 
                  Icon={MdOutlineAlarm}
                  text={infos.opensAt}
                />
              )}
            </>
          )
        )}
        <InfoItem 
          Icon={MdCheckCircle}
          text={infos.deliveryMethods}
        />
        <InfoItem 
          Icon={MdStore}
          text={'Lojas'}
          link={`/${company.id}/stores`}
        />
      </Informations>
    </Container>
  );
}