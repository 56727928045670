import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return ({
    listTitle: {
      fontSize: '18px',
      fontWeight: 'normal',
      textAlign: 'center'
    },
    listItem: {
      paddingRight: '5px',
      paddingLeft: '5px'
    },
    divisor: {
      marginBottom: '10px',
      [theme.breakpoints.up(960)]: {
        marginBottom: '15px'
      },
    },
    addAddressLink:{
      color: '#0c65ea',
      fontSize: '18px',
      fontWeight: 400,
      marginTop: '10px'
    },
    addAddressIcon:{
      width: '24px',
      height: '24px',
      color: '#0c65ea',
    },
    addAddressButton:{
      color: '#0c65ea',
      fontSize: '1rem',
      textDecoration: 'none',
      fontWeight: 500,
      transition: 'all 0.35s ease-in-out',
      '&:hover':{
        color: '#0c65ea',
      },
    },
  })
});
