import styled from 'styled-components';

export const DiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 28px;
  width: 42px;
  top: 5px;
  right: 5px;
  background-color: #f4546a;
  border-radius: 3px;
`;

export const Card = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  overflow: visible;
  transition: 0.2s;
  border: 1px solid #ddd;
  flex-direction: column;

  width: 130px;
  height: 200px;
  flex-shrink: 0;

  &:hover {
    transform: scale(1.03);
    z-index: 100;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 580px) {
    width: 150px;
    height: 250px;
  }

  @media (min-width: 768px) {
    width: 170px;
    height: 300px;
    border-radius: 12px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const OutOfStockTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.primary};

  width: 100%;
  padding-block: 3px;
`;
