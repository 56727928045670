import React from "react";
import { Container } from "./styles";
import { Banner as BannerType } from "../../services/empresaApi";
import { Link, useHistory } from "react-router-dom";

interface BannerProps {
  banner: BannerType;
}

export function Banner({ banner }: BannerProps) {
  const history = useHistory();

  return (
    <Link to={`/banner/${banner.id}`}>
      <Container 
        src={banner.image}
        alt={banner.name}
      />
    </Link>
  );
}