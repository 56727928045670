import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import stylesMaterial from './stylesMaterial';
import { IItemPedido } from '../../../interfaces/itemPedido';

interface NotifyOrderValueChangeProps {
  isOpen: boolean;
  onClose: () => void;
  fractionedItemsText: string;
  excludedItems: IItemPedido[];
}

export function NotifyOrderValueChange({
  isOpen,
  onClose,
  fractionedItemsText,
  excludedItems,
}: NotifyOrderValueChangeProps) {
  const [excludedItemsText, setExcludedItemsText] = useState('');

  useEffect(() => {
    setExcludedItemsText(excludedItems
      .map(item => item.nomeProduto || '')
      .join('\n')
    );
  }, []);

  const classes = stylesMaterial();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {!!fractionedItemsText ? "Itens atualizados" : "Itens indisponíveis"}
      </DialogTitle>
      <DialogContent>
        {fractionedItemsText && (
          <DialogContentText 
            id="alert-dialog-description" 
            className={classes.alertText}
          >
            {fractionedItemsText}
          </DialogContentText>
        )}
        {excludedItemsText && (
          <DialogContentText 
            id="alert-dialog-description-items-exluded" 
            className={classes.alertTextExcludedItems}
          >
            {excludedItemsText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
