import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  groupTitle: {
    width: 'max-content',
    fontFamily: 'Segoe UI, sans-serif',
    whiteSpace: 'nowrap',
    margin: '0px 8px',
    fontSize: '1.15rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    fontSize: '16px',
    width: 'max-content',
    fontFamily: 'Segoe UI, sans-serif',
    fontWeight: 600,
    lineHeight: 1.2,
    textDecoration: 'underline',
    marginTop: '10px',
  },
  carouselRoot: {
    display: 'flex',
    width: '100%',
    padding: '25px 10px',
    gap: '20px',
    [theme.breakpoints.down(768)]: {
      gap: '15px',
    },
    [theme.breakpoints.down(580)]: {
      gap: '10px',
    },
    [theme.breakpoints.down(425)]: {
      gap: '5px',
    },
  },
}));
