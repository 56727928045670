import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  padding-right: 5vw;
  padding-left: 5vw;
  width: 100%;
  min-height: calc(100vh - 64px);
  flex: 1;
  padding-bottom: 100px;
  margin-top: 30px;

  h1 {
    font-size: 28px;
    font-weight: 700;
    color: #263238;
  }

  @media (max-width: 640px) {
    h1 {
      align-self: center;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 960px) and (max-width: 1359px) {
    padding-right: 115px;
    padding-left: 115px;
  }

  @media (min-width: 1360px) {
    padding-right: 100px;
    padding-left: 100px;
  }
`;

export const SegmentFilter = styled.div`
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;

  background-color: white;

  position: sticky;
  top: 64px;
  z-index: 101;
`;

export const LongLine = styled.div`
  align-self: center;
  width: 100%;
  height: 1px;
  transform: rotate(-360deg);
  border-bottom: 1.5px solid #c4c4c4;
  margin: 0;
`;

export const ShortLine = styled.hr`
  width: 130px;
  height: 1px;
  transform: rotate(-360deg);
  background-color: #c4c4c4;
  margin: 0px 20px;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const SegmentTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

export const SegmentHeader = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SeeAll = styled.button`
  margin-top: 6px;
  margin-bottom: 22px;
  background: transparent;
  border: none !important;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  text-align: right;
  text-decoration: underline;

  &:hover {
    color: #000b90;
    cursor: pointer;
  }

  &:focus {
    color: #ea300c;
    cursor: pointer;
  }

  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

export const GrupoAnchor = styled.div`
  display: block;
  position: relative;
  visibility: hidden;

  top: -84px;

  @media (min-width: 680px) {
    top: -96px;
  }
`;

export const LoadingDiv = styled.div`
  background-color: gray;
  width: 100%;
  height: 100%;
`;

export const Carrossel = styled.section`
  width: 100%;
  position: relative;
`;

export const CartLength = styled.div`
  width: 70px;
  display: flex;
`;

export const CartPrice = styled.div`
  /* width: 70px; */
`;
