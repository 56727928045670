import React, { useEffect, useState } from "react";

import Typography  from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DeliveryAddressModal from '../delivery-address-modal';
import SaleAdressCard from "./sale-address-card";

import { IEnderecoEntrega } from "../../interfaces/enderecoEntrega";

import { Container, GridContainer, AddAddressButton } from './styles';
import stylesMaterial from './stylesMaterial';
import { AutocompleteGoogleModal } from "../autocomplete-google-modal";
import { useTheme } from "../../hooks/useThemeState";

interface SaleAdressGroupProps{
  selectedAddress: number;
  selectAddress: (id: number) => void;
  enderecos: IEnderecoEntrega[];
  getAddresses: () => void;
  companieId: number;
  hasTaxByNeighborhoods: boolean;
}

const SaleAddressGroup: React.FC<SaleAdressGroupProps> = ({
  selectedAddress,
  selectAddress,
  enderecos,
  getAddresses,
  companieId,
  hasTaxByNeighborhoods,
}) => {

  const classes = stylesMaterial();

  const { theme } = useTheme()

  const [isEmpty, setIsEmpty] = useState(true);

  const [openAutocompleteGoogleModal, setOpenAutocompleteGoogleModal] = useState(false);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if(enderecos.length === 0){
      setIsEmpty(true);
    } else{
      setIsEmpty(false);
    }
  }, [enderecos])

  const handleSelectAddress = (id: number) => {
    selectAddress(id);
  };

  const handleOpenAutocompleteGoogleModal = () => {
    setOpenAutocompleteGoogleModal(true);
  }

  const handleCloseAutocompleteGoogleModal = () => {
    setOpenAutocompleteGoogleModal(false);
    getAddresses();
  }

  return (
    <Container>
      <Typography className={classes.listTitle}>
        Endereços de entrega
      </Typography>

      <GridContainer>
        {
          enderecos.map((endereco: IEnderecoEntrega) => {
              return (
                <SaleAdressCard
                  key={endereco.id}
                  endereco={endereco}
                  checked={selectedAddress===endereco.id}
                  onClickAddress={handleSelectAddress}
                  getAdresses={getAddresses}
                  companieId={companieId}
                />
              )
          })
        }
        <Button
          aria-label="add_address"
          onClick={handleOpenAutocompleteGoogleModal}
          startIcon={
            <AddCircleOutlineIcon
              className={classes.addAddressIcon}
              style={{ color: theme.primary }}
            />
          }
          className={classes.addAddressButton}
          style={{ color: theme.primary }}
        >
          ADICIONAR ENDEREÇO
        </Button>

      </GridContainer>

      {
        openAutocompleteGoogleModal && (
          <AutocompleteGoogleModal
            handleCloseAutocompleteGoogleModal={handleCloseAutocompleteGoogleModal}
            openAutocompleteGoogleModal={openAutocompleteGoogleModal}
            hasTaxByNeighborhoods={hasTaxByNeighborhoods}
          />
        )
      }


    </Container>
  )
}

export default SaleAddressGroup;
