import styled from 'styled-components';

export const Icon = styled.div`
    width: 22.5px;
    height: 27.5px;
`;
export const IconSpan = styled.span<{selected: boolean}>`  
    
    cursor: pointer;

    margin: 10px 0 0;

    font-size: 16px;
    font-weight: ${(props) => props.selected?'bold':'600'};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.selected?'#0c65ea':'#c4c4c4'};

    
    @media(max-width: 640px){
        font-size: 14px;
        padding: 0px;
    }
    @media(max-width: 960px){
        margin: 6px 0 0;
    }
`;
export const RoundIconHolder = styled.button<{selected: boolean}>`
    width: 50px;
    height: 50px;
    flex-grow: 0;
    border-radius: 50%;
    border: none;

    background-color: ${(props) => props.selected?'#0c65ea':'#c4c4c4'};
`;


export const OptionContainer = styled.div<{selected: boolean}>`
    background: transparent;
    border: none !important;

    min-width:80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
        ${RoundIconHolder} {
            opacity: 0.8;
            background-color: ${(props) => props.selected?'':'#b1b1b1'};
        }   
        ${IconSpan} {
            opacity: 0.8;
            color: ${(props) => props.selected?'':'#b1b1b1'};
        }
    }
`;