import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React from 'react';

import { Carousel } from 'react-responsive-carousel';

import { ImageContainer } from './styles';
import stylesMaterial from './stylesMaterial';

interface CustomCarouselProps {
  paddingTop?: string;
  banners: {
    url: string;
    product_id?: number;
    product?: any;
    ordem: number | null;
  }[];
  handleSelectedProduct: (productId: number) => void;
  setProductIdByBanner?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const CustomCarousel: React.FC<CustomCarouselProps> = (props) => {
  const { paddingTop, banners, handleSelectedProduct, setProductIdByBanner } =
    props;

  const classes = stylesMaterial();

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      className={classes.carouselRoot}
      autoPlay
      infiniteLoop
      interval={5000}
    >
      {banners
        .sort((a, b) => (a.ordem || Infinity) - (b.ordem || Infinity))
        .map((banner, index) => (
          <ImageContainer
            isClicked={!!banner.product_id && !!banner.product}
            onClick={() => {
              if (!!banner.product_id && !!banner.product) {
                if (setProductIdByBanner) {
                  setProductIdByBanner(banner.product_id);
                } else {
                  handleSelectedProduct(banner.product_id);
                }
              }
            }}
            key={index}
            paddingTop={paddingTop}
          >
            <img src={banner.url} alt="Banner" />
          </ImageContainer>
        ))}
    </Carousel>
  );
};
