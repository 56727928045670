import styled from 'styled-components';

interface SizeProps {
  selected: boolean;
  disabled: boolean;
}

export const Size = styled.button<SizeProps>`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  min-width: 28px;
  border-radius: 10px;
  border: ${props => props.selected ? '1px solid #000' : '0'};
  background-color: #ddd;
  background-clip: content-box;
  cursor: pointer;
`;

export const SizeText = styled.p<SizeProps>`
  font-size: 16px;
  color: #757575;
  margin-inline: 3px;
  text-decoration: ${props => props.disabled ? 'line-through' : 'none'};
`;