import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  hide:{
    display: 'none !important'
  },
  troco: {
    margin: '10px 0px 10px 30px',
    maxWidth: '80%'
  }
}));
