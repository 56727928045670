import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Cards, Container, LineTitle, LinkText, TitleAndShowAll, TitleGroup } from './styles';
import stylesMaterial from './stylesMaterial';

import { useTheme } from '../../hooks/useThemeState';
import { IProduto } from '../../interfaces/produto';
import { OfferCard } from './offer-card';

interface OfferGroupProps {
  products: IProduto[];
  title: string;
}

export const OfferGroup: React.FC<OfferGroupProps> = (props) => {
  const [showAll, setShowAll] = useState(false);
  const classes = stylesMaterial();

  const { products } = props;

  return (
    <Container>
      <TitleGroup>
        <LineTitle />
        <Typography className={classes.groupTitle}>{props.title}</Typography>
        <LineTitle />
      </TitleGroup>

      <TitleAndShowAll>
        <Typography className={classes.groupTitleSmallScreens}>{props.title}</Typography>

        <LinkText
          showAll={showAll}
          href="/#"
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            setShowAll(!showAll);
          }}
        >
          {showAll ? 'Esconder' : 'Ver todos'}
        </LinkText>
      </TitleAndShowAll>
      
      {showAll ? (
        <Cards>
          {products.map((produto) => (
            <OfferCard key={produto.id} product={produto} />
          ))}
        </Cards>
      ) : (
        <ScrollContainer
          horizontal
          hideScrollbars
          nativeMobileScroll
          className={classes.carouselRoot}
        >
          {products.map((produto) => {
            return <OfferCard key={produto.id} product={produto} />;
          })}
        </ScrollContainer>
      )}
    </Container>
  );
};
