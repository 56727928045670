import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import Typography  from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import CompanyAdressCard from "./company-address-card";

import { IEmpresa } from "../../interfaces/empresa";
import empresaApi from '../../services/empresaApi';

import { Container, GridContainer } from './styles';
import stylesMaterial from './stylesMaterial';

interface CompanyAdressGroupProps{
  selectedCompany: number;
  selectCompany: (id: number) => void;
  companyId: number;
}

const CompanyAddressGroup: React.FC<CompanyAdressGroupProps> = ({selectedCompany, selectCompany, companyId}) => {
  const classes = stylesMaterial();

  const [companies, setCompanies] = useState<IEmpresa[]>();

  useEffect(() => {
    empresaApi.getFiliais(companyId).then((res) =>{
      setCompanies(res);
      selectCompany(res[0].id || -1)
    }).catch((error) => {
      // console.log(error);
    });
  }, [companyId]);

  const handleSelectCompany = (id: number) => {
    selectCompany(id);
  };

  return (
    <Container>
      <Typography className={classes.listTitle}>
        Pontos para retirada
      </Typography>

      <GridContainer>
        {
          companies!==undefined
          ?
          companies.map((company) => {
              return (
                <CompanyAdressCard
                  key={company.id}
                  empresa={company}
                  checked={selectedCompany===company.id}
                  selectCompany={handleSelectCompany}
                />
              )
          })
          :<div />
        }

      </GridContainer>
    </Container>
  )
}

export default CompanyAddressGroup;
