import React, { useContext, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { AuthContext } from '../../../hooks/provideAuth';

import { IEnderecoEntrega } from "../../../interfaces/enderecoEntrega";

import userApi from "../../../services/userApi";

import DeliveryAddressModal from '../../delivery-address-modal';
import LoginModal from '../../login-modal';

import { GridItem, EnderecoCard } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../../hooks/useThemeState";

interface SaleAdressProps{
  endereco: IEnderecoEntrega;
  checked: boolean;
  onClickAddress: (id: number) => void;
  getAdresses: () => void;
  companieId: number;
}

const SaleAdressCard: React.FC<SaleAdressProps> = ({
  endereco,
  checked,
  onClickAddress,
  getAdresses,
  companieId
}) => {

  const classes = stylesMaterial();

  const [openDeliveryAddressModal, setOpenDeliveryAddressModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { state, dispatch } = useContext(AuthContext);

  const { theme } = useTheme()

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleSelect = () => {
    onClickAddress(endereco.id||0);
  }

  const handleRemoveAddress = () => {
    userApi.removeEndereco(_.toNumber(endereco.id), state.token).then(() => {
      getAdresses();
    }).catch((erro) => {
      toast.error('Ocorreu um erro na remoção do endereço.', toast_config);
    })
  }

  const handleOpenDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(true);
  }

  const handleCloseDeliveryAddressModal = () => {
    setOpenDeliveryAddressModal(false);
    getAdresses();
  }

  const handleOpenLoginModal = () => {
    toast.warning("Entre para alterar seus dados", toast_config);
    setOpenLoginModal(true);
  }

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  }

  const handleRegisterSucess = () => {
    toast.success('Endereço alterado com sucesso!', toast_config);
  }

  return (
    <GridItem>
      <Card>
        <CardActionArea
          onClick={handleSelect}
          className={classes.card}
        >
          <EnderecoCard>
            {
              endereco.descricao && (
                <>
                  <Typography
                    variant="body1"
                    className={classes.description}
                  >
                    {endereco.descricao}
                  </Typography>
                  <Divider />
                </>
              )
            }
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {endereco.endereco.logradouro}, {endereco.endereco.numero}, {endereco.endereco.bairro}, {endereco.endereco.cep}
            </Typography>
            {
              endereco.endereco.complemento && (
                <Typography
                  variant="body1"
                  className={classes.infos}
                >
                  {endereco.endereco.complemento}
                </Typography>
              )
            }
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {endereco.endereco.cidade}, {endereco.endereco.estado}
            </Typography>
          </EnderecoCard>
          <div>
            {
              checked
              ?
              <RadioButtonCheckedIcon
                className={classes.iconChecked}
                style={{ color: theme.primary }}
              />
              :
              <RadioButtonUncheckedIcon
                className={classes.iconUnchecked}
              />
            }
          </div>
        </CardActionArea>

        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={
              state.token
              ?
              handleOpenDeliveryAddressModal
              :
              handleOpenLoginModal
            }
            className={classes.editButton}
          >
            Editar
          </Button>

          <Button
            size="small"
            color="primary"
            onClick={
              state.token
              ?
              handleRemoveAddress
              :
              handleOpenLoginModal
            }
            className={classes.removeButton}
          >
            Remover
          </Button>
        </CardActions>
      </Card>

      {
        openDeliveryAddressModal
        ? <DeliveryAddressModal
            handleCloseDeliveryAddressModal={handleCloseDeliveryAddressModal}
            openDeliveryAddressModal={openDeliveryAddressModal}
            registerSucess={handleRegisterSucess}
            enderecoDefault={endereco}
          />
        : <div />
      }
      {
        openLoginModal
        ? <LoginModal
            handleCloseLoginModal={handleCloseLoginModal}
            openLoginModal={openLoginModal}
            handleCloseDrawer={() => {}}
            companieId={Number(companieId)}
          />
        : <div />
      }

    </GridItem>
  )
}

export default SaleAdressCard;
