import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  height: 100vh;
`;

export const TopMenu = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3px;

  div{
    img {
      width: auto;
      height: 60px;
    }
  }
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  padding-right: 5vw;
  padding-left: 5vw;
  width: 100%;
  min-height: max-content;
  align-items: left;
  padding-bottom: 100px;
  flex: 1;
  h1 {
    font-size: 60px;
  }
  @media(min-width: 961px){
    padding-right: 100px;
    padding-left: 100px;
  }
`;

export const Companies = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  justify-items: center;
  align-items: center;

  @media (max-width: 875px){
    grid-template-columns: 1fr;
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 876px) and (max-width: 960px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 961px) and (max-width: 1280px){
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1281px) and (max-width: 1601px){
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  width: 100%;
  min-height: max-content;

  align-items: center;
  justify-content: center;
  h4 {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }
  a {
    text-decoration: none;
  }
  h5 {
    margin-bottom: 10px;
    color: #b1b1b1;
    font-weight: 300;
    font-size: 14px;
  }
  @media(max-width: 640px){
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 10px;
    }
  }
`;