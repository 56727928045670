import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 5px 10px;
  background: #e0e0e0;
  border-radius: 4px;
`;