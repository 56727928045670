import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BannerName, Container, Body, CompanyItems, ProductItems } from "./styles";
import { CustomAppBar } from "../../new-components/custom-appbar";
import empresaApi, { Banner } from "../../services/empresaApi";
import { IEmpresa } from "../../interfaces/empresa";
import BusinessList from "../../components/business-list";
import { IProduto } from "../../interfaces/produto";
import produtosApi from "../../services/produtosApi";
import { OfferCard } from "../../new-components/offer-group/offer-card";

export function BannerItems() {
  const { bannerId } = useParams<{ bannerId: string }>();

  const [banner, setBanner] = useState<Banner | null>(null);
  const [companies, setCompanies] = useState<IEmpresa[]>([]);
  const [products, setProducts] = useState<IProduto[]>([]);

  useEffect(() => {
    empresaApi.getBannersHome()
      .then((res) => {
        const bannerTemp = res.find(banner => banner.id.toString() === bannerId);
        setBanner(bannerTemp || null);

        if (bannerTemp) {
          if (bannerTemp.companies.length > 0) {
            empresaApi.getCompaniesByIds(bannerTemp.companies)
            .then(res => setCompanies(res))
            .catch((err) => console.log(err));
          } else {
            produtosApi.getProdutoByIds(bannerTemp.products)
              .then(res => setProducts(res))
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);
  
  function handleSearch() {

  }

  return (
    <Container>
      <CustomAppBar
        hasSearchField
        search={handleSearch}
      />

      <Body>
        {banner && <BannerName>{banner.name}</BannerName>}

        {
          companies.length > 0 ? (
            <CompanyItems>
              <BusinessList
                data={companies}
                showAll={true}
              />
            </CompanyItems>
          ) : (
            <ProductItems>
              {products.map((produto) => (
                <OfferCard key={produto.id} product={produto} />
              ))}
            </ProductItems>
          )
        }
      </Body>
    </Container>
  );
}