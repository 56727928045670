import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 700px) {
    flex-direction: column;
    padding-inline: 0.5rem;
    overflow-y: scroll;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const InformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  overflow-y: scroll;
  width: 50%;
  padding-inline: 10px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    margin-top: 1rem;
    width: 100%;
    overflow-y: visible;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0px;
  margin-left: 25px;
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 1px;
  padding-top: 1px;
  max-height: 88%;
  @media (min-width: 700px) {
    max-height: 80%;
  }
`;

export const Scroll = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  @media (max-width: 960px) {
    height: calc(100vh - 90px);
  }
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0.3rem 0.3rem;
  background-color: #fefefe;
  margin-top: 10px;
  padding: 15px 0px;
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  min-height: max-content;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Body = styled.div.attrs({
  className: 'conteudo',
})`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  overflow-y: auto;
`;

export const CarouselMobile = styled.div`
  margin-top: 10px;

  @media (min-width: 700px) {
    display: none;
  }
`;
