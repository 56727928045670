import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Informations = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

export const ImageComplement = styled.figure<{defaultImg: string, img: string}>`
  background-image: url(${props => props.defaultImg});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  object-fit: cover;
  align-self: center;
  position: relative;
  height: 50px;
  width: 50px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  border: 2px dashed gray;

  div {
    background-image: url(${(props) => props.img});
    background-position: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    background-size: cover;
  }
`
