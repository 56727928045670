import React, { useState, useEffect } from "react";

import _ from 'lodash';

import { IProduto } from '../../../interfaces/produto';
import { IItemPedido } from "../../../interfaces/itemPedido";
import { moneyFormat } from "../../../utils/moneyFormat";

interface OrderDetailsItemProps{
  item: IProduto;
  itemPedido: IItemPedido;
  complementos: IItemPedido[];
  produtosGrade: IItemPedido[];
}

const OrderDetailsItem: React.FC<OrderDetailsItemProps> = (props) => {
  const {
    item,
    itemPedido,
    complementos,
    produtosGrade,
  } = props

  const [qtdSabores, setQtdSabores] = useState(0);

  useEffect(() => {
    let qtdTemp = 1/Math.min(...produtosGrade.map(produto => produto.quantidade));
    if((1 - qtdTemp) > 0.002){
      let i = 2;
      while(((qtdTemp*i) % 1) !== 0){
        i++;
      }
      qtdTemp = qtdTemp*i;
    }
    setQtdSabores(qtdTemp);
  }, []);

  return (
    <div className="info">
      <h3>
        {
          (produtosGrade.length > 1) && (_.toNumber(itemPedido.produtoGradeId)!==null)
          ?
          (
            itemPedido.nomeGradeTamanho !== null
            ?
            _.toNumber(itemPedido.quantidade)+"x Pizza "+itemPedido.nomeGradeTamanho
            :
            _.toNumber(itemPedido.quantidade)+"x Pizza"
          ) + ` (${moneyFormat(itemPedido.valorProduto)})`
          :
          (
            (produtosGrade.length > 0) && (itemPedido.nomeGradeTamanho !== null)
            ?
            _.toNumber(itemPedido.quantidade)+"x "+itemPedido.nomeProduto+" "+itemPedido.nomeGradeTamanho
            : (
              item.controla_grade === 1
                ? `${_.toNumber(itemPedido.quantidade)}x ${itemPedido.nomeProduto} - Tam: ${itemPedido.nomeGradeTamanho}, Cor: ${itemPedido.nomeGradeCor}`
                : (itemPedido.fracionado 
                    ? `${
                      itemPedido.quantidade >= 1
                        ? Number(itemPedido.quantidade).toFixed(3)
                        : itemPedido.quantidade * itemPedido.unidadeMedida[0].quantidadePorcao
                    }${
                      itemPedido.quantidade >= 1 
                        ? itemPedido.unidadeMedida[0].sigla
                        : itemPedido.unidadeMedida[0].siglaPorcao
                    }` + ' de '
                    : Number(itemPedido.quantidade) + 'x '
                  )+ itemPedido.nomeProduto
            )
          ) + ` (${moneyFormat(itemPedido.valorProduto)})`
        }
        {(itemPedido.valorTotal && Number(itemPedido.valorTotal) > 0) && <>
          <> - </>
          <span>R$ {Number(itemPedido.valorTotal).toFixed(2).replace('.',',')}</span>
        </>}
      </h3>
      {
        (
          complementos.length || 
          produtosGrade.length > 1 || 
          (produtosGrade.length > 0 && item.vitrine)
        ) && 
        <>
          {
            ((produtosGrade.length > 0 && item.vitrine) ||
            produtosGrade.length > 1) &&
            produtosGrade.map((produto) => (
              <p key={produto.id}>
                {Math.round(produto.quantidade*qtdSabores)+"/"+Math.round(qtdSabores)+"x "+produto.nomeProduto}
              </p>
            ))
          }
          {
            complementos.map((complemento) => (
              <p
                key={complemento.id}
              >
                {_.toNumber(complemento.quantidade)+"x "+complemento.nomeProduto}
              </p>
            ))
          }
        </>
      }
    </div>
  )
}

export default OrderDetailsItem;
