import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import { Link, useLocation, useParams } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import { SearchField } from './search-field';

import { Context } from '../../hooks/cartStore';
import { IEmpresa } from '../../interfaces/empresa';
import { IItemCarrinho } from '../../interfaces/itemCarrinho';
import empresaApi from '../../services/empresaApi';
import Cart from '../cart';
import { Menu } from './menu';
import stylesMaterial from './stylesMaterial';

import logoAtacImg from '../../assets/logopng.png';
import { useTheme } from '../../hooks/useThemeState';
import { ReducedState } from '../../pages/Home';
import { Typography } from '@material-ui/core';
import { RedoCart } from '../../utils/redoCartNew';

interface CustomAppBarProps {
  hasSearchField?: boolean;
  search?: (name: string) => void;
  openLocationModal?: () => void;
  state?: ReducedState | null;
}

export const CustomAppBar: React.FC<CustomAppBarProps> = (props) => {
  const { hasSearchField, search, openLocationModal, state: estado } = props;

  const classes = stylesMaterial();
  const { companieId } = useParams<{ companieId: string | undefined }>();
  const { state, openCart, setOpenCart, dispatch } = useContext(Context);
  const location = useLocation();

  const [openSearchFieldOnMobile, setOpenSearchFieldOnMobile] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [cart, setCart] = useState({ id: 0, cart: new Array<IItemCarrinho>() });
  const [isOpen, setIsOpen] = useState(true);
  const [company, setCompany] = useState<IEmpresa>();
  const [companyId, setcompanyId] = useState(-1);
  const { theme } = useTheme();

  const handleOpenCart = () => {
    setOpenCart(true);
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const showSearchFieldOnMobile = () => {
    setOpenSearchFieldOnMobile(true);
    if (window.innerWidth < 960) {
      setShowLogo(false);
    }
  };

  const hideSearchFieldOnMobile = () => {
    setOpenSearchFieldOnMobile(false);
    setShowLogo(true);
  };

  useEffect(() => {
    if (companieId) {
      empresaApi
        .getEmpresa(companieId)
        .then((res) => {
          setCompany(res);
          setcompanyId(res.id);
        })
        .catch(() => {
          empresaApi.getEmpresaSlug(companieId).then((res) => {
            setCompany(res);
            setcompanyId(res.id);
          });
        });
    }
  }, [companieId]);

  useEffect(() => {
    setCart(
      state.carts.find(
        (cartItem) => _.toString(cartItem.id) === company?.id.toString(),
      ) || { id: 0, cart: new Array<IItemCarrinho>() },
    );
  }, [state.carts, company]);

  useEffect(() => {
    let carrinho = state.carts.find((cart) => _.toString(cart.id) === company?.id.toString());

    if (carrinho){
      if(company){
        RedoCart(carrinho).then((cart) => {
          dispatch({type: 'SET_CART', empresaId: company.id, payload: cart});
        });
      }
    }
  }, [company]);

  return (
    <AppBar
      elevation={0}
      position="static"
      className={classes.appbar}
      style={{ backgroundColor: theme.secondary }}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        {location.pathname !== '/' && company && <Menu companieId={company.id} />}

        {showLogo && (
          companieId ? (
            <Link to={`/${companieId}`} className={classes.imgLink}>
              <img
                src={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company?.logomarca}`}
                alt="logo"
                title="temporario"
                className={classes.img}
              />
            </Link>
          ) : (
            <Link to="/#" className={classes.imgLink}>
              <img
                src={logoAtacImg}
                alt="logo"
                title="Logo da atac"
                className={classes.img}
              />
            </Link>
          ))}

        {hasSearchField && search && <SearchField search={search} hide />}

        <div className={classes.grow} />

        {showLogo && openLocationModal && (
          <IconButton
            color="inherit"
            onClick={openLocationModal}
            className={classes.location}
          >
            <LocationOnIcon />
            <Typography 
              color='inherit'
              className={classes.stateShort}
            >
              {estado ? estado.shortName : 'Brasil'}
            </Typography>
            <Typography 
              color='inherit'
              className={classes.stateLong}
            >
              {estado ? estado.longName : 'Brasil'}
            </Typography>
          </IconButton>
        )}

        {openSearchFieldOnMobile && hasSearchField && search && (
          <>
            <IconButton
              onClick={hideSearchFieldOnMobile}
              className={classes.retractSearchField}
            >
              <ArrowBackIcon />
            </IconButton>
            <SearchField search={search} />
          </>
        )}

        <div className={classes.buttonSection}>
          {!openSearchFieldOnMobile && hasSearchField && (
            <IconButton
              aria-label="search"
              color="inherit"
              onClick={showSearchFieldOnMobile}
              className={classes.searchButton}
            >
              <SearchIcon />
            </IconButton>
          )}

          {companieId && (
            <IconButton
              aria-label="carrinho"
              color="inherit"
              onClick={handleOpenCart}
            >
              {(cart.cart.length > 0 ? (
                <Badge
                  badgeContent={cart.cart.length}
                  className={classes.badge}
                >
                  <LocalMallIcon style={{ color: '#FF0000' }} />
                  {!!cart.cart
                    .map((item) => item.prodComposto.produto)
                    .find(
                      (product) =>
                        !!product.produto_promocao &&
                        product.produto_promocao.filter(
                          (produto_promocao) =>
                            produto_promocao.produto !== null,
                        ).length > 0,
                    ) && (
                    <MotorcycleIcon
                      style={{
                        color: '#35BA83',
                        position: 'absolute',
                        marginLeft: '10px',
                        marginTop: '8px',
                      }}
                    />
                  )}
                </Badge>
              ) : (
                <LocalMallIcon />
              ))}
            </IconButton>
          )}
        </div>
      </Toolbar>
      {openCart && (
        <Cart
          handleCloseCart={handleCloseCart}
          openCart={openCart}
          closed={company?.aberto ? !isOpen : true}
          companyId={company?.id.toString()}
        />
      )}
    </AppBar>
  );
};
