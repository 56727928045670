import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listTitle: {
    fontSize: '18px',
    fontWeight: 'normal',
    textAlign: 'center',
  },
  listItem: {
    paddingRight: '5px',
    paddingLeft: '5px'
  },
  divisor: {
    marginBottom: '10px',
    [theme.breakpoints.up(960)]: {
      marginBottom: '15px'
    },
  },
  addAddressIcon:{
    width: '50px',
    height: '50px',
    color: '#f57c00'
  }
}));
