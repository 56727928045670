/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { CardMedia, Typography } from '@material-ui/core';
import { IProduto } from '../../interfaces/produto';
import { IProdutoGrade } from '../../interfaces/produtoGrade';
import stylesMaterial from './stylesMaterial';
import { OutOfStockTag } from '../offer-group/offer-card/styles';
import { Context } from '../../hooks/cartStore';

interface CustomImageProps {
  product: IProduto;
  featuredProduct?: IProdutoGrade;
}

export const CustomFigure: React.FC<CustomImageProps> = (props) => {
  const { product, featuredProduct } = props;

  const { state, dispatch } = useContext(Context);

  const classes = stylesMaterial();

  const [productPromoIds, setProductPromoIds] = useState<number[]>([]);

  useEffect(() => {
    const cart = state.carts.find((_cart) => _cart.id === product.empresa_id);

    if (cart) {
      const promocoes = cart.cart
        .map((item) => item.prodComposto.produto.promocoes)
        .filter((promo) => !!promo);
      if(promocoes && promocoes.length > 0) {
        const promoProducts = promocoes
        .reduce((prev, next) => prev.concat(next))
        .map((promo) => promo.produto_promocao.map((prod) => prod.produto_id));

        if(promoProducts && promoProducts.length > 0) {
          setProductPromoIds(
            promoProducts.reduce((prev, next) => prev.concat(next)),
          );
        }
      } else {
        setProductPromoIds([]);
      }
    }
  }, [state.carts]);

  return (
    <CardMedia
      image={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${
        featuredProduct?.imagens?.length || false
          ? featuredProduct!.imagens.find((img) => img.ordem === 1)?.imagem
          : product.imagem
      }`}
      className={classes.media}
    >
      {product.estoque &&
        product.estoque.ativo &&
        (product.controla_grade === 0
          ? product.estoque.estoque_atual == 0
          : product.produto_grades
              .map((_product) => _product.estoque.estoque_atual == 0)
              .reduce((prev, next) => prev && next)) && (
          // eslint-disable-next-line react/jsx-indent
          <OutOfStockTag className={classes.outOfStock}>
            <Typography className={classes.stripe}>ESGOTADO</Typography>
          </OutOfStockTag>
        )}
      {product.produto_promocao &&
      product.produto_promocao.filter(
        (produto_promocao) =>
          produto_promocao.primario &&
          Number(product.preco?.valor || product?.minGradePreco?.valor) >=
            +produto_promocao.promocao.valor_minimo,
      ).length > 0 ? (
        product.produto_promocao.find(
          ({ promocao }) => promocao.tipo_valor === 2,
        ) ? (
          <>
            <OutOfStockTag>
              <Typography className={classes.stripe}>FRETE GRÁTIS</Typography>
            </OutOfStockTag>
            {product.produto_promocao.find(
              ({ promocao }) => promocao.tipo_valor === 2,
            )?.promocao.primeira_compra && (
              <OutOfStockTag>
                <Typography className={classes.stripe}>NA PRIMEIRA COMPRA</Typography>
              </OutOfStockTag>
            )}
          </>
        ) : (
          <>
            <OutOfStockTag>
              <Typography className={classes.stripe}>
                DESCONTO NO FRETE
              </Typography>
            </OutOfStockTag>
            {product.produto_promocao[0].promocao.primeira_compra && (
              <OutOfStockTag>
                <Typography className={classes.stripe}>NA PRIMEIRA COMPRA</Typography>
              </OutOfStockTag>
            )}
          </>
        )
      ) : (
        productPromoIds.includes(product.id) && (
          <OutOfStockTag>
            <Typography className={classes.stripe}>
              ACEITO NA PROMOÇÃO
            </Typography>
          </OutOfStockTag>
        )
      )}
    </CardMedia>
  );
};
