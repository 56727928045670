import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  background-color: #fff;
  width: 100%;

  @media(max-width: 649px) {
    padding: 0 5px;
  }
`;

export const Cards = styled.div`
  padding: 25px 10px;

  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;

  @media(max-width: 768px) {
    gap: 15px;
  }
  @media(max-width: 580px) {
    gap: 10px;
  }
  @media(max-width: 425px) {
    column-gap: 5px;
    row-gap: 15px;
    padding-inline: 5px;
  }
`;

export const TitleGroup = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const LineTitle = styled.div`
  flex-grow: 1;
  border-bottom: 0.2px solid gray;
  max-width: 80px;
`;

export const LinkText = styled.a<{ showAll: boolean }>`
  color: #0c65ea;
  text-decoration: none;
  position: relative;
  font-size: 0.9rem;
  font-weight: 500;
  border-bottom: 1px solid #0c65ea;
`;
