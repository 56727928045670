import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 15px 15px 15px 15px;
`;

export const Logo = styled.img`
  border-radius: 50%;
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);

  width: 120px;
  height: 120px;

  @media (max-width: 1080px){
    width: 100px;
    height: 100px;
  }
  
  @media (max-width: 720px){
    width: 80px;
    height: 80px;
  }
`;

export const Informations = styled.div`
  display: flex;
  margin-left: 25px;

  column-gap: 15px;
  row-gap: 5px;

  flex-wrap: wrap;
`;