import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  info:{
    marginTop: '25px'
  },
  form: {
    width: '100%'
  },
  emailInput: {
    width: '100%',
    marginTop: '35px'
  },
  passwordInput: {
    width: '100%',
    marginTop: '20px',
  },
  errorMessage: {
    fontSize: '12px',
    letterSpacing: '0.05rem',
    paddingLeft: '0.25rem',
    color: '#B22222',
    marginTop: 1
  },
  continueButton:{
    color: '#fefefe',
    backgroundColor: '#ef9a9a ',
    height: '100%',
    width: '100%',
    '&:hover': {
      backgroundColor: '#B22222',
      transition: 'all 150ms ease 150ms'
    }
  },
  linkContinueButton:{
    height: '40px',
    width: '500px',
    marginTop: '50px',
    textDecoration: 'none',
    [theme.breakpoints.down(700)]: {
      width: '80%'
    },
  },
  buttonWithIcon:{
    '& svg':{
      marginRight: '5px'
    }
  },
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  loginTitle: {
    fontSize: '1.2rem'
  },
  link: {
    color: '#B22222',
    fontSize: '1rem',
    marginTop: '30px',
  },
  linkTransition:{
    transition: 'all 0.35s ease-in-out',
    '&:hover':{
      color: '#FF0000',
      textDecoration: 'none'
    },
    // '&:after':{
    //   content: '""',
    //   position: 'absolute',
    //   left: 0,
    //   bottom: '-1px',

    //   height: '1px',
    //   width: 0,

    //   background: '#FF0000',

    //   transition: 'width 0.35s ease-in-out'
    // },
    // '&:hover::after':{
    //   width: '100%'
    // }
  },
}));
