import React, { useContext, useEffect, useState } from 'react';

import _, { toNumber } from 'lodash';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt-BR';
import 'moment/locale/pt-br';
import 'react-datepicker/dist/react-datepicker.css';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import EventIcon from '@material-ui/icons/Event';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import CompanyAddressGroup from '../../../new-components/company-address-group';
import SaleAddressGroup from '../../../new-components/sale-address-group';
import ScheduledDeliveryModal from '../../../new-components/scheduled-options';

import { IEnderecoEntrega } from '../../../interfaces/enderecoEntrega';

import { AuthContext } from '../../../hooks/provideAuth';
import empresaApi from '../../../services/empresaApi';
import pedidoApi, { Shipping } from '../../../services/pedidoApi';
import userApi from '../../../services/userApi';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { handleResponse } from '../../../helpers/handleResponse';
import { useTheme } from '../../../hooks/useThemeState';
import { ITurno } from '../../../interfaces/diasHorarios';
import { SelectorRM } from './SelectorRM';
import {
  ButtonRow,
  Container,
  ContainerAgendamento,
  Content,
  FirstColumn,
  InvalidDateWarning,
  ReceivingMethod,
  ScheduledDelivery,
  ScheduledDeliveryOptions,
  Warning,
} from './styles';
import stylesMaterial from './stylesMaterial';
import { SelectShippingModal } from '../../../new-components/select-shipping-modal';
import { IItemCarrinho } from '../../../interfaces/itemCarrinho';
import LoginModal from '../../../components/login-modal';
import { ISchedule, IScheduleItem } from '../../../interfaces/schedule';
import { IHorario } from '../../../interfaces/horario';
import { utcToZonedTime } from 'date-fns-tz';
import { format, isWithinInterval } from 'date-fns';

interface EntregaProps {
  handleNext: () => void;
  handleBack: () => void;
  selectedAddress: number;
  selectAddress: (id: number) => void;
  valueRM: string;
  selectRM: (RM: string) => void;
  handleDeliveryTax: (tax: number) => void;
  selectedCompany: number;
  setSelectedCompanie: (selected: number) => void;
  setScheduledDelivery: (data: Date | null) => void;
  underMinimum: boolean;
  inDeliveryRange: boolean;
  handleInDeliveryRange: (is: boolean) => void;
  closed: boolean;
  companieId: number;
  deliveryTax: number;
  scheduledDelivery: Date | null;
  isClothesStore: boolean;
  withdrawOptions: string;
  setWithdrawOptions: (option: string) => void;
  selectedShipping: Shipping | null;
  setSelectedShipping: (value: Shipping) => void;
  setPromotionDelivery: (value: number) => void;
  cart: IItemCarrinho[];
  schedule: IHorario | undefined;
  timezone: string | undefined;
  hasTaxByNeighborhoods: boolean;
}

export const Entrega: React.FC<EntregaProps> = (props) => {
  const {
    selectedAddress,
    selectAddress,
    handleNext,
    handleBack,
    valueRM,
    selectRM,
    handleDeliveryTax,
    selectedCompany,
    setSelectedCompanie,
    setScheduledDelivery,
    underMinimum,
    inDeliveryRange,
    handleInDeliveryRange,
    closed,
    companieId,
    deliveryTax,
    scheduledDelivery,
    isClothesStore,
    withdrawOptions,
    setWithdrawOptions,
    selectedShipping,
    setSelectedShipping,
    cart,
    setPromotionDelivery,
    schedule,
    timezone,
    hasTaxByNeighborhoods,
  } = props;

  const classes = stylesMaterial();
  const moment = require('moment');

  const { state } = useContext(AuthContext);

  const { theme } = useTheme();

  const [cashierIsOpen, setCashierIsOpen] = useState<boolean | null>(null);
  const [enderecos, setEnderecos] = useState<IEnderecoEntrega[]>([]);
  const [scheduledDeliveryDays, setScheduledDeliveryDays] =
    useState<ISchedule>();
  const [selectedDay, setSelectedDay] = useState<any>(new Date(moment.now()));
  const [selectedHour, setSelectedHour] = useState<string>('');
  const [hoursOptionsTemp, setHoursOptionsTemp] = useState<IScheduleItem[]>();
  const [hoursOptions, setHoursOptions] = useState(new Array<string>());
  const [openSDModal, setOpenSDModal] = useState(false);
  const [hasConsumptionOnSite, setHasConsumptionOnSite] = useState(false);
  const [validDate, setValidDate] = useState(true);
  const [hasDeliverFee, setHasDeliveryFee] = useState(false);
  const [hasMelhorEnvio, setHasMelhorEnvio] = useState(false);
  const [openSelectShippingModal, setOpenSelectShippingModal] = useState(false);
  const [shippingOptions, setShippingOptions] = useState<Shipping[]>([]);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [hasDelivery, setHasDelivery] = useState(true);
  const [hasWithdraw, setHasWithdraw] = useState(true);
  const [hasDeliveryParameter, setHasDeliveryParameter] = useState(true);
  const [hasWithdrawParameter, setHasWithdrawParameter] = useState(true);

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (!!schedule) {
      const dias = schedule.dias;
      let todaySchedule: ITurno | undefined;

      const zonedToday = utcToZonedTime(new Date(), timezone || '');
      const weekDay = format(zonedToday, 'iiiiii', {
        locale: pt,
      });

      if (dias[weekDay]) {
        todaySchedule = dias[weekDay].find((time) => {
          const start = time.start.split(':');
          const end = time.end.split(':');

          return isWithinInterval(zonedToday, {
            start: new Date(
              zonedToday.getFullYear(),
              zonedToday.getMonth(),
              zonedToday.getDate(),
              Number(start[0]),
              Number(start[1]),
            ),
            end: new Date(
              zonedToday.getFullYear(),
              zonedToday.getMonth(),
              zonedToday.getDate(),
              Number(end[0]),
              Number(end[1]),
            ),
          });
        });

        if (todaySchedule) {
          setHasDelivery(
            typeof todaySchedule.delivery === 'boolean'
              ? todaySchedule.delivery
              : true,
          );
          setHasWithdraw(
            typeof todaySchedule.retirada === 'boolean'
              ? todaySchedule.retirada
              : true,
          );
        }
      }
    }
  }, [schedule]);

  useEffect(() => {
    if (hasDeliverFee && hasDelivery) {
      selectRM('delivery');
    }
  }, [hasDeliverFee, hasDelivery]);

  useEffect(() => {
    handleGetEnderecos();
  }, [state.token, state.tokenLogin]);

  useEffect(() => {
    setDefaultLocale('pt-br');
    registerLocale('pt-br', pt);

    if (companieId) {
      empresaApi
        .getScheduledDelivery(toNumber(companieId))
        .then((days) => {
          if (days !== undefined) {
            setScheduledDeliveryDays(days);
          }
        })
        .catch((error) => {
          // console.log(error);
        });

      empresaApi
        .getHasDeliveryFee(Number(companieId))
        .then((res) => {
          setHasDeliveryFee(res.has);
        })
        .catch((error) => {
          console.log('HasDeliveryFee', error);
        });

      empresaApi
        .getParametro(companieId.toString(), 'consumo_local')
        .then((res) => {
          const role = JSON.parse(res.role);
          if (role) {
            if (role.status) {
              setHasConsumptionOnSite(true);
            }
          }
        })
        .catch((err) => console.log(err));

      empresaApi
        .getCashierParentCompany(companieId)
        .then((res) => {
          if (res.dataFechamento == null) {
            setCashierIsOpen(true);
          } else {
            setCashierIsOpen(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCashierIsOpen(false);
        });
    }

    empresaApi
      .getParametro(companieId.toString(), 'best_shipping_services')
      .then((res) => {
        const role = JSON.parse(res.role);
        if (role) {
          if (role.active) {
            setHasMelhorEnvio(true);
          }
        }
      })
      .catch(() => {
        setHasMelhorEnvio(false);
      });

    empresaApi
      .getParametro(companieId.toString(), 'delivery_enabled')
      .then((res) => {
        const role = JSON.parse(res.role);
        if (role) {
          if (typeof role.status === 'boolean') {
            setHasDeliveryParameter(role.status);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    empresaApi
      .getParametro(companieId.toString(), 'withdraw_enabled')
      .then((res) => {
        const role = JSON.parse(res.role);
        if (role) {
          if (typeof role.status === 'boolean') {
            setHasWithdrawParameter(role.status);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [companieId]);

  useEffect(() => {
    if (selectedHour === 'Padrão') {
      setSelectedDay(new Date());
    }
  }, [selectedHour]);

  useEffect(() => {
    if (selectedDay !== undefined && scheduledDeliveryDays !== undefined) {
      switch (moment(selectedDay).format('dddd')) {
        case 'domingo':
          if (scheduledDeliveryDays.dom !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.dom.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
        case 'segunda-feira':
          if (scheduledDeliveryDays.seg !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.seg.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
        case 'terça-feira':
          if (scheduledDeliveryDays.ter !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.ter.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
        case 'quarta-feira':
          if (scheduledDeliveryDays.qua !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.qua.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
        case 'quinta-feira':
          if (scheduledDeliveryDays.qui !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.qui.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
        case 'sexta-feira':
          if (scheduledDeliveryDays.sex !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.sex.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
        case 'sábado':
          if (scheduledDeliveryDays.sab !== undefined) {
            setHoursOptionsTemp(scheduledDeliveryDays.sab.flat());
          } else {
            setHoursOptionsTemp([]);
          }
          break;
      }
    }
  }, [selectedDay, scheduledDeliveryDays]);

  useEffect(() => {
    const format = 'HH:mm';

    if (hoursOptionsTemp !== undefined) {
      if (hoursOptionsTemp.length > 0) {
        const temp = hoursOptionsTemp
          .filter((schedule) => {
            if (valueRM === 'retirada') {
              return typeof schedule.retirada === 'boolean'
                ? schedule.retirada
                : true;
            } else {
              return typeof schedule.delivery === 'boolean'
                ? schedule.delivery
                : true;
            }
          })
          .sort((a, b) => moment(a.time, format) - moment(b.time, format));
        const day = _.toNumber(moment(moment.now()).format('DD'));
        const month = _.toNumber(moment(moment.now()).format('MM'));
        const day2 = selectedDay.getDate();
        const month2 = selectedDay.getMonth() + 1;
        if (day === day2 && month === month2) {
          const temp2 = temp
            .filter((option) =>
              moment(moment.now()).isBefore(moment(option.time, format)),
            )
            .map((option) => option.time);
          setHoursOptions(temp2);
        } else {
          setHoursOptions(temp.map((option) => option.time));
        }
      } else {
        setHoursOptions([]);
      }
    }
  }, [hoursOptionsTemp, valueRM]);

  useEffect(() => {
    if (valueRM === 'delivery') {
      enderecos.map((endereco) => {
        if (endereco.principal === true && selectedAddress === -1) {
          selectAddress(endereco.id || -1);
        }
      });
    } else {
      selectAddress(-1);
    }
  }, [valueRM]);

  useEffect(() => {
    if (selectedAddress !== -1 && hasDeliverFee) {
      selectRM('delivery');

      if (state.token) {
        pedidoApi
          .getTaxaEntrega(
            state.token,
            _.toNumber(companieId),
            selectedAddress,
            cart,
          )
          .then((res) => {
            let temp: number = res.valor || 0;

            if (temp === 0) {
              handleInDeliveryRange(false);

              if (hasMelhorEnvio) {
                handleQuoteOptions();
              }
            } else {
              setShippingOptions([]);
              handleInDeliveryRange(true);
              setSelectedCompanie(res.empresaId);
            }

            if (res.valor_desconto) {
              setPromotionDelivery(res.valor_desconto);
            } else {
              setPromotionDelivery(0);
            }

            handleDeliveryTax(temp);
          })
          .catch((error) => {
            console.log('Erro ao acesar taxa de entrega com token');
          });
      } else {
        pedidoApi
          .getTaxaEntregaWithoutToken(
            state.tokenLogin,
            _.toNumber(companieId),
            selectedAddress,
            cart,
          )
          .then((res) => {
            let temp: number = res.valor || 0;

            if (temp === 0) {
              handleInDeliveryRange(false);

              if (hasMelhorEnvio) {
                handleOpenLoginModal();
              }
            } else {
              handleInDeliveryRange(true);
              setSelectedCompanie(res.empresaId);
            }

            if (res.valor_desconto) {
              setPromotionDelivery(res.valor_desconto);
            } else {
              setPromotionDelivery(0);
            }

            handleDeliveryTax(temp);
          })
          .catch((error) => {
            console.log('Erro ao acesar taxa de entrega com login');
          });
      }
    } else {
      handleDeliveryTax(Number(0));
    }
  }, [
    selectedAddress,
    hasDeliverFee,
    hasMelhorEnvio,
    state.token,
    enderecos,
    cart,
  ]);

  useEffect(() => {
    if (scheduledDelivery === null) {
      setValidDate(true);
    } else {
      setValidDate(!moment(scheduledDelivery).isBefore(moment(moment.now())));
    }
  }, [scheduledDelivery]);

  useEffect(() => {
    const pizza = cart.find(
      (item) => item.prodComposto.produto.controla_grade === 2,
    );
    const productWoForm = cart.find((item) => {
      const product = item.prodComposto.produto;

      if (product.controla_grade === 0 || product.controla_grade === 1) {
        return (
          !product.produto_dimensao ||
          (!product.produto_dimensao.altura &&
            !product.produto_dimensao.comprimento &&
            !product.produto_dimensao.largura &&
            !product.produto_dimensao.peso)
        );
      } else {
        return false;
      }
    });

    if (hasMelhorEnvio && !inDeliveryRange) {
      if (pizza) {
        toast.error(
          `O produto ${pizza.prodComposto.produto.descricao} não pode ser enviado para o endereço`,
          toast_config,
        );
      } else if (productWoForm) {
        toast.error(
          `O produto ${productWoForm.prodComposto.produto.descricao} não pode ser enviado para o endereço`,
          toast_config,
        );
      }
    }
  }, [hasMelhorEnvio, inDeliveryRange, cart]);

  useEffect(() => {
    if (cart.length > 0 && hasMelhorEnvio) {
      handleQuoteOptions();
    }
  }, [cart]);

  function handleQuoteOptions() {
    const pizza = cart.find(
      (item) => item.prodComposto.produto.controla_grade === 2,
    );
    const productWoForm = cart.find((item) => {
      const product = item.prodComposto.produto;

      if (product.controla_grade === 0 || product.controla_grade === 1) {
        return (
          !product.produto_dimensao ||
          (!product.produto_dimensao.altura &&
            !product.produto_dimensao.comprimento &&
            !product.produto_dimensao.largura &&
            !product.produto_dimensao.peso)
        );
      } else {
        return false;
      }
    });

    if (!pizza && !productWoForm) {
      if (
        selectedAddress > 0 &&
        enderecos.length &&
        companieId >= 0 &&
        cart.length &&
        hasMelhorEnvio
      ) {
        const address = enderecos.find((adr) => adr.id === selectedAddress);
        const products = cart.map((item) => {
          return {
            id: !item.produtoGrade ? item.prodComposto.produto.id : null,
            quantity: item.quantidade,
            gridId: item.produtoGrade ? item.produtoGrade.id : null,
          };
        });

        if (address && address.endereco.cep[0] !== '*') {
          pedidoApi
            .getQuoteOptions(products, address.endereco.cep, companieId)
            .then((res) => {
              if (Array.isArray(res)) {
                setShippingOptions(res);
              } else {
                if (!res.error) {
                  setShippingOptions([res]);
                } else {
                  toast.error(res.error, toast_config);
                }
              }
              handleOpenSelectShippingModal();
            })
            .catch((error) => console.log(error));
        }
      }
    }
  }

  const handleConclusion = () => {
    if (
      selectedDay !== undefined &&
      selectedHour !== '' &&
      selectedHour !== 'Padrão'
    ) {
      const mDay = moment(selectedDay);
      const [hours, minutes] = selectedHour.split(':');
      const day = _.toNumber(mDay.format('DD'));
      const month = _.toNumber(mDay.format('MM'));
      const year = _.toNumber(mDay.format('YYYY'));
      setScheduledDelivery(
        new Date(year, month - 1, day, _.toNumber(hours), _.toNumber(minutes)),
      );
    } else {
      setScheduledDelivery(null);
    }
    handleCloseSDModal();
  };

  const handleOpenSDModal = () => {
    setOpenSDModal(true);
  };

  const handleCloseSDModal = () => {
    setOpenSDModal(false);
    if (selectedHour === 'Padrão') {
      if (
        moment(selectedDay).format('DD/MM/YYYY') !==
        moment(moment.now()).format('DD/MM/YYYY')
      ) {
        setSelectedDay(new Date(moment.now()));
      }
    }
  };

  const handleOpenSelectShippingModal = () => {
    setOpenSelectShippingModal(true);

    if (!state.token) {
      handleOpenLoginModal();
    }
  };

  const handleCloseSelectShippingModal = () => {
    setOpenSelectShippingModal(false);
  };

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleSelectedDay = (value: any) => {
    setSelectedDay(value);
  };

  const handleSelectedHour = (value: string) => {
    setSelectedHour(value);
  };

  const handleGetEnderecos = () => {
    if (state.token) {
      userApi
        .getEnderecos(state.token)
        .then((res) => {
          setEnderecos(res.data);
          res.data.map((endereco: IEnderecoEntrega) => {
            if (endereco.principal === true && selectedAddress === -1) {
              selectAddress(endereco.id || -1);
            }
          });
        })
        .catch((error) => {
          handleResponse(error);
        });
    } else if (state.tokenLogin) {
      userApi
        .getEnderecosWithoutLogin(state.tokenLogin)
        .then((res) => {
          setEnderecos(res.data);
          res.data.map((endereco: IEnderecoEntrega) => {
            if (endereco.principal === true && selectedAddress === -1) {
              selectAddress(endereco.id || -1);
            }
          });
        })
        .catch((error) => {
          handleResponse(error);
        });
    } else {
      handleBack();
    }
  };

  const handleNextStep = () => {
    if (enderecos.length === 0 && valueRM === 'delivery') {
      toast.error('Cadastre um endereço para prosseguir', toast_config);
    } else if (selectedAddress === -1 && valueRM === 'delivery') {
      toast.error('Selecione um endereço para prosseguir', toast_config);
    } else {
      handleNext();
    }
  };

  return (
    <Container>
      <Content>
        <FirstColumn>
          <ReceivingMethod>
            <Typography className={classes.rMText}>
              Método de Recebimento
            </Typography>
            <SelectorRM
              valueRM={valueRM}
              selectRM={selectRM}
              isClothesStore={isClothesStore}
              hasDelivery={
                !!scheduledDeliveryDays && !selectedShipping
                  ? hasDeliveryParameter
                  : hasDeliveryParameter && hasDelivery && hasDeliverFee
              }
              hasWithdraw={
                !!scheduledDeliveryDays && !selectedShipping
                  ? hasWithdrawParameter
                  : hasWithdrawParameter && hasWithdraw
              }
            />

            {hasDeliverFee &&
              hasDelivery &&
              !hasWithdraw &&
              hasWithdrawParameter &&
              valueRM === 'retirada' &&
              !!scheduledDeliveryDays &&
              !selectedShipping && (
                <InvalidDateWarning>
                  <WarningIcon style={{ color: 'red' }} />
                  <Typography variant="body1" style={{ color: 'red' }}>
                    Retirada só está disponível para agendamentos
                  </Typography>
                </InvalidDateWarning>
              )}

            {!(hasDeliverFee && hasDelivery) &&
              hasWithdraw &&
              hasDeliveryParameter &&
              valueRM === 'delivery' &&
              !!scheduledDeliveryDays &&
              !selectedShipping && (
                <InvalidDateWarning>
                  <WarningIcon style={{ color: 'red' }} />
                  <Typography variant="body1" style={{ color: 'red' }}>
                    Envio só está disponível para agendamentos
                  </Typography>
                </InvalidDateWarning>
              )}

            {hasConsumptionOnSite && valueRM === 'retirada' && (
              <RadioGroup
                aria-label="opcoesRetirada"
                name="opcoesRetirada"
                value={withdrawOptions}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setWithdrawOptions((event.target as HTMLInputElement).value)
                }
                style={{ color: theme.primary }}
                className={classes.consumptionOnSite}
              >
                <FormControlLabel
                  value="default"
                  control={<Radio style={{ color: theme.primary }} />}
                  label="Retirada no balcão"
                />
                <FormControlLabel
                  value="onSite"
                  control={<Radio style={{ color: theme.primary }} />}
                  label="Consumo no local"
                />
              </RadioGroup>
            )}

            {hasMelhorEnvio && !inDeliveryRange && (
              <Button
                onClick={handleOpenSelectShippingModal}
                className={classes.selectShipping}
              >
                Selecionar frete
              </Button>
            )}
          </ReceivingMethod>

          {!!scheduledDeliveryDays && !selectedShipping ? (
            <ScheduledDelivery>
              {scheduledDelivery !== null && (
                <Typography className={classes.scheduled}>
                  Agendamento
                </Typography>
              )}
              {scheduledDelivery === null ? (
                <ContainerAgendamento
                  isClothesStore={isClothesStore}
                  isClosed={!!(closed && cashierIsOpen)}
                  isClosedUndetermined={cashierIsOpen === false}
                >
                  {cashierIsOpen !== null && (
                    <>
                      <p className="title_closed">
                        Estamos fechado. Agende seu pedido abaixo.
                      </p>
                      <p className="title_closed_undetermined">
                        Estamos fechado temporariamente. Tente mais tarde.
                      </p>
                    </>
                  )}
                  <button
                    disabled={cashierIsOpen === false}
                    onClick={() => {
                      handleOpenSDModal();
                    }}
                  >
                    <EventIcon className="calendar" />
                    <p>
                      Agendar {valueRM === 'delivery' ? 'Envio' : 'Retirada'}
                    </p>
                    <ArrowForwardIosIcon />
                  </button>
                </ContainerAgendamento>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleOpenSDModal}
                  className={classes.sDInfosButton}
                >
                  <ScheduledDeliveryOptions>
                    {moment(scheduledDelivery).format('DD/MM/YYYY') !==
                    moment(moment.now()).format('DD/MM/YYYY') ? (
                      <Typography className={classes.dayText}>
                        {moment(scheduledDelivery).format('DD/MM/YYYY')}
                      </Typography>
                    ) : (
                      <Typography className={classes.dayText}>Hoje</Typography>
                    )}
                    <Typography className={classes.hourText}>
                      {`${selectedHour}${
                        valueRM === 'delivery' ? ` - R$ ${deliveryTax}` : ''
                      }`}
                    </Typography>
                  </ScheduledDeliveryOptions>
                  <KeyboardArrowDownOutlinedIcon style={{ color: '#0c65ea' }} />
                </Button>
              )}
              {!validDate && (
                <InvalidDateWarning>
                  <WarningIcon style={{ color: 'red' }} />
                  <Typography variant="body1" style={{ color: 'red' }}>
                    Você selecionou uma data inválida
                  </Typography>
                </InvalidDateWarning>
              )}
            </ScheduledDelivery>
          ) : (
            <div style={{ display: 'none' }} />
          )}
          {!inDeliveryRange && valueRM === 'delivery' && !hasMelhorEnvio && (
            <Warning>
              <WarningIcon color="secondary" />
              <Typography variant="body1">
                Este endereço está fora da área de entrega!
              </Typography>
            </Warning>
          )}
        </FirstColumn>

        <Divider className={classes.divider} />

        {valueRM === 'delivery' ? (
          <SaleAddressGroup
            selectedAddress={selectedAddress}
            selectAddress={selectAddress}
            enderecos={enderecos}
            getAddresses={handleGetEnderecos}
            companieId={companieId}
            hasTaxByNeighborhoods={hasTaxByNeighborhoods}
          />
        ) : (
          <CompanyAddressGroup
            selectedCompany={selectedCompany}
            selectCompany={setSelectedCompanie}
            companyId={companieId}
          />
        )}
      </Content>
      {openSDModal && (
        <ScheduledDeliveryModal
          handleCloseSDModal={handleCloseSDModal}
          openSDModal={openSDModal}
          handleConclusion={handleConclusion}
          selectedHour={selectedHour}
          selectedDay={selectedDay}
          setSelectedDay={handleSelectedDay}
          setSelectedHour={handleSelectedHour}
          hoursOptions={hoursOptions}
          valueRM={valueRM}
          deliveryTax={deliveryTax}
          isOpen={!closed}
          hasDeliveryNow={hasDeliverFee && hasDelivery && hasDeliveryParameter}
          hasWithdrawNow={hasWithdraw && hasWithdrawParameter}
        />
      )}
      {openSelectShippingModal && shippingOptions.length > 0 && (
        <SelectShippingModal
          handleCloseSelectShippingModal={handleCloseSelectShippingModal}
          openSelectShippingModal={openSelectShippingModal}
          selectedShipping={selectedShipping}
          setSelectedShipping={setSelectedShipping}
          shippingOptions={shippingOptions}
        />
      )}
      {openLoginModal && (
        <LoginModal
          handleCloseLoginModal={handleCloseLoginModal}
          openLoginModal={openLoginModal}
          handleCloseDrawer={() => {}}
          companieId={companieId}
        />
      )}
      <ButtonRow>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBack}
          className={classes.backButton}
          style={{
            width: '200px',
            height: '40px',
            marginTop: '30px',
            alignSelf: 'flex-end',
            border: `1px solid ${theme.primary}`,
            color: theme.primary,
            background: 'transparent',
          }}
        >
          Voltar
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={
            (underMinimum && valueRM === 'delivery') ||
            (!inDeliveryRange && valueRM === 'delivery' && !selectedShipping) ||
            closed ||
            !validDate ||
            cashierIsOpen === false ||
            (!selectedHour && (!hasDelivery && valueRM === 'delivery' || !hasWithdraw && valueRM === 'retirada'))
          }
          onClick={handleNextStep}
          className={classes.nextButton}
          style={{ backgroundColor: theme.primary }}
        >
          Próximo
        </Button>
      </ButtonRow>
    </Container>
  );
};
