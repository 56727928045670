import React, { useContext, useState, useEffect } from "react";

import _ from 'lodash';

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import 'react-toastify/dist/ReactToastify.css';

import { 
  Container,
  Header,
  Scroll,
  MapWrapper,
  Footer
} from './styles';
import stylesMaterial from './stylesMaterial';
import { googleLoader } from "../../utils/googleLoader";
import { useTheme } from "../../hooks/useThemeState";

interface MapModalProps{
  handleCloseMapModal: () => void;
  openMapModal: boolean;
  latLong: {lat: number, long: number};
  register: (lat: number, long: number) => void;
}

const MapModal: React.FC<MapModalProps> = (props: MapModalProps) => {
  const {
    handleCloseMapModal, 
    openMapModal, 
    latLong,
    register
  } = props;
  
  const classes = stylesMaterial();
  
  const { theme } = useTheme();

  const [open, setOpen] = useState(openMapModal);
  const [isLoading, setIsLoading] = useState(true);

  let map: google.maps.Map;
  let marker: google.maps.Marker;
  let circle: google.maps.Circle;
  let infoWindow: google.maps.InfoWindow;
  let geocoder: google.maps.Geocoder;

  const [lat, setLat] = useState(latLong.lat || undefined);
  const [lng, setLng] = useState(latLong.long || undefined);

  useEffect(() =>{
    googleLoader.load().then(() => {
      const acceptedRadius = 500;

      let myLatlng = new google.maps.LatLng(latLong.lat,latLong.long);

      map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: myLatlng,
        zoom: 16,
      });

      marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        draggable:true,
        title:"Localização"
      });

      circle = new google.maps.Circle({
        strokeColor: "blue",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "blue",
        fillOpacity: 0.35,
        map: map,
        center: myLatlng,
        radius: acceptedRadius,
      });

      

      infoWindow = new google.maps.InfoWindow();

      geocoder = new google.maps.Geocoder();

      let markerPosition: google.maps.LatLng | null | undefined;

			marker.addListener('dragstart', () => {
				markerPosition = marker.getPosition();
			});

      marker.addListener("dragend", () => {
        const position = marker.getPosition();
        if(position){
          const distance = google.maps.geometry.spherical.computeDistanceBetween(myLatlng, position);
          if(distance > acceptedRadius) {
            marker.setPosition(markerPosition);
          } else {
            setLat(marker.getPosition()?.lat());
            setLng(marker.getPosition()?.lng());
          }
        }
      });

      setIsLoading(false);
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseMapModal();
  };

  const confirmAddress = () => {
    if ((lat !== undefined) && (lng !== undefined)){
      register(lat, lng);
      handleClose();
    }
  };
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <span className={classes.span} />
          <div>
            <Typography 
              variant="h6" 
              className={classes.title}
            >
              Confirmar endereço
            </Typography>
          </div>
          <IconButton 
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon  
              style={{ color: '#3f51b5' }}
            />
          </IconButton>
        </Header>
        <Divider className={classes.titleDivider} />
        <Typography 
          variant="subtitle1"
          className={classes.subtitle}
        >
          Mova o marcador para o endereço certo antes de confirmar:
        </Typography>
        
        <Scroll>
          <MapWrapper id='map'>

          </MapWrapper>
        </Scroll>
        
        <React.Fragment>
          <Footer >
            <Button 
              variant="contained" 
              disabled={isLoading}
              onClick={confirmAddress}
              style={{ 
                backgroundColor: '#3f51b5', 
                color: '#fefefe', 
                height: '45px',
                width: '90%'
              }}
            >
              Concluir
            </Button>
            <Button 
              variant="contained"
              onClick={handleClose}
              style={{ 
                background: 'transparent',
                border: `1px solid ${theme.primary}`,
                color: theme.primary,  
                height: '45px',
                marginTop: '10px',
                width: '90%'
              }}
            >
              Voltar
            </Button>
          </Footer>
          <div className={classes.offset1} />
        </React.Fragment>

         
      </Container>

    </Modal>
  )
}

export default MapModal;