import React, { useContext, useState } from "react";

import { useParams } from "react-router-dom";
import _ from 'lodash';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { Context } from '../../../hooks/cartStore';
import { IItemCarrinho } from '../../../interfaces/itemCarrinho';

import {
  Container,
  Header,
  Body,
  Quantidade,
  Note,
  Footer
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../../hooks/useThemeState";

interface CartProps{
  handleCloseEditionModal: () => void;
  openEditionModal: boolean;
  item: IItemCarrinho;
  setQtd: React.Dispatch<React.SetStateAction<number>>;
  companieId: string | undefined;
}

export const ItemEditModal: React.FC<CartProps> = ({ handleCloseEditionModal, openEditionModal, item, setQtd, companieId }) => {
  const { theme } = useTheme()
  const classes = stylesMaterial();
  const [open, setOpen] = useState(openEditionModal);
  const [observacao, setObservacao] = useState(item.observacao);
  const [quantidade, setQuantidade] = useState(item.quantidade);

  const { state, dispatch } = useContext(Context);

  const handleEdition = () => {
    dispatch({type: 'EDIT_ITEM', empresaId: _.toNumber(companieId), id: item.id, obs: observacao, qtd: quantidade});
    setQtd(quantidade);
    handleClose();
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseEditionModal();
  };

  const handleAdition = () => {
    if(item.prodComposto.produto){
      if(item.prodComposto.produto.estoque){
        if(item.prodComposto.produto.estoque.ativo){
          if(quantidade < item.prodComposto.produto.estoque.estoque_atual){
            setQuantidade(quantidade+1);
          }
        } else {
          setQuantidade(quantidade+1);
        }
      } else {
        setQuantidade(quantidade+1);
      }
    }
  }
  const handleRemove = () => {
    if(quantidade>1)
      setQuantidade(quantidade-1);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <span className={classes.span} />
          <div>
            <Typography
              variant="h6"
              className={classes.detailTitle}
            >
              Edição de item
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
            />
          </IconButton>
        </Header>

        <Body>
            <Typography
              variant='body1'
              className={classes.titles}
            >
              Quantidade
            </Typography>
          <Quantidade>
            <IconButton
              aria-label="remove"
              onClick={handleRemove}
            >
              <RemoveIcon
                style={{ color: theme.primary }}
              />
            </IconButton>
            <label className={classes.quantityLabel}> {quantidade} </label>
            <IconButton
              aria-label="add"
              onClick={handleAdition}
            >
              <AddIcon
                style={{ color: theme.primary }}
              />
            </IconButton>
          </Quantidade>

          <Note>
            <Typography
              variant='body1'
              className={classes.titles}
            >
              Observação
            </Typography>
            <TextareaAutosize
              aria-label="observacoes"
              rowsMin={5}
              rowsMax={5}
              placeholder="Escreva observações sobre o produto"
              value={observacao}
              onChange={event => setObservacao(event.target.value)}
              className={classes.textArea}
            />
          </Note>

        </Body>
        <Footer>
          <Button
            aria-label="close"
            onClick={handleEdition}
            style={{
              width: 120,
              height: 40,
              padding: 5,
              marginTop: 15,
              backgroundColor: theme.primary,
              color: '#fefefe'
            }}
          >
            <CheckIcon
              style={{ color: '#fefefe' }}
            />
            Confirmar
          </Button>
        </Footer>

      </Container>

    </Modal>
  )
}
