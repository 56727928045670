import React from 'react';

import { 
  Size,
  SizeText
} from './styles';

interface SizeItemProps {
  selected: boolean;
  size: string;
  disabled: boolean;
  setSelectedSize: (size: string) => void;
}

export const SizeItem: React.FC<SizeItemProps> = (props) => {
  const { selected, size, disabled, setSelectedSize } = props;

  return (
    <Size 
      selected={selected}
      disabled={disabled}
      onClick={() => setSelectedSize(size)}
    >
      <SizeText 
        selected={selected}
        disabled={disabled}
      >
        {size}
      </SizeText> 
    </Size>
  );
}