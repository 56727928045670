import { ICliente } from '../interfaces/cliente';
import { IEnderecoEntrega } from '../interfaces/enderecoEntrega';
import { IUserInfoWithoutLogin } from '../interfaces/userInfoWithoutLogin';

import api from './api';

const userApi = {

  cadastro: async (cliente: ICliente) => {
    const response = await api.post('cliente', cliente);
    return response;
  },
  partialLogin: async (login: string): Promise<{ token: string }> => {
    const { data } = await api.get('cliente/partial-login', {
      params: {
        login
      }
    });
    return data;
  },
  login: async (login: string, password: string): Promise<{ token: string }> => {
    const { data } = await api.post('cliente/login', {login: login, senha: password});
    return data;
  },
  loginSocialMedia: async (token: string, email: string, socialMediaName: string, empresaId: number): Promise<{ token: string }> => {
    const { data } = await api.post('cliente/loginSocial', { token, email, social_media: socialMediaName, empresa_id: empresaId });
    return data;
  },
  getEnderecos: async (token: string) => {
    const res = await api.get('cliente/endereco',{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return res;
  },
  saveEndereco: async (endereco: IEnderecoEntrega, token: string) => {
    const res = await api.post('cliente/endereco',endereco,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return res;
  },
  removeEndereco: async (id: number, token: string) => {
    const res = await api.delete(`cliente/endereco/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return res;
  },
  updateEndereco: async (endereco: IEnderecoEntrega, token: string) => {
    const res = await api.put('cliente/endereco',endereco,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return res;
  },
  requireNewPassword: async (email: string) => {
    const { data } = await api.post('cliente/request-new-password', { email });
    return data;
  },
  changePassword: async (password: string, newPassword: string, token: string) => {
    const res = await api.post('cliente/change-password',{password,newPassword},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return res;
  },
  getUserInfo: async (token: string): Promise<{cnpjCpf: string, razaoSocial: string, fone: string, email: string}> => {
    const { data } = await api.get('cliente/me',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  },
  updateUserInfo: async (name: string, telephone: string, email: string, cnpjCpf: string, token: string) => {
    const response = await api.put('cliente/me', { fone: telephone, razaoSocial: name, email, cnpjCpf},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  },
  getCheckUser: async (login: string) => {
    const { data } = await api.get('cliente/check-exist',{
      params: {
        login
      }
    });
    return data;
  },
  getUserInfoLogin: async (login: string): Promise<IUserInfoWithoutLogin> => {
    const { data } = await api.get('cliente/user-info',{
      params: {
        login
      }
    });
    return data;
  },
  getUserInfoLoginByToken: async (tokenLogin: string): Promise<IUserInfoWithoutLogin> => {
    const { data } = await api.get('cliente/user-info-token',{
      headers: {
        Authorization: `Bearer ${tokenLogin}`
      }
    });
    return data;
  },
  simplifiedRegister: async (email: string, fone: string, name: string, cnpjCpf: string, empresaId: number): Promise<{ token: string }> => {
    const { data } = await api.post('cliente/simple-create', {
      email,
      fone,
      razaoSocial: name,
      cnpjCpf,
      empresaId: Number(empresaId)
    });
    return data;
  },
  getEnderecosWithoutLogin: async (tokenLogin: string) => {
    const res = await api.get('cliente/endereco/without-token',{
      headers: {
        Authorization: `Bearer ${tokenLogin}`
      }
    });
    return res;
  },
  saveEnderecoWithoutLogin: async (endereco: IEnderecoEntrega, tokenLogin: string) => {
    const res = await api.post('cliente/endereco/without-token',endereco,
      {
        headers: {
          tokenLogin,
          Authorization: `Bearer ${tokenLogin}`
        }
      }
    );
    return res;
  },
  requestToken: async (login: string) => {
    const res = await api.post('cliente/request-token', { login });
    return res;
  },
  loginWithToken: async (login: string, token: string): Promise<{ token: string }> => {
    const { data } = await api.post('cliente/login-with-token', { login, token });
    return data;
  },
};

export default userApi;
