import React from 'react';

import CartStore from './cartStore';
import ProvideAuth from './provideAuth';

const AppProvider: React.FC = ({ children }) => (
  <ProvideAuth>
    <CartStore>{children}</CartStore>
  </ProvideAuth>
);

export default AppProvider;
