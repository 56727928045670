import styled from 'styled-components';


export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: calc(calc(100vw - 550px) / 2);
  width: 550px;
  height: 80vh;
  border: '2px solid #000';
  align-self: center;
  background-color: #fefefe;
  border-radius: 0.3rem;
  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
  @media(max-height: 680px){
    height: 100vh;
    margin-top: 0;
  }
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 1px;
  padding-top: 1px;
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  display: -webkit-flex;
  flex-direction: column;
  height: calc(80vh - 45px);
  border-radius: 0.3rem 0.3rem 0 0;
  @media(max-width: 960px){
    height: calc(100vh - 45px);
  }
  @media(max-height: 680px){
    height: calc(100vh - 45px);
  }
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  min-height: max-content;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 45px;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  width: 100%;

  .react-transform-wrapper {
    width: 100%;
    height: auto;

    .react-transform-component {
      width: 100%;
      height: auto;
    }
  }
`;

export const Imagem = styled.img`
  width: 100%;
  height: auto;
`;