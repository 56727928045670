import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import {Link as MUILink} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { IEmpresa } from '../../interfaces/empresa';
import { ISegmento } from '../../interfaces/segmento';
import empresaApi from '../../services/empresaApi';

import logo from "../../assets/atac_delivery.svg";

import CompanieLogoHome from '../../components/company-logo-home';

import stylesMaterial from './stylesMaterial';
import { 
  Container, 
  TopMenu, 
  Body,
  Companies
} from './styles';
import { Footer } from "../../components/footer";

const SegmentCompanies: React.FC = () => {
  const classes = stylesMaterial();

  const { segmentId } = useParams<{ segmentId: string }>();

  const [companies, setCompanies] = useState(new Array<IEmpresa>());
  const [companiesTemp, setCompaniesTemp] = useState(new Array<IEmpresa>());
  const [segments, setSegments] = useState(new Array<ISegmento>());
  const [searchValue, setSearchValue] = useState("");
  let segmentV: ISegmento[] = new Array<ISegmento>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let temp: ISegmento[];
    empresaApi.getGrupos()
      .then((data) => {
        setCompanies(data);
        setCompaniesTemp(data);
        temp=data.map((companie) => companie.seguimento);
        temp.forEach((segmento) => {
          if (!(segmentV.find((segment) => _.isEqual(segment,segmento))))
            segmentV.push(segmento);
        });
        setSegments(segmentV);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    
    handleSearch();
  }

  const handleSearch = () => {
    let compTemp: IEmpresa[];

    compTemp = companies.filter((segment) => segment.razao_social.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
                                            searchValue.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));

    setCompaniesTemp(compTemp);
  }

  return (
    <Container>
      <React.Fragment>
        <AppBar
          position="fixed"
          elevation={2}
          className={classes.appBar}
        >
          <TopMenu>
            <div>
              <span></span>
              <Link to="" >
                <img src={ logo } alt="Atac Delivery"/>
              </Link>
            </div>
            <Paper component="form" onSubmit={handleSubmit} elevation={0} className={classes.paper}>
              <InputBase
                className={classes.input}
                placeholder="Digite sua busca"
                onBlur={() => {handleSearch()}}
                value={searchValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchValue((event.target as HTMLInputElement).value);
                }}
                inputProps={{ 'aria-label': 'search', 'name': 'search' }}
              />
              <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </TopMenu>
        </AppBar>
        <div className={classes.offset1} />
      </React.Fragment>

      <Body>
        <Typography className={classes.segmentName} >
          {
            segments.find((segment) => segment.id === _.toNumber(segmentId))?.descricao
          }
        </Typography>

        <Divider />

        <Companies>
          {
            companiesTemp.map((company) =>{
              if (company.seguimento_id === _.toNumber(segmentId)){
                return <CompanieLogoHome 
                          key={company.id}
                          company={company}
                        />
              }
            })
          }
        </Companies>
      </Body>

      <Footer />
    </Container>
  );
}

export default SegmentCompanies;