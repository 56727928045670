import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  alertText: {
    whiteSpace: 'break-spaces',
    lineHeight: 1,
  },
  alertTextExcludedItems: {
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    textDecoration: 'line-through',
  },
}));
