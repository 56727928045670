import React, { useContext, useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import _ from 'lodash';

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Loader } from '@googlemaps/js-api-loader';
import geographicApi from '../../services/geographicApi';
import userApi from '../../services/userApi';
import { cep } from '../masks';
import MapModal from '../map-modal';

import { AuthContext } from '../../hooks/provideAuth';

import { IEstado } from '../../interfaces/estado';
import { ICidade } from '../../interfaces/cidade';
import { IEmpresa } from '../../interfaces/empresa';
import { IEnderecoEntrega } from '../../interfaces/enderecoEntrega';

import {
  FirstStep,
  Header,
  Scroll,
  UserInfo,
  CurrentLocationButton,
  Footer,
} from './styles';
import stylesMaterial from './stylesMaterial';
import empresaApi from '../../services/empresaApi';

interface IAddressInfo {
  address: string;
  complement: string;
  neighborhood: string;
}

interface DeliveryAddressModalProps {
  handleCloseDeliveryAddressModal: () => void;
  openDeliveryAddressModal: boolean;
  registerSucess: () => void;
  enderecoDefault?: IEnderecoEntrega;
  isEmpty?: boolean;
}

const defaultValues = {
  description: '',
  cep: '',
  address: '',
  complement: '',
  neighborhood: '',
  house_number: '',
  uf: {
    id: 21,
    sigla: 'MA',
    nome: 'Maranhão',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  city: {
    id: 2111300,
    nome: 'São Luís',
    microrregiao: {
      id: 21002,
      nome: 'Aglomeração Urbana de São Luís',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: {
            id: 2,
            sigla: 'NE',
            nome: 'Nordeste',
          },
        },
      },
    },
  },
  principal: 'Não',
};

const DeliveryAddressModal: React.FC<DeliveryAddressModalProps> = ({
  handleCloseDeliveryAddressModal,
  openDeliveryAddressModal,
  registerSucess,
  enderecoDefault,
  isEmpty,
}) => {
  const classes = stylesMaterial();
  const { companieId } = useParams<{ companieId: string }>();

  defaultValues.description = enderecoDefault?.descricao
    ? enderecoDefault.descricao
    : '';
  defaultValues.house_number =
    enderecoDefault?.endereco.numero !== undefined
      ? _.toString(enderecoDefault.endereco.numero)
      : '';
  defaultValues.principal = enderecoDefault?.principal ? 'Sim' : 'Não';
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues,
  });
  const { state, dispatch } = useContext(AuthContext);

  const [open, setOpen] = useState(openDeliveryAddressModal);
  const [cepInput, setCepInput] = useState('');
  const [openMapModal, setOpenMapModal] = useState(false);
  const [companie, setCompanie] = useState<IEmpresa>();
  const [endEntrega, setEndEntrega] = useState<IEnderecoEntrega>();
  const [latLong, setLatLong] = useState<{ lat: number; long: number }>({
    lat: -2.535286,
    long: -44.22638,
  });
  const [addressInfo, setAddressInfo] = useState<IAddressInfo>({
    address: '',
    complement: '',
    neighborhood: '',
  });
  const [houseN, setHouseN] = useState('');
  const [executionNum, setExecutionNum] = useState(0);
  const [selectedState, setSelectedState] = useState<IEstado>(defaultValues.uf);
  const [selectedCity, setSelectedCity] = useState<ICidade>(defaultValues.city);
  const [selectedPrincipal, setSelectedPrincipal] = useState(
    enderecoDefault?.principal ? 'Sim' : isEmpty ? 'Sim' : 'Não',
  );
  const [selectedDescription, setSelectedDescription] = useState(
    enderecoDefault ? enderecoDefault.descricao : 'Casa',
  );
  const [hasError, setHasError] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [alreadyOpened, setAlreadyOpened] = useState(false);
  const [stateOptions, setStateOptions] = useState<IEstado[]>([
    {
      id: 21,
      sigla: 'MA',
      nome: 'Maranhão',
      regiao: {
        id: 2,
        sigla: 'NE',
        nome: 'Nordeste',
      },
    },
  ]);
  const [cityOptions, setCityOptions] = useState<ICidade[]>([
    {
      id: 2111300,
      nome: 'São Luís',
      microrregiao: {
        id: 21002,
        nome: 'Aglomeração Urbana de São Luís',
        mesorregiao: {
          id: 2101,
          nome: 'Norte Maranhense',
          UF: {
            id: 21,
            sigla: 'MA',
            nome: 'Maranhão',
            regiao: {
              id: 2,
              sigla: 'NE',
              nome: 'Nordeste',
            },
          },
        },
      },
    },
  ]);
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  let locationFromMap = false;
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_KEY || '',
    version: 'weekly',
  });
  let geocoder: google.maps.Geocoder;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseDeliveryAddressModal();
  };

  const handleOpenMapModal = () => {
    setAlreadyOpened(true);
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setIsLoading(false);
    setOpenMapModal(false);
  };

  const registerAddress = (
    lat: number,
    long: number,
    endEntregaParam?: IEnderecoEntrega,
  ) => {
    if (endEntregaParam !== undefined) {
      if (enderecoDefault === undefined) {
        if (state.token) {
          userApi
            .saveEndereco(
              {
                ...endEntregaParam,
                lat: _.toNumber(lat),
                lon: _.toNumber(long),
              },
              state.token,
            )
            .then((res) => {
              if (res.status >= 200 && res.status < 300) {
                registerSucess();
                handleClose();
              } else {
                throw new Error(res.status.toString());
              }
            })
            .catch((error) => {
              setIsLoading(false);
              toast.error(
                'Erro no cadastro. Verifique seus dados ou tente mais tarde.',
                toast_config,
              );
              console.log('Erro no cadastro de endereço');
            });
        } else {
          userApi
            .saveEnderecoWithoutLogin(
              {
                ...endEntregaParam,
                lat: _.toNumber(lat),
                lon: _.toNumber(long),
              },
              state.tokenLogin,
            )
            .then((res) => {
              if (res.status >= 200 && res.status < 300) {
                registerSucess();
                handleClose();
              } else {
                throw new Error(res.status.toString());
              }
            })
            .catch((error) => {
              setIsLoading(false);
              toast.error(
                'Erro no cadastro. Verifique seus dados ou tente mais tarde.',
                toast_config,
              );
              console.log('Erro no cadastro de endereço sem token');
            });
        }
      } else {
        const temp = { ...endEntregaParam, lat, lon: long };
        userApi
          .updateEndereco({ ...temp, id: enderecoDefault.id }, state.token)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              registerSucess();
              handleClose();
            } else {
              throw new Error(res.status.toString());
            }
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(
              'Erro na alteração. Verifique seus dados ou tente mais tarde.',
              toast_config,
            );

            // if (error.response){

            //   console.log("response");
            //   console.log(error.response);

            //   }else if(error.request){

            //   console.log("request");
            //   console.log(error.request);

            //   }else if(error.message){

            //   console.log("message")
            //   console.log(error.message);

            //   }
          });
      }
    }
  };

  const onSubmit = (data: any): void => {
    const { house_number, address, neighborhood, city, uf } = data;

    let canRegister = alreadyOpened;

    if (
      endEntrega?.endereco?.cep !== data.cep ||
      endEntrega?.endereco?.bairro !== neighborhood ||
      endEntrega?.endereco?.cidade !== city ||
      endEntrega?.endereco?.estado !== uf
    ) {
      canRegister = false;
    }

    setIsLoading(true);
    setEndEntrega({
      taxaEntregaId: 0,
      principal: data.principal === 'Sim',
      descricao: data.description,
      endereco: {
        cep: data.cep,
        numero: house_number,
        logradouro: address,
        bairro: neighborhood,
        complemento: data.complement,
        cidade: city,
        estado: uf,
      },
    });

    if (canRegister) {
      registerAddress(latLong.lat, latLong.long, {
        taxaEntregaId: 0,
        principal: data.principal === 'Sim',
        descricao: data.description,
        endereco: {
          cep: data.cep,
          numero: house_number,
          logradouro: address,
          bairro: neighborhood,
          complemento: data.complement,
          cidade: city,
          estado: uf,
        },
      });

      return;
    }

    if (
      enderecoDefault?.lat !== undefined &&
      enderecoDefault.lon !== undefined
    ) {
      setLatLong({ lat: enderecoDefault.lat, long: enderecoDefault.lon });
      handleOpenMapModal();
      setIsLoading(false);
    } else {
      geographicApi
        .getLatLong(house_number, address, neighborhood, city, uf)
        .then((res) => {
          if (res.results[0]) {
            setLatLong({
              lat: Number(res.results[0].geometry.location.lat),
              long: Number(res.results[0].geometry.location.lng),
            });
            handleOpenMapModal();
          } else {
            geographicApi
              .getLatLongBairro(data.neighborhood, data.city, data.uf)
              .then((res) => {
                if (res.results[0]) {
                  setLatLong({
                    lat: Number(res.results[0].geometry.location.lat),
                    long: Number(res.results[0].geometry.location.lng),
                  });
                  handleOpenMapModal();
                } else {
                  geographicApi
                    .getLatLongCidade(data.city, data.uf)
                    .then((res) => {
                      if (res.results[0]) {
                        setLatLong({
                          lat: Number(res.results[0].geometry.location.lat),
                          long: Number(res.results[0].geometry.location.lng),
                        });
                        handleOpenMapModal();
                      } else {
                        handleOpenMapModal();
                      }
                    })
                    .catch((error) => {
                      handleOpenMapModal();
                    });
                }
              })
              .catch((error) => {
                geographicApi
                  .getLatLongCidade(data.city, data.uf)
                  .then((res) => {
                    if (res.results[0]) {
                      setLatLong({
                        lat: Number(res.results[0].geometry.location.lat),
                        long: Number(res.results[0].geometry.location.lng),
                      });
                      handleOpenMapModal();
                    } else {
                      handleOpenMapModal();
                    }
                  })
                  .catch((error) => {
                    handleOpenMapModal();
                  });
              });
          }
        })
        .catch((error) => {
          geographicApi
            .getLatLongBairro(data.neighborhood, data.city, data.uf)
            .then((res) => {
              if (res.results[0]) {
                setLatLong({
                  lat: Number(res.results[0].geometry.location.lat),
                  long: Number(res.results[0].geometry.location.lng),
                });
                handleOpenMapModal();
              } else {
                geographicApi
                  .getLatLongCidade(data.city, data.uf)
                  .then((res) => {
                    if (res.results[0]) {
                      setLatLong({
                        lat: Number(res.results[0].geometry.location.lat),
                        long: Number(res.results[0].geometry.location.lng),
                      });
                      handleOpenMapModal();
                    } else {
                      handleOpenMapModal();
                    }
                  })
                  .catch((error) => {
                    handleOpenMapModal();
                  });
              }
            })
            .catch((error) => {
              geographicApi
                .getLatLongCidade(data.city, data.uf)
                .then((res) => {
                  if (res.results[0].geometry.location.lat !== undefined) {
                    setLatLong({
                      lat: Number(res.results[0].geometry.location.lat),
                      long: Number(res.results[0].geometry.location.lng),
                    });
                    handleOpenMapModal();
                  } else {
                    handleOpenMapModal();
                  }
                })
                .catch((error) => {
                  handleOpenMapModal();
                });
            });
          // console.log("GeographicAPI",error);
        });
    }
  };

  useEffect(() => {
    geographicApi
      .getEstados()
      .then((res) => {
        setStateOptions(res);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    empresaApi
      .getEmpresa(companieId)
      .then((res) => {
        setCompanie(res);
      })
      .catch((error) => {
        empresaApi
          .getEmpresaSlug(companieId)
          .then((res) => {
            setCompanie(res);
          })
          .catch((error) => {
            // console.log("Erro no slug");
            // console.log(error);
          });
      });
  }, [companieId]);

  useEffect(() => {
    const cepValue = cepInput.replace(/[^0-9]/, '');

    if (
      stateOptions.length > 1 &&
      companie &&
      !enderecoDefault?.endereco?.estado &&
      cepValue.length === 0
    ) {
      const defaultState = stateOptions.find(
        (state) =>
          state.nome === companie.empresas[0].endereco.estado ||
          state.sigla === companie.empresas[0].endereco.estado,
      );
      if (defaultState) {
        setSelectedState(defaultState);
      }
    }
  }, [cepInput, stateOptions, companie, enderecoDefault]);

  useEffect(() => {
    const cepValue = cepInput.replace(/[^0-9]/, '');

    if (
      cityOptions.length > 1 &&
      companie &&
      !enderecoDefault?.endereco?.cidade &&
      cepValue.length === 0
    ) {
      const defaultCity = cityOptions.find(
        (city) => city.nome === companie.empresas[0].endereco.cidade,
      );
      if (defaultCity) {
        setSelectedCity(defaultCity);
      }
    }
  }, [cepInput, cityOptions, companie, enderecoDefault]);

  useEffect(() => {
    geographicApi
      .getCidades(selectedState.id.toString())
      .then((res) => {
        setCityOptions(res);
      })
      .catch((error) => console.log(error));
  }, [selectedState]);

  useEffect(() => {
    const cepValue = cepInput.replace(/[^0-9]/, '');

    if (cepValue.length === 8) {
      geographicApi
        .getEndereco(cepValue)
        .then((address) => {
          if (address.cep) {
            const foundState = stateOptions.find((findState) => {
              return findState.sigla === address.uf;
            });
            if (foundState !== undefined) {
              geographicApi
                .getCidades(foundState.id.toString())
                .then((res) => {
                  const city = res.find((findCity) => {
                    return findCity.nome === address.localidade;
                  });
                  if (city !== undefined) {
                    if (
                      (!enderecoDefault || executionNum !== 0) &&
                      !locationFromMap
                    ) {
                      setAddressInfo({
                        address: address.logradouro,
                        complement: address.complemento,
                        neighborhood: address.bairro,
                      });
                    }
                    setSelectedState(foundState);
                    setSelectedCity(city);
                    setExecutionNum(executionNum + 1);
                    locationFromMap = false;
                  }
                  setCityOptions(res);
                })
                .catch((error) => console.log(error));
            }
          }
        })
        .catch((error) => console.log(error));
    }
  }, [cepInput, stateOptions, enderecoDefault]);

  useEffect(() => {
    if (enderecoDefault?.endereco?.estado && stateOptions.length > 0) {
      const foundState = stateOptions.find(
        (findState) =>
          findState.nome === enderecoDefault.endereco.estado ||
          findState.sigla === enderecoDefault.endereco.estado,
      );

      if (foundState) {
        setSelectedState(foundState);

        if (enderecoDefault.endereco?.cidade) {
          geographicApi
            .getCidades(foundState.id.toString())
            .then((res) => {
              const foundCity = res.find(
                (findCity) => findCity.nome === enderecoDefault.endereco.cidade,
              );

              if (foundCity) {
                setSelectedCity(foundCity);
              }
            })
            .catch((error) => console.log(error));
        }
      }
    }
  }, [enderecoDefault, stateOptions]);

  useEffect(() => {
    if (enderecoDefault !== undefined) {
      if (executionNum === 0) {
        setCepInput(enderecoDefault.endereco.cep);
        setHouseN(enderecoDefault.endereco.numero);
        const { logradouro, bairro, complemento } = enderecoDefault.endereco;
        const tempLog = _.toString(logradouro);
        const tempBairro = _.toString(bairro);
        const tempComp = _.toString(complemento);
        setAddressInfo({
          address: tempLog,
          complement: tempComp,
          neighborhood: tempBairro,
        });
      }
    }
  }, [executionNum]);

  const verifyAddress = (uf: string, city: string, neighborhood: string) => {
    if (endEntrega?.endereco) {
      const { bairro, cidade, estado } = endEntrega.endereco;
      setHasError(0);

      if (uf !== estado) {
        // setHasError(1);
        toast.info(
          'O estado informado não corresponde com a localização selecionada',
          toast_config,
        );

        return;
      }

      if (city !== cidade) {
        setHasError(2);
        toast.info(
          'A cidade informada não corresponde com a localização selecionada',
          toast_config,
        );

        const foundCity = cityOptions.find(
          (findCity) => findCity.nome === city,
        );

        if (foundCity) {
          setSelectedCity(foundCity);
        }

        setAddressInfo({
          ...addressInfo,
          neighborhood,
        });
        setEndEntrega({
          ...endEntrega,
          endereco: {
            cep: endEntrega?.endereco?.cep || '',
            numero: endEntrega?.endereco?.numero || '',
            logradouro: endEntrega?.endereco?.logradouro || '',
            bairro: neighborhood || endEntrega?.endereco?.bairro || '',
            complemento: endEntrega?.endereco?.complemento || '',
            cidade: city || endEntrega?.endereco?.cidade || '',
            estado: endEntrega?.endereco?.estado || '',
          },
        });

        return;
      }

      if (neighborhood !== bairro) {
        setHasError(3);
        toast.info(
          'O bairro informado não corresponde com a localização selecionada',
          toast_config,
        );

        setAddressInfo({
          ...addressInfo,
          neighborhood,
        });
        setEndEntrega({
          ...endEntrega,
          endereco: {
            cep: endEntrega?.endereco?.cep || '',
            numero: endEntrega?.endereco?.numero || '',
            logradouro: endEntrega?.endereco?.logradouro || '',
            bairro: neighborhood || endEntrega?.endereco?.bairro || '',
            complemento: endEntrega?.endereco?.complemento || '',
            cidade: endEntrega?.endereco?.cidade || '',
            estado: endEntrega?.endereco?.estado || '',
          },
        });
      }
    }
  };

  const changeAddressInfo = (
    streetNumber: string,
    route: string,
    sublocality: string,
    postalCode: string,
  ) => {
    locationFromMap = true;
    setCepInput(postalCode);
    setAddressInfo({
      address: route,
      complement: '',
      neighborhood: sublocality,
    });
    setHouseN(streetNumber);
    if (endEntrega !== undefined) {
      setEndEntrega({
        ...endEntrega,
        endereco: {
          cep: postalCode,
          numero: streetNumber,
          logradouro: route,
          bairro: sublocality,
          complemento: endEntrega?.endereco.complemento || '',
          cidade: endEntrega?.endereco.cidade || '',
          estado: endEntrega?.endereco.estado || '',
        },
      });
    }
  };

  const useCurrentLocation = () => {
    loader.load().then(() => {
      geocoder = new google.maps.Geocoder();

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            geocoder.geocode({ location: pos }, (res, status) => {
              if (status === 'OK') {
                // console.log("res");
                if (res !== null) {
                  // console.log(res);
                  // console.log(res[0].address_components);

                  const streetN =
                    res[0].address_components.find((info) =>
                      info.types.includes('street_number'),
                    )?.long_name || '';
                  const route =
                    res[0].address_components.find((info) =>
                      info.types.includes('route'),
                    )?.long_name || '';
                  const sublocality =
                    res[0].address_components.find((info) =>
                      info.types.includes('sublocality'),
                    )?.long_name || '';
                  const postalCode =
                    res[0].address_components.find((info) =>
                      info.types.includes('postal_code'),
                    )?.long_name || '';

                  // console.log(streetN, route, sublocality, postalCode);
                  changeAddressInfo(streetN, route, sublocality, postalCode);
                }
              }

              // console.log("Status");
              // console.log(status);
            });
          },
          () => {
            toast.error(
              'Ative a localização e dê permissão no navagador para usar',
              toast_config,
            );
          },
        );
      } else {
        // Browser doesn't support Geolocation
        toast.error(
          'Ative a localização e dê permissão no navagador para usar',
          toast_config,
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <FirstStep>
        <Header>
          <span className={classes.span} />
          <div>
            <Typography variant="h6" className={classes.title}>
              {enderecoDefault !== undefined
                ? 'Edição de endereço'
                : 'Criação de endereço'}
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon style={{ color: '#B22222' }} />
          </IconButton>
        </Header>
        <Divider className={classes.titleDivider} />
        <Typography variant="subtitle1" className={classes.subtitle}>
          {enderecoDefault !== undefined
            ? 'Altere os dados que deseja editar:'
            : 'Preencha os dados para cadastrar um endereço de entrega:'}
        </Typography>
        <Scroll>
          <form
            id="envio-info-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={classes.form}
          >
            <UserInfo>
              <div className={`${classes.description} ${classes.fixDisplay}`}>
                <Autocomplete
                  id="description-combo"
                  options={['Casa', 'Trabalho', 'Outros']}
                  getOptionLabel={(option) => option}
                  getOptionSelected={(option, value) =>
                    _.isEqual(option, value)
                  }
                  value={selectedDescription}
                  onChange={(event: any, newValue: string | null) => {
                    if (newValue !== null) {
                      setSelectedDescription(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="description"
                      type="description"
                      label="Descrição"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
              </div>
              <div className={classes.currentLocation}>
                <CurrentLocationButton>
                  <IconButton
                    aria-label="currentLocation"
                    onClick={useCurrentLocation}
                    className={classes.buttonCL}
                  >
                    <LocationOnIcon
                      style={{ color: '#2196f3' }}
                      className={classes.iconCL}
                    />
                  </IconButton>
                  <Typography
                    variant="body1"
                    className={classes.currentLocationText}
                  >
                    Usar localização
                  </Typography>
                </CurrentLocationButton>
              </div>
              <div className={`${classes.cep} ${classes.fixDisplay}`}>
                <TextField
                  required
                  id="cep"
                  name="cep"
                  type="cep"
                  label="CEP"
                  placeholder="Digite seu CEP"
                  variant="outlined"
                  value={cepInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const temp = cep(event);
                    setCepInput(temp);
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Por favor digite seu cep',
                    },
                    minLength: {
                      value: 9,
                      message: 'Por favor digite um cep válido',
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="cep"
                  render={({ message }) => (
                    <p className={classes.errorMessage}>{message}</p>
                  )}
                />
              </div>
              <div className={`${classes.address} ${classes.fixDisplay}`}>
                <TextField
                  required
                  id="address"
                  name="address"
                  type="address"
                  label="Logradouro"
                  placeholder="Digite o logradouro"
                  variant="outlined"
                  value={addressInfo.address}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAddressInfo({
                      ...addressInfo,
                      address: (event.target as HTMLInputElement).value,
                    });
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Por favor digite o logradouro',
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="address"
                  render={({ message }) => (
                    <p className={classes.errorMessage}>{message}</p>
                  )}
                />
              </div>
              <div className={`${classes.complement} ${classes.fixDisplay}`}>
                <TextField
                  id="complement"
                  name="complement"
                  type="complement"
                  label="Complemento"
                  placeholder="Digite o complemento"
                  variant="outlined"
                  value={addressInfo.complement}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAddressInfo({
                      ...addressInfo,
                      complement: (event.target as HTMLInputElement).value,
                    });
                  }}
                  inputRef={register}
                />
              </div>
              <div className={`${classes.neighborhood} ${classes.fixDisplay}`}>
                <TextField
                  required
                  id="neighborhood"
                  name="neighborhood"
                  type="neighborhood"
                  label="Bairro"
                  placeholder="Digite o bairro"
                  variant="outlined"
                  InputProps={{
                    classes:
                      hasError <= 3 && hasError >= 1
                        ? {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          }
                        : {},
                  }}
                  value={addressInfo.neighborhood}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAddressInfo({
                      ...addressInfo,
                      neighborhood: (event.target as HTMLInputElement).value,
                    });
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Por favor digite o bairro',
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="neighborhood"
                  render={({ message }) => (
                    <p className={classes.errorMessage}>{message}</p>
                  )}
                />
              </div>
              <div className={`${classes.houseNumber} ${classes.fixDisplay}`}>
                <TextField
                  id="house_number"
                  name="house_number"
                  type="house_number"
                  label="Número"
                  placeholder="Digite o número"
                  variant="outlined"
                  value={houseN}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setHouseN((event.target as HTMLInputElement).value);
                  }}
                  inputProps={{
                    maxLength: 15,
                  }}
                  inputRef={register({
                    required: {
                      value: false,
                      message: 'Por favor digite o número',
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="house_number"
                  render={({ message }) => (
                    <p className={classes.errorMessage}>{message}</p>
                  )}
                />
              </div>
              <Autocomplete
                id="combo-box-uf"
                options={stateOptions}
                getOptionLabel={(option) => option.nome}
                value={selectedState}
                onChange={(event: any, newValue: IEstado | null) => {
                  if (newValue !== null) {
                    setSelectedState(newValue);
                  }
                }}
                getOptionSelected={(option, value) => _.isEqual(option, value)}
                className={classes.uf}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="uf"
                    type="uf"
                    label="Estado"
                    variant="outlined"
                    inputRef={register}
                    InputProps={{
                      classes:
                        hasError === 1
                          ? {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            }
                          : {},
                    }}
                  />
                )}
              />
              <Autocomplete
                id="combo-box-city"
                options={cityOptions}
                getOptionLabel={(option) => option.nome}
                value={selectedCity}
                onChange={(event: any, newValue: ICidade | null) => {
                  if (newValue !== null) {
                    setSelectedCity(newValue);
                  }
                }}
                getOptionSelected={(option, value) => _.isEqual(option, value)}
                className={classes.city}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="city"
                    type="city"
                    label="Cidade"
                    variant="outlined"
                    inputRef={register}
                    InputProps={{
                      classes:
                        hasError <= 2 && hasError >= 1
                          ? {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            }
                          : {},
                    }}
                  />
                )}
              />
              <div className={`${classes.principal} ${classes.fixDisplay}`}>
                <Autocomplete
                  id="combo-box-principal"
                  options={['Sim', 'Não']}
                  disableClearable
                  defaultValue={isEmpty === true ? 'Sim' : 'Não'}
                  getOptionLabel={(option) => option}
                  getOptionSelected={(option, value) =>
                    _.isEqual(option, value)}
                  value={selectedPrincipal}
                  onChange={(event: any, newValue: string | null) => {
                    if (newValue !== null) {
                      setSelectedPrincipal(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="principal"
                      type="principal"
                      label="Principal"
                      variant="outlined"
                      inputRef={register({
                        required: {
                          value: true,
                          message: 'Por favor selecione uma opção',
                        },
                      })}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="principal"
                  render={({ message }) => (
                    <p className={classes.errorMessage}>{message}</p>
                  )}
                />
              </div>
            </UserInfo>
          </form>
        </Scroll>

        <>
          <Footer>
            <Button
              type="submit"
              form="envio-info-form"
              variant="contained"
              fullWidth
              disabled={isLoading}
              style={{
                backgroundColor: '#B22222',
                color: '#fefefe',
                height: '45px',
                width: '90%',
              }}
            >
              Concluir
            </Button>
          </Footer>
          <div className={classes.offset1} />
        </>


        {openMapModal ? (
          <MapModal
            handleCloseMapModal={handleCloseMapModal}
            openMapModal={openMapModal}
            latLong={latLong}
            register={setLatLong}
            verifyAddress={verifyAddress}
            changeAddressInfo={changeAddressInfo}
          />
        ) : (
          <div />
        )}
      </FirstStep>
    </Modal>
  );
};

export default DeliveryAddressModal;
