import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  min-height: 100vh;

  align-items: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  padding-right: 2vw;
  padding-left: 2vw;
  margin-top: 30px;
  padding-bottom: 100px;

  width: 100%;
  min-height: max-content;

  h1 {
    font-size: 28px;
    font-weight: 700;
    color: #263238;
  }

  @media (max-width: 640px) {
    h1 {
      align-self: center;
      margin-bottom: 20px;
      padding-right: 1vw;
      padding-left: 1vw;
    }
  }

  @media (min-width: 960px) and (max-width: 1359px) {
    padding-right: 2vw;
    padding-left: 2vw;
  }
`;

export const CategoryFilter = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 80vw;
  align-items: center;
`;

export const FilterTitle = styled.div`
  width: 239px;
  height: 24px;
  margin: 30px 0px 26px 0px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  @media (max-width: 960px) {
    font-size: 16px;
    margin: 22px 0px 27px 0px;
  }

  @media (min-width: 960px) and (max-width: 1359px) {
    font-size: 18px;
    margin: 30px 0px 26px 0px;
  }
`;

export const SegmentFilter = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding-inline: 10px;
`;