import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  infoPortion: {
    fontSize: '0.8rem',
    fontWeight: 400,
    lineHeight: 1,
    color: '#757575',
    [theme.breakpoints.down(768)]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.7rem',
    },
  },
  discountIconText: {
    position: 'absolute',
    color: '#FFF',
    fontWeight: 500,
    zIndex: 2,
  },
  oldPrice: {
    fontSize: '16px',
    lineHeight: 1,
    color: '#757575',
    fontWeight: 300,
    textDecoration: 'line-through',
    whiteSpace: 'nowrap',
  },
  stripe: {
    fontSize: '1rem',
    lineHeight: 1,
    color: '#fff',
    fontWeight: 500,
    [theme.breakpoints.down(768)]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down(580)]: {
      fontSize: '0.7rem',
    },
  },
  outOfStock: {
    backgroundColor: '#898989 !important'
  },
}));
