import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import empresaApi from '../../services/empresaApi';
import { IEmpresa } from '../../interfaces/empresa';

import { Container } from './styles';
import Store from './InitialStore';
import { InitialClothes } from './InitialClothes';
import { PageNotFound } from '../NotFound';

export const Initial: React.FC = () => {
  const [company, setCompany] = useState<IEmpresa | '404' | null>(null);
  const [isClothesStore, setIsClothesStore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { companieId } = useParams<{ companieId: string }>();

  useEffect(() => {
    empresaApi
      .getEmpresa(companieId)
      .then((res) => {
        setCompany(res);
      })
      .catch(() => {
        empresaApi
          .getEmpresaSlug(companieId)
          .then((res) => {
            setCompany(res);
          })
          .catch(() => {
            setCompany('404');
            setIsLoading(false);
          });
      });
  }, [companieId]);

  useEffect(() => {
    if (company && company !== '404') {
      empresaApi
        .getParametro(company.id.toString(), 'view_mode')
        .then((res) => {
          const role = JSON.parse(res.role);
          if (role) {
            if (role.value === 'store') {
              setIsClothesStore(true);
            }
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsClothesStore(false);
          setIsLoading(false);
        });
    }
  }, [company]);

  // eslint-disable-next-line no-nested-ternary
  return company === null || isLoading ? (
    <Container>
      <CircularProgress />
    </Container>
  ) : // eslint-disable-next-line no-nested-ternary
  company === '404' ? (
    <PageNotFound />
  ) : isClothesStore ? (
    <InitialClothes company={company} />
  ) : (
    <Store company={company} />
  );
};
