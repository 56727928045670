import React, { useState, useEffect, useContext } from "react";

import _ from 'lodash';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import ButtonBase from '@material-ui/core/ButtonBase';

import { IItemComplemento } from "../../interfaces/itemComplemento";
import { IComplementoCarrinho } from "../../interfaces/complementoCarrinho";

import { ImageComplement, Informations } from './styles';
import stylesMaterial from './stylesMaterial';
import { ThemeContext } from "styled-components";
import DefaultImg from '../../assets/no_image.png'

interface ProductComponentRadioProps{
  complemento: IItemComplemento;
  setComponent: (cartComponent: IComplementoCarrinho) => void;
  handleClearRadioGroup: (value: string) => void;
}

const ProductComponentRadio: React.FC<ProductComponentRadioProps> = ({
                                                                        complemento,
                                                                        setComponent,
                                                                        handleClearRadioGroup
                                                                      }) => {
  const classes = stylesMaterial();
  const [price, setPrice] = useState(complemento.preco.valor);

  const { primary } = useContext(ThemeContext);

  function handleClick() {
    handleClearRadioGroup(_.toString(complemento.id));
  }

  return (
    <ListItem
      divider
      className={classes.listItem}
    >
      <ButtonBase
        onClick={handleClick}
        className={classes.buttonBase}
      >
        <Informations>
          <Typography
            variant='body1'
            className={classes.productTitle}
          >
            {complemento.descricao}
          </Typography>
          {
            (_.toNumber(price) === 0) ?
            <div /> :
            <Typography
              variant='h6'
              className={classes.priceText}
            >
              + R$ {Number(price).toFixed(2).replace(".", ",")}
            </Typography>
          }
        </Informations>
        <Radio
          value={_.toString(complemento.id)}
          style={{ color: primary }}
        />
      </ButtonBase>
    </ListItem>
  )
}

export default ProductComponentRadio;
