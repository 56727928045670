import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 550px;
  height: 50vh;

  background-color: #fefefe;
  border: '2px solid #000';
  border-radius: 0.3rem;

  @media(max-width: 960px){
    border-radius: 0;
    width: 100vw;
    height: 100vh;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const DeliveryFeeContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  
  margin-top: 10px;
  width: 100%;
  max-width: 25rem;
  flex: 1;
  padding-inline: 5px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  button {
    height: 56px;
    margin-top: 20px;

    background-color: ${props => props.theme.primary};
    color: #fff;

    &:hover {
      background-color: ${props => props.theme.primary};
    }
  }
`;