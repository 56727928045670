import React, { useContext, useState, useEffect } from 'react';

import _ from 'lodash';
import TextTruncate from 'react-text-truncate';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import { IItemCarrinho } from '../../../interfaces/itemCarrinho';
import { Context } from '../../../hooks/cartStore';
import { ItemEditModal } from '../item-edit-modal';
import { calcOrderPrice } from '../../../utils/calculator';

import {
  GridItem,
  Top,
  Bottom,
  Informations,
  Aditionals,
  Price,
  ComponentsList,
  Imagem,
} from './styles';
import stylesMaterial from './stylesMaterial';

interface CartItemProps {
  item: IItemCarrinho;
  companieId: string | undefined;
}

export const CartItem: React.FC<CartItemProps> = ({ item, companieId }) => {
  const classes = stylesMaterial();
  const [price, setPrice] = useState(calcOrderPrice(item));
  const [quantidade, setQuantidade] = useState(item.quantidade);
  const [openEditionModal, setOpenEditionModal] = useState(false);
  const [qtdSabores, setQtdSabores] = useState(0);

  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    let qtdTemp =
      1 / Math.min(...item.produtosGrade.map((produto) => produto.quantidade));
    if (1 - qtdTemp > 0.002) {
      let i = 2;
      while ((qtdTemp * i) % 1 !== 0) {
        i++;
      }
      qtdTemp = qtdTemp * i;
    }
    setQtdSabores(qtdTemp);
  }, []);

  useEffect(() => {
    setPrice(calcOrderPrice({ ...item, quantidade: quantidade }));
  }, [state.carts, item, quantidade]);

  const handleOpenEditionModal = () => {
    setOpenEditionModal(true);
  };

  const handleCloseEditionModal = () => {
    setOpenEditionModal(false);
  };

  const removeItem = () => {
    if (
      state.carts.find((cart) => _.toString(cart.id) === companieId)!.cart
        .length > 1
    ) {
      dispatch({
        type: 'REMOVE_ITEM',
        empresaId: _.toNumber(companieId),
        id: item.id,
      });
    } else {
      dispatch({
        type: 'SET_CART',
        empresaId: _.toNumber(companieId),
        payload: [],
      });
    }
  };

  return (
    <>
      <GridItem>
        <Top>
          <Imagem
            image={
              item.prodComposto.produto.controla_grade === 0
                ? `${process.env.REACT_APP_RETAGUARDA_API_FILES}` +
                  item.prodComposto.produto.imagem
                : item.produtoGrade
                ? item.produtoGrade.imagens.length > 0
                  ? `${process.env.REACT_APP_RETAGUARDA_API_FILES}` +
                    item.produtoGrade.imagens[0].imagem
                  : `${process.env.REACT_APP_RETAGUARDA_API_FILES}` +
                    item.prodComposto.produto.imagem
                : `${process.env.REACT_APP_RETAGUARDA_API_FILES}` +
                  item.prodComposto.produto.imagem
            }
          >
            <div />
          </Imagem>
          <Informations>
            <Typography variant="body1" className={classes.productTitle}>
              <TextTruncate
                line={2}
                element="span"
                truncateText="…"
                text={
                  item.prodComposto.produto.controla_grade === 2
                    ? item.produtosGrade.length > 1 &&
                      _.toNumber(item.prodComposto.produto.controla_grade) === 2
                      ? item.tamanho !== null
                        ? _.toNumber(quantidade) + 'x Pizza ' + item.tamanho
                        : _.toNumber(quantidade) + 'x Pizza'
                      : _.toNumber(quantidade) +
                        'x ' +
                        item.prodComposto.produto.descricao +
                        ' ' +
                        item.tamanho
                    : item.produtoGrade &&
                      item.prodComposto.produto.controla_grade === 1
                    ? _.toNumber(quantidade) +
                      'x ' +
                      item.prodComposto.produto.descricao +
                      ' - ' +
                      item.produtoGrade.tamanho.descricao +
                      ' - ' +
                      item.produtoGrade.cor.descricao
                    : (item.prodComposto.produto.fracionado
                        ? (item.prodComposto.produto.fracao
                            ? `${
                              (quantidade * item.prodComposto.produto.fracao) >=1
                                ? (quantidade * item.prodComposto.produto.fracao).toFixed(3)
                                : quantidade * item.prodComposto.produto.unidadeMedida.quantidadePorcao * item.prodComposto.produto.fracao
                            }${
                              (quantidade * item.prodComposto.produto.fracao) >=1 
                                ? item.prodComposto.produto.unidadeMedida.sigla
                                : item.prodComposto.produto.unidadeMedida.siglaPorcao
                            }`
                            : quantidade) +
                          ' - '
                        : _.toNumber(quantidade) + 'x ') +
                      item.prodComposto.produto.descricao
                }
              />
            </Typography>
            <div>
              <Aditionals>
                {item.prodComposto.complementos.length || 
                  (item.produtosGrade.length > 0 && item.prodComposto.produto.vitrine) ||
                  item.produtosGrade.length > 1 ? (
                  <ComponentsList>
                    {item.produtosGrade?.map((produto) => (
                      <li key={produto.produto.id}>
                        {Math.round(produto.quantidade * qtdSabores) +
                          '/' +
                          Math.round(qtdSabores) +
                          'x ' +
                          produto.produto.descricao}
                      </li>
                    ))}
                    {item.prodComposto.complementos.map((complemento) => (
                      <li key={complemento.complemento.id}>
                        {_.toNumber(complemento.quantidade) +
                          'x ' +
                          complemento.complemento.descricao}
                      </li>
                    ))}
                  </ComponentsList>
                ) : (
                  <Typography variant="body2" className={classes.prodDescr}>
                    <TextTruncate
                      line={3}
                      element="span"
                      truncateText="…"
                      text={item.prodComposto.produto.observacao || ''}
                    />
                  </Typography>
                )}
                {item.observacao !== '' && (
                  <Typography variant="body2" className={classes.prodObs}>
                    Obs: {item.observacao}
                  </Typography>
                )}
              </Aditionals>
              <Price>
                <Typography variant="h6" className={classes.priceText}>
                  R$ {price.toFixed(2).replace('.', ',')}
                </Typography>
              </Price>
            </div>
          </Informations>
        </Top>

        <Bottom>
          <Link
            href="#"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              handleOpenEditionModal();
            }}
            className={classes.editLink}
          >
            Editar
          </Link>
          <Link
            href="#"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              removeItem();
            }}
            className={classes.removeLink}
          >
            Remover
          </Link>
        </Bottom>
        {openEditionModal ? (
          <ItemEditModal
            handleCloseEditionModal={handleCloseEditionModal}
            openEditionModal={openEditionModal}
            item={item}
            setQtd={setQuantidade}
            companieId={companieId}
          />
        ) : (
          <div />
        )}
      </GridItem>
      <Divider className={classes.divisor} />
    </>
  );
};
