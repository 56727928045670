import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  min-height: max-content;

  align-items: center;
`;

export const OrderSteps = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Esboco = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

  & + & {
    margin-top: 10px;
  }

  h2 {
    color: ${props => props.theme.primary};
    font-size: 1.2rem;
  }

  h3 {
    margin-top: 8px;
  }

  p {
    color: #666;
    font-weight: bold;

    span {
      font-weight: normal;
    }
  }

  .price, a {
    font-weight: normal;
    color: ${props => props.theme.primary};
  }

  .info {
    h3 {
      font-size: 1.1rem;
      color: #666;
      font-weight: bold;
    }

    p {
      margin-left: 15px;
      font-size: 1rem;
      font-weight: normal;
    }

    span {
      color: ${props => props.theme.primary};
    }
  }

  .divider {
    border-bottom: 1px solid gray;
    margin: 8px 0px;
  }
`
export const ContainerInfo = styled.section`
  display: flex;
  flex-direction: column;
`
export const Steps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;

  div {
    flex: 1;
  }
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`
export const ButtonWhatsapp = styled(Button)`
  background-color: transparent;
  color: ${props => props.theme.primary};
  border: 1px solid gray;
  border-color: ${props => props.theme.primary};
  width: 100%;
  max-width: 300px;
  flex: 1;

  &:hover {
    background-color: ${props => props.theme.primary};
    color: #fff;
    border-color: transparent;
  }
`

export const ContainerStatus = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin-bottom: 10px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: ${props => props.theme.primary};
    border-radius: 8px;

    svg {
      font-size: 3rem;
      color: #fff;
    }
  }

  .conteudo {
    flex: 1;
    display: flex;
    align-items: center;
  }
`

export const ContainerInfoPedido = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin-bottom: 10px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: ${props => props.theme.primary};
    border-radius: 8px;

    svg {
      font-size: 3rem;
      color: #fff;
    }
  }

  .conteudo {
    flex: 1;
    display: flex;
    align-items: center;
  }
`

export const ContainerInfoStatus = styled.div`
  border-radius: 8px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 8px;
  margin-bottom: 10px;

  h2 {
    font-size: 1.2rem;
    color: ${props => props.theme.primary};
  }

  p {
    font-size: 0.8rem;
  }
`
