import React, { useEffect, useState } from 'react';

import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import { IProduto } from '../../interfaces/produto';

import { DiscountContainer, Card, PromotionContainer } from './styles';
import stylesMaterial from './stylesMaterial';
import { ClotheDetailsModal } from '../clothe-details-modal';
import { IEmpresa } from '../../interfaces/empresa';
import { useQuery } from '../../hooks/useQuery';
import { CustomFigure } from './CustomFigure';
import { CustomDetails } from './CustomDetails';
import { IGrupoProduto } from '../../interfaces/grupoProduto';
import { ISchedule } from '../../interfaces/schedule';

interface ClothingCardProps {
  product: IProduto;
  company: IEmpresa;
  group?: IGrupoProduto;
  productIdByBanner: number | null;
  setNullProductIdByBanner: () => void;
  scheduledDays: ISchedule | null;
}

export const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const ClothingCard: React.FC<ClothingCardProps> = (props) => {
  const {
    product,
    company,
    group,
    productIdByBanner,
    setNullProductIdByBanner,
    scheduledDays,
  } = props;
  const featuredProduct = product?.produto_grades.find((grid) => grid.destaque);

  const query = useQuery();

  const classes = stylesMaterial();

  const [isOpenDetails, setIsOpenDetails] = useState(false);

  const handleOpenDetails = () => {
    setIsOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setIsOpenDetails(false);
    if (setNullProductIdByBanner) setNullProductIdByBanner();
  };

  useEffect(() => {
    if (!productIdByBanner) {
      const prodId = query.get('selectedProduct');
      if (prodId) {
        if (
          Number(prodId) === product.id &&
          group?.descricao.toLocaleLowerCase() === 'ofertas'
        ) {
          handleOpenDetails();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (productIdByBanner) {
      if (
        Number(productIdByBanner) === product.id &&
        !(group?.descricao.toLocaleLowerCase() === 'ofertas')
      ) {
        handleOpenDetails();
      }
    }
  }, [productIdByBanner]);

  return (
    <Card>
      <CardActionArea
        onClick={handleOpenDetails}
        className={classes.cardAction}
      >
        <CustomFigure product={product} featuredProduct={featuredProduct} />
        <CustomDetails product={product} featuredProduct={featuredProduct} />
      </CardActionArea>

      {product.controla_grade === 0
        ? product.promocao &&
          product.promocao.fator > 0 && (
            <DiscountContainer>
              <Typography className={classes.discountIconText}>
                {`-${Math.round(product.promocao.fator * 100).toFixed(0)}%`}
              </Typography>
            </DiscountContainer>
          )
        : product.produto_grades
            .map((prod) => !!prod.promocao?.ativo)
            .reduce((prev, next) => prev || next) && (
            <DiscountContainer>
              <Typography className={classes.discountIconText}>Off</Typography>
            </DiscountContainer>
          )}

      {isOpenDetails && (
        <ClotheDetailsModal
          isOpen={isOpenDetails}
          onClose={handleCloseDetails}
          product={product}
          company={company}
          scheduledDays={scheduledDays}
        />
      )}
    </Card>
  );
};
