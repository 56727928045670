import styled from 'styled-components';

interface CircleProps {
  selected: boolean;
  color: string;
}

export const Circle = styled.div<CircleProps>`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  padding: ${props => props.selected ? '0.15rem' : '0px'};
  border: 1px solid #000;
  background-color: ${props => props.color};
  background-clip: content-box;
  cursor: pointer;
`;