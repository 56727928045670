import React, { useState, useEffect, useContext, useCallback } from "react";

import _ from 'lodash';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { IProduto } from '../../../../../interfaces/produto';
import { IProdutoGradeCarrinho } from '../../../../../interfaces/produtoGradeCarrinho';

import { Informations } from './styles';
import stylesMaterial from './stylesMaterial';
import { ThemeContext } from "styled-components";
import { Checkbox } from "@material-ui/core";

interface PizzaComponentCheckboxProps{
  pizza: IProduto;
  setPizzaComponent: (cartPizzaComponent: IProdutoGradeCarrinho) => void;
  base: boolean;
  full: boolean;
  numSelectedFlavors: number;
}

export const PizzaComponentCheckbox: React.FC<PizzaComponentCheckboxProps> = ({
  pizza,
  setPizzaComponent,
  base,
  full,
  numSelectedFlavors,
}) => {
  const [quantidade, setQuantidade] = useState(base ? 1 : 0);
  const [checked, setChecked] = useState(base ? true : false);

  const { primary } = useContext(ThemeContext);
  const classes = stylesMaterial();

  useEffect(() => {
    setPizzaComponent({produto: pizza, quantidade: quantidade});
  }, [quantidade]);

  const handleSelection = useCallback(() => {
    if (checked) {
      setQuantidade(0);
      setChecked(false);
    } else {
      setQuantidade(1);
      setChecked(true);
    }
  }, [quantidade, checked]);

  return (
    <ListItem
      divider
      className={classes.listItem}
    >
      <Informations>
        <Typography
          variant='body1'
          className={classes.productTitle}
        >
          {pizza.descricao}
        </Typography>

        <Typography
          variant='body1'
          className={classes.description}
        >
          {pizza.observacao}
        </Typography>

        {
          pizza.produto_grades[0].estoque &&
          pizza.produto_grades[0].estoque.ativo &&
          pizza.produto_grades[0].estoque.estoque_atual == 0 ? (
            <Typography className={classes.priceText}>
              Sem estoque
            </Typography>
          ) : (
            <section>
              <Typography
                variant='h6'
                className={classes.priceText}
              >
                R$ {Number(
                  pizza.produto_grades[0].promocao?.ativo
                  ? pizza.produto_grades[0].preco.valor-pizza.produto_grades[0].promocao.preco
                  : pizza.produto_grades[0].preco.valor
                ).toFixed(2).replace('.',',')}
              </Typography>

              {
                pizza.produto_grades[0].promocao?.ativo && (
                  <Typography className={classes.oldPrice}>
                    R$ {Number(pizza.produto_grades[0].preco.valor).toFixed(2).replace('.',',')}
                  </Typography>
                )
              }
            </section>
          )
        }
      </Informations>

      <Checkbox
        checked={checked}
        onChange={handleSelection}
        inputProps={{ 'aria-label': 'select flavor checkbox' }}
        disabled={(!checked && full) ||
          (pizza.produto_grades[0].estoque &&
            pizza.produto_grades[0].estoque.ativo &&
            (quantidade + 1)/(numSelectedFlavors + 1) > pizza.produto_grades[0].estoque.estoque_atual)}
      />
    </ListItem>
  )
}
