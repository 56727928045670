import React, { useState } from 'react';
import { ItemContainer, ItemHead, ItemTitle, HalfLine, TitleAndShowAll, SeeMore } from './styles';
import { Typography } from '@material-ui/core';
import stylesMaterial from './stylesMaterial';
import { IEmpresa } from '../../interfaces/empresa';
import BusinessList from '../business-list';

interface ItemListProps {
    title: string;
    companies: IEmpresa[];
    isCloseStores?: boolean;
}

const ItemList = ({ title, companies, isCloseStores = false }:ItemListProps) => {
    const classes = stylesMaterial();

    const [showAll, setShowAll] = useState(false);

    return (
        <ItemContainer id="item-container">
            <ItemHead>
                <HalfLine />
                <ItemTitle> 
                    {title}
                </ItemTitle>
                <HalfLine />
            </ItemHead>

            <TitleAndShowAll>
                <Typography className={classes.groupTitleSmallScreens}>{title}</Typography>

                <SeeMore
                    showAll={showAll}
                    onClick={(event) => {
                        setShowAll(!showAll);
                    }}
                >
                    {showAll ? 'Esconder' : 'Ver todos'}
                </SeeMore>
            </TitleAndShowAll>
            
            <BusinessList
                data={companies}
                showAll={showAll}
                isCloseStores={isCloseStores}
            />
        </ItemContainer>
    )
    
}

export default ItemList;