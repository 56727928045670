import { IGrupoProduto } from '../interfaces/grupoProduto';
import { IProduto } from '../interfaces/produto';
import { IItemComplemento } from '../interfaces/itemComplemento';
import { IProdutoGrade } from '../interfaces/produtoGrade';

import api from './api';

const produtosApi = {

  getGrupos: async (id: number): Promise<IGrupoProduto[]> => {
    const { data } = await api.get(`produto/grupo/${id}`);
    return data;
  },
  getProduto: async (id: string | number): Promise<IProduto> => {
    const { data } = await api.get(`produto/${id}`);
    return data;
  },
  getProdutoByIds: async (ids: number[]): Promise<IProduto[]> => {
    const { data } = await api.post(`produto/by-ids`, { ids });
    return data;
  },
  getItemComplementoByIds: async (ids: number[]): Promise<IItemComplemento[]> => {
    const { data } = await api.post(`produto/item-complemento/by-ids`, { ids });
    return data;
  },
  getProdutosGradeByIds: async (ids: number[]): Promise<IProduto[]> => {
    const { data } = await api.post(`produto/produto-grade/by-ids`, { ids });
    return data;
  },
  getProdutosByTamanhos: async (tamanhoId: number): Promise<IProduto[]> => {
    const { data } = await api.get(`produto/tamanho/${tamanhoId}`);
    return data;
  },
  getProdutosWithPromo: async (companyId: number): Promise<IProduto[]> => {
    const { data } = await api.get(`produto/grupo/${companyId}/promocao`);
    return data;
  },
  getProdutosWithFreeDelivery: async ({ category, name, state }: {
    category?: string;
    name?: string;
    state?: string;
  }): Promise<IProduto[]> => {
    const { data } = await api.get(`produto/frete-gratis`, {
      params: {
        category,
        name: name ? name : null,
        state: state ? state : null,
      }
    });
    return data;
  },
};

export default produtosApi;