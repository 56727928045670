import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";
import _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import empresaApi from '../../services/empresaApi';

import CompanyContactInfo from '../../components/company-contact-info';

import { IEmpresa } from '../../interfaces/empresa';

import stylesMaterial from './stylesMaterial';
import {
  Container,
  TopMenu,
  Body,
  Companies
} from './styles';
import { Footer } from "../../components/footer";
import { CustomAppBar } from "../../new-components/custom-appbar";

const Contact: React.FC = () => {
  const classes = stylesMaterial();

  const { companieId } = useParams<{ companieId: string }>();

  const [companie, setCompanie] = useState<IEmpresa>();
  const [companies, setCompanies] = useState<IEmpresa[]>();

  useEffect(() => {
    empresaApi.getEmpresa(companieId).then((res) => {
      setCompanie(res);
    }).catch((error) => {
      empresaApi.getEmpresaSlug(companieId).then((res) => {
        setCompanie(res);
      }).catch((error) => {
        // console.log("Erro no slug");
        // console.log(error);
      })
    });
    if (companie?.id !== undefined) {
      empresaApi.getFiliais(companie.id).then((res) => {
        setCompanies(res);
      }).catch((error) => {
        //console.log(error)
      });
    }
  }, []);

  useEffect(() => {
    if (companie?.id !== undefined) {
      empresaApi.getFiliais(companie.id).then((res) => {
        setCompanies(res);
      }).catch((error) => console.log(error));
    }
  }, [companie])

  return (
    <Container>
      <CustomAppBar />

      <Body>
        <Typography variant='h6' className={classes.title}>
          Contato
        </Typography>

        <Companies>
          {
            companies !== undefined
              ?
              companies.map((company) => {
                return (
                  <CompanyContactInfo
                    key={company.id}
                    empresa={company}
                  />
                )
              })
              : <div />
          }
        </Companies>
      </Body>

      <Footer />
    </Container>
  )
}

export default Contact;
