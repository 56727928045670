import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  flex: 2;
  min-height: max-content;

  align-items: center;
`;

export const Content = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  min-height: max-content;
  width: 100%;

  @media (max-width: 760px){
    flex-direction: column;
    gap: 20px;
  }
`;

export const FirstColumn = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  min-height: max-content;
  flex: 1;
`;

export const ReceivingMethod = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 20px;
  min-height: max-content;
  min-width: max-content;
  align-items: center;

  @media (max-width: 760px){
    margin-top: 0;
  }
`;

export const ScheduledDelivery = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  align-items: center;

  min-height: max-content;
`;

export const ScheduledDeliveryOptions = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
`;

export const InvalidDateWarning = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: max-content;
  align-self: start;

  margin-top: 15px;

  svg + p{
    margin-left: 8px;
  }
`;

export const Warning = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  min-height: max-content;

  margin-top: 10px;

  animation: float 2s ease-in-out infinite;

  svg + p{
    margin-left: 8px;
    color: #f50057;
  }

  @keyframes float{
    0%,100%{
      transform:translatey(0)
    }
    50%{
      transform:translatey(-5px)
    }
  }
`;

export const ContainerAgendamento = styled.div<{
  isClosed?: boolean, isClosedUndetermined?: boolean, isClothesStore: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
  text-align: center;

  .title_closed {
    display: ${p => p.isClosed ? 'initial' : 'none'};
  }

  .title_closed_undetermined {
    display: ${p => p.isClosedUndetermined ? 'initial' : 'none'};
  }

  p {
    font-weight: bold;
    color: red;
    font-size: 14px;
  }

  svg{
    color: #333;
  }

  button {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #333;
    border-radius: 8px;
    padding: 6px;
    margin: 0 auto;
    gap: 8px;
    width: ${p => p.isClothesStore ? '199px' : '214px'};
    max-width: ${p => p.isClothesStore ? '199px' : '214px'};
    cursor: no-drop;

    p {
      color: #333;
      flex: 1;
      text-align: left;
      animation: ${p => p.isClosed ? 'float 1s ease-in-out infinite' : 'initial'};

      @keyframes float {
        0%, 100% {
          transform: translatey(3);
        }
        50% {
          transform: translatey(-3px);
        }
      }
    }

    &:disabled {
      border-color: #ccc;

      p, svg {
        color: #ccc;
        animation: initial;
      }
    }

    &:not(:disabled):hover {
      cursor: pointer;
      border-color: ${p => p.theme.primary};

      p {
        color: ${p => p.theme.primary};
      }

      svg {
        color: ${p => p.theme.primary};
      }
    }
  }
`;

export const CustomButtonScheduling = styled(Button)<{ closed?: boolean }>`
  animation: ${p => p.closed ? 'float 1s ease-in-out infinite': 'initial'};

  @keyframes float {
    0%, 100% {
      transform: translatey(3)
    }
    50% {
      transform: translatey(-3px)
    }
  }
`;

export const ButtonRow = styled.div`
  display: -webkit-flex;
  flex-direction: row;

  width: 100%;
  min-height: max-content;
  margin-top: 25px;

  justify-content: space-between;
`;
