import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  min-height: max-content;

  align-items: center;
`;

export const UserInfo = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 30px;
`;