import React, { useContext, useState } from "react";

import clsx from 'clsx';
import _ from 'lodash';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { AuthContext } from '../../../hooks/provideAuth';

import { IEmpresa } from "../../../interfaces/empresa";

import { GridItem, EnderecoCard } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../../hooks/useThemeState";

interface CompanyAdressProps{
  empresa: IEmpresa;
  checked: boolean;
  selectCompany: (id: number) => void;
}

const CompanyAdressCard: React.FC<CompanyAdressProps> = ({empresa, checked, selectCompany}) => {
  const classes = stylesMaterial();

  const { theme } = useTheme()

  const { state, dispatch } = useContext(AuthContext);

  const handleSelect = () => {
    selectCompany(empresa.id||0);
  }

  return (
    <GridItem>
      <Card>
        <CardActionArea
          onClick={handleSelect}
          className={classes.card}
        >
          <EnderecoCard>
            <Typography
              variant="body1"
              className={classes.description}
            >
              {empresa.nome_fantasia}
            </Typography>
            <Divider />
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {empresa.endereco.logradouro}, {empresa.endereco.numero}, {empresa.endereco.bairro}, {empresa.endereco.cep}
            </Typography>
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {empresa.endereco.complemento}
            </Typography>
            <Typography
              variant="body1"
              className={classes.infos}
            >
              {empresa.endereco.cidade}, {empresa.endereco.estado}
            </Typography>
          </EnderecoCard>
          <div>
            {
              checked
              ?
              <RadioButtonCheckedIcon
                className={classes.iconChecked}
                style={{ color: theme.primary }}
              />
              :
              <RadioButtonUncheckedIcon
                className={classes.iconUnchecked}
              />
            }
          </div>
        </CardActionArea>
      </Card>
    </GridItem>
  )
}

export default CompanyAdressCard;
