import React, { useState } from "react";

import _ from 'lodash';
import clsx from 'clsx';

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { cpf as cpfValidator } from 'cpf-cnpj-validator'; 

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import userApi from "../../services/userApi";
import { cpf, telefone } from '../masks';

import {
  Container,
  Header,
  Scroll,
  UserInfo,
  Footer
} from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../hooks/useThemeState";

interface RegisterModalProps{
  handleCloseRegisterModal: () => void;
  openRegisterModal: boolean;
  registerSucess: () => void;
  companieId: number;
  handleOpenRequestTokenModalFromSignup?: (login: string) => void;
}

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  password: "",
  confirmPassword: "",
  telephone: "",
  cpf: ""
};

const RegisterModal: React.FC<RegisterModalProps> = ({
                                                        handleCloseRegisterModal, 
                                                        openRegisterModal, 
                                                        registerSucess, 
                                                        companieId,
                                                        handleOpenRequestTokenModalFromSignup
                                                      }) => {

  const classes = stylesMaterial();
  const { theme } = useTheme()

  const { register, handleSubmit, errors, getValues } = useForm({ defaultValues });

  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(openRegisterModal);
  const [isLoading, setIsLoading] = useState(false);
  const toast_config = {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        };
  const [checkedTerms, setCheckedTerms] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseRegisterModal();
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const clienteInfo = {
      taxaEntregaId: 0,
      empresaId: _.toNumber(companieId),
      razaoSocial: data.name + " "+ data.family_name,
      email: data.email.toLowerCase().trim(),
      senha: data.password,
      fone: data.tel.replace(/\D/g, ''),
      cnpjCpf: data.cpf.replace(/\D/g, ''),
      endereco: {},
      descricao: data.name,
      prospect: false,
      principal: true
    };

    userApi.cadastro(clienteInfo).then((response) => {
      if (response?.status>=200 && response?.status<300){
        registerSucess();
        handleClose();
      } else {
        throw new Error(response.status.toString());
      }
      setIsLoading(false);
    }).catch((error) => {
      if((error.response.status === 400) && (error.response.data.message === "user-email-already-exists")){
        toast.error('Erro no cadastro. Email utilizado já cadastrado.', toast_config);
        if(handleOpenRequestTokenModalFromSignup){
          handleOpenRequestTokenModalFromSignup(clienteInfo.email);
          handleClose();
        }
      } else if((error.response.status === 400) && (error.response.data.message === "user-fone-already-exists")){
        toast.error('Erro no cadastro. Telefone utilizado já cadastrado.', toast_config);
        if(handleOpenRequestTokenModalFromSignup){
          handleOpenRequestTokenModalFromSignup(clienteInfo.fone);
          handleClose();
        }
      } else {
        toast.error('Erro no cadastro. Verifique seus dados ou tente mais tarde.', toast_config);
      }
      setIsLoading(false);
    });
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setCheckedTerms(event.target.checked);
  }


  const CustomCheckbox = withStyles({
    root: {
      color: '#757575',
      '&$checked': {
        color: theme.primary,
      },
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Header>
          <IconButton
            aria-label="arrow-down"
            onClick={handleClose}
            className={classes.buttonArrow}
          >
            <ArrowBackIosIcon
              fontSize='large'
              style={{ color: '#B22222' }}
              className={classes.iconArrow}
            />
          </IconButton>
          <div>
            <Typography
              variant="h6"
              className={classes.title}
            >
              Criar conta
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <CloseIcon
              style={{ color: '#B22222' }}
              className={classes.iconClose}
            />
          </IconButton>
        </Header>
        <Divider className={classes.titleDivider} />
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
        >
          Preencha os dados para realizar o cadastro:
        </Typography>
        <Scroll>
        <form
          id="envio-info-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={classes.form}
        >
          <UserInfo>
            <div className={`${classes.name} ${classes.fixDisplay}`}>
              <TextField
                required
                id="name"
                name="name"
                type="name"
                label="Nome"
                placeholder="Digite seu nome"
                variant="outlined"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu nome",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.surname} ${classes.fixDisplay}`}>
              <TextField
                required
                id="family_name"
                name="family_name"
                type="family_name"
                label="Sobrenome"
                placeholder="Digite seu sobrenome"
                variant="outlined"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu sobrenome",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="family_name"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.email} ${classes.fixDisplay}`}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                placeholder="Digite seu email"
                variant="outlined"
                inputRef={register}
              />
            </div>
            <div className={`${classes.password} ${classes.fixDisplay}`}>
              <TextField
                required
                id="password"
                name="password"
                type="password"
                label="Senha"
                placeholder="Digite sua senha"
                variant="outlined"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite sua senha",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.confirmPassword} ${classes.fixDisplay}`}>
              <TextField
                required
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label="Confirme a senha"
                placeholder="Repita a senha"
                variant="outlined"
                inputRef={register({
                  validate: {
                    passwordEqual: value => (value === getValues().password) || 'Senhas diferentes',
                  }
                })}
              />
              <ErrorMessage
                errors={errors}
                name="confirmPassword"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.telephone} ${classes.fixDisplay}`}>
              <TextField
                required
                id="tel"
                name="tel"
                type="tel"
                label="Telefone"
                placeholder="Digite seu telefone"
                variant="outlined"

                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  telefone(event);
                }}
                onPaste={(event) =>{
                  event.preventDefault()
                  return false;
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite seu telefone",
                  },
                })}
                autoComplete="off"
              />
              <ErrorMessage
                errors={errors}
                name="tel"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
            <div className={`${classes.cpf} ${classes.fixDisplay}`}>
              <TextField
                id="cpf"
                name="cpf"
                type="cpf"
                label="CPF (Opcional)"
                placeholder="Digite seu CPF"
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  cpf(event);
                }}
                inputRef={register({
                  validate: value => value==='' || cpfValidator.isValid(value.replace(/\D/g, '')) || "CPF inválido!"
                })}
              />
              <ErrorMessage
                errors={errors}
                name="cpf"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />
            </div>
          </UserInfo>
          <FormControlLabel
            control={<CustomCheckbox checked={checkedTerms} onChange={handleChangeCheckBox} name="checkedTerms" />}
            label={
              <Typography>
                Aceito os <a href="https://retaguarda.atacdelivery.com/api/files/TERMOS_E_CONDICOES_GERAIS_DE_USO_E_DE_COMPRA_E_VENDA_C.pdf" target="_blank">Termos e Condições</a> e a <a href="https://retaguarda.atacdelivery.com/api/files/TERMO_DE_CONSENTIMENTO.pdf" target="_blank">Política de Privacidade</a>
              </Typography>
            }
            className={classes.terms}
          />
        </form>
        </Scroll>
        <React.Fragment>
          <Footer >
            <Button
              type="submit"
              form="envio-info-form"
              variant="contained"
              fullWidth
              disabled={isLoading || (!checkedTerms)}
              style={{
                backgroundColor: (isLoading || (!checkedTerms)) ? '#757575' : theme.primary,
                color: '#fefefe',
                height: '45px',
                width: '90%',
              }}
            >
              Concluir
            </Button>
          </Footer>
          <div className={clsx(classes.offset1&&(width<960))} />
        </React.Fragment>

      </Container>
    </Modal>
  )
}

export default RegisterModal;
