import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    display: 'none',
    textAlign: 'start',
    margin: 'auto',
    borderRadius: '0.3rem',
    padding: '10px 15px',
    [theme.breakpoints.up(961)]: {
      display: '-webkit-flex',
      flexDirection: "column",
      width: '240px',
      height: '327px',
      boxShadow: '0px 1px 3px rgba(71,67,71,0.1)',
      border: '1px solid #f5f5f5',
      "&:hover":{
        border: '1px solid #d4d4d4',
      },
      transition: 'all 150ms ease 150ms'
    },
  },
  buttonBase:{
    display: '-webkit-flex',
    flexDirection: 'column',
    textAlign: 'start',
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.up(961)]: {
      display: 'none',
    },
  },
  orderNumber:{
    fontWeight: 500, 
    textAlign: 'center',
    fontSize: '0.9rem',
    '& span':{
      fontWeight: 400
    }
  },
  status:{
    marginTop: '10px',
    fontSize: '0.9rem',
    fontWeight: 500,
    [theme.breakpoints.down(961)]: {
      marginTop: '0px',
      padding: '0px 4px',
      backgroundColor: '#eeeeee',
      height: 'max-content',
    },
  },
  deliveryTime:{
    fontSize: '0.81rem'
  },
  item:{  
    fontSize: '0.95rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  zoomIcon:{
    fontSize: '1.5rem',
    color: '#B22222'
  },
  total: {
    fontSize: '1rem',
    color: '#B22222',
    [theme.breakpoints.down(961)]: {
      // alignSelf: 'flex-end',
      // marginTop: '10px'
    },
  },
  divisor: {
    marginTop: '10px',
    width: '100%',
    [theme.breakpoints.up(961)]: {
      display: 'none'
    }
  },
  linkTransition:{
    transition: 'all 0.35s ease-in-out',

    '&:hover':{
      color: '#B22222',
      textDecoration: 'none'
    },
    '&:after':{
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1px',

      height: '1px',
      width: 0,

      background: '#B22222',

      transition: 'width 0.35s ease-in-out'
    },
    '&:hover::after':{
      width: '100%'
    }
  },
  link: {
    fontSize: '1rem',
    marginTop: '10px',
    maxWidth: 'max-content'
  },
}));
