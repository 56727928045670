import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import ButtonBase from '@material-ui/core/ButtonBase';

import { ThemeContext } from 'styled-components';
import { IProdutoGrade } from '../../interfaces/produtoGrade';

import { Informations } from './styles';
import stylesMaterial from './stylesMaterial';

interface PizzaSizesRadioProps {
  tamanho: IProdutoGrade;
  handleClearRadioGroup: (value: string) => void;
}

const PizzaSizesRadio: React.FC<PizzaSizesRadioProps> = ({
  tamanho,
  handleClearRadioGroup,
}) => {
  const classes = stylesMaterial();
  const { primary } = useContext(ThemeContext);

  function handleClick() {
    handleClearRadioGroup(_.toString(tamanho.id));
  }

  return (
    <ListItem divider className={classes.listItem}>
      <ButtonBase onClick={handleClick} className={classes.buttonBase}>
        <Informations>
          <Typography variant="body1" className={classes.productTitle}>
            {tamanho.descricao}
          </Typography>
          <Typography variant="body2" className={classes.componentDesc}>
            {tamanho.tamanho.qtd_pedacos} pedaço(s)
          </Typography>
          <section>
            <Typography variant="h6" className={classes.priceText}>
              R${' '}
              {_.toNumber(
                tamanho.promocao?.ativo
                  ? tamanho.preco.valor - Number(tamanho.promocao.preco)
                  : tamanho.preco.valor,
              )
                .toFixed(2)
                .replace('.', ',')}
            </Typography>

            {tamanho.promocao?.ativo && (
              <Typography className={classes.oldPrice}>
                R$ {Number(tamanho.preco.valor).toFixed(2).replace('.', ',')}
              </Typography>
            )}
          </section>
        </Informations>
        <Radio value={_.toString(tamanho.id)} style={{ color: primary }} />
      </ButtonBase>
    </ListItem>
  );
};

export default PizzaSizesRadio;
