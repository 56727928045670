import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress, IconButton } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { DefaultTheme } from 'styled-components';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { IEmpresa } from '../../../interfaces/empresa';
import { IGrupoProduto } from '../../../interfaces/grupoProduto';

import SegmentCarousel from '../../../components/segmentCaroselClothes';
import { ClothingGroup } from '../../../new-components/clothing-group';
import { CustomAppBar } from '../../../new-components/custom-appbar';
import { CustomCarousel } from '../../../new-components/custom-carousel';

import empresaApi from '../../../services/empresaApi';
import produtosApi from '../../../services/produtosApi';

import { Footer } from '../../../components/footer';
import IBanner from '../../../interfaces/banner';
import api from '../../../services/api';
import { Container, Content, SegmentFilter } from './styles';

import offerIcon from '../../../assets/specialoff.png';
import { useTheme } from '../../../hooks/useThemeState';
import stylesMaterial from './stylesMaterial';
import { CompanyInfo } from '../../../new-components/company-info';
import { ISchedule } from '../../../interfaces/schedule';

interface InitialClothesProps {
  company: IEmpresa;
}

export const InitialClothes: React.FC<InitialClothesProps> = ({ company }) => {
  const classes = stylesMaterial();
  const [banners, setBanners] = useState(
    [] as {
      url: string;
      product_id?: number;
      product?: any;
      ordem: number | null;
    }[],
  );
  const [grupos, setGrupos] = useState(new Array<IGrupoProduto>());
  const [gruposTemp, setGruposTemp] = useState(new Array<IGrupoProduto>());
  const [selectedSegment, setSelectedSegment] = useState(-1);
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [productIdByBanner, setProductIdByBanner] = useState<number | null>(
    null,
  );
  const [scheduledDays, setScheduledDays] = useState<ISchedule | null>(null);

  const { setTheme } = useTheme();

  useEffect(() => {
    if (company) {
      const groups = produtosApi.getGrupos(company.id);
      const productsWPromo = produtosApi.getProdutosWithPromo(company.id);

      Promise.allSettled([groups, productsWPromo])
        .then((values) => {
          if (values[1].status === 'fulfilled' && values[1].value.length > 0) {
            const tempGroup: IGrupoProduto = {
              id: 0,
              empresa_id: company.id,
              grupo_id: 0,
              ordem: 0,
              descricao: 'Ofertas',
              tipo: 0,
              imagem: offerIcon,
              produtos: values[1].value,
            };

            setGrupos([tempGroup, ...values[0].value]);
            setGruposTemp([tempGroup, ...values[0].value]);
          } else {
            setGrupos(values[0].value);
            setGruposTemp(values[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      empresaApi.getParametro(`${company.id}`, 'customizations').then((res) => {
        if (res) {
          const role: DefaultTheme = JSON.parse(res.role);
          if (role.title === 'custom') {
            setTheme({
              title: role.title,
              primary: role.primary,
              secondary: role.secondary,
            });
          }
        }
      });

      empresaApi.getParametro(company.id.toString(), 'whatsapp').then((res) => {
        if (res) {
          if (res.role) {
            const tempObj = JSON.parse(res.role);
            if (tempObj.number && tempObj.status) {
              setWhatsappNumber(tempObj.number);
            }
          }
        }
      });

      api
        .get(`empresa/banner/${company.id}`)
        .then(({ data }) => {
          setBanners(
            data.map((banner: IBanner) => ({
              url: `${process.env.REACT_APP_RETAGUARDA_API_FILES}${banner.name}`,
              product_id: banner.product_id,
              product: banner.product,
              ordem: banner.ordem,
            })),
          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });

      empresaApi
        .getScheduledDelivery(company.id)
        .then((days) => {
          setScheduledDays(days);
        })
        .catch((error) => {});
    }
  }, [company]);

  const handleSearch = useCallback(
    _.debounce((name: string) => {
      const validationForTempProd = (produto: string) => {
        return produto
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            name
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          );
      };

      let groupsTemp: IGrupoProduto[] = grupos
        .map((grupo) => {
          const prodTemp = grupo.produtos.filter((produto) => {
            return validationForTempProd(produto.descricao);
          });
          return { ...grupo, produtos: prodTemp };
        })
        .filter((grupo) => grupo.produtos.length > 0);

      if (groupsTemp.length === 0) {
        groupsTemp = grupos.filter((grupo) =>
          validationForTempProd(grupo.descricao),
        );
      }

      setGruposTemp(groupsTemp);
    }, 1000),
    [grupos],
  );

  return (
    <Container>
      <Helmet>
        <title>{company.slug}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
        <link
          rel="icon"
          sizes="32x32"
          type="image/png"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
        <link
          rel="icon"
          sizes="16x16"
          type="image/png"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
        <link
          rel="shortcut icon"
          type="imagem/x-icon"
          href={`${process.env.REACT_APP_RETAGUARDA_API_FILES}${company.logomarca}`}
        />
      </Helmet>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CustomAppBar hasSearchField search={handleSearch} />
          <Content>
            <CustomCarousel
              paddingTop="30%"
              banners={banners}
              handleSelectedProduct={() => {
                //
              }}
              setProductIdByBanner={setProductIdByBanner}
            />
            <CompanyInfo company={company} />
            {gruposTemp.length > 0 ? (
              <>
                <SegmentFilter>
                  <SegmentCarousel
                    data={gruposTemp}
                    chosen={selectedSegment}
                    onChange={(n: number) => {
                      setSelectedSegment(n);
                    }}
                    isLoading={false}
                  />
                </SegmentFilter>
                {gruposTemp.map((grupo) => {
                  return (
                    <ClothingGroup
                      key={grupo.id}
                      group={grupo}
                      company={company}
                      productIdByBanner={productIdByBanner}
                      setNullProductIdByBanner={() =>
                        setProductIdByBanner(null)
                      }
                      scheduledDays={scheduledDays}
                    />
                  );
                })}
              </>
            ) : (
              <CircularProgress style={{ alignSelf: 'center' }} />
            )}
            {whatsappNumber && (
              <IconButton
                aria-label="start-conversation"
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${whatsappNumber}&text=${' '}`,
                  );
                }}
                className={classes.whatsappButton}
              >
                <WhatsAppIcon
                  fontSize="large"
                  className={classes.whatsappIcon}
                />
              </IconButton>
            )}
          </Content>
          <Footer />
        </>
      )}
    </Container>
  );
};
