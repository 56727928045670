import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonArrow: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  iconArrow:{
    width: 30,
    height: 30,
    [theme.breakpoints.up(640)]: {
      height: 0
    },
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  iconClose:{
    [theme.breakpoints.down(640)]: {
      height: 0
    },
  },
  detailTitle: {
    fontSize: '1.2rem'
  },
  itemTitle: {
    fontSize: '1.2rem',
    [theme.breakpoints.up(960)]: {
      fontSize: '1.4rem',
      marginTop: 12,
      lineHeight: '1.5'
    },
  },
  moreItems: {
    color: '#B22222',
    fontSize: '1rem',
    textDecoration: 'none',
    fontWeight: 400,

    transition: 'all 0.35s ease-in-out',


    '&:hover':{
      color: '#FF0000',
      textDecoration: 'none'
    },
  },
  showNoteLink: {
    color: '#B22222',
    fontSize: '0.9rem',
    fontWeight: 400
  },
  total:{
    fontSize: '1.2rem',
    color: '#424242',
    fontWeight: 500
  },
  subDelTax:{
    fontSize: '1rem',
    color: '#757575'
  },
  itemList:{
    listStyleType: 'circle'
  },
  hide:{
    display: 'none !important',
  },
  scroll:{
    height: 'calc(80vh - 50px) !important'
  },
  offset1: {
    minHeight: 90
  },
  note: {
    fontSize: '1.05rem',
    '& span':{
      fontWeight: 500
    }
  },
  status:{
    lineHeight: '1.4rem',
    fontSize: '0.9rem',
    fontWeight: 500,
    padding: '0px 6px',
    backgroundColor: '#eeeeee'
  },
  deliveryTime:{
    lineHeight: '1rem',
    fontSize: '0.9rem'
  },
  descValue: {
    fontSize: '1rem',
    color: 'green'
  },
  modalConfimation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalConfimationContainer: {
    padding: '10px 5px',
    background: '#f5f5f5',
    borderRadius: '4px'
  },
  modalConfimationButtonClose: {
    width: 25,
    height: 25,
    padding: 0,
  },
  modalConfimationItemTitle: {
    fontSize: '1.2rem',
    [theme.breakpoints.up(960)]: {
      fontSize: '1.4rem',
      marginTop: 12,
      lineHeight: '1.5'
    },
    margin: '12px 0px'
  },
  loading: {
    alignSelf: 'center',
    marginTop: '15px'
  }
}));
