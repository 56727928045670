import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import {
  Button,
  Divider,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import { IProduto } from '../../../../interfaces/produto';
import { calcOrderPrice } from '../../../../utils/calcOrderPriceWOPromo';
import { ColorGroup } from './ColorGroup';
import { SizeGroup } from './SizeGroup';
import { IComplementoCarrinho } from '../../../../interfaces/complementoCarrinho';

import {
  Container,
  CarouselContainer,
  InformationsContainer,
  PriceContainer,
  QuantityContainer,
} from './styles';

import stylesMaterial from './stylesMaterial';
import ProductComponentGroup from '../ProductComponentGroup';
import { IGrupoComplemento } from '../../../../interfaces/grupoComplemento';
import { Context } from '../../../../hooks/cartStore';
import { IProdutoGrade } from '../../../../interfaces/produtoGrade';
import { IItemCarrinho } from '../../../../interfaces/itemCarrinho';
import { ICor } from '../../../../interfaces/cor';
import { useTheme } from '../../../../hooks/useThemeState';
import { ProductCarousel } from '../../../product-carousel';
import { IEstoque } from '../../../../interfaces/estoque';
import { Shipping } from '../../../../services/pedidoApi';
import { ConsultShippingModal } from '../../../consult-shipping-modal';
import { toast } from 'react-toastify';

export interface SizeForShow {
  name: string;
  estoque: IEstoque;
  ordem: number;
}

export interface ColorForShow {
  color: ICor;
  sizes: SizeForShow[];
}

interface ClotheProductProps {
  product: IProduto;
  isStoreOpen: boolean;
  companyId: number;
  onClose: () => void;
  hasMelhorEnvio: boolean;
}

export function ClotheProduct({
  product,
  isStoreOpen,
  companyId,
  onClose,
  hasMelhorEnvio,
}: ClotheProductProps) {
  const classes = stylesMaterial();
  const { state, dispatch, setOpenCart } = useContext(Context);

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [complementos, setComplementos] = useState(
    new Array<IComplementoCarrinho>(),
  );
  const [groupsUnderMinimum, setGroupsUnderMinimum] = useState<
    { id: number; under: boolean }[]
  >([]);
  const [anyGroupUnder, setAnyGroupUnder] = useState(false);

  const [colors, setColors] = useState<ColorForShow[]>([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState<ColorForShow | null>(null);
  const [selectedProdutoGrade, setselectedProdutoGrade] =
    useState<IProdutoGrade | null>(
      product.produto_grades.find((grade) => grade.destaque) || null,
    );
  const [openConsultShippingModal, setOpenConsultShippingModal] =
    useState(false);
  const [selectedShipping, setSelectedShipping] = useState<Shipping | null>(
    null,
  );

  const { theme } = useTheme();

  let id = 0;
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    let tempColors = product.produto_grades.map(
      (produtoGrade) => produtoGrade.cor,
    );

    tempColors = tempColors.filter((color, index) => {
      return (
        tempColors.findIndex((tempColor) => tempColor.id === color.id) === index
      );
    });

    const tempColorsWithSizes = tempColors.map((color) => {
      return {
        color,
        sizes: product.produto_grades
          .filter((produtoGrade) => produtoGrade.cor.id === color.id)
          .map((produtoGrade) => {
            return {
              name: produtoGrade.tamanho.descricao,
              estoque: produtoGrade.estoque,
              ordem: produtoGrade.tamanho.ordem,
            };
          }),
      };
    });

    setColors(tempColorsWithSizes);

    const productFeatured = product.produto_grades.find(
      (grid) => grid.destaque,
    );

    if (productFeatured) {
      const tempDefaultColor = tempColorsWithSizes.find(
        (cor: any) => cor.color.id === productFeatured.cor.id,
      );
      if (tempDefaultColor) {
        setSelectedColor(tempDefaultColor);
      }
      setSelectedSize(productFeatured.tamanho.descricao);
    } else {
      product.produto_grades.forEach((produtoGrade) => {
        if (product.minGradePreco?.produto_grade_id === produtoGrade.id) {
          const tempDefaultColor = tempColorsWithSizes.find(
            (cor: any) => cor.color.id === produtoGrade.cor.id,
          );
          if (tempDefaultColor) {
            setSelectedColor(tempDefaultColor);
          }
          setSelectedSize(produtoGrade.tamanho.descricao);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedProdutoGrade) {
      const { estoque } = selectedProdutoGrade;
      if (
        estoque &&
        estoque.ativo &&
        estoque.estoque_atual < quantity &&
        estoque.estoque_atual > 0
      ) {
        setQuantity(Number(estoque.estoque_atual));
      }
    }
  }, [selectedProdutoGrade]);

  useEffect(() => {
    if (product && selectedProdutoGrade) {
      setPrice(
        calcOrderPrice({
          id: product.id,
          prodComposto: {
            produto: product,
            complementos,
          },
          produtosGrade: [],
          produtoGrade: selectedProdutoGrade,
          tamanho: null,
          quantidade: quantity,
          observacao,
        }),
      );
    }
  }, [quantity, complementos, product, selectedProdutoGrade]);

  useEffect(() => {
    product.complemento.map((comp) => {
      if (groupsUnderMinimum !== undefined) {
        let tempGroupsUnder = groupsUnderMinimum;
        if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
          tempGroupsUnder.push({ id: comp.id, under: true });
        } else {
          tempGroupsUnder.push({ id: comp.id, under: false });
        }
        setGroupsUnderMinimum(tempGroupsUnder);
      } else {
        if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
          setGroupsUnderMinimum([{ id: comp.id, under: true }]);
        } else {
          setGroupsUnderMinimum([{ id: comp.id, under: false }]);
        }
      }
    });
  }, [product]);

  useEffect(() => {
    let counter = 0;

    if (groupsUnderMinimum !== undefined) {
      groupsUnderMinimum.map((group) => {
        if (group.under) {
          setAnyGroupUnder(true);
        } else {
          counter += 1;
        }
      });
      if (counter === groupsUnderMinimum.length) {
        setAnyGroupUnder(false);
      }
    }
  }, [groupsUnderMinimum]);

  useEffect(() => {
    if (selectedColor) {
      const tempProdutoGrade = product.produto_grades.find((produtoGrade) => {
        return (
          produtoGrade.tamanho.descricao === selectedSize &&
          produtoGrade.cor.id === selectedColor?.color.id
        );
      });

      if (tempProdutoGrade) {
        setselectedProdutoGrade(tempProdutoGrade);
      }
    }
  }, [selectedSize, selectedColor]);

  const setComponents = (cartComponent: IComplementoCarrinho) => {
    let temp: IComplementoCarrinho[];
    if (product !== undefined) {
      const grupo = product.complemento.find(
        (group) => cartComponent.complemento.complemento_id === group.id,
      );
      if (grupo !== undefined) {
        if (_.toNumber(grupo.qtd_max) === 1) {
          temp = complementos.filter((item: IComplementoCarrinho) => {
            return (
              item.complemento.id !== cartComponent.complemento.id &&
              item.complemento.complemento_id !==
                cartComponent.complemento.complemento_id
            );
          });
        } else {
          temp = complementos.filter((item: IComplementoCarrinho) => {
            return item.complemento.id !== cartComponent.complemento.id;
          });
        }

        if (cartComponent.quantidade > 0) {
          temp.push(cartComponent);
        }
        setComplementos(temp);
      }
    }
  };

  const handleGroupsUnderMinimum = (
    groups: { id: number; under: boolean }[],
  ) => {
    setGroupsUnderMinimum(groups);
  };

  const addToCarts = () => {
    if (!isStoreOpen) {
      toast.error('Ops, no momento estamos fechados!', toast_config);
      return;
    }

    if (anyGroupUnder) {
      const firstGroupUnder = groupsUnderMinimum.find(group => group.under);

      if(firstGroupUnder) {
        const groupElement = document.getElementById(`Grupo-Complemento-${firstGroupUnder.id}`);

        if (groupElement) {
          groupElement.scrollIntoView({ behavior: 'smooth' });
          toast.error('Ops, selecione seu complemento', toast_config);
        }
      }
      return;
    }

    let cart = state.carts.find((cart) => cart.id === companyId);

    if (cart) {
      addItemToCart(cart.cart);
    } else {
      dispatch({
        type: 'ADD_CART',
        empresaId: companyId,
        payload: [
          {
            id,
            prodComposto: {
              produto: product,
              complementos,
            },
            produtosGrade: [],
            produtoGrade: selectedProdutoGrade,
            tamanho: null,
            quantidade: quantity,
            observacao,
          },
        ],
      });
    }
    onClose();
    setOpenCart(true);
  };

  const addItemToCart = (cart: IItemCarrinho[]) => {
    if (cart.length) {
      id = cart[cart.length - 1].id + 1;
    }

    dispatch({
      type: 'ADD_ITEM',
      empresaId: companyId,
      payload: {
        id,
        prodComposto: {
          produto: product,
          complementos,
        },
        produtosGrade: [],
        produtoGrade: selectedProdutoGrade,
        tamanho: null,
        quantidade: quantity,
        observacao,
      },
    });

    setOpenCart(true);
  };

  function handleOpenConsultShippingModal() {
    setOpenConsultShippingModal(true);
  }

  function handleCloseConsultShippingModal() {
    setOpenConsultShippingModal(false);
  }

  return (
    <Container>
      <CarouselContainer>
        <ProductCarousel
          images={
            selectedProdutoGrade && selectedProdutoGrade.imagens.length > 0
              ? selectedProdutoGrade.imagens
                  .sort((a, b) => a.ordem - b.ordem)
                  .map((imagem) => imagem.imagem)
              : [product.imagem]
          }
        />
      </CarouselContainer>
      <InformationsContainer>
        <Typography className={classes.clotheTitle}>
          {product.descricao}
        </Typography>
        <div>
          <Typography className={classes.description}>
            {product.observacao}
          </Typography>
          {selectedProdutoGrade?.estoque &&
          selectedProdutoGrade.estoque.ativo &&
          selectedProdutoGrade.estoque.estoque_atual == 0 ? (
            <Typography
              className={classes.price}
              style={{ color: theme.primary }}
            >
              Sem estoque
            </Typography>
          ) : (
            <PriceContainer>
              {product.produto_promocao &&
                product.produto_promocao.filter(
                  (produto_promocao) => produto_promocao.produto !== null,
                ).length > 0 && (
                  <MotorcycleIcon
                    titleAccess={product.produto_promocao
                      .filter(
                        (produto_promocao) => produto_promocao.produto !== null,
                      )
                      .map((produto_promocao, index) => {
                        let text = '';

                        if (index !== 0) {
                          text += '\n\n';
                        }

                        text += `${produto_promocao.promocao?.nome}\n`;
                        text += produto_promocao.promocao?.descricao;

                        return text;
                      })
                      .reduce((prev, next) => prev.concat(next))}
                    style={{ fontSize: 28 }}
                  />
                )}
              <Typography
                className={classes.price}
                style={{
                  color: selectedProdutoGrade?.promocao?.ativo
                    ? '#0c65ea'
                    : theme.primary,
                }}
              >
                R${' '}
                {Number(
                  selectedProdutoGrade?.promocao
                    ? price - selectedProdutoGrade.promocao.preco
                    : price,
                )
                  .toFixed(2)
                  .replace('.', ',')}
              </Typography>
              {selectedProdutoGrade?.promocao?.ativo && (
                <>
                  <Typography className={classes.oldPrice}>
                    R$ {Number(price).toFixed(2).replace('.', ',')}
                  </Typography>
                  <Typography className={classes.discount}>
                    -
                    {Math.round(
                      selectedProdutoGrade.promocao.fator * 100,
                    ).toFixed(0)}
                    %
                  </Typography>
                </>
              )}
              {selectedShipping && (
                <Typography className={classes.shipping}>
                  {selectedShipping.name}: +R${' '}
                  {selectedShipping.price.replace('.', ',')}
                </Typography>
              )}
            </PriceContainer>
          )}
        </div>
        <Divider className={classes.divider} />
        <div>
          <Typography className={classes.cores}>
            COR - {selectedColor?.color.descricao}
          </Typography>
          <ColorGroup
            colors={colors}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            setSelectedSize={setSelectedSize}
          />
        </div>
        <Divider className={classes.divider} />
        <div>
          <Typography className={classes.cores}>TAMANHO</Typography>
          <SizeGroup
            sizes={selectedColor ? selectedColor.sizes : []}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
          />
        </div>
        <Divider className={classes.divider} />

        {product.complemento.map((grupoComplemento: IGrupoComplemento) => {
          return (
            <ProductComponentGroup
              grupoComplemento={grupoComplemento}
              setComponent={setComponents}
              complementos={complementos}
              isLoading={false}
              groupsUnderMinimum={groupsUnderMinimum}
              handleGroupsUnderMinimum={handleGroupsUnderMinimum}
              key={grupoComplemento.id}
            />
          );
        })}

        <QuantityContainer>
          <IconButton
            color="primary"
            aria-label="remover
            item"
            component="span"
            disabled={quantity === 1}
            onClick={() => setQuantity(quantity - 1)}
            style={{ color: theme.primary }}
          >
            <RemoveCircleIcon />
          </IconButton>
          <Typography className={classes.quantity}>{quantity}</Typography>
          <IconButton
            color="primary"
            aria-label="adicionar item"
            component="span"
            disabled={
              selectedProdutoGrade?.estoque &&
              selectedProdutoGrade.estoque.ativo &&
              quantity == selectedProdutoGrade.estoque.estoque_atual
            }
            onClick={() => setQuantity(quantity + 1)}
            style={{ color: theme.primary }}
          >
            <AddCircleIcon />
          </IconButton>
        </QuantityContainer>

        {hasMelhorEnvio && (
          <Link
            component="button"
            onClick={handleOpenConsultShippingModal}
            className={classes.consultShipping}
          >
            Consultar frete
          </Link>
        )}

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={addToCarts}
          disabled={
            selectedProdutoGrade?.estoque &&
            selectedProdutoGrade.estoque.ativo &&
            selectedProdutoGrade.estoque.estoque_atual == 0
          }
          style={{
            marginTop: '20px',
            width: '80%',
            height: '46px',
            alignSelf: 'center',
            backgroundColor:
              selectedProdutoGrade?.estoque &&
              selectedProdutoGrade.estoque.ativo &&
              selectedProdutoGrade.estoque.estoque_atual == 0
                ? '#757575'
                : theme.primary,
          }}
        >
          COLOCAR NA SACOLA
        </Button>
      </InformationsContainer>
      {openConsultShippingModal && selectedProdutoGrade && (
        <ConsultShippingModal
          openConsultShippingModal={openConsultShippingModal}
          handleCloseConsultShippingModal={handleCloseConsultShippingModal}
          gridId={selectedProdutoGrade.id}
          quantity={quantity}
          companyId={companyId}
          selectedShipping={selectedShipping}
          setSelectedShipping={setSelectedShipping}
        />
      )}
    </Container>
  );
}
