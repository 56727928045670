import React, { useEffect, useState } from 'react';

import 'moment/locale/pt-br';

import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { IPedidoVenda } from '../../interfaces/pedidoVenda';
import { useTheme } from '../../hooks/useThemeState';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import { CustomLinearProgress, CustomPaper, CustomTimelineDot } from './styles';
import { Steps } from '../sale-conclusion/styles';

const useStyles = makeStyles((theme) => ({
  timeline: {
    marginTop: '0px'
  },
  paper: {
    padding: '10px',
  },
  primaryColor: {
    backgroundColor: "#B22222",
  },
  title:{
    fontSize: '1rem'
  },
  text:{
    fontSize: '0.90rem'
  }
}));

interface OrderTimelineComponentSaleProps{
  order: IPedidoVenda;
}

const OrderTimelineComponentSale: React.FC<OrderTimelineComponentSaleProps> = ({ order }) => {
  const classes = useStyles();
  const { theme } = useTheme()
  const [inicio, setInicio] = useState("");
  const [preparoIniciado, setPreparoIniciado] = useState("");
  const [saiuEntrega, setSaiuEntrega] = useState("");
  const [retiradoEm, setRetiradoEm] = useState("");
  const [dataFechamento, setDataFechamento] = useState("");
  const [prontoParaRetiradaEm, setProntoParaRetiradaEm] = useState("");

  const moment = require('moment');

  useEffect(() => {
    moment.locale();

    if((order.dataAbertura !== undefined) && (order.dataAbertura !== null)){
      setInicio(moment(new Date(order.dataAbertura)).format('LT L'));
      if((order.preparoIniciadoEm !== undefined) && (order.preparoIniciadoEm !== null)){
        setPreparoIniciado(moment(new Date(order.preparoIniciadoEm)).format('LT L'));
        if(!!order.saiuEntregaEm){
          setSaiuEntrega(moment(new Date(order.saiuEntregaEm)).format('LT L'));
          if(!!order.dataFechamento){
            setDataFechamento(moment(new Date(order.dataFechamento)).format('LT L'));
          }
        }
        if((order.retiradoEm !== undefined) && (order.retiradoEm !== null)){
          setRetiradoEm(moment(new Date(order.retiradoEm)).format('LT L'));
        }

      }
    }

    if(order.prontoParaRetiradaEm){
      setProntoParaRetiradaEm(moment(new Date(order.prontoParaRetiradaEm)).format('LT L'));
    }
  }, [order]);

  const StepsCase: React.FC = () => {
    switch (order.estagio) {
      case 0: // Pedido em aguardo
        return (
          <Steps >
            <CustomLinearProgress variant="indeterminate"/>
            <CustomLinearProgress variant="determinate" value={0}/>
            <CustomLinearProgress variant="determinate" value={0}/>
          </Steps>
        )
        case 2: // Pedido negado
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
            </Steps>
          )
        case 3: // Pedido em preparação
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="indeterminate"/>
              <CustomLinearProgress variant="determinate" value={0}/>
            </Steps>
          )
        case 5: // Pedido em rota de entrega
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="indeterminate"/>
            </Steps>
          )
        case 6: // Pedido entregue
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
            </Steps>
          )
        case 9: // Pedido cancelado pela loja
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
            </Steps>
          )
        case 11: // Pedido pronto para retirada na loja
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="indeterminate"/>
            </Steps>
          )
        default:
          return (
            <Steps >
              <CustomLinearProgress variant="determinate" value={100}/>
              <CustomLinearProgress variant="indeterminate"/>
              <CustomLinearProgress variant="determinate" value={100}/>
            </Steps>
          )
      }
    }

    return (
      <Timeline
        className={classes.timeline}
      style={{
        alignItems: 'flex-end',
        width: 'min-content',
        margin: '0 auto',
        padding: 0,
      }}
    >
      <StepsCase/>
      { // confirmado ou não
        order.estagio === 0
          ? (
            <TimelineItem>
              <TimelineOppositeContent  >
                {inicio.split(' ').map(item => (
                  <Typography variant="body2" color="textSecondary">{item}</Typography>
                  ))}

              </TimelineOppositeContent>
              <TimelineSeparator>
                <CustomTimelineDot efect={true} className={classes.primaryColor} >
                  <AddShoppingCartIcon />
                </CustomTimelineDot>
                <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
              </TimelineSeparator>
              <TimelineContent >
                <CustomPaper efect={true} elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" className={classes.title} >
                    Pedido feito
                  </Typography>
                </CustomPaper>
              </TimelineContent>
            </TimelineItem>
          )
          : (
            order.estagio === 2
              ? (
                <TimelineItem>
                  <TimelineSeparator>
                    <CustomTimelineDot efect={true} className={classes.primaryColor} >
                      <ThumbDownAltIcon/>
                    </CustomTimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    <CustomPaper efect={true} elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1" className={classes.title} >
                        Pedido negado
                      </Typography>
                      <Typography className={classes.text} >O seu pedido foi cancelado</Typography>
                    </CustomPaper>
                  </TimelineContent>
                </TimelineItem>
              )
              : (
                <TimelineItem style={{ opacity: 0.55 }} >
                  <TimelineOppositeContent  >
                    <Typography variant="body2" color="textSecondary">
                      {preparoIniciado}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <CustomTimelineDot className={classes.primaryColor}>
                      <ThumbUpAltIcon/>
                    </CustomTimelineDot>
                    <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <CustomPaper elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1" className={classes.title} >
                        Pedido confirmado
                      </Typography>
                      <Typography className={classes.text}>Seu pedido foi aceito</Typography>
                    </CustomPaper>
                  </TimelineContent>
                </TimelineItem>
              )
          )
      }
      { // demais estagio
        order.estagio === 3
          ? (
            <TimelineItem >
              <TimelineOppositeContent  >
                <Typography variant="body2" color="textSecondary">
                  {preparoIniciado}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <CustomTimelineDot efect className={classes.primaryColor}>
                  <ThumbUpAltIcon/>
                </CustomTimelineDot>
                <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
              </TimelineSeparator>
              <TimelineContent>
                <CustomPaper efect elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" className={classes.title} >
                    Em preparação
                  </Typography>
                  <Typography className={classes.text}>Seu pedido está em preparação</Typography>
                </CustomPaper>
              </TimelineContent>
            </TimelineItem>
          )
          : (
            <TimelineItem style={{ opacity: 0.55 }} >
              <TimelineOppositeContent  >
                <Typography variant="body2" color="textSecondary">
                  {preparoIniciado}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <CustomTimelineDot className={classes.primaryColor} >
                  <ThumbUpAltIcon/>
                </CustomTimelineDot>
                <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
              </TimelineSeparator>
              <TimelineContent>
                <CustomPaper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" className={classes.title} >
                    Em preparação
                  </Typography>
                  <Typography className={classes.text}>Seu pedido está em preparação</Typography>
                </CustomPaper>
              </TimelineContent>
            </TimelineItem>
          )
      }
      { // entregue ou não
        order.estagio === 5
          ? (
            <TimelineItem>
              <TimelineOppositeContent >
                <Typography variant="body2" color="textSecondary">
                  {saiuEntrega}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <CustomTimelineDot efect className={classes.primaryColor} style={{ background: theme.primary }}>
                  <SportsMotorsportsIcon/>
                </CustomTimelineDot>
                <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
              </TimelineSeparator>
              <TimelineContent>
                <CustomPaper efect elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" className={classes.title} >
                    Saiu para entrega
                  </Typography>
                  <Typography className={classes.text} >Em rota</Typography>
                </CustomPaper>
              </TimelineContent>
            </TimelineItem>
          )
          : (
            order.estagio === 6
            ? (
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {dataFechamento}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <CustomTimelineDot efect className={classes.primaryColor}>
                    <CheckCircleOutlineIcon/>
                  </CustomTimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <CustomPaper efect elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1" className={classes.title} >
                      Pedido finalizado
                    </Typography>
                  </CustomPaper>
                </TimelineContent>
              </TimelineItem>
            )
            : (
              order.estagio === 11
                ? (
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {prontoParaRetiradaEm}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <CustomTimelineDot efect className={classes.primaryColor}>
                        <DirectionsWalkIcon/>
                      </CustomTimelineDot>
                      <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <CustomPaper efect elevation={3} className={classes.paper}>
                        <Typography variant="h6" component="h1" className={classes.title} >
                          Pronto para retirar
                        </Typography>
                      </CustomPaper>
                    </TimelineContent>
                  </TimelineItem>
                )
                : (
                  order.estagio === 9
                    ? (
                      <TimelineItem>
                        <TimelineOppositeContent>
                          <Typography variant="body2" color="textSecondary">
                            {dataFechamento}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <CustomTimelineDot efect className={classes.primaryColor}>
                            <ThumbDownAltIcon/>
                          </CustomTimelineDot>
                          <TimelineConnector className={classes.primaryColor} style={{ background: theme.primary }} />
                        </TimelineSeparator>
                        <TimelineContent>
                        <CustomPaper efect elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1" className={classes.title} >
                            Cancelado
                          </Typography>
                          <Typography className={classes.text} >Cancelado pela loja</Typography>
                        </CustomPaper>
                        </TimelineContent>
                      </TimelineItem>
                    )
                    : (
                      order.enderecoEntregaId
                        ? (
                          <TimelineItem style={{ opacity: 0.55 }}>
                            <TimelineSeparator>
                              <CustomTimelineDot >
                                <DirectionsWalkIcon/>
                              </CustomTimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <CustomPaper elevation={3} className={classes.paper}>
                                <Typography variant="h6" component="h1" className={classes.title} >
                                  Saída para entrega
                                </Typography>
                              </CustomPaper>
                            </TimelineContent>
                          </TimelineItem>
                        ) : (
                          <TimelineItem style={{ opacity: 0.55 }}>
                            <TimelineSeparator>
                              <CustomTimelineDot >
                                <DirectionsWalkIcon/>
                              </CustomTimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <CustomPaper elevation={3} className={classes.paper}>
                                <Typography variant="h6" component="h1" className={classes.title} >
                                  Pronto para retirar
                                </Typography>
                              </CustomPaper>
                            </TimelineContent>
                          </TimelineItem>
                        )
                    )
                )
            )
          )
      }
    </Timeline>
  );
}

export default OrderTimelineComponentSale;
