import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonBase: {
    display: '-webkit-flex',
    flexDirection: 'column',
    maxWidth: '120px'
  },
  image: {
    position: 'relative',
    height: 60,
    width: 60,
    borderRadius: '50%',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
    },
  },
  focusVisible: {},
  imageSrc: {
    filter: 'none',
  },
  desc: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.7rem',
    fontWeight: 'bold'
  },
}));
