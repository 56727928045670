import React, { useContext, useEffect, useRef, useState } from 'react';

import _ from 'lodash';

import {
  Button,
  Divider,
  IconButton,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import { IProduto } from '../../../../interfaces/produto';
import { calcOrderPrice } from '../../../../utils/calculator';
import { calcOrderPrice as calcWoPromo } from '../../../../utils/calcOrderPriceWOPromo';
import { IComplementoCarrinho } from '../../../../interfaces/complementoCarrinho';

import {
  CarouselContainer,
  Container,
  InformationsContainer,
  PriceContainer,
  QuantityContainer,
} from './styles';

import stylesMaterial from './stylesMaterial';
import ProductComponentGroup from '../ProductComponentGroup';
import { IGrupoComplemento } from '../../../../interfaces/grupoComplemento';
import { Context } from '../../../../hooks/cartStore';
import { IItemCarrinho } from '../../../../interfaces/itemCarrinho';
import { IProdutoGrade } from '../../../../interfaces/produtoGrade';
import PizzaSizesRadio from './pizza-sizes-radio';
import { IProdutoGradeCarrinho } from '../../../../interfaces/produtoGradeCarrinho';
import produtosApi from '../../../../services/produtosApi';
import { toast } from 'react-toastify';
import PizzaComponent from './pizza-component';
import { useTheme } from '../../../../hooks/useThemeState';
import { ProductCarousel } from '../../../product-carousel';
import { PizzaComponentCheckbox } from './pizza-component-checkbox';

interface PizzaProductProps {
  product: IProduto;
  isStoreOpen: boolean;
  companyId: number;
  onClose: () => void;
}

export function PizzaProduct({
  product,
  isStoreOpen,
  companyId,
  onClose,
}: PizzaProductProps) {
  const classes = stylesMaterial();
  const { state, dispatch, setOpenCart } = useContext(Context);
  const afterFlavorsRef = useRef<HTMLSpanElement>(null);

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [priceWithOffer, setPriceWithOffer] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [complementos, setComplementos] = useState(
    new Array<IComplementoCarrinho>(),
  );
  const [groupsUnderMinimum, setGroupsUnderMinimum] = useState<
    { id: number; under: boolean }[]
  >([]);
  const [anyGroupUnder, setAnyGroupUnder] = useState(false);
  const [srcImg, setSrcImage] = useState(product.imagem);
  const [selectedSizeId, setSelectedSizeId] = useState('');
  const [selectedSize, setSelectedSize] = useState<IProdutoGrade>();
  const [step, setStep] = useState(1);

  const [pizzaOptions, setPizzaOptions] = useState<IProduto[]>([]);
  const [selectedPizzaOptions, setSelectedPizzaOptions] = useState<
    IProdutoGradeCarrinho[]
  >([]);
  const [selectedPizzaOptionsTemp, setSelectedPizzaOptionsTemp] = useState<
    IProdutoGradeCarrinho[]
  >([]);
  const [numSelectedFlavors, setNumSelectedFlavors] = useState(0);
  const [maxFlavorsSelected, setMaxFlavorsSelected] = useState(false);
  const [hasPromo, setHasPromo] = useState(false);

  const { theme } = useTheme();

  let id = 0;

  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (product && selectedPizzaOptionsTemp.length > 0) {
      setPrice(
        calcWoPromo({
          id,
          prodComposto: {
            produto: product,
            complementos,
          },
          produtosGrade: selectedPizzaOptionsTemp,
          tamanho: null,
          quantidade: quantity,
          observacao,
        }),
      );
      setPriceWithOffer(
        calcOrderPrice({
          id,
          prodComposto: {
            produto: product,
            complementos,
          },
          produtosGrade: selectedPizzaOptionsTemp,
          tamanho: null,
          quantidade: quantity,
          observacao,
        }),
      );
    }

    setHasPromo(
      selectedPizzaOptionsTemp.findIndex(
        (option) => option.produto.produto_grades[0].promocao?.ativo,
      ) >= 0,
    );
  }, [quantity, complementos, product, selectedPizzaOptionsTemp]);

  useEffect(() => {
    if (product.produto_grades.length === 1) {
      setSelectedSizeId(product.produto_grades[0].id.toString());
      setSelectedSize(product.produto_grades[0]);

      if (!product.vitrine) {
        setSelectedPizzaOptions([{
          produto: product,
          quantidade: 1,
        }]);
      }

      setStep(2);
    }

    product.complemento.map((comp) => {
      if (groupsUnderMinimum !== undefined) {
        let tempGroupsUnder = groupsUnderMinimum;
        if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
          tempGroupsUnder.push({ id: comp.id, under: true });
        } else {
          tempGroupsUnder.push({ id: comp.id, under: false });
        }
        setGroupsUnderMinimum(tempGroupsUnder);
      } else {
        if (_.toNumber(comp.qtd_min) > 0 && _.toNumber(comp.qtd_max) > 1) {
          setGroupsUnderMinimum([{ id: comp.id, under: true }]);
        } else {
          setGroupsUnderMinimum([{ id: comp.id, under: false }]);
        }
      }
    });
  }, [product]);

  useEffect(() => {
    let counter = 0;

    if (groupsUnderMinimum !== undefined) {
      groupsUnderMinimum.map((group) => {
        if (group.under) {
          setAnyGroupUnder(true);
        } else {
          counter += 1;
        }
      });
      if (counter === groupsUnderMinimum.length) {
        setAnyGroupUnder(false);
      }
    }
  }, [groupsUnderMinimum]);

  useEffect(() => {
    if (selectedSize) {
      produtosApi
        .getProdutosByTamanhos(selectedSize.tamanho_id)
        .then((res) => {
          setPizzaOptions(res);
          let temp = res.find((prod) => prod.id === product.id);
          if (temp) {
            setSelectedPizzaOptions([{ produto: temp, quantidade: 1 }]);
          }
        })
        .catch((erro) => {
          console.log(erro);
        });
    }
  }, [selectedSize]);

  useEffect(() => {
    if (selectedSize && step === 2) {
      if (selectedSize.qtd_sabores > 1) {
        toast.info('Você pode adicionar sabores', toast_config);
      }
    }
  }, [selectedSize, step]);

  useEffect(() => {
    if (selectedPizzaOptions.length > 0) {
      setNumSelectedFlavors(
        selectedPizzaOptions
          .map((option) => option.quantidade)
          .reduce((prev, next) => prev + next),
      );
    } else {
      setNumSelectedFlavors(0);
    }
  }, [selectedPizzaOptions]);

  useEffect(() => {
    if (selectedSize) {
      if (numSelectedFlavors === selectedSize.qtd_sabores) {
        setMaxFlavorsSelected(true);
      } else {
        setMaxFlavorsSelected(false);
      }

      if (selectedPizzaOptions.length > 0) {
        setSelectedPizzaOptionsTemp(
          selectedPizzaOptions.map((option) => {
            return {
              ...option,
              quantidade: option.quantidade / numSelectedFlavors,
            };
          }),
        );
      }
    }
  }, [numSelectedFlavors, selectedSize]);

  useEffect(() => {
    if (product.vitrine && numSelectedFlavors === selectedSize?.qtd_sabores) {
      if (afterFlavorsRef) {
        afterFlavorsRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [numSelectedFlavors]);

  const setComponents = (cartComponent: IComplementoCarrinho) => {
    let temp: IComplementoCarrinho[];
    if (product) {
      const grupo = product.complemento.find(
        (group) => cartComponent.complemento.complemento_id === group.id,
      );
      if (grupo) {
        if (_.toNumber(grupo.qtd_max) === 1) {
          temp = complementos.filter((item: IComplementoCarrinho) => {
            return (
              item.complemento.id !== cartComponent.complemento.id &&
              item.complemento.complemento_id !==
                cartComponent.complemento.complemento_id
            );
          });
        } else {
          temp = complementos.filter((item: IComplementoCarrinho) => {
            return item.complemento.id !== cartComponent.complemento.id;
          });
        }

        if (cartComponent.quantidade > 0) {
          temp.push(cartComponent);
        }
        setComplementos(temp);
      }
    }
  };

  const handleGroupsUnderMinimum = (
    groups: { id: number; under: boolean }[],
  ) => {
    setGroupsUnderMinimum(groups);
  };

  const addToCarts = () => {
    if (!isStoreOpen) {
      toast.error('Ops, no momento estamos fechados!', toast_config);
      return;
    }

    if (anyGroupUnder) {
      const firstGroupUnder = groupsUnderMinimum.find(group => group.under);

      if(firstGroupUnder) {
        const groupElement = document.getElementById(`Grupo-Complemento-${firstGroupUnder.id}`);

        if (groupElement) {
          groupElement.scrollIntoView({ behavior: 'smooth' });
          toast.error('Ops, selecione seu complemento', toast_config);
        }
      }
      return;
    }

    let cart = state.carts.find((cart) => cart.id === companyId);

    if (cart) {
      addItemToCart(cart.cart);
    } else {
      dispatch({
        type: 'ADD_CART',
        empresaId: companyId,
        payload: [
          {
            id,
            prodComposto: {
              produto: product,
              complementos,
            },
            produtosGrade: selectedPizzaOptionsTemp,
            tamanho: selectedSize?.tamanho.descricao,
            quantidade: quantity,
            observacao,
          },
        ],
      });
    }
    onClose();
    setOpenCart(true);
  };

  const addItemToCart = (cart: IItemCarrinho[]) => {
    if (cart.length) {
      id = cart[cart.length - 1].id + 1;
    }

    dispatch({
      type: 'ADD_ITEM',
      empresaId: _.toNumber(companyId),
      payload: {
        id,
        prodComposto: {
          produto: product,
          complementos,
        },
        produtosGrade: selectedPizzaOptionsTemp,
        tamanho: selectedSize?.tamanho.descricao,
        quantidade: quantity,
        observacao,
      },
    });

    setOpenCart(true);
  };

  const handleRadioSelect = (value: string) => {
    if (value === selectedSizeId) {
      setSelectedSizeId('');
    } else {
      setSelectedSizeId(value);
      if (product) {
        const size = product.produto_grades.find(
          (size) => _.toNumber(value) === size.id,
        );
        if (size) {
          setSelectedSize(size);
        }
      }
    }
  };

  const setPizzaComponent = (cartPizzaComponent: IProdutoGradeCarrinho) => {
    let temp: IProdutoGradeCarrinho[];
    temp = selectedPizzaOptions.filter(
      (option) => cartPizzaComponent.produto.id !== option.produto.id,
    );

    if (cartPizzaComponent.quantidade > 0) {
      temp.push(cartPizzaComponent);
    }

    setSelectedPizzaOptions(temp);
  };

  return (
    <Container>
      <CarouselContainer>
        <ProductCarousel
          images={
            product && product.imagens.length > 0
              ? product.imagens
                  .sort((a, b) => a.ordem - b.ordem)
                  .map((imagem) => imagem.imagem)
              : [product.imagem]
          }
        />
      </CarouselContainer>

      {step === 1 ? (
        <InformationsContainer>
          <Typography className={classes.clotheTitle}>
            {product.descricao}
          </Typography>
          <RadioGroup value={selectedSizeId} style={{ marginTop: 20 }}>
            {product.produto_grades
              .sort((a, b) => a.preco.valor - b.preco.valor)
              .map((size) => {
                return (
                  <PizzaSizesRadio
                    tamanho={size}
                    handleClearRadioGroup={handleRadioSelect}
                    key={size.id}
                  />
                );
              })}
          </RadioGroup>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setStep(2)}
            disabled={!selectedSize}
            style={{
              marginTop: '20px',
              width: '80%',
              height: '46px',
              alignSelf: 'center',
              color: '#fff',
              backgroundColor: selectedSize ? theme.primary : '#757575',
            }}
          >
            AVANÇAR
          </Button>

          <Button
            variant="contained"
            onClick={onClose}
            style={{
              border: `1px solid ${theme.primary}`,
              color: theme.primary,
              background: 'transparent',
              height: '46px',
              width: '80%' ,
              marginTop: '10px'
            }}
          >
            Pular Sugestão
          </Button>
        </InformationsContainer>
      ) : (
        <InformationsContainer>
          <Typography className={classes.clotheTitle}>
            {product.descricao} {
              selectedSize && `${selectedSize.tamanho.descricao} (${selectedSize.qtd_pedacos} pedaços)`
            }
          </Typography>
          <div>
            <Typography className={classes.description}>
              {product.observacao}
            </Typography>
            {selectedSize?.estoque &&
            selectedSize.estoque.ativo &&
            selectedSize.estoque.estoque_atual == 0 ? (
              <Typography
                className={classes.price}
                style={{ color: theme.primary }}
              >
                Sem estoque
              </Typography>
            ) : (
              <PriceContainer>
                {product.produto_promocao &&
                  product.produto_promocao.filter(
                    (produto_promocao) => produto_promocao.produto !== null,
                  ).length > 0 && (
                    <MotorcycleIcon
                      titleAccess={product.produto_promocao
                        .filter(
                          (produto_promocao) =>
                            produto_promocao.produto !== null,
                        )
                        .map((produto_promocao, index) => {
                          let text = '';

                          if (index !== 0) {
                            text += '\n\n';
                          }

                          text += produto_promocao.promocao?.nome + '\n';
                          text += produto_promocao.promocao?.descricao;

                          return text;
                        })
                        .reduce((prev, next) => prev.concat(next))}
                      style={{ fontSize: 28 }}
                    />
                  )}
                <Typography
                  className={classes.price}
                  style={{ color: theme.primary }}
                >
                  R${' '}
                  {Number(hasPromo ? priceWithOffer : price)
                    .toFixed(2)
                    .replace('.', ',')}
                </Typography>

                {hasPromo && selectedSize?.promocao && (
                  <>
                    <Typography className={classes.oldPrice}>
                      R$ {Number(price).toFixed(2).replace('.', ',')}
                    </Typography>
                    <Typography className={classes.discount}>
                      -
                      {Math.round(selectedSize.promocao.fator * 100).toFixed(0)}
                      %
                    </Typography>
                  </>
                )}
              </PriceContainer>
            )}
          </div>
          <Divider className={classes.divider} />

          <Typography variant="subtitle1" className={classes.maxFlavors}>
            {product.vitrine 
              ? `Você deve selecionar ${selectedSize?.qtd_sabores} sabores`
              : `Você pode selecionar até ${selectedSize?.qtd_sabores} sabores`
            }
          </Typography>

          <Typography variant="subtitle1" className={classes.flavors}>
            Sabores
          </Typography>
          <Typography variant="subtitle1" className={classes.flavorsQuantity}>
            Sabores selecionados {numSelectedFlavors}
          </Typography>
          <Divider className={classes.divider} />

          {product.vitrine ? (
            (selectedSize &&
              pizzaOptions.map((option) => {
                return (
                  <PizzaComponentCheckbox
                    pizza={option}
                    setPizzaComponent={setPizzaComponent}
                    base={option.produto_grades[0].id === selectedSize?.id}
                    full={maxFlavorsSelected}
                    numSelectedFlavors={numSelectedFlavors}
                    key={option.id}
                  />
                );
              })
            )
          ) : (selectedSize && selectedSize.qtd_sabores > 1 &&
            pizzaOptions.map((option) => {
              return (
                <PizzaComponent
                  pizza={option}
                  setPizzaComponent={setPizzaComponent}
                  base={option.produto_grades[0].id === selectedSize?.id}
                  full={maxFlavorsSelected}
                  numSelectedFlavors={numSelectedFlavors}
                  key={option.id}
                />
              );
            })
          )}

          <span ref={afterFlavorsRef} />

          {product.complemento.map((grupoComplemento: IGrupoComplemento) => {
            return (
              <ProductComponentGroup
                grupoComplemento={grupoComplemento}
                setComponent={setComponents}
                complementos={complementos}
                isLoading={false}
                groupsUnderMinimum={groupsUnderMinimum}
                handleGroupsUnderMinimum={handleGroupsUnderMinimum}
                key={grupoComplemento.id}
              />
            );
          })}

          <QuantityContainer>
            <IconButton
              color="primary"
              aria-label="remover
                item"
              component="span"
              disabled={quantity === 1}
              onClick={() => setQuantity(quantity - 1)}
              style={{ color: theme.primary }}
            >
              <RemoveCircleIcon />
            </IconButton>
            <Typography className={classes.quantity}>{quantity}</Typography>
            <IconButton
              color="primary"
              aria-label="adicionar item"
              component="span"
              disabled={
                selectedSize?.estoque &&
                selectedSize.estoque.ativo &&
                selectedPizzaOptionsTemp.length > 0 &&
                selectedPizzaOptionsTemp
                  .map(
                    (product) =>
                      product.quantidade * (quantity + 1) >
                      product.produto.produto_grades[0].estoque.estoque_atual,
                  )
                  .reduce((prev, next) => prev || next)
              }
              onClick={() => setQuantity(quantity + 1)}
              style={{ color: theme.primary }}
            >
              <AddCircleIcon />
            </IconButton>
          </QuantityContainer>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={addToCarts}
            disabled={
              (selectedSize?.estoque &&
              selectedSize.estoque.ativo &&
              selectedSize.estoque.estoque_atual == 0) ||
              (product.vitrine && selectedSize && numSelectedFlavors < selectedSize.qtd_sabores)
            }
            style={{
              marginTop: '20px',
              width: '80%',
              height: '46px',
              alignSelf: 'center',
              backgroundColor:
                (selectedSize?.estoque &&
                selectedSize.estoque.ativo &&
                selectedSize.estoque.estoque_atual == 0) ||
                (product.vitrine && selectedSize && numSelectedFlavors < selectedSize.qtd_sabores)
                  ? '#757575'
                  : theme.primary,
            }}
          >
            COLOCAR NA SACOLA
          </Button>

          {!product.vitrine && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setStep(1)}
              style={{
                marginTop: '20px',
                width: '80%',
                height: '46px',
                alignSelf: 'center',
                border: `1px solid ${theme.primary}`,
                color: theme.primary,
                background: 'transparent',
              }}
            >
              Voltar
            </Button>
          )}

          <Button
            variant="contained"
            onClick={onClose}
            style={{
              border: `1px solid ${theme.primary}`,
              color: theme.primary,
              background: 'transparent',
              height: '46px',
              width: '80%' ,
              marginTop: '10px'
            }}
          >
            Pular Sugestão
          </Button>
        </InformationsContainer>
      )}
    </Container>
  );
}
