import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0px 10px 8px 0px;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  flex-grow: 1;
  align-self: center;
  background-color: #fefefe;
  @media (min-width: 700px) {
    max-width: 50%;
  }
`;

export const Scroll = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  @media (max-width: 960px) {
    height: calc(100vh - 90px);
  }
`;

export const Header = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  min-height: max-content;
  div {
    display: -webkit-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const Body = styled.section`
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex-grow: 1;
  overflow: auto;
`;

export const Imagem = styled.div<{ image: string }>`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  margin-top: 12px;

  div {
    height: 192px;
    width: 100%;
    border-radius: 0.5rem;

    background-image: url(${(props) => props.image}),
      url(${process.env.REACT_APP_RETAGUARDA_API_FILES}no_image.png);
    background-position: center;
    background-size: cover;
  }

  @media (min-width: 700px) {
    display: none;
  }

  @media (max-width: 960px) {
    margin-top: 10px;
  }
`;

export const NumeroSabores = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;

  margin-top: 20px;
  padding-left: 20px;
  min-height: max-content;
`;

export const Quantidade = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
`;

export const Footer = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0.3rem 0.3rem;
  background-color: #fefefe;
  margin-top: 10px;
  padding: 15px 0px;
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 700px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 1px;
  padding-top: 1px;
  max-height: 88%;
  @media (min-width: 700px) {
    max-height: 80%;
  }
`;
