import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Informations = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Quantidade = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;