import styled from 'styled-components';

export const IconSpan = styled.span<{ selected: boolean }>`
  cursor: pointer;

  margin: 10px 0 0;

  font-size: 16px;
  font-weight: ${(props) => (props.selected ? 'bold' : '600')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => (props.selected ? '#0c65ea' : '#c4c4c4')};

  @media (max-width: 640px) {
    font-size: 14px;
    padding: 0px;
  }

  @media (max-width: 960px) {
    margin: 6px 0 0;
  }
`;
export const RoundIconHolder = styled.button<{ selected: boolean }>`
  width: 50px;
  height: 50px;
  flex-grow: 0;
  border-radius: 50%;
  border: none;

  background-color: ${(props) => (props.selected ? '#0c65ea' : '#c4c4c4')};
`;

export const ItemImage = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 680px) {
    width: 85px;
    height: 85px;
  }
`;
export const ItemName = styled.p`
  margin-top: 15px;
  word-break: break-word;
  font-size: 1rem;
  text-transform: capitalize;

  @media (max-width: 680px) {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 7.5px;
  }
`;

export const ItemWorkingHours = styled.p<{ isOpen: boolean }>`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.isOpen ? '#269660' : '#fa0e0e')};
  text-align: center;
  margin-top: 5px;
`;

export const ItemDelivery = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: #269660;
  text-align: center;
`;

export const ItemContainer = styled.div`
  cursor: pointer;

  background: transparent;
  border: none !important;

  min-width: 80px;

  border-radius: 10px;
  padding: 15px 0px;

  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  width: 130px;

  @media (max-width: 680px) {
    padding: 10px 0px;
    width: 85px;
  }

  &:hover {
    background-color: #f8f6f6;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    opacity: 1;

    ${ItemName} {
      font-weight: 500;
    }
  }

  &:focus {
    background-color: #f8f6f6;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    opacity: 1;

    ${ItemName} {
      font-weight: bold;
    }
  }
`;

export const CustomFigure = styled.figure<{ urlImage: string }>`
  width: 112px;
  height: 112px;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  background-image: url(${(props) => props.urlImage});
  background-size: cover;
  background-position: center;
  object-fit: cover;
  align-self: center;
  overflow: auto;

  @media (max-width: 680px) {
    width: 85px;
    height: 85px;
  }
`;

export const CustomImage = styled.div<{ urlImage: string }>`
  background-image: url(${(props) => props.urlImage});
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
`;
