import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonClose: {
    width: 40,
    height: 40,
    padding: 5,
  },
  iconClose:{
    color: '#b11111'
  },
}));
