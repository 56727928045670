import React, { useEffect } from 'react';

import Typography from '@material-ui/core/Typography';

import {
  Container,
  Option
 } from './styles';
 import stylesMaterial from './stylesMaterial';

interface SelectorRMProps{
  valueRM: string;
  selectRM: (RM: string) => void;
  isClothesStore: boolean;
  hasDelivery: boolean;
  hasWithdraw: boolean;
}

export const SelectorRM: React.FC<SelectorRMProps> = (props) => {
  const { valueRM, selectRM, isClothesStore, hasDelivery, hasWithdraw } = props;

  const classes = stylesMaterial();

  useEffect(() => {
    if(hasDelivery && !hasWithdraw && valueRM === "retirada") {
      selectRM("delivery");
    } else if(!hasDelivery && hasWithdraw && valueRM === "delivery") {
      selectRM("retirada");
    }
  }, [hasDelivery, hasWithdraw, valueRM]);

  return (
    <Container>
      <Option
        active={valueRM === "delivery"}
        isDisabled={!hasDelivery}
        onClick={hasDelivery ? () => selectRM("delivery") : () => {}}
      >
        <Typography color="inherit" className={classes.optionText}>
          { isClothesStore ? 'Envio' : 'Delivery' }
        </Typography>
      </Option>
      <Option
        active={valueRM === "retirada"}
        isDisabled={!hasWithdraw}
        onClick={hasWithdraw ? () => selectRM("retirada") : () => {}}
      >
        <Typography color="inherit" className={classes.optionText}>
          Retirar
        </Typography>
      </Option>
    </Container>
  );
}
