import React, { useContext } from "react";

import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import userApi from '../../services/userApi';
import { AuthContext } from '../../hooks/provideAuth';

import {
  Container,
  Header,
  Wrapper,
  Body } from './styles';
import stylesMaterial from './stylesMaterial';
import { useTheme } from "../../hooks/useThemeState";

interface ChangePasswordModalProps{
  handleCloseChangePasswordModal: () => void;
  openChangePasswordModal: boolean;
  changePasswordSucess: () => void;
}

interface LoginInfo {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const defaultValues = {
  password: "",
  newPassword: "",
  confirmNewPassword: ""
};

export function ChangePasswordModal(props: ChangePasswordModalProps) {
  const {
    handleCloseChangePasswordModal,
    openChangePasswordModal,
    changePasswordSucess
  } = props;

  const { theme } = useTheme()
  const classes = stylesMaterial();
  const [open, setOpen] = React.useState(openChangePasswordModal);
  const toast_config = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const { register, handleSubmit, getValues, errors } = useForm({ defaultValues });
  const { state, dispatch } = useContext(AuthContext);

  const onSubmit = (data: LoginInfo) => {
    userApi.changePassword(data.password, data.newPassword, state.token).then((res) => {
      changePasswordSucess();
      handleClose();
    }).catch((error) => {
      toast.error('Falha na alteração da senha.', toast_config);
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseChangePasswordModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal"
    >
      <Container>
        <Wrapper>
          <Header>
            <IconButton
              aria-label="arrow-down"
              onClick={handleClose}
              className={classes.buttonArrow}
            >
              <ArrowBackIosIcon
                fontSize='large'
                style={{ color: '#B22222' }}
                className={classes.iconArrow}
              />
            </IconButton>
            <div>
              <Typography
                variant="h6"
                className={classes.loginTitle}
              >
                Alterar Senha
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.buttonClose}
            >
              <CloseIcon
                style={{ color: '#B22222' }}
                className={classes.iconClose}
              />
            </IconButton>
          </Header>
          <Divider />

          <Body>
            <Typography
              variant="h6"
              className={classes.welcomeTitle}
            >
              Digite sua senha antiga e sua nova senha, então confirme a alteração.
            </Typography>

            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className={classes.form}
            >

              <TextField
                required
                id="password-input"
                type="password"
                name="password"
                label="Senha atual"
                autoComplete="current-password"
                placeholder="Digite sua senha"
                variant="outlined"
                className={classes.passwordInput}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite sua senha",
                  },
                }
              )}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <TextField
                required
                id="newPassword"
                name="newPassword"
                type="password"
                label="Nova senha"
                placeholder="Digite sua nova senha"
                variant="outlined"
                className={classes.passwordInput}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Por favor digite sua nova senha",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="newPassword"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <TextField
                required
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                label="Confirme a nova senha"
                placeholder="Repita a nova senha"
                variant="outlined"
                className={classes.passwordInput}
                inputRef={register({
                  validate: {
                    passwordEqual: value => (value === getValues().newPassword) || 'Senhas diferentes',
                  }
                })}
              />
              <ErrorMessage
                errors={errors}
                name="confirmNewPassword"
                render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: theme.primary,
                  color: '#fefefe',
                  height: '45px',
                  marginTop: '60px'
                }}
                className={classes.buttonWithIcon}
              >
                <CheckIcon />
                Salvar
              </Button>
            </form>
          </Body>
        </Wrapper>

      </Container>

    </Modal>
  )
}
