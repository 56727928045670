import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  @media(max-width: 959px){
    width: 90vw;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  padding: 25px 10px 25px 10px;

  justify-items: center;
  align-items: center;

  @media (max-width: 875px){
    grid-template-columns: 1fr;
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 876px) and (max-width: 960px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 961px) and (max-width: 1280px){
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1281px) and (max-width: 1601px){
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const AddAddressButton = styled.div`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 150px;
`;