import styled from 'styled-components';


export const OptionAnchor = styled.a`
    text-decoration: none;
    display: block;
`;

export const OptionLabel = styled.div`
    display: inline-box;
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      white-space: nowrap;
      font-size: 0.87rem;
      font-size: '0.87rem';
      font-weight: normal;
      color: black;
      font-family: sans-serif;

      overflow: hidden;
      text-overflow: ellipsis;
    }
`;

export const OptionContainer = styled.div`
    width: 100px;
    overflow: hidden;
    border-radius: 0px;
    cursor: pointer;
    text-align: center;
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757575;
    margin-right:8px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media(max-width:540px) {
        font-size: 12px;
        margin-right:5px;
    }

    &:hover {
        animation: slide-fwd-center 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        @keyframes slide-fwd-center {
            0% {
                transform: translateZ(0);
            }
            100% {
                transform: translateZ(160px);
            }
        }
        cursor: pointer;
        /* background-color: #dbe6f6; */
        ${OptionLabel} {
            opacity: 0.8;
            color: #757575;
        }
    }
`;

export const LoadingLabel = styled.div`
    margin: 0px 5px;
`;

export const BoxCard = styled.div`
    position: 'relative';
    height: 50;
    width: 50;
    border-radius: 50%;
    
    &:hover {
      z-index: 1;
      
      &:imageBackdrop {
        opacity: 0.15;
      } 
    }
    
    &:focusVisible {
      z-index: 1;
      
      &:imageBackdrop {
        opacity: 0.15;
      }
    }
`

export const Image = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 50%;
`