import styled from 'styled-components';

export const GridItem = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  text-align: start;
  margin: auto;
  margin-top: 5px;
  border-radius: 0.3rem;
`;

export const Informations = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-right: 10px;
`; 

export const Title = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;

  height: 36px;
`; 

export const Aditionals = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 10px;

  flex: 1;
`;

export const ComponentsList = styled.ul`
  list-style-type: disc;
  padding-left: 17px;

  max-width: 325px;
  @media(max-width: 960px){
    max-width: calc(100vw - 148px);
  }
  
  li{
    color: #9e9e9e;
    font-size: 0.95rem;
  }
`;

export const Price = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;

  margin-top: 5px;
`;

export const Body = styled.div`
  display: -webkit-flex;
  flex-direction: column;

  div{
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Imagem = styled.div<{ image: string }>`
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  margin-right: 9px;
  flex: 1;

  div{
    height: 144px;
    width: 144px;
    border-radius: 0.2rem;

    box-shadow: 9px 10px 5px -7px rgba(0,0,0,0.17);

    background-image: url(${props => props.image}), url(${process.env.REACT_APP_RETAGUARDA_API_FILES}no_image.png);
    background-position: center;
    background-size: cover;

    @media(max-width: 960px){
      height: 100px;
      width: 100px;
      box-shadow: 9px 10px 5px -8px rgba(0,0,0,0.17);
    }
  }
`;