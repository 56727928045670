import React from "react";
import {Helmet} from "react-helmet";

const HelmetComponent: React.FC = () => {
  return (
    <Helmet>
      <meta name="google-signin-client_id" content={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`} />
    </Helmet>
  );
}

export default HelmetComponent;